import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
 
import {
    Button,
    Input,
    Modal,
    Container,
    Row, Col
} from "reactstrap";

class RecoveryModal extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }

    }

    render() {
        const { isActive, handleToggle,handleYes,data } = this.props;
        return (
            <Translation>
                {t => (
                    <>
                        <Modal
                            className="modal-dialog-centered"
                            isOpen={isActive}>
                            <div className="modal-header pageBusy-header">
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body pageBusy-container">
                                <Container>
                                    <Row className={"pageBusy-container-row"}>
                                        <p className={"pageBusy-title"}> {t("TITLE_RECOVERY")} </p>
                                    </Row>
                                    <Row className={"pageBusy-container-row"}>
                                        <p className={"pageBusy-message"}>
                                            {t("AUTOSAVE_DATA_QUESTION")}
                                        </p>
                                    </Row>
                                </Container>
                            </div>
                            <div className="modal-footer pageBusy-footer">
                                <Button
                                    color="white"
                                    size={"sm"}
                                    data-dismiss="modal"
                                    disabled={this.state.loading}
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    {t("BUTTON_NO")}
                                </Button>

                                <Button color="primary" size={"sm"} type="button" onClick={() => handleYes(data)}>
                                    {t("BUTTON_YES")}
                                </Button>
                            </div>
                        </Modal>

                    </>

                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { currentstatesReducer } = state;
    const { pageStatus } = currentstatesReducer;
    return {
        pageStatus,
    }
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(RecoveryModal)
