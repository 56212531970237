import {
    componentConstants
} from "../_constants";
import Emitter from "./emitter";

export function updateComponent(newComponent) {
    Emitter.emit("HANDLE_OPEN_FIELD_TAP", "");
    return {
        type: componentConstants.UPDATE_COMPONENT,
        payload: {
            component: newComponent,
        }
    }
}
export function removeComponent() {
    return {
        type: componentConstants.REMOVE_COMPONENT,

    }
}
export function addFavoriteComponent(_name) {
    return {
        type: componentConstants.ADD_FAV_COMPONENT,
        payload: {
            name: _name,
        }
    }
}

export function updateComponentContext(newContext, handleDelete, handleDublicate) {
    return {
        type: componentConstants.CONTEXT_COMPONENT,
        payload: {
            componentContext: {
                newContext: newContext,
                handleDelete: handleDelete,
                handleDublicate: handleDublicate
            },
        }
    }
}

export function setComponentList(list) {
    return {
        type: componentConstants.SET_COMPONENT_LIST,
        payload: {
            componentList : list,
        },
    }
}

export function setMyComponentList(list) {
    return {
        type: componentConstants.SET_MYCOMPONENT_LIST,
        payload: {
            myComponentList : list,
        },
    }
}