import React, { Component } from 'react'
import PropTypes, { array } from 'prop-types';

import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import i18n from 'i18n';
import Emitter from "_actions/emitter";

import SplitterLayout from 'react-splitter-layout';
//import 'react-splitter-layout/lib/index.css';

import { CheckTreePicker, Nav, Dropdown, Alert, Loader, Steps, Panel, Navbar, ButtonGroup, Message, Icon, Popover, Whisper, Tooltip } from 'rsuite';
//import MonacoEditor from 'react-monaco-editor';
import { loader } from '@monaco-editor/react';

import { DynamicFunctionService } from '_services'
import { setDynamicFuncs } from '_actions'

import {
    Button,
    Modal,
    Row,
    Col,
} from "reactstrap";

class CustomScript extends Component {
    constructor(props) {
        super(props)
        this.state = {
            script: "",
            loading: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        const { isActive,data } = this.props;
        if (prevProps.isActive !== isActive) {
            if (isActive) {
                this.setState({
                    script: data.script,
                    myDetail: null,
                    loading: false,

                })
                this.handleGenerateScriptEditor("script");

            }

        }
    }


    handleGenerateScriptEditor = async (_where) => {
        const that = this;
        //componentNames
        let _pages = {}; // mevcut sayfa dışında ki sayfalar
        let _pages2 = {};


        await that.props.pages.map(x => {
            if (that.props.page.pageName !== x.pageName) {
                _pages[x.pageName] = "string";
                _pages2[x.pageName] = "string";
            } else {
                let _cNames = {};
                that.props.componentNames.forEach((item, key) => {
                    _cNames[item.componentName] = "string";
                })
                _pages2[x.pageName] = _cNames;
            }
        });


        let _httpObjects = {}
        await that.props.httpObjects.dynamicValue.map(x => {
            _httpObjects[x.httpObjectName] = "string"
        });

        loader
            .init()
            .then(monaco => {
                let keys = [];
                monaco.languages.typescript.javascriptDefaults.setExtraLibs({})

                monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
                    //target: monaco.languages.typescript.ScriptTarget.ES6,
                    allowNonTsExtensions: true,
                    noLib: true,
                    //  target: monaco.languages.typescript.ScriptTarget.ES2015,
                    lib: []
                });
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var pages =',
                    JSON.stringify(_pages2)
                ].join('\n'));

                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var httpObjects =',
                    JSON.stringify(_httpObjects)
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var toastTypes = {success:"success",warning:"warning",danger:"danger"}',
                ].join('\n'));



                // ! FIX EVENTS START ----------------------------------------------

                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var executeHttpObject =(httpObjects:name) =>{',
                    '}'
                ].join('\n'));

                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var makeCall =(phone:string) =>{',
                    '}'
                ].join('\n'));


                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var turnBackPage =() =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var openPage =(page:pages) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var openGroupPage =(page:pages) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var openHamburgerMenu =(page:pages) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var openModalPage =(page:pages) =>{',
                    '}'
                ].join('\n'));

                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var GetDynamicStore =(keyName) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var SetDynamicStore =(keyName,value) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var SetComponentProperties =(page:pages,componentName,object:Object) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var Toast =(message:string,type:toastTypes) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var OpenShare =(title:string,message:string,url:string) =>{',
                    '}'
                ].join('\n'));


                // ! FIX EVENTS END ----------------------------------------------

                let _monaco = monaco.editor.create(document.getElementById(_where), {
                    loading: <Loader />,
                    //onChange: that.onChangeBody,
                    value: that.state[_where],
                    language: 'javascript',

                });

                _monaco.onDidChangeModelContent(function (e, v) {
                    that.setState({
                        [_where]: _monaco.getValue()
                    })
                });



            });

    }



    render() {
        const { isActive, handleToggle, data } = this.props;
        const { loading, myDetail } = this.state;

        return (
            <Translation>
                {t => (
                    <Modal
                        isOpen={isActive}
                        size="xl"
                        scrollable={true}
                        className={"modal-dialog-centered httpobject-modal"}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="CheckProjectModal">
                                {t("MODAL_TITLE_FUNC_EDITOR")}
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => handleToggle()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <Row>
                                    <Col sm={12} >
                                        {
                                            <>
                                                <span className={"script-text script-text-blue"}>{"function "}
                                                    <span className={"script-text script-text-black"}>{data?data.name:""}</span>
                                                    <span className={"script-text script-text-black"}>{"("}</span>
                                                    <span className={"script-text script-text-black"}>{") {"}</span>
                                                </span>
                                                <div className={"custom-fade-in"} id={"script"} style={{ height: "40vh", border: "1px solid #dee2e6" }} />
                                                <span className={"script-text script-text-black"}>{" }"}</span>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <ButtonGroup className={"httpobject-status"} style={{ paddingTop: "10px" }}>
                                <Button className={"custom-fade-in"} size="sm" onClick={() => handleToggle()} disabled={loading}>
                                    {t("BUTTON_CANCEL")}
                                </Button>
                                <Button color={"primary"} className={"custom-fade-in"} size="sm" onClick={()=>{
                                    this.props.handleSave({
                                        ...data,
                                        script:this.state.script,
                                    })
                                }} disabled={loading}>
                                    {t("BUTTON_SAVE")}
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Modal>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { present } = editorReducer;
    const { pages, page } = present;

    const { component } = componentReducer;
    const { componentNames, environments, httpObjects, currentProjectId, dynamicFunctions } = currentstatesReducer;

    return {
        page,
        pages,
        componentNames,
        component,
        environments,
        httpObjects,
        currentProjectId,
        dynamicFunctions,
    };
}
const mapDispatchToProps = {
    setDynamicFuncs,
    //  onUpdateComponent: updateComponent,

}
export default connect(mapStateToProps, mapDispatchToProps)(CustomScript)



