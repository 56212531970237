import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { Alert } from 'rsuite';
import {
    Button,
    Row,
    Col,
    ListGroup,
} from "reactstrap";
import QRCode from "react-qr-code";
import { setUIEditorStatus } from '_actions';

import HttpObjectModal from 'components/Modals/HttpObjectModal';
import ManageEnvironmentsModal from 'components/Modals/ManageEnvironmentsModal';


class Functions extends Component {

    constructor(props) {
        super(props)

        this.state = {

        }
    }


    render() {
        const { currentPage, currentProjectId } = this.props;

        return (
            <Translation>
                {t => (
                    <>
                        {t("RIGHT_SIDE_TITLE_FUNCTIONSETTINGS")}
                        <hr />
                        {
                            (this.props.currentProjectId && this.props.pageStatus.status) ? (
                                this.props.component ? (
                                    <>
                                        <Row>
                                            <Col style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                color: "#cfcfd0",
                                            }}>
                                                <i style={{ fontSize: "80px" }} className="far fa-grin-tongue-wink"></i>
                                                <p>Developing...</p>

                                            </Col>
                                        </Row>

                                    </>) :
                                    <p className="text-muted">
                                        {t("MESSAGE_PROPERTY_NOT_SELECTED_COMPONENT")}
                                    </p>
                            ) :
                                <p className={"text-muted"}>
                                    {t("PAGE_BUSY_ERROR")}
                                </p>

                        }

                    </>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;

    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;

    const { pageStatus, currentProjectId } = currentstatesReducer;


    return {
        componentReducer,
        component,
        editor,
        pageStatus,
        currentProjectId
    };
}
const mapDispatchToProps = {
    setUIEditorStatus,
}
export default connect(mapStateToProps, mapDispatchToProps)(Functions)


