import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";

import {
    Button,
    FormGroup,
    Form,
    Row,
    Col,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    UncontrolledTooltip
} from "reactstrap";

import { updateComponent,updateList } from '_actions';
import { findObjUpdate } from 'assets/util';

class BorderSize extends Component {

    constructor(props) {
        super(props)
        const { component } = props;
        const  {object}  = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
       
        const { style, value } = object.props;

        this.state = {
            isAll: true,
            borderTopWidth: component && parseInt(style["borderTopWidth"] == undefined ? "0px" : style["borderTopWidth"]),
            borderBottomWidth: component && parseInt(style["borderBottomWidth"] == undefined ? "0px" : style["borderBottomWidth"]),
            borderRightWidth: component && parseInt(style["borderRightWidth"] == undefined ? "0px" : style["borderRightWidth"]),
            borderLeftWidth: component && parseInt(style["borderLeftWidth"] == undefined ? "0px" : style["borderLeftWidth"]),
        }

    }

    checkIsEqual = () => {
        const { borderTopWidth, borderBottomWidth, borderRightWidth, borderLeftWidth } = this.state;
        if (borderTopWidth == borderBottomWidth == borderRightWidth == borderLeftWidth) {
            return true;
        } else {
            return false;
        }
    }


    setProperties = () => {
        let _clone = Object.assign({}, this.props.component);
        const that = this;
        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props.style = {
                ..._clone.object.components[0].object.props.style,
                borderTopWidth: that.state.borderTopWidth + "px",
                borderBottomWidth: that.state.borderBottomWidth + "px",
                borderRightWidth: that.state.borderRightWidth + "px",
                borderLeftWidth: that.state.borderLeftWidth + "px",            }
        } else {
            _clone.components[0].object.props.style = {
                ..._clone.components[0].object.props.style ,
                borderTopWidth: that.state.borderTopWidth + "px",
                borderBottomWidth: that.state.borderBottomWidth + "px",
                borderRightWidth: that.state.borderRightWidth + "px",
                borderLeftWidth: that.state.borderLeftWidth + "px",            }
        }

        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);
    }


    handleChange = (event) => {
        const { target } = event;
        const { name } = target;
        let value = (event.target.validity.valid) ? event.target.value : (this.state.isAll ? this.state.borderTopWidth : this.state[name]);
        const that = this;

        if (value.length < 1) { value = "0" }

        value = parseInt(value);

        if (this.state.isAll) {
            this.setState({
                borderTopWidth: value,
                borderBottomWidth: value,
                borderRightWidth: value,
                borderLeftWidth: value,
            }, () => {
                that.setProperties();
            });
        } else {
            this.setState({
                [name]: value,
            }, () => {
                that.setProperties();
            });
        }


    }

    componentDidMount() {
        this.setState({
            isAll: this.checkIsEqual(),
        })
    }
    componentDidUpdate = (prevProps) => {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        const { style, value } = object.props;

        if (prevProps.component != component) {
            this.setState({
                //isAll: this.checkIsEqual(),
                borderTopWidth: parseInt(style["borderTopWidth"] == undefined ? "0px" : style["borderTopWidth"]),
                borderBottomWidth: parseInt(style["borderBottomWidth"] == undefined ? "0px" : style["borderBottomWidth"]),
                borderRightWidth: parseInt(style["borderRightWidth"] == undefined ? "0px" : style["borderRightWidth"]),
                borderLeftWidth: parseInt(style["borderLeftWidth"] == undefined ? "0px" : style["borderLeftWidth"]),
            })
        }
    }
    handleChangeType = () => {
        this.setState({
            isAll: !this.state.isAll,
            borderTopWidth: !this.state.isAll ? this.state.borderTopWidth : this.state.borderTopWidth,
            borderBottomWidth: !this.state.isAll ? this.state.borderTopWidth : this.state.borderBottomWidth,
            borderRightWidth: !this.state.isAll ? this.state.borderTopWidth : this.state.borderRightWidth,
            borderLeftWidth: !this.state.isAll ? this.state.borderTopWidth : this.state.borderLeftWidth,
        }, () => {
            this.setProperties();
        })
    }

    onKeyDown = (event) => {
        if (event.key === 'Enter') { event.preventDefault() }
    }



    render() {
        const { component } = this.props;
        const { isAll } = this.state;
        return (
            <Translation>
                {t => (
                    <div>

                        <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                                <Button onClick={this.handleChangeType} size="sm" color="primary" outline={!isAll} type="button">
                                    <i className="far fa-square"></i>
                                </Button>
                                <Button onClick={this.handleChangeType} size="sm" color="primary" outline={isAll} type="button">
                                    <i className="fas fa-expand"></i>
                                </Button>
                            </InputGroupAddon>
                            {
                                isAll ? (
                                    <Input
                                        pattern="[0-9]*"
                                        name={"borderLeftWidth"}
                                        value={this.state.borderLeftWidth}
                                        onBlur={() => { }}
                                        onKeyDown={this.onKeyDown.bind(this)}
                                        onInput={this.handleChange.bind(this)}
                                        className="form-control-sm"
                                        placeholder="" type="text" />
                                ) : (
                                        <>
                                            <Input
                                                id={"border-left-weigth"}
                                                name={"borderLeftWidth"}
                                                value={this.state.borderLeftWidth}
                                                onKeyDown={this.onKeyDown.bind(this)}
                                                onBlur={() => { }}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                className="form-control-sm"
                                                placeholder="" type="text" />
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target="border-left-weigth">
                                                {t("PROPERTY_LAYOUT_BORDER_LEFT")}
                                            </UncontrolledTooltip>
                                            <Input
                                                id={"border-right-weigth"}
                                                name={"borderRightWidth"}
                                                value={this.state.borderRightWidth}
                                                onKeyDown={this.onKeyDown.bind(this)}
                                                onBlur={() => { }}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                className="form-control-sm"
                                                placeholder="" type="text" />
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target="border-right-weigth">
                                                {t("PROPERTY_LAYOUT_BORDER_RIGHT")}
                                            </UncontrolledTooltip>
                                            <Input
                                                id={"border-top-weigth"}
                                                name={"borderTopWidth"}
                                                value={this.state.borderTopWidth}
                                                onKeyDown={this.onKeyDown.bind(this)}
                                                onBlur={() => { }}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                className="form-control-sm"
                                                placeholder="" type="text" />
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target="border-top-weigth">
                                                {t("PROPERTY_LAYOUT_BORDER_TOP")}
                                            </UncontrolledTooltip>
                                            <Input
                                                id={"border-bottom-weigth"}
                                                name={"borderBottomWidth"}
                                                value={this.state.borderBottomWidth}
                                                onKeyDown={this.onKeyDown.bind(this)}
                                                onBlur={() => { }}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                className="form-control-sm"
                                                placeholder="" type="text" />
                                            <UncontrolledTooltip
                                                delay={3}
                                                placement="top"
                                                target="border-bottom-weigth">
                                                {t("PROPERTY_LAYOUT_BORDER_BOTTOM")}
                                            </UncontrolledTooltip>
                                        </>
                                    )
                            }



                        </InputGroup>

                    </div>

                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer,editorReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        component,
        editor
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
}
export default connect(mapStateToProps, mapDispatchToProps)(BorderSize)
