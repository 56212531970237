import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Translation } from "react-i18next";
import { ReactSortable, ItemInterface } from "react-sortablejs";
import classnames from "classnames";
import { Popover, Whisper, Alert } from 'rsuite';



import { createId } from 'assets/util';

import { ComponentService, CustomComponentService } from '_services';

import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import Emitter from "_actions/emitter";

import {
    sweetAlertActions,
    setMyComponentList,
} from "_actions";


import {
    Collapse,
    NavItem,
    NavLink,
} from "reactstrap";
import EditComponent from '../Modals/EditComponent';


class SidebarNavItemCustom extends Component {
    constructor(props) {
        super(props)

        //  this.props.data.data = this.props.data.data.map(obj => ({ ...obj, _id: createId() }))
        this.state = {//, _id: createId() 
            list: this.props.data.map(obj => ({ ...obj, _id: createId() })), //Component listesinde ki elemanlar için kendi uniq idlerinimizi oluşturuyoruz.
            isActive: false, // Menü açık/değil durumu
            updateComponent: false,
            selected: {},
        }
    }



    handleUpdateComponentModal = (_data = {}) => {
        this.setState({
            updateComponent: !this.state.updateComponent,
            selected: _data,

        })
    }

    removeComponent = (_id) => {
        const { currentPage } = this.props;
        CustomComponentService.RemoveCustomComponent(_id).then(data => {
            this.getMyComponentList();
        }).catch(err => {
            debugger
            // let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            // Emitter.emit("SHOW_WARNING_MODAL", data);
        });

    }

    getMyComponentList = () => {
        const { setMyComponentList } = this.props;
        CustomComponentService.GetMycustomComponents().then(data => {

            setMyComponentList(data.dynamicValue);
        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
        });
    }

    _handleRemove = (_id) => {

        Emitter.on("handleYes", newValue =>
            this.removeComponent(_id)
        );
        sweetAlertActions.SHOW_ASK(
            "",
            "",
            "",
            i18n.t("MESSAGE_DELETE_COMPONENT_FROM_EDITOR_SUBTITLE"),
            i18n.t("MESSAGE_DELETE_COMPONENT_FROM_EDITOR_SUCCESS"),
            i18n.t("MESSAGE_DELETE_COMPONENT_FROM_EDITOR_UNSUCCESS")
        );
    }

    componentDidMount() {
        Emitter.on("handleNo", newValue =>
            Emitter.off("handleYes")
        );
        

    };
    componentWillUnmount() {
        Emitter.off("handleYes");
    }

    handleToggleMenu = () => {
        this.setState({
            isActive: !this.state.isActive,
        })
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
            this.setState({
                list: this.props.data.map(obj => ({ ...obj, _id: createId() })),
            })
        }

        //this.props.data.data =  this.props.data.data.map(obj => ({ ...obj, _id: createId() }))
    }


    _item = (item, key, t) => {
        // debugger
        const { sidebarStatus } = this.props;

        let trigger = null;
        const triggerRef = ref => (trigger = ref);
        const showPopover = () => trigger.show();
        const hidePopover = () => trigger.hide();
        return (
            <Whisper key={key+item.componentName} placement="rightStart"  triggerRef={triggerRef} trigger="active" enterable speaker={
                <Popover>
                    {
                        // popoverIsActive &&
                        <img src={require('assets/gif/button.gif')} alt="loading..." className={"custom-popover-gif"} />
                    }
                    <div className={"custom-popover-content"}>
                        <h5>{item.componentName}</h5>
                        <span>Several buttons which can be used to navigate on your app screens</span>
                        <div className={"custom-popover-more"}>
                            <a onClick={() => {
                                hidePopover();
                                this.handleUpdateComponentModal(item)
                                //Alert.warning(t("ERROR_ITS_NOT_WORKING"), 1000)
                            }}>{t("POPOVER_COMPONENT_BUTTON_UPDATE")}</a>
                            <a onClick={() => {
                                this._handleRemove(item.xid)
                            }}>
                                {t("POPOVER_COMPONENT_BUTTON_REMOVE")}</a>
                        </div>
                    </div>
                </Popover>
            }>
                <div className={(sidebarStatus ? ("componentItem") : "componentItemMini") + " noselect"} key={item._id ? item._id : item.name} style={{
                    cursor: "pointer",
                }}>
                    <div className={"componentItemIcon"}>
                        <i className={item.object.icon} />
                    </div>
                    <div className={"componentItemText"}>
                        <span>{item.componentName}</span>
                    </div>

                </div>
            </Whisper>
        )
    }

    render() {
        const { isActive, list } = this.state;
        const { sidebarStatus, currentProjectId, pageStatus } = this.props;

        return (
            <Translation>
                {t => (
                    <>
                        <NavItem>
                            <NavLink
                                data-toggle="collapse"
                                href="#detail"
                                aria-expanded={isActive}
                                disabled={this.props.data ? (this.props.data.length > 0 ? false : true) : true}
                                className={classnames({
                                    active: sidebarStatus && isActive,
                                }, "nav-link2 " + (isActive ? "nav-link2-active" : ""))}
                                onClick={e => {
                                    e.preventDefault();
                                    this.handleToggleMenu();
                                }}
                            >
                                <i className={"fas fa-user text-white " + (this.props.data.length > 0 ? "category-icon" : "category-icon-null")} />
                                <span className="nav-link-text category-name">{t("TITLE_COMPONENT_CATEGORTY_MYCOMPONENTS")}</span>
                            </NavLink>
                            <Collapse isOpen={isActive}>
                                <div className="navbar-inner category-item">
                                    <div className="ct-example-row">
                                        <div className={"row"}>
                                            <ReactSortable
                                                className={(sidebarStatus ? "componentItemContainer" : "componentItemContainerMini")}
                                                group={{
                                                    name: 'Kurnaz',
                                                    pull: 'clone',
                                                    put: false // Do not allow items to be put into this list
                                                }}
                                                chosenClass={"sortable-chosen-sidebar"}
                                                // forceFallback= {true}
                                                sort={false} // To disable sorting: set sort to false
                                                list={list.map(x => ({
                                                    ...x,
                                                    xid: x.id != null ? x.id : x.xid,
                                                    id: null,
                                                    _id: createId(),
                                                    object: {
                                                        ...x.object,
                                                        id: x.id,
                                                        _id: createId(),
                                                        componentName: x.componentName,
                                                        components: x.object.components.map((c, i) => i == 0 ? {
                                                            ...c,
                                                            object: {
                                                                ...c.object,
                                                            }
                                                        } : c),
                                                    }
                                                }))}
                                                clone={item => ({
                                                    ...item,
                                                    id: null,
                                                    _id: createId(),
                                                    object: {
                                                        ...item.object,
                                                        id: item.id,
                                                        componentName: item.componentName,
                                                        _id: createId(),
                                                        components: item.object.components.map((c, i) => i == 0 ? {
                                                            ...c,
                                                            object: {
                                                                ...c.object,
                                                                props: {
                                                                    ...c.object.props,
                                                                    // value: "Sample text..."
                                                                }
                                                            }

                                                        } : c),
                                                    }
                                                })}
                                                setList={newState => { this.setState({ list: newState }) }}
                                                swapThreshold={0.02}
                                                invertSwap={false}
                                                easing={"cubic-bezier(1, 0, 0, 1)"}
                                                animation="0"
                                            >
                                                {this.props.data &&
                                                    (list.map((item, key) => (
                                                        this._item(item, key, t)
                                                    )))
                                                }

                                            </ReactSortable>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </NavItem>
                        <EditComponent data={this.state.selected} isActive={this.state.updateComponent} handleToggle={this.handleUpdateComponentModal} />
                    </>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { editorReducer, currentstatesReducer,componentReducer } = state;
    const { present } = editorReducer;
    const { editor, pages, currentPage, page } = present;

    const { pageStatus, currentProjectId } = currentstatesReducer;
    const { componentList, myComponentList } = componentReducer;

    return {
        editor,
        pages,
        currentPage,
        page,
        pageStatus,
        currentProjectId,
        myComponentList
    };
}
const mapDispatchToProps = {
    setMyComponentList,

}
export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItemCustom)