import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateComponent,updateList } from '_actions';
import { findObjUpdate } from 'assets/util';

import GalleryModal from "components/Modals/GalleryModal";

class ImageProperty extends Component {
    constructor(props) {
        super(props)
        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style, value } = object.props;
        //debugger

        this.state = {
            isImageModalActive: false,
            file: component && ((style["backgroundImage"] == undefined || style["backgroundImage"] == "unset" || style["backgroundImage"] == null) ? null : this.replaceURL(style["backgroundImage"])),
        }
        this.inputFileSelecter = React.createRef();
    }

    replaceURL = (_img) => {
        let _result = _img.replace("url(", "");
        _result = _result.replace(")", "");
        //debugger
        return _result;
    }

    handleChange = (e) => {
        if (!e) var e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();


        try {
            this.setState({
                file: URL.createObjectURL(e.target.files[0])
            }, () => {
                let _clone = Object.assign({}, this.props.component);
                if (_clone.hasOwnProperty("componentOwner")) {
                    _clone.object.components[0].object.props.style = {
                        ..._clone.object.components[0].object.props.style,
                        backgroundImage: this.state.file == null ? "unset" : ("url(" + this.state.file + ")")
                    }
                } else {
                    _clone.components[0].object.props.style = {
                        ..._clone.components[0].object.props.style,
                        backgroundImage: this.state.file == null ? "unset" : ("url(" + this.state.file + ")")
                    }
                }

                let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
                let _result = findObjUpdate(_newEditor, _clone);
                this.props.updateComponent(_clone);
                this.props.onUpdateList(_result);
            })
        } catch (error) {

        }
    }

 

    componentDidUpdate = (prevProps) => {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        const { style, value } = object.props;

        if (prevProps.component != component) {
            this.setState({
                file: component && ((style["backgroundImage"] == undefined || style["backgroundImage"] == "unset" || style["backgroundImage"] == null) ? null : this.replaceURL(style["backgroundImage"])),
            })
        }
    }

    handleClick = (e) => {
        //const { current } = this.inputFileSelecter;
        this.inputFileSelecter.current.click()

    }

    handleImageModal = () => {
        this.setState({
            isImageModalActive: !this.state.isImageModalActive,
        })
    }

    render() {
        return (
            <>
                <div style={{ position: "relative" ,width: "50px"}}>
                    <input className={"property-image-input"} type="file"
                        ref={this.inputFileSelecter}
                        onChange={this.handleChange} />
                    <div className={"property-image"}
                        onClick={this.handleImageModal}
                        style={{
                            backgroundImage: "url(" + this.state.file + ")"
                        }} >


                    </div>
                    {
                        (this.state.file != null) &&
                        <div onClick={() => {
                            this.setState({
                                file: null
                            }, () => {

                                let _clone = Object.assign({}, this.props.component);
                                if (_clone.hasOwnProperty("componentOwner")) {
                                    _clone.object.components[0].object.props.style = {
                                        ..._clone.object.components[0].object.props.style,
                                        backgroundImage: "unset"
                                    }
                                } else {
                                    _clone.components[0].object.props.style = {
                                        ..._clone.components[0].object.props.style,
                                        backgroundImage: "unset"
                                    }
                                }
                                let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
                                let _result = findObjUpdate(_newEditor, _clone);
                                this.props.updateComponent(_clone);
                                this.props.onUpdateList(_result);
                            })
                        }} className={"property-image-remove"}>
                            <i className="fas fa-times"></i>
                        </div>
                    }
                </div>
                <GalleryModal
                    isActive={this.state.isImageModalActive}
                    handleToggle={this.handleImageModal}
                 />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { componentReducer,editorReducer,globalpropsReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor } = present;
    const { globalProps } = globalpropsReducer;

    return {
        component,
        editor,
        globalProps
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
    
}
export default connect(mapStateToProps, mapDispatchToProps)(ImageProperty)
