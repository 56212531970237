import React from "react";
import { Translation } from "react-i18next";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { connect } from 'react-redux'
import { setUIEditorStatusBack, setUIEditorStatus, updateListSocket, updateCurrentPage, removeComponent, setPage, setPages, SocketOperationFunctions } from '_actions';
import { findObjUpdateItemList } from 'assets/util';
import { Alert, Tooltip, Whisper, SelectPicker } from 'rsuite';

import GenerateComponent from 'components/Custom/GenerateComponent'
import ComponentSelector from 'components/Modals/ComponentSelector';

// reactstrap components
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";

class UIHeader extends React.Component {

  constructor(props) {
    super(props)
    this.refPageSelector = React.createRef();

    this.state = {
      newPageModal: false,
      editor: props.editor,
      pageBusy: false,
      isComponentSelector: false,
      selectedUI: null,
    }
  }


  handleClearAutoSave = () => {
    const { socket, page } = this.props;
    const projectId = localStorage.ProjectId;
    SocketOperationFunctions.ClearAutoSaved(socket, projectId, page.id, "1.0");


  }



  handleCompnentSelector = () => {
    this.setState({
      isComponentSelector: !this.state.isComponentSelector
    })
  }

  handleSetComponent = (_data) => {
    debugger
    this.setState({
      isComponentSelector: false,
    })
  }

  handleSaveUI = () => {
    const {
      setUIEditorStatus,
      uiEditorStatus,
      onUpdateList,
      clearHistory,
      setPage,
      removeComponent,
      page,
      editor,
      setUIEditorStatusBack
    } = this.props;

    let uiEditorComponent =  Object.values(Object.assign({}, this.props.uiEditorComponent));

    const _currentEditor = Object.assign({}, editor);
    const _currentPage = Object.assign({}, page);

    // _currentEditor -> uiEditorComponent[uiEditorComponent.length - 1] içine entegre edilmeli

    if (uiEditorStatus) {
      /*
          ! UI Editor Kapatılacaktır.
          Mevcut sayfa içeriği bir diğer değişken içerisine veri kaybı 
          olmaması için aktarılacaktır.
      */

      if (uiEditorComponent.length > 1) {

        let _customData = {
          current:{...JSON.parse(JSON.stringify(uiEditorComponent[uiEditorComponent.length - 1].current))},
          backup:{...JSON.parse(JSON.stringify(uiEditorComponent[uiEditorComponent.length - 1].backup))},
        }
        
    
        if (_customData.current.hasOwnProperty("componentOwner")) {
          _customData.current.object.components[0].object[_customData.current._field] = _currentEditor[0];
        } else {
          _customData.current.components[0].object[_customData.current._field] = _currentEditor[0];
        }
      
        onUpdateList([_customData.current]);
        

      } else {
         
        const _last = findObjUpdateItemList(_currentPage.detail, uiEditorComponent[uiEditorComponent.length - 1].current, _currentEditor[0]);
       
        _currentPage.detail = Object.values(_last);
        
        setPage(_currentPage);

      }




      clearHistory();
      removeComponent();
    }
    setUIEditorStatusBack()
    //setUIEditorStatus(!uiEditorStatus, null);


  }

  handleCloseUI = () => {

    const {
      setUIEditorStatus,
      uiEditorStatus,
      onUpdateList,
      clearHistory,
      removeComponent,
      page,
      uiEditorComponent,
      setUIEditorStatusBack,
    } = this.props;

    const _currentPage = Object.assign({}, page);


    if (uiEditorStatus) {
      /*
          ! UI Editor Kapatılacaktır.
          Mevcut sayfa içeriği bir diğer değişken içerisine veri kaybı 
          olmaması için aktarılacaktır.
      */
       
 

      if (uiEditorComponent && uiEditorComponent.length > 1) {
        onUpdateList([uiEditorComponent[uiEditorComponent.length - 1].backup]);
        clearHistory();
         removeComponent();
        setUIEditorStatusBack()
      } else {
        onUpdateList(Object.values(_currentPage.detail));
        clearHistory();
        removeComponent();
        setUIEditorStatus(false);

      }
    }



  }


  render() {
    const { canUndo, canRedo, onUndo, onRedo, pages, currentPage } = this.props;
    //debugger;
    return (
      <Translation>
        {t => (
          <>
            <div className="header pb-6 custom-fade-in">
              <Container fluid>
                <div className="header-body">
                  <Row className="custom-header align-items-center py-4">
                    <Col lg="4" xs="4">

                      <Whisper placement="bottom" trigger="hover" speaker={
                        <Tooltip>
                          {t("TOOLTIP_UNDO")}
                        </Tooltip>
                      }>
                        <Button
                          id={"tooltipUndo"}
                          color="primary" outline type="button" size="sm"
                          onClick={onUndo} disabled={!canUndo}>
                          <i className="fas fa-undo-alt"></i>
                        </Button>
                      </Whisper>
                      <Whisper placement="bottom" trigger="hover" speaker={
                        <Tooltip>
                          {t("TOOLTIP_REDO")}
                        </Tooltip>
                      }>
                        <Button
                          id={"tooltipRedo"}
                          color="primary" outline type="button" size="sm"
                          onClick={onRedo} disabled={!canRedo}>
                          <i className="fas fa-redo"></i>
                        </Button>
                      </Whisper>



                    </Col>

                    <Col lg="4" xs="4" style={{
                      justifyContent: "center",
                      display: "flex"
                    }}>
                       


                    </Col>


                    <Col className="text-right" lg="4" xs="4">
                      {
                        this.props.currentProjectId &&
                        <>

                          {
                            this.props.currentProjectId &&
                            this.props.pageStatus.status &&
                            <Whisper placement="bottom" trigger="hover" speaker={
                              <Tooltip>
                                {t("BUTTON_SAVE")}
                              </Tooltip>
                            }>
                              <Button
                                color="primary" outline type="button" size="sm"
                                onClick={this.handleSaveUI}
                              >
                                <i className="fas fa-save"></i>
                              </Button>
                            </Whisper>
                          }
                          <Whisper placement="bottom" trigger="hover" speaker={
                            <Tooltip>
                              {t("BUTTON_CANCEL")}
                            </Tooltip>
                          }>
                            <Button
                              color="primary" outline type="button" size="sm"
                              onClick={this.handleCloseUI}
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </Whisper>

                        </>
                      }

                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
            <ComponentSelector isActive={this.state.isComponentSelector} handleToggle={this.handleCompnentSelector} handleSelect={this.handleSetComponent} />
          </>
        )}
      </Translation>
    );
  }
}


const mapStateToProps = (state) => {
  const { editorReducer, currentstatesReducer, SocketReducer, componentReducer } = state;
  const { present } = editorReducer;
  const { editor, pages, currentPage, page } = present;

  const { pageStatus, currentProjectId, uiEditorStatus, uiEditorComponent } = currentstatesReducer;
  const { socket } = SocketReducer;

  const { myComponentList } = componentReducer;

  return {
    canUndo: state.editorReducer.past.length > 0,
    canRedo: state.editorReducer.future.length > 0,
    editor, pages, currentPage, page,
    pageStatus,
    socket,
    currentProjectId,
    myComponentList,
    uiEditorStatus,
    uiEditorComponent

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUndo: () => dispatch(UndoActionCreators.undo()),
    onRedo: () => dispatch(UndoActionCreators.redo()),
    clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
    updateCurrentPage: (id) => dispatch(updateCurrentPage(id)),
    removeComponent: () => dispatch(removeComponent()),
    setPage: (_data) => dispatch(setPage(_data)),
    setPages: (_data) => dispatch(setPages(_data)),
    onUpdateList: (_data) => dispatch(updateListSocket(_data)),
    setUIEditorStatus: (_data, _component) => dispatch(setUIEditorStatus(_data, _component)),
    setUIEditorStatusBack: (_data) => dispatch(setUIEditorStatusBack(_data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UIHeader)
