import React, { Component } from 'react'
import { Button } from 'reactstrap';
import { Translation } from "react-i18next";
import { connect } from 'react-redux';
import CustomIcon from './CustomIcon';

import i18n from 'i18n';
import moment from "moment";

moment.locale(i18n.t("LANG"));
class CustomDatePicker extends Component {
    /*   constructor(props) {
           super(props)
   
           this.state = {
               v: 1,
           }
       }
   
   
       componentDidUpdate = (prevProps) => {
           if (JSON.stringify(prevProps.globalProps) != JSON.stringify(this.props.globalProps)) {
               this.setState({
                   v: this.state.v * 2,
               })
           }
       }
   */


    componentDidMount = () => {
        // debugger
        const that = this;
        const { editor, path, isChild, main } = that.props;
        if (isChild) {
            const hasObj = that.findNestedObj(editor, main.id);
            if (!hasObj)
                that.handleDirectSetID(editor, path);
        }
    }

    findNestedObj = (mainState, valToFind) => {
        if (mainState) {
            let foundObj;
            JSON.stringify(mainState, (_, nestedValue) => {
                if (nestedValue && nestedValue["id"] === valToFind) {
                    foundObj = nestedValue;
                }
                return nestedValue;
            });
            //debugger;

            return foundObj;
        } else {
            return null;
        }

    };


    getComp = (_data) => {
        try {
            if (_data.hasOwnProperty("componentOwner")) { return _data.object.components[0]; }
            else { return _data.components[0]; }
        } catch (error) {
            debugger
        }


    }

    handleDirectSetID = async (old, _path) => {
        const that = this;
        const { main } = this.props;

        const p = _path.split("-")

        let _old = Object.values(JSON.parse(JSON.stringify(old)));
        let _array2 = _old;

        let current = null;

        p.forEach((element, index) => {
            if (index === 0) {
                current = this.getComp(old[element]);
            } else if (index === p.length - 1) {
                //current.object.data[element].object.data
                try {

                    current.object.data.splice(element, 1);
                    current.object.data.splice(element, 0, main);

                } catch (error) {
                    debugger
                }
            }
            else {
                current = this.getComp(current.object.data[element]);
            }
        });



    }

    render() {
        const { data, component, main, isChild } = this.props;

        const _element = document.getElementsByClassName("deviceInside");
        const _elementW = _element[0].offsetWidth + "px";
        const _elementH = _element[0].offsetHeight + "px";
        const _elementW2 = _element[0].offsetWidth / 2 + "px";
        const _elementH2 = _element[0].offsetHeight / 2 + "px";

        const _h = (data.props.style.minHeight == "device" ? _elementH : (data.props.style.minHeight == "half_device" ? _elementH2 : data.props.style.minHeight));
        const _w = (data.props.style.minWidth == "device" ? _elementW : (data.props.style.minWidth == "half_device" ? _elementW2 : data.props.style.minWidth));


        //icon
        let iconData = data.props.style.iconData;
        let iconPosition = data.props.style.iconPosition;
        let iconType = data.props.style.iconType;
        let iconSize = data.props.style.iconSize;
        let iconColor = data.props.style.iconColor;

        /* GLOBAL PROPS */

        /*  GLOBAL Background Color */
        let gBGIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalBackgroundColor) : -1;
        let gBGColor = ((gBGIndex > -1) && (this.props.globalProps[gBGIndex].type == 0)) ? this.props.globalProps[gBGIndex].value : data.props.style.backgroundColor;
        let gBG = (data.props.style.hasOwnProperty("globalBackgroundColor")) ? gBGColor : data.props.style.backgroundColor;

        /*  GLOBAL Font Color */
        let gFCIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalColor) : -1;
        let gFCColor = ((gFCIndex > -1) && (this.props.globalProps[gFCIndex].type == 0)) ? this.props.globalProps[gFCIndex].value : data.props.style.color;
        let gFC = (data.props.style.hasOwnProperty("globalColor")) ? gFCColor : data.props.style.color;

        /*  GLOBAL Border Color */
        let gBCIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalBorderColor) : -1;
        let gBCColor = ((gBCIndex > -1) && (this.props.globalProps[gBCIndex].type == 0)) ? this.props.globalProps[gBCIndex].value : data.props.style.borderColor;
        let gBC = (data.props.style.hasOwnProperty("globalBorderColor")) ? gBCColor : data.props.style.borderColor;


        /*  GLOBAL Icon Color */
        let gICIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalBackgroundColor) : -1;
        let gICColor = ((gICIndex > -1) && (this.props.globalProps[gICIndex].type == 0)) ? this.props.globalProps[gICIndex].value : data.props.style.backgroundColor;
        let gIC = (data.props.style.hasOwnProperty("globalIconColor")) ? gICColor : data.props.style.backgroundColor;


        /*  GLOBAL Font Size */
        let gFSIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalFontSize) : -1;
        let gFSFontSize = ((gFSIndex > -1) && (this.props.globalProps[gFSIndex].type == 1)) ? this.props.globalProps[gFSIndex].value : data.props.style.fontSize;
        let gFS = (data.props.style.hasOwnProperty("globalFontSize")) ? gFSFontSize : data.props.style.fontSize;

        // debugger

        /* Standar ve cutom componentleri standart a çevirme işlemi */
        let _component;
        if (component) {
            if (component.hasOwnProperty("componentOwner"))
                _component = component.object.components[0];
            else
                _component = component.components[0]
        }

        const _tA = data.props.style.textAlign;

        return (
            <Translation>
                {t => (
                    <>
                        <div {...data.props}
                            style={{
                                ...data.props.style,
                                backgroundColor: gBG,
                                color: gFC,
                                borderColor: gBC,
                                fontSize: gFS,
                                minHeight: (isChild && (data.props.style.minHeight != "auto")) ? "100%" : isChild == false ? "100%" : (data.props.style.minHeight == "square" ? _w : _h),
                                minWidth: (isChild && (data.props.style.minWidth != "auto")) ? "100%" : isChild == false ? "100%" : (data.props.style.minWidth == "square" ? _h : _w),

                                marginTop: "0px !important",
                                marginBottom: "0px !important",
                                marginLeft: "0px !important",
                                marginRight: "0px !important",
                                textAlign: _tA ? _tA : "center",

                                display: "flex",
                                justifyContent: _tA ? (_tA === "right" ? "flex-end" : _tA === "left" ? "flex-start" : _tA === "center" ? "center" : "space-around") : "center",
                                alignItems: "center",
                                position: "relative",
                                overflow: "hidden"
                            }}
                            className={"noselect " + (component && (component.id == main.id ? "selected-component" : ""))} >
                            {
                                data.props.blurview &&
                                <div style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    top: 1,
                                    left: 0,
                                    backdropFilter: `brightness(${data.props.blurview ? ((data.props.blurview.type === "light" ? "130" : "80")) : "100"}%) blur(${data.props.blurview ? (data.props.blurview.amount) : "0"}px)`,
                                    WebkitBackdropFilter: `brightness(${data.props.blurview ? ((data.props.blurview.type === "light" ? "130" : "80")) : "100"}%) blur(${data.props.blurview ? (data.props.blurview.amount) : "0"}px)`
                                }} />
                            }
                            {
                                iconData &&
                                iconPosition == "left" &&
                                <CustomIcon
                                    data={data}
                                    src={iconData.url}
                                    fill={gFC}
                                    height={gFS} />
                            }
                            {
                                (component && (component.id == main.id)) ?
                                    (
                                        data.data ? (
                                            _component.object.data.hasOwnProperty("path") ?
                                                "{" + _component.object.data.path.split("-")[_component.object.data.path.split("-").length - 1] + "}" :
                                                ((_component.object.props.value && _component.object.props.value !== "") ?
                                                    moment(!isNaN(Date.parse(_component.object.props.value)) ? (new Date(_component.object.props.value)) : new Date()).format(_component.object.props.visibleFormat) :
                                                    ""
                                                )

                                        ) :
                                            (_component.object.props.value && _component.object.props.value !== "") ?
                                                ((_component.object.props.value && _component.object.props.value !== "") ?
                                                    moment(!isNaN(Date.parse(_component.object.props.value)) ? (new Date(_component.object.props.value)) : new Date()).format(_component.object.props.visibleFormat) :
                                                    ""
                                                )
                                                :
                                                (iconData ? "" : t("PROPERTY_NULL_VALUE"))
                                    )
                                    : (

                                        data.data ? (
                                            data.data.hasOwnProperty("path") ?
                                                "{" + data.data.path.split("-")[data.data.path.split("-").length - 1] + "}" :
                                                ((data.props.value && data.props.value !== "") ?
                                                    moment(!isNaN(Date.parse(data.props.value)) ? (new Date(data.props.value)) : new Date()).format(data.props.visibleFormat) :
                                                    ""
                                                )
                                         ) :
                                            data.props.hasOwnProperty("value") &&
                                                data.props.value ?
                                                 ((data.props.value && data.props.value !== "") ?
                                                    moment(!isNaN(Date.parse(data.props.value)) ? (new Date(data.props.value)) : new Date()).format(data.props.visibleFormat) :
                                                    ""
                                                )
                                                :
                                                (iconData ? "" : t("PROPERTY_NULL_VALUE"))
                                    )
                            }

                            {
                                iconData &&
                                iconPosition == "right" &&
                                <CustomIcon
                                    data={data}
                                    src={iconData.url}
                                    fill={gFC}
                                    height={gFS} />
                            }


                        </div>
                    </>

                )
                }
            </Translation>
        )
    }
}

//export default CustomButton
const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, globalpropsReducer } = state;
    const { component } = componentReducer;
    const { globalProps } = globalpropsReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        componentReducer,
        component,
        globalProps,
        editor,//: JSON.parse(editor),

    };
}
const mapDispatchToProps = {
    //  onUpdateComponent: updateComponent,

}
export default connect(mapStateToProps, mapDispatchToProps)(CustomDatePicker)


/*


        let gBGIndex = this.props.globalProps.findIndex(x => x.id === data.props.style.globalBackgroundColor);
        let gBGColor = (this.props.globalProps[gBGIndex] && (this.props.globalProps.type == 0)) ? this.props.globalProps[gBGIndex].value : data.props.style.backgroundColor;
        let gBG = (data.props.style.hasOwnProperty("globalBackgroundColor")) ? gBGColor : data.props.style.backgroundColor;

        let gFCIndex = this.props.globalProps.findIndex(x => x.id === data.props.style.globalColor);
        let gFCColor = (this.props.globalProps[gFCIndex] && (this.props.globalProps.type == 0)) ? this.props.globalProps[gFCIndex].value : data.props.style.color;
        let gFC = (data.props.style.hasOwnProperty("globalColor")) ? gFCColor : data.props.style.color;

        let gBCIndex = this.props.globalProps.findIndex(x => x.id === data.props.style.globalBorderColor);
        let gBCColor = (this.props.globalProps[gBCIndex] && (this.props.globalProps.type == 0)) ? this.props.globalProps[gBCIndex].value : data.props.style.borderColor;
        let gBC = (data.props.style.hasOwnProperty("globalBorderColor")) ? gBCColor : data.props.style.borderColor;

     
        let gICIndex = this.props.globalProps.findIndex(x => x.id === data.props.style.globalBackgroundColor);
        let gICColor = (this.props.globalProps[gICIndex] && (this.props.globalProps.type == 0)) ? this.props.globalProps[gICIndex].value : data.props.style.backgroundColor;
        let gIC = (data.props.style.hasOwnProperty("globalIconColor")) ? gICColor : data.props.style.backgroundColor;



*/