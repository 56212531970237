import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateComponent,updateList } from '_actions';
import { findObjUpdate } from 'assets/util';
import { ReactSVG } from 'react-svg'

import IconsModal from '../../../components/Modals/IconsModal';
import { Alert, Loader } from 'rsuite';

class IconProperty extends Component {
    constructor(props) {
        super(props)
        const { component } = props;
        const  {object}  = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
         
        const { style,value } = object.props;
        //debugger

        this.state = {
            iconsModal: false,
            file: component && ((style["iconData"] == undefined) ? null : style["iconData"]),
        }
    }

    handleIcon = () => {
        this.setState({
            iconsModal: !this.state.iconsModal,
        })
    }
    handleClose = () => {
        this.setState({
            iconsModal: false,
        })
    }

    componentDidUpdate = (prevProps) => {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
         
        const { style,value } = object.props;

        if (prevProps.component != component) {
            this.setState({
                file: component && ((style["iconData"] == undefined) ? null : style["iconData"]),
            })
        }
    }

    handleClick = (e) => {
        this.handleIcon();
    }

    handleSelectIcon = (_data) => {
       
         this.setState({
            file: _data,
        }, () => {
            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.style = {
                    ..._clone.object.components[0].object.props.style,
                    iconData: this.state.file
                }
            } else {
                _clone.components[0].object.props.style = {
                    ..._clone.components[0].object.props.style,
                    iconData: this.state.file
                }
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }

    render() {
        return (
            <>
                <div style={{ position: "relative" }}>
                    <div className={"property-icon"}
                        onClick={this.handleClick}>
                        <ReactSVG
                            src={this.state.file ? this.state.file.url:""}
                            afterInjection={(error, svg) => {
                                if (error) {
                                    //console.error(error)
                                    return
                                }
                                //console.log(svg)
                            }}
                            beforeInjection={(svg) => {
                                //svg.classList.add('svg-class-name')
                                svg.setAttribute('style', 'height:26px  ; fill:#2baebf')
                            }}
                            evalScripts="always"
                            fallback={() => <span></span>}
                            loading={() => <Loader/>}
                            renumerateIRIElements={false}
                            wrapper="span"
                        />

                    </div>
                    {
                        (this.state.file != null) &&
                        <div onClick={() => {
                            this.setState({
                                file: null
                            }, () => {
                                
                                let _clone = Object.assign({}, this.props.component);
                                if (_clone.hasOwnProperty("componentOwner")) {
                                    _clone.object.components[0].object.props.style = {
                                        ..._clone.object.components[0].object.props.style,
                                        iconData: this.state.file
                                    }
                                } else {
                                    _clone.components[0].object.props.style = {
                                        ..._clone.components[0].object.props.style,
                                        iconData: this.state.file
                                    }
                                }
                                
                                let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
                                let _result = findObjUpdate(_newEditor, _clone);
                                this.props.updateComponent(_clone);
                                this.props.onUpdateList(_result);
                            })
                        }} className={"property-image-remove"}>
                            <i className="fas fa-times"></i>
                        </div>
                    }


                </div>
                <IconsModal
                    isActive={this.state.iconsModal}
                    handleToggle={this.handleClose}
                    currentSelected={this.state.file && this.state.file.id}
                    handleSelectIcon={this.handleSelectIcon} />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { componentReducer,editorReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        component,
        editor
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
}
export default connect(mapStateToProps, mapDispatchToProps)(IconProperty)
