//base url ler burada olacak

//eger localde frontend yapilip back end calismiyor ise her baglantiyi publish e zorla
let ForceProductionMode = true;

export function GetUserLang() {
  //burasi sonra yapilabilie..
  return "en";
}
export function GetBaseConsoleURL() {
  var CurrentURL = "";
  if (ForceProductionMode) return ServiceURLs.Console_Production_URL;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Console_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Console_Production_URL;
  }
  return CurrentURL;
}
export function Get_FRONTEND_CMS_URL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  //if (ForceProductionMode) return ServiceURLs.CMS_Production_FrontEnd_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.CMS_Dev_FrontEnd_URL;
  } else {
    CurrentURL = ServiceURLs.CMS_Production_FrontEnd_URL;
  }
  return CurrentURL;
}
export const ProjectEntPoints={
  CLONE_PROJECT: "projects/cloneproject",
  CREATE_PROJECT: "projects/createproject",
  GET_MY_PROJECT: "projects/paging",
  GET_PROJECT_TYPES:"projecttypes/withlang/",
  GET_APP_CATEGORIES:"appcategory/withlang/",
  SET_PROJECT_DETAIL:"redirecttemp/setproject"

  
}



export function GetConsoleFrontEnd() {
  var CurrentURL = "";
  //if (ForceProductionMode) return ServiceURLs.Console_Production_FrontEnd_URL;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Console_Dev_FrontEnd_URL;
  } else {
    CurrentURL = ServiceURLs.Console_Production_FrontEnd_URL;
  }
  return CurrentURL;
}



export function GetCreatorFrontEnd() {
  var CurrentURL = "";
 // if (ForceProductionMode) return ServiceURLs.Creator_Production_FrontEnd_URL;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Creator_Dev_FrontEnd_URL;
  } else {
    CurrentURL = ServiceURLs.Creator_Production_FrontEnd_URL;
  }
  return CurrentURL;
}






export function GetBaseStorageURL() {
  var CurrentURL = "";
  if (ForceProductionMode) return ServiceURLs.Storage_Production_URL;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Storage_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Storage_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseConnectURL() {
  var CurrentURL = "";
  if (ForceProductionMode) return ServiceURLs.Connect_Production_URL;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Connect_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Connect_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseApiURL() {
  var CurrentURL = "";

  if (ForceProductionMode) return ServiceURLs.Api_Production_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Api_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Api_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseSocketURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.Socket_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Socket_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Socket_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseVideoURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.Video_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Video_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Video_Production_URL;
  }
  return CurrentURL;
}





export function GetAuthURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  //if (ForceProductionMode) return ServiceURLs.Auth_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Auth_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Auth_Production_URL;
  }
  return CurrentURL;
}

export function GetBaseCreatorURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.Creator_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.Creator_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.Creator_Production_URL;
  }
  return CurrentURL;
}


 

export const ServiceURLs = {
  Connect_Production_URL: "https://connect.mobilex360.io/api/",
  Connect_DEV_URL: "http://localhost:7001/api/",
  Api_Production_URL: "https://api.mobilex360.io/api/",
  Api_DEV_URL: "http://localhost:7000/api/",
  Storage_Production_URL: "https://storage.mobilex360.io/api/",
  Storage_DEV_URL: "http://localhost:7002/api/",
  Auth_Production_URL: "https://auth.mobilex360.io/login/",
  Auth_DEV_URL: "http://localhost:3002/login/",
  Creator_Production_URL: "https://creatorapi.mobilex360.io/api/",
  Creator_DEV_URL: "http://localhost:7003/api/",
  Socket_DEV_URL:"http://127.0.0.1:9393",
  Socket_Production_URL:"https://socket.mobilex360.io/",
  Console_Production_URL: "https://consoleapi.mobilex360.io/api/",
  Console_DEV_URL: "http://localhost:7004/api/",
  Creator_Production_FrontEnd_URL: "https://creator.mobilex360.io/",
  Creator_Dev_FrontEnd_URL: "http://localhost:3001/",
  Console_Dev_FrontEnd_URL:"http://localhost:3020/",
  Console_Production_FrontEnd_URL: "https://console.mobilex360.io/",
  
  CMS_Dev_FrontEnd_URL:"http://localhost:3010/",
  CMS_Production_FrontEnd_URL: "https://cms.mobilex360.io/",
  CMS_Production_URL: "https://cmsapi.mobilex360.io/api/",
  CMS_DEV_URL: "http://localhost:7010/api/",

};

export const UserEndPoints = {
  GET_MY_INFORMATION: "user/getmyinformation",
  LOGIN: "user/login",
  REGISTER: "user/register",
  CHANGE_PASS_W_TOKEN: "user/changepasswordwithtoken",
  UPDATE_PASSWORD: "user/changepassword",
  UPDATE_EMAIL: "user/changeemail",
  UPDATE_USERNAME: "user/changeusername",
  CONFIRM_USER: "user/confirm",
  LOGOUT: "user/logout",
  FORGOT_PASSWORD: "user/forgotpassword",
  GET_GENDER: "gender/withlang/",
  GET_RANDOM: "user/getrandom/",
  GET_USERNAME_AVAILABLE: "user/checkusernameusable",
  GET_EMAIL_AVAILABLE: "user/checkemailusable",
  SEARCH_USER: "user/stepbyload",
  GET_LAST_LOGGINED: "user/getlastloggined",
  UPDATE_PP: "user/updatepp",
  ADD_ITEM_TO_GALLERY: "gallery/addnewgalleryitem",
  REMOVE_ITEM_FROM_GALLERY: "gallery/deletegalleryitem",
  GET_MY_GALLERY: "gallery/getmygallery",
  GET_USER_GALLERY: "gallery/getusergallery",
  EDIT_USER01: "user/edituserinfo1",
  GET_RANDOM_NEWS_PHOTOS: "gallery/getnewrandomphotos",
  "SET_REDIRECT_URL":"redirecttemp/seturl",
  "GET_REDIRECT_URL":"redirecttemp/geturl/",
  SET_REDIRECT_TOKEN_URL: "redirecttemp/settoken",
  GET_REDIRECT_TOKEN_URL: "redirecttemp/gettoken/",
  GET_PROJECT_TOKEN_URL: "redirecttemp/getproject/"
};

export const StorageEndPoints = {
  UPLOAD: "storage/upload",
  UPLOAD_BASE64: "storage/uploadbase64",
  UPLOAD_BASE64SOUND: "storage/uploadsound",
  UPLOAD_VIDEO: "storage/uploadvideo",
  STORAGE_GET_MY_STREAM:"storage/getmystreams",
  STORAGE_GET_MY_PROJECT_STREAM:"storage/getprojectstream",
  STORAGE_GET_BOTH_STREAM:"storage/getbothstream",
  STORAGE_ADD_PROJECTID_TO_STREAM:"storage/addprojectidtostream",
  STORAGE_REMOVE_PROJECT_ID_FROM_STREAM:"storage/removeprojectidtostream",
  STORAGE_DELETE:"storage/deletestream",
  STORAGE_GET_ADMIN_ONLY:"storage/getsystemonly",

};

export const CreatorEndPoints = {
  CREATE_PROJECT: "projects/createproject",
  SAVE_PROJECT: "projects/saveproject",
  GET_PROJECT_SETTING: "projects/getprojectsetting",
  SET_PROJECT_SETTING: "projects/setprojectsetting",
  CHECK_IS_HAVE_PROJECT_SETTING: "projects/checkishaveprojectsetting",
  GET_PAGING: "projects/paging",
  CREATE_TEAM: "team/createteam",
  ADD_USER_TO_TEAM: "team/addusertoteam",
  UPDATE_USER_TEAM: "team/updateuserrole",
  REMOVE_FROM_TEAM: "team/removefromteam",
  ASSIGN_PAGE_TO_USER: "team/assignpagetouser",
  REMOVE_USER_FROM_PAGE: "team/removeuserfrompage",
  CHECK_IS_TEAM_OWNER: "team/checkisteamowner",
  GET_PROJECT_MEMBERS: "team/getprojectmembers",
  ADD_PAGE_TO_PROJECT: "pages/addpagetoproject",
  GET_PAGE_BY_ID: "pages/getpagebyid",
  GET_PAGES_ALL: "pages/getpagesall",
  GET_PAGES_THUMB: "pages/getpagesthumb",
  GET_PAGES_CONFIRMED_ONLY: "pages/getpagesconfirmedonly",
  GET_PAGES_UNCONFIRMED_ONLY: "pages/getpagesunconfirmedonly",
  SET_PAGE_ACTIVE_STATUS: "pages/setpageactivestatus",
  CLONE_PAGE: "pages/clonepage",
  SET_COMMIT_PAGE: "pages/setcommit",
  INC_BUILD_NUMBER: "pages/incbuildnumber",
  EDIT_PAGE_DETAIL: "pages/editpagedetail",
  EDIT_PAGE_PROPERTIES: "pages/editpageproperties",
  GENERATE_COMPONENT_ID: "component/generatecomponentid",
  GENERATE_COMPONENT_ID_LIST: "component/generatecomponentidlist",
  REMOVE_COMPONENT_ID: "component/removecomponentid",
  REMOVE_COMPONENT_ID_LIST :"component/removecomponentidlist",
  GET_COMPONENTS: "component/getcomponents", 
  GET_CUSTOM_COMPONENTS: "customcomponent/getmycustomcomponents/", 
  SAVE_CUSTOM_COMPONENTS: "customcomponent/savecustomcomponent", 
  REMOVE_CUSTOM_COMPONENTS: "customcomponent/removecustomcomponent", 
  SEARCH_CUSTOM_COMPONENTS: "customcomponent/stepbyload", 
  GET_HISTORY_BY_VERSION: "projecthistory/gethistorybyversion", 
  SET_NEW_PROJECT_HISTORY: "projecthistory/setnewprojecthistory", 
  HISTORY_STEP_BY_LOAD: "projecthistory/stepbyload", 
  GET_GLOBAL_PROP_TYPES:"globalproptypes/get",
  GET_GLOBAL_PROPERTIES:"globalproperties/getglobalproperties",
  CREATE_GLOBAL_PROPERTIES:"globalproperties/createglobalproperties",
  UPDATE_GLOBAL_PROPERTIES:"globalproperties/updateglobalproperties",
  REMOVE_GLOBAL_PROPERTIES:"globalproperties/removeglobalproperties",
  GET_PROJECT_BY_ID:"projects/getprojectbyid",
  CHANGE_PAGE_NAME: "pages/changepagename",
  CHANGE_COMPONENT_NAME: "component/changecomponentname",
  GET_COMPONENT_NAMES:"component/getpagecomponentnames"
};


export const CMSEndPoints = {
  GET_ALL_FOR_PANEL: "static/getallforpanel",
  GET_BY_ID: "static/getbyid",
  SAVE: "static/save",
  SAVE_FROM_CREATOR: "static/savefromcreator"


};

export const EnvironmentEndPoints = {
  CREATE_ENVIRONMENT: "environment/createenvironment",
  UPDATE_ENVIRONMENT: "environment/updateenvironment",
  REMOVE_ENVIRONMENT: "environment/removeenvironment",
  STEPBYLOAD: "environment/stepbyload",


};

export const DynamicFunctionsEndPoints = {
  CREATE_FUNCTION: "dynamicfunctions/createfunction",
  UPDATE_FUNCTION: "dynamicfunctions/updatefunction",
  REMOVE_FUNCTION: "dynamicfunctions/removefunctions",
  STEPBYLOAD: "dynamicfunctions/stepbyload",
  GET_EVENTS: "dynamicfunctions/getevents",
 
};

export const CustomFunctionsEndPoints = {
  CREATE_FUNCTION: "customfunctions/createfunction",
  UPDATE_FUNCTION: "customfunctions/updatefunction",
  REMOVE_FUNCTION: "customfunctions/removefunctions",
  STEPBYLOAD: "customfunctions/stepbyload"
 
};

export const HttpObjectEndPoints = {
  CREATE_HTTP_OBJECT: "httpobject/createhttpobject",
  UPDATE_HTTP_OBJECT: "httpobject/updatehttpobject",
  REMOVE_HTTP_OBJECT: "httpobject/removehttpobject",
  STEPBYLOAD: "httpobject/stepbyload",
  CLONE_HTTP_OBJECT: "httpobject/clonehttpobject",
 
};


export const ExportEndPoints = {
  EXPORT_PROJECT:"projectexportstore/newexportrequest",
  GET_MY_EXPORT:"projectexportstore/getmyexports"
 
};


export function GetBaseCMSURL() {
  var CurrentURL = "";

  // socket local servis remote olunca aciklamaya al publish de unutma..
  if (ForceProductionMode) return ServiceURLs.CMS_Production_URL;
  //test icin
  //return ServiceURLs.Socket_DEV_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    CurrentURL = ServiceURLs.CMS_DEV_URL;
  } else {
    CurrentURL = ServiceURLs.CMS_Production_URL;
  }
  return CurrentURL;
}

