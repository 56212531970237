import React, { Component } from 'react';
import { Translation } from "react-i18next";
import { connect } from 'react-redux';
import i18n from "i18n";
import Dropzone from "dropzone";
import { Divider, Pagination, Animation, IconButton, Icon, Alert } from 'rsuite';
import { ResponseStatusCode } from "_helpers";
import { StorageService } from "_services";
import { setImageUploadLoading, setMyStream, setMyStreamLoading } from "_actions";
import { Loader } from 'rsuite';

import GalleryBox from '../GalleryModal/GalleryBox';
import CropBox from '../GalleryModal/CropBox';

import {
    Button,
    ListGroup,
    ListGroupItem,
    Modal,
    Container,
    Row, Col
} from "reactstrap";
const { Fade, Collapse, Transition, Slide } = Animation;

Dropzone.autoDiscover = false;

class GalleryModal extends Component {
    constructor(props) {
        super(props)
        this.myDropzoneRef = React.createRef();
        this.handleToggleGallery = this.handleToggleGallery.bind(this);

        this.state = {
            croperImage: null,
            activePage: 1,
            isGalleryShowing: true,
            galleryPlacement: "left",
            isCropShowing: false,
            cropPlacement: "right",
            dropzone: null,

            counter:0,

        }
    }

 

    getMyStream = (_searchTerm = "", _page = 1) => {
        const { setMyStream, currentMyStream, myStream, setMyStreamLoading,setImageUploadLoading } = this.props;
        const { firstIndexID, lastIndexId, rowPerCount, isOrderByDesc } = currentMyStream;

        setMyStreamLoading(true);
        StorageService.STORAGE_GET_MY_STREAM(_page, rowPerCount, firstIndexID, isOrderByDesc, lastIndexId, this.props.currentProjectId, _searchTerm).then(data => {
            setMyStreamLoading(false)
            setImageUploadLoading(false);
            if (data.operationResult) {
                setMyStream(data.dynamicValue)
            }
        }).catch(err => {
            setMyStreamLoading(false)
            setImageUploadLoading(false);
            Alert.error(ResponseStatusCode(err.statusCode));

        });
    }



    handleDropImage = (_image) => {
        this.setState({
            croperImage: _image,
            counter:this.state.counter++,
        }, () => {
            this.handleToggleGallery();
        })
    }

    handleToggleGallery = () => {
        this.setState({
            galleryPlacement: "left",
            isGalleryShowing: !this.state.isGalleryShowing,
            isCropShowing: !this.state.isCropShowing,
            cropPlacement: "right",
            counter:this.state.counter++,
        });
    }
    handleToggleBack = () => {
        let _drop = this.state.dropzone;
        _drop.removeAllFiles();
        this.handleToggleGallery();

    }

    handleCropSuccess = () => {
        this.getMyStream("", 0)
        this.handleToggleBack();

    }

  

    handlePrepareDropzone = () => {
        let currentSingleFile = undefined;
        const { handleDropImage } = this;
        // single dropzone file - accepts only images
        let _d = new Dropzone(document.getElementById("dropzone-single"), {
            url: "/",
            thumbnailWidth: null,
            thumbnailHeight: null,
            previewsContainer: document.getElementsByClassName(
                "dz-preview-single"
            )[0],
            previewTemplate: document.getElementsByClassName("dz-preview-single")[0]
                .innerHTML,
            maxFiles: 1,
            acceptedFiles: "image/*",
            init: function () {
                this.on("addedfile", function (file) {
                    if (currentSingleFile) {
                        this.removeFile(currentSingleFile);
                    }
                    currentSingleFile = file;
                    handleDropImage(URL.createObjectURL(file));
                });
            }
        });

        this.setState({
            dropzone: _d,
            counter:this.state.counter++,
        })
        document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";


    }

    render() {
        const { isActive, handleToggle } = this.props;
        const { isCropShowing } = this.state;
        return (
            <Translation>
                {t => (
                    <>
                        <Modal
                            className="modal-dialog-centered"
                            isOpen={isActive}
                            size="lg"
                            scrollable={true}
                        >
                            <div className="modal-header modal-header-flex modal-header-pb0">
                                {
                                    isCropShowing &&
                                    <IconButton onClick={() => { this.handleToggleBack(); }} icon={<Icon icon="angle-left" />} circle size="sm" />
                                }
                                <h5 className="modal-title" id="CheckProjectModal">
                                    {t("TITLE_IMAGE_GALLERY_MODAL")}
                                </h5>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Container className={"modal-sweet-control"}>
                                    <Slide exitedClassName="custom-exited" in={this.state.isGalleryShowing} placement={this.state.galleryPlacement}>
                                        {(props, ref) =>
                                            <GalleryBox {...props} ref={ref} handleDropImage={this.handleDropImage} handlePrepareDropzone={this.handlePrepareDropzone} handleModalToggle={handleToggle}/>
                                        }
                                    </Slide>
                                    <Slide in={this.state.isCropShowing} placement={this.state.cropPlacement}>
                                        {(props, ref) =>
                                            <CropBox {...props} ref={ref} key={this.state.counter} currentImage={this.state.croperImage} handleSuccess={this.handleCropSuccess} handleToggleBack={this.handleToggleBack} />
                                        }
                                    </Slide>
                                </Container>
                            </div>
                            {
                                this.props.isImageUploadLoading &&
                                <div className={"modal-loading-container fade-in"}>
                                    <Loader center size="md" />
                                </div>
                            }

                        </Modal>
                    </>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { currentstatesReducer } = state;
    const { isImageUploadLoading, currentMyStream,currentProjectId } = currentstatesReducer;
    const { dynamicValue } = currentMyStream;
    return {
        isImageUploadLoading,
        currentMyStream,
        myStream: dynamicValue,
        currentProjectId
    };
}
const mapDispatchToProps = {
    setImageUploadLoading,
    setMyStream,
    setMyStreamLoading

}
export default connect(mapStateToProps, mapDispatchToProps)(GalleryModal)
