import React, { Component } from 'react'
import { Translation } from "react-i18next";
import { connect } from 'react-redux'

class CustomYoutubeVideo extends Component {

    componentDidMount = () => {
        // debugger
        const that = this;
        const { editor, path, isChild, main } = that.props;
        if (isChild) {
            const hasObj = that.findNestedObj(editor, main.id);
            if (!hasObj)
                that.handleDirectSetID(editor, path);
        }
    }

    findNestedObj = (mainState, valToFind) => {
        if (mainState) {
            let foundObj;
            JSON.stringify(mainState, (_, nestedValue) => {
                if (nestedValue && nestedValue["id"] === valToFind) {
                    foundObj = nestedValue;
                }
                return nestedValue;
            });
            //debugger;

            return foundObj;
        } else {
            return null;
        }

    };


    getComp = (_data) => {
        try {
            if (_data.hasOwnProperty("componentOwner")) { return _data.object.components[0]; }
            else { return _data.components[0]; }
        } catch (error) {
            debugger
        }


    }

    handleDirectSetID = async (old, _path) => {
        const that = this;
        const { main } = this.props;

        const p = _path.split("-")

        let _old = Object.values(JSON.parse(JSON.stringify(old)));
        let _array2 = _old;

        let current = null;

        p.forEach((element, index) => {
            if (index === 0) {
                current = this.getComp(old[element]);
            } else if (index === p.length - 1) {
                //current.object.data[element].object.data
                try {

                    current.object.data.splice(element, 1);
                    current.object.data.splice(element, 0, main);

                } catch (error) {
                    debugger
                }
            }
            else {
                current = this.getComp(current.object.data[element]);
            }
        });



    }

    render() {
        const { data, component, main, isChild } = this.props;

        const _element = document.getElementsByClassName("deviceInside");
        const _elementW = _element[0].offsetWidth + "px";
        const _elementH = _element[0].offsetHeight + "px";
        const _elementW2 = _element[0].offsetWidth / 2 + "px";
        const _elementH2 = _element[0].offsetHeight / 2 + "px";

        const _h = (data.props.style.minHeight == "device" ? _elementH : (data.props.style.minHeight == "half_device" ? _elementH2 : data.props.style.minHeight));
        const _w = (data.props.style.minWidth == "device" ? _elementW : (data.props.style.minWidth == "half_device" ? _elementW2 : data.props.style.minWidth));

        const letrW = (data.props.style.minWidth).match(/[a-zA-Z%]+/g)
        const letrH = (data.props.style.minHeight).match(/[a-zA-Z%]+/g)

        /*  GLOBAL Background Color */
        let gBGIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalBackgroundColor) : -1;
        let gBGColor = ((gBGIndex > -1) && (this.props.globalProps[gBGIndex].type == 0)) ? this.props.globalProps[gBGIndex].value : data.props.style.backgroundColor;
        let gBG = (data.props.style.hasOwnProperty("globalBackgroundColor")) ? gBGColor : data.props.style.backgroundColor;

        /*  GLOBAL Border Color */
        let gBCIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalBorderColor) : -1;
        let gBCColor = ((gBCIndex > -1) && (this.props.globalProps[gBCIndex].type == 0)) ? this.props.globalProps[gBCIndex].value : data.props.style.borderColor;
        let gBC = (data.props.style.hasOwnProperty("globalBorderColor")) ? gBCColor : data.props.style.borderColor;


        const min_h = (isChild && (data.props.style.minHeight != "auto")) ? "100%" : (isChild == false ? ((letrH == "%" || letrH == "auto") ? "100%" : _h) : (data.props.style.minHeight == "square" ? _w : _h));
        const min_w = (isChild && (data.props.style.minWidth != "auto")) ? "100%" : (isChild == false ? ((letrW == "%" || letrW == "auto") ? "100%" : _w) : (data.props.style.minWidth == "square" ? _h : _w));

        return (
            <>
                <div
                    className={"noselect " + ((component && (component.id == main.id)) ? "selected-component" : "")}
                    style={{
                        ...data.props.style,
                        backgroundColor: gBG,
                        borderColor: gBC,
                        WebkitUserDrag: "none",
                        KhtmlUserSelect: "none",
                        MozUserSelect: "none",
                        OUserSelect: "none",
                        userSelect: "none",
                        userDrag: "none",
                        WebkitUserSelect: "none",
                        msUserSelect: "none",
                        //  minHeight: min_h,
                        //  minWidth: min_w,
                        minHeight: (isChild && (data.props.style.minHeight != "auto")) ? "100%" : isChild == false ? "100%" : (data.props.style.minHeight == "square" ? _w : _h),
                        minWidth: (isChild && (data.props.style.minWidth != "auto")) ? "100%" : isChild == false ? "100%" : (data.props.style.minWidth == "square" ? _h : _w),
                        position: "relative",
                        marginTop: "0px !important",
                        marginBottom: "0px !important",
                        marginLeft: "0px !important",
                        marginRight: "0px !important",
                        overflow: "hidden"
                    }}>
                        <div style={{
                            
                        }}>

                        </div>
                    {
                        data.props.blurview &&
                        <div style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: 0,
                            left: 0,
                            backdropFilter: `brightness(${data.props.blurview ? ((data.props.blurview.type === "light" ? "130" : "80")) : "100"}%) blur(${data.props.blurview ? (data.props.blurview.amount) : "0"}px)`,
                            WebkitBackdropFilter: `brightness(${data.props.blurview ? ((data.props.blurview.type === "light" ? "130" : "80")) : "100"}%) blur(${data.props.blurview ? (data.props.blurview.amount) : "0"}px)`
                        }} />
                    }
                </div>

            </>


        )
    }
}

//export default CustomImage
const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, globalpropsReducer } = state;
    const { component } = componentReducer;
    const { globalProps } = globalpropsReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        componentReducer,
        component,
        globalProps,
        editor,//: JSON.parse(editor),

    };
}
const mapDispatchToProps = {
    //  onUpdateComponent: updateComponent,

}
export default connect(mapStateToProps, mapDispatchToProps)(CustomYoutubeVideo)
