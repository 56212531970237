import {
    //ServiceURLs,
    EnvironmentEndPoints,
    GetBaseCreatorURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const EnvironmentService = {
    CREATE_ENVIRONMENT,
    UPDATE_ENVIRONMENT,
    REMOVE_ENVIRONMENT,
    STEPBYLOAD
  };

//PAGE ID NULL VERIRSEN ALAYI GELIR.
  function CREATE_ENVIRONMENT(RelProjectId,VariableName,VariableValue,IsActive) {
    var js = {
      RelProjectId:RelProjectId ,
      VariableName:VariableName ,
      VariableValue:VariableValue,
      IsActive:IsActive
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + EnvironmentEndPoints.CREATE_ENVIRONMENT,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function UPDATE_ENVIRONMENT(id,RelProjectId,VariableName,VariableValue,IsActive) {
    var js = {
      id:id,
      RelProjectId:RelProjectId ,
      VariableName:VariableName ,
      VariableValue:VariableValue,
      IsActive:IsActive
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + EnvironmentEndPoints.UPDATE_ENVIRONMENT,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function REMOVE_ENVIRONMENT(id,RelProjectId) {
    var js = {
      id:id,
      RelProjectId:RelProjectId 
 
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + EnvironmentEndPoints.REMOVE_ENVIRONMENT,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function STEPBYLOAD(Count,DynamicObj,IsOrderByDesc,LastIndexId,SearchTerm) {
    var js = {
        Count: Count,
        DynamicObj:DynamicObj,// null,
        IsOrderByDesc: IsOrderByDesc,
        LastIndexId: LastIndexId,//null,
        SearchTerm: SearchTerm
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + EnvironmentEndPoints.STEPBYLOAD,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }