import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { FormGroup, Form, Input, Row, Col, ButtonGroup, Button } from "reactstrap";

import { updateComponent, updateList } from '_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';

import {
    PropertyTitle,
} from "../Core/";

import ColorPicker from "../Core/ColorPicker";
import NumaricDataProperty from "../Core/NumaricDataProperty"
import DynamicFieldValue from '../Core/DynamicFieldValue'


class ProgressBarSettings extends Component {
    constructor(props) {
        super(props)
        this.fontSizeSlider = React.createRef();

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;

        this.state = {
            componentClone: component,
            bgColor: component && (object.props["bgColor"] == undefined ? "#000000" : object.props["bgColor"]),
            pbgColor: component && (object.props["pbgColor"] == undefined ? "rgba(255, 255, 255, 0)" : object.props["pbgColor"]),
        }
    }

    handleChangeProgressBarColor = (color) => {
        const that = this;

        this.setState({
            pbgColor: color,
        }, () => {
            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props["pbgColor"] = `rgba(${this.state.pbgColor.r}, ${this.state.pbgColor.g}, ${this.state.pbgColor.b}, ${this.state.pbgColor.a})`
            } else {
                _clone.components[0].object.props["pbgColor"] = `rgba(${this.state.pbgColor.r}, ${this.state.pbgColor.g}, ${this.state.pbgColor.b}, ${this.state.pbgColor.a})`
            }

            //this.props.updateList(findNestedObjAndChangeALL(this.props.editor,_clone._id,_clone));
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

    handleChangeBackgroundColor = (color) => {
        const that = this;

        this.setState({
            bgColor: color,
        }, () => {
            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props["bgColor"] = `rgba(${this.state.bgColor.r}, ${this.state.bgColor.g}, ${this.state.bgColor.b}, ${this.state.bgColor.a})`
            } else {
                _clone.components[0].object.props["bgColor"] = `rgba(${this.state.bgColor.r}, ${this.state.bgColor.g}, ${this.state.bgColor.b}, ${this.state.bgColor.a})`
            }

            //this.props.updateList(findNestedObjAndChangeALL(this.props.editor,_clone._id,_clone));
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;

        if (prevProps.component != component) {
            this.setState({
                fontColor: style["color"] == undefined ? "#000000" : style["color"],
                backgroundColor: style["backgroundColor"] == undefined ? "rgba(255, 255, 255, 0)" : style["backgroundColor"],

            }, () => {


            })
        }
    }


    componentDidMount() {
    }

    render() {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        return (
            <Translation>
                {t => (
                    <Form>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_PROGRESS_VALUE")}
                                </label>
                            </div>
                            <DynamicFieldValue basicField={
                                <NumaricDataProperty styleClassName={"progressValue"} key={"value-of-progressbar" + component.id} propName={"value"} isCustom={"value"} isType={false} defaultValue={"10"} />
                            } />
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_LAYOUT_BACKGROUNDCOLOR")}
                                </label>
                                <ColorPicker isComponent={true} id={"bgColor"} color={this.state.bgColor} handle={this.handleChangeBackgroundColor} />
                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_PROGRESS_COLOR")}
                                </label>
                                <ColorPicker isComponent={true} id={"pbgColor"} color={this.state.pbgColor} handle={this.handleChangeProgressBarColor} />

                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_LAYOUT_HEIGHT")} subtitle={""} />
                            <NumaricDataProperty key={"size-progressbar" + component.id} propName={"size"} isCustom={"progressbar-size"} isType={false} defaultValue={"10"} />
                        </FormGroup>
                    </Form>
                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;
    return {
        editor,
        component,
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,

}
export default connect(mapStateToProps, mapDispatchToProps)(ProgressBarSettings)
