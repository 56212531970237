import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import IconBox from '../IconModal/IconBox';
import icons from '../../assets/test/icons.json'
import { Input, Loader } from 'rsuite';

import {
    Button,
    Modal,
    Container,
    Row, Col
} from "reactstrap";

class IconsModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: "",
            items: 60,
            loading: false,
            isListener:false,
        }
        this.myscroll = React.createRef();

    }

    componentDidUpdate = (prevProps) => {
        if ((prevProps.isActive !== this.props.isActive) && this.props.isActive) {
            this.setState({
                search: "",
                items: 60,
                loading: false,
                isListener:false,
            })

        }
        if (this.myscroll.current) {
            if(!this.state.isListener){
                this.setState({
                    isListener:true,
                })
                this.myscroll.current.addEventListener("scroll", () => {
                    if (
                        this.myscroll.current.scrollTop + this.myscroll.current.clientHeight >=
                        this.myscroll.current.scrollHeight
                    ) {
                        const iconsArray = icons;
                        if ((iconsArray.filter(x => x.name.toLowerCase().includes(this.state.search.toLowerCase()))).length > this.state.items && !this.state.loading) {
                            this.loadMore();
                        }
                    }
                });
            }
           
        }
    }

    handleChange = (event) => {
        const { target } = event;
        const { name } = target;
        this.setState({
            value: name,
            items: 20
        })
    }

    componentDidMount() {
        // Detect when scrolled to bottom.

    }

    loadMore() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ items: this.state.items + 10, loading: false });
        }, 200);
    }

    render() {
        const { isActive, handleToggle } = this.props;
        const iconsArray = icons;
        return (
            <Translation>
                {t => (
                    <Modal
                        className={"modal-dialog-centered" + " httpobject-modal"}
                        isOpen={isActive}
                        size="lg"
                        scrollable={true}

                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="CheckProjectModal">
                                {t("MODAL_ICONS_TITLE")}
                            </h5>
                            <div className={"icon-search"}>
                                <Input onChange={value => {
                                    this.setState({
                                        search: value,
                                    })
                                }} size="sm" style={{ width: 180 }} placeholder={t("PLACEHOLDER_SEARCH")} />
                            </div>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => handleToggle()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body" ref={this.myscroll}>

                            <Row>
                                <div className={"icons-modal-container"}>
                                    {
                                        iconsArray &&
                                        (iconsArray.filter(x => x.name.toLowerCase().includes(this.state.search.toLowerCase()))).slice(0, this.state.items).map((item, key) => {
                                            return <IconBox
                                                key={key}
                                                src={item.url}
                                                item={item}
                                                name={item.name}
                                                handleModalToggle={handleToggle}
                                                currentSelected={this.props.currentSelected}
                                                onClick={this.props.handleSelectIcon} />
                                        })
                                    }
                                    {
                                        iconsArray &&
                                        (iconsArray.filter(x => x.name.toLowerCase().includes(this.state.search.toLowerCase()))).length == 0 &&
                                        <p>{t("MESSAGE_NO_RESULT_ICONS")}</p>
                                    }
                                </div>

                            </Row>
                            {
                                this.state.loading &&
                                <div style={{ position: "relative",padding:"10px" }}>
                                    <Loader center />

                                </div>
                            }

                        </div>

                    </Modal>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;

    return {
        component,
    };
}
const mapDispatchToProps = {
    //  onUpdateComponent: updateComponent,

}
export default connect(mapStateToProps, mapDispatchToProps)(IconsModal)
