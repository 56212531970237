import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { addNewPage, removeComponent, setMyComponentList } from '_actions'
import { CustomComponentService } from "_services";
import { Alert, Checkbox, CheckboxGroup } from 'rsuite';


import {
    Button,
    Input,
    Modal,
    Container,
    Row, Col
} from "reactstrap";

class EditComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isPublicAccess: false,
            customStyles: {
                componentName: "",
                componentNameState: null,
                componentDesc: "",
                componentDescState: null,
                alert: null,
            }
        }

    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.data != this.props.data) {
             this.setState({
                isPublicAccess: this.props.data ? this.props.data.isPublicAccess : false,
                customStyles: {
                    componentName: this.props.data ? this.props.data.componentName : "",
                    componentNameState: null,
                    componentDesc: "", // SERVİSTE bilgi entegrasyonu olduğunda set edilmedi
                    componentDescState: null,
                    alert: null,
                }

            })
        }
    }

    successAlert = (data) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={data.title}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText={i18n.t("BUTTON_OK")}
                    btnSize=""
                >
                    {data.desc}
                </ReactBSAlert>
            ),
        });
    };
    warningAlert = (data) => {
        this.setState({
            alert: (
                <div className={"modal-sweet-alert"}>
                    <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={data.title}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="warning"
                        confirmBtnText={i18n.t("BUTTON_OK")}
                        btnSize=""
                    >
                        {data.desc}
                    </ReactBSAlert>
                </div>

            ),
        });
    };
    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };


    handleUpdateInfo = () => {
        let _state = this.state.customStyles;
        const { myComponentList, setMyComponentList, handleToggle } = this.props;
        let dupList = [...myComponentList];

        CustomComponentService.SaveCustomComponent(_state.componentName, this.state.isPublicAccess, this.props.data.object, this.props.data.object.id).then(data => {

            let fId = dupList.findIndex(x => x.id === data.dynamicValue.id);
            let _newObj = {
                ...data.dynamicValue,
                object: JSON.parse(data.dynamicValue.object)
            }
            dupList[fId] = _newObj;
            setMyComponentList(dupList);
            Alert.success(i18n.t("MESSAGE_UPDATE_CUSTOM_COMPONENT"));
            handleToggle();

        }).catch(err => {
            Alert.warning(ResponseStatusCode(err.statusCode))
        });

    }

    customStylesForm = (e, stateName) => {
        let newState = this.state.customStyles;
        newState[stateName] = e.target.value;
        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }
        this.setState({
            customStyles: newState
        });
    };


    validateCustomStylesForm = () => {
        let newState = this.state.customStyles;
        newState.componentName = newState.componentName.trim();
        if (newState.componentName === "") {
            newState.componentNameState = "invalid";
        } else {
            newState.componentNameState = "valid";
        }

        newState.componentDesc = newState.componentDesc.trim();
        //  if (newState.componentDesc === "") {
        //     newState.componentDescState = "invalid";
        // } else {
        newState.componentDescState = "valid";
        // }

        this.setState({
            customStyles: newState
        }, () => {
            if (
                (newState.componentNameState == "valid") &&
                (newState.componentDescState == "valid")
            ) {
                this.handleUpdateInfo()
            }
        });
    };


    render() {
        const { isActive, handleToggle, data } = this.props;

        return (
            <Translation>
                {t => (
                    <>
                        <Modal
                            className="modal-dialog-centered"
                            isOpen={isActive}  >
                            <div className="modal-header">
                                <h5 className="modal-title" id="CheckProjectModal">
                                    {t("MODAL_TITLE_EDITCOMPONENT")}
                                </h5>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Container>
                                    <Row style={{ marginBottom: "10px" }}>
                                        <label
                                            className="form-control-label"
                                            htmlFor="validationCustomComponentname"
                                        >
                                            {t("MODAL_TITLE_EDITCOMPONENT_NAME")}
                                        </label>
                                        <Input
                                        innerRef={(input)=>{setTimeout(() => {try {input.focus() } catch (error) { }}, 300);}}
                                        autoFocus
                                            aria-describedby="inputGroupPrepend"
                                            id="validationCustomComponentname"
                                            placeholder={t("MODAL_TITLE_EDITCOMPONENT_NAME_PLACEHOLDER")}
                                            type="text"
                                            defaultValue={this.state.customStyles.componentName}
                                            valid={
                                                this.state.customStyles.componentNameState === "valid"
                                            }
                                            invalid={
                                                this.state.customStyles.componentNameState ===
                                                "invalid"
                                            }
                                            onChange={e => this.customStylesForm(e, "componentName")}
                                        />

                                        <div className="invalid-feedback">
                                            {t("MODAL_CREATE_NEWPAGE_TEXTBOX_NAME_INVALID")}
                                        </div>
                                    </Row>
                                    <Row style={{ marginBottom: "10px" }}>
                                        <label
                                            className="form-control-label"
                                            htmlFor="validationCustomcomponentDesc"
                                        >
                                            {t("MODAL_TITLE_EDITCOMPONENT_DESC")}
                                        </label>
                                        <Input
                                            aria-describedby="inputGroupPrepend"
                                            id="validationCustomcomponentDesc"
                                            placeholder={t("MODAL_TITLE_EDITCOMPONENT_DESC_PLACEHOLDER")}
                                            rows="3"
                                            type="textarea"
                                            defaultValue={this.state.customStyles.componentDesc}
                                            valid={
                                                this.state.customStyles.componentDescState === "valid"
                                            }
                                            invalid={
                                                this.state.customStyles.componentDescState ===
                                                "invalid"
                                            }
                                            onChange={e => this.customStylesForm(e, "componentDesc")}
                                        />

                                        <div className="invalid-feedback">
                                            {t("MODAL_TITLE_EDITCOMPONENT_DESC_INVALID")}
                                        </div>
                                    </Row>
                                    <Row style={{ marginBottom: "10px" }}>
                                        {//checked={data.isPublicAccess} 
                                        }
                                        <Checkbox defaultChecked={this.state.isPublicAccess}   onChange={(value, checked, event) => {
                                            this.setState({ isPublicAccess: value }) }} 
                                            style={{ fontSize: "14px" }}> {t("MODAL_TITLE_EDITCOMPONENT_ISPUBLIC")}</Checkbox>

                                    </Row>
                                </Container>
                            </div>
                            <div className="modal-footer">

                                <Button
                                    color="white"
                                    size={"sm"}
                                    data-dismiss="modal"
                                    disabled={this.state.loading}
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    {t("BUTTON_CANCEL")}
                                </Button>

                                <Button color="primary" size={"sm"} type="button" onClick={() => this.validateCustomStylesForm()}>
                                    {t("BUTTON_SAVE")}
                                </Button>

                            </div>
                            <div></div>
                            {this.state.alert}
                        </Modal>

                    </>

                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { currentstatesReducer, componentReducer, editorReducer } = state;
    const { component, myComponentList } = componentReducer;
    const { currentProjectId } = currentstatesReducer;

    return {
        component,
        currentProjectId,
        myComponentList
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
        addNewPage: (data) => dispatch(addNewPage(data)),
        removeComponent: () => dispatch(removeComponent()),
        setMyComponentList: (_data) => dispatch(setMyComponentList(_data))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditComponent)
