import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { addNewPage, removeComponent } from '_actions'
import { pagesService } from "_services";
import { Alert } from 'rsuite';

import {
    Button,
    Input,
    Modal,
    Container,
    Row, Col
} from "reactstrap";

import CImage from 'components/UI/CImage';

class PageBusy extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }

    }

    componentDidUpdate = () => {
        const { pageStatus, isActive } = this.props;

    }

    render() {
        const { isActive, handleToggle } = this.props;
        return (
            <Translation>
                {t => (
                    <>
                        <Modal
                            className="modal-dialog-centered"
                            isOpen={isActive}>
                            <div className="modal-header pageBusy-header">
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body pageBusy-container">
                                <Container>
                                    <Row className={"pageBusy-container-row"}>
                                        <p className={"pageBusy-title"}>
                                            {t("MODAL_BUSY_ASK")}
                                        </p>
                                    </Row>
                                    <Row className={"pageBusy-container-row"}>
                                        {
                                            // <div className={"pageBusy-photo"}> </div>
                                        }

                                        <CImage
                                            images={
                                                !this.props.pageStatus.status &&
                                                this.props.pageStatus.data &&
                                                this.props.pageStatus.data.hasOwnProperty('profilePhotoImages') &&
                                                this.props.pageStatus.data.profilePhotoImages
                                            }
                                            single={false}
                                            variant={"circle"}
                                        />

                                        <p style={{ textAlign: "center" }}>{!this.props.pageStatus.status && this.props.pageStatus.data && (this.props.pageStatus.data.name + " " + this.props.pageStatus.data.surname)}</p>
                                    </Row>
                                    <Row className={"pageBusy-container-row"}>
                                        <p className={"pageBusy-message"}>
                                            {t("MODAL_BUSY_ASK_DETAIL")}
                                        </p>

                                        <p style={{ textAlign: "center" }}>{"SocketID : " + (!this.props.pageStatus.status && (this.props.pageStatus.data && this.props.pageStatus.data.SocketID))}</p>

                                    </Row>
                                </Container>
                            </div>
                            <div className="modal-footer pageBusy-footer">
                                <Button
                                    color="white"
                                    size={"sm"}
                                    data-dismiss="modal"
                                    disabled={this.state.loading}
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    {t("MODAL_CREATE_NEWPAGE_BUTTON_CANCEL")}
                                </Button>

                                <Button color="primary" size={"sm"} type="button" onClick={() => handleToggle()}>
                                    {t("BUTTON_OK")}
                                </Button>
                            </div>
                        </Modal>

                    </>

                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { currentstatesReducer } = state;
    const { pageStatus } = currentstatesReducer;
    return {
        pageStatus,
    }
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(PageBusy)
