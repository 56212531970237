import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import { ActionCreators as UndoActionCreators } from 'redux-undo'

import { addFavoriteComponent, setMyComponentList } from '_actions';
import { CustomComponentService, ComponentService } from '_services'
import { ResponseStatusCode } from "_helpers";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { Alert } from 'rsuite';

import {
    Button,
    Input,
    Modal,
    Container,
    Row, Col
} from "reactstrap";

class AddFavModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customStyles: {
                name: "",
                nameState: null,
            }
        }

    }
    handleAddFav = () => {
        //  this.props.addFavComponent(this.state.customStyles.name);
        //  this.props.handleToggle();
        this.setCustomComponent();
    }

    generateComponentId = async () => {
        return await ComponentService.GenerateComponentId(this.props.currentProjectId, this.props.currentPage).then(data => {
            // Alert.success(data.dynamicValue)
            return data.dynamicValue;
        }).catch(err => {
            debugger
            Alert.warning(ResponseStatusCode(err.statusCode))
            return null;
        });
    }
    removeAllIdObject = async (obj, key, secondKey) => {
        const that = this;

        var newValue = null;//await this.generateComponentId();
        var objects = [];
        for (var i in obj) {

            if (!obj.hasOwnProperty(i)) continue;
            if (typeof obj[i] == 'object') {
                objects = objects.concat(await this.removeAllIdObject(obj[i], key, secondKey));
            } else if (i == secondKey) {
                //debugger
                obj[key] = newValue;
            }

        }


        return obj;

    }


  

    setCustomComponent = async () => {
        const { addFavComponent, component } = this.props;
        const { customStyles } = this.state;

        // let _id = await this.generateComponentId();


        let _component;
        if (component.hasOwnProperty("componentOwner")) {
            _component = JSON.parse(JSON.stringify(component.object));
        } else {
            _component = JSON.parse(JSON.stringify(component));
        }

        _component = await this.removeAllIdObject(_component, "id", "componentName");

        debugger
        //_component._id = null;

        _component.id = null;
        const that = this;



        CustomComponentService.SaveCustomComponent(customStyles.name, true, _component).then(data => {
            this.getMyComponentList();
            this.props.handleToggle();
        }).catch(err => {
            Alert.warning(ResponseStatusCode(err.statusCode))
            /*
               let newState = that.state.customStyles;
               newState.nameState = "invalid";
               that.setState({
                   customStyles:newState,
               })
               */
        });
    }

    getMyComponentList = () => {
        const { setMyComponentList } = this.props;
        CustomComponentService.GetMycustomComponents().then(data => {
            setMyComponentList(data.dynamicValue);
        }).catch(err => {
            Alert.warning(ResponseStatusCode(err.statusCode))
        });
    }

    customStylesForm = (e, stateName) => {
        let newState = this.state.customStyles;
        newState[stateName] = e.target.value.trim();;
        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }
        this.setState({
            customStyles: newState
        });
    };

    resetContent =()=>{
        this.setState({
            customStyles: {
                name: "",
                nameState: null,
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.isActive)
            if (prevProps.isActive != this.props.isActive && this.props.isActive) {
               this.resetContent();
            }
    }


    validateCustomStylesForm = () => {
        let newState = this.state.customStyles;
        newState.name = newState.name.trim();
        if (newState.name === "") {
            newState.nameState = "invalid";
        } else {
            newState.nameState = "valid";
        }

        this.setState({
            customStyles: newState
        }, () => {
            if (newState.nameState == "valid") {
                this.handleAddFav()
            }
        });
    };


    render() {
        const { isActive, handleToggle } = this.props;
        return (
            <Translation>
                {t => (
                    <Modal
                        className="modal-dialog-centered"
                        isOpen={isActive}
                    //size="lg"
                    // scrollable={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="CheckProjectModal">
                                {t("MODAL_TITLE_ADDFAV")}
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => handleToggle()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Container>
                                <Row>

                                    <label
                                        className="form-control-label"
                                        htmlFor="validationCustomname"
                                    >
                                        {t("MODAL_ADDFAV_COMPONENT_NAME")}
                                    </label>
                                    <Input
                                    innerRef={(input)=>{setTimeout(() => {try {input.focus() } catch (error) { }}, 300);}}
                                    autoFocus
                                        aria-describedby="inputGroupPrepend"
                                        id="validationCustomname"
                                        placeholder={t("MODAL_ADDFAV_COMPONENT_NAME_PLACEHOLDER")}
                                        type="text"
                                        defaultValue={this.state.customStyles.name}
                                        valid={
                                            this.state.customStyles.nameState === "valid"
                                        }
                                        invalid={
                                            this.state.customStyles.nameState ===
                                            "invalid"
                                        }
                                        onChange={e => this.customStylesForm(e, "name")}
                                    />

                                    <div className="invalid-feedback">
                                        {t("MODAL_ADDFAV_COMPONENT_NAME_INVALID")}
                                    </div>
                                </Row>
                            </Container>
                        </div>
                        <div className="modal-footer">

                            <Button
                                color="white"
                                size={"sm"}
                                data-dismiss="modal"
                                disabled={this.state.loading}
                                type="button"
                                onClick={() => handleToggle()}
                            >
                                {t("BUTTON_CANCEL")}
                            </Button>

                            <Button color="primary" size={"sm"} type="button" onClick={() => this.validateCustomStylesForm()}>
                                {t("BUTTON_ADD")}
                            </Button>

                            {
                                /*
                                      <CButton
                                color="primary"
                                size={"sm"}
                                loading={this.state.loading}
                                disabled={(this.state.loading || (this.state.customStyles.nameState == "invalid"))}
                                value={data ? t("BUTTON_UPDATE") : t("BUTTON_CREATE")}
                                onClick={() => { this.validateCustomStylesForm() }}>
                            </CButton>
                                */
                            }
                        </div>
                    </Modal>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor, pages, currentPage, page } = present;
    const { currentProjectId } = currentstatesReducer;

    return {
        component,
        currentPage,
        currentProjectId
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
        addFavComponent: (_name) => dispatch(addFavoriteComponent(_name)),
        setMyComponentList: (list) => dispatch(setMyComponentList(list)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddFavModal)
