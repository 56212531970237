import {
    //ServiceURLs,
    CreatorEndPoints,
    GetBaseCreatorURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const ComponentService = {
   GenerateComponentId,
   GenerateComponentIdList,
   RemoveComponentId,
   GetComponents,
   Removecomponentid_list,
   ChangeComponentName,
   GetComponentNames
  };


  function GetComponentNames(RelProjectID,PageId) {
    var js = {
        RelProjectID:RelProjectID ,
        PageId:PageId
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.GET_COMPONENT_NAMES,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 
  function ChangeComponentName(RelProjectID,PageId,ComponentId,ComponentName) {
    var js = {
        RelProjectID:RelProjectID ,
        PageId:PageId,
        ComponentId:ComponentId,
        ComponentName:ComponentName
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.CHANGE_COMPONENT_NAME,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 
  function Removecomponentid_list(RelProjectID,id,ComponentIds) {
    var js = {
        RelProjectID:RelProjectID ,
        id:id ,
        ComponentIdList:ComponentIds
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.REMOVE_COMPONENT_ID_LIST,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 
  function GenerateComponentIdList(RelProjectID,id,Count) {
    var js = {
        RelProjectID:RelProjectID ,
        id:id,
        Count:Count
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.GENERATE_COMPONENT_ID_LIST,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 
  function GenerateComponentId(RelProjectID,id) {
    var js = {
        RelProjectID:RelProjectID ,
        id:id 
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.GENERATE_COMPONENT_ID,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function RemoveComponentId(RelProjectID,id,ComponentId) {
    var js = {
        RelProjectID:RelProjectID ,
        id:id ,
        ComponentId:ComponentId
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.REMOVE_COMPONENT_ID,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function GetComponents(Version,Latest) {
    var js = {
        Version:Version ,
        Latest:Latest ,
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.GET_COMPONENTS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 