import moment from "moment";
import "moment/locale/de";
import "moment/locale/tr";
import i18n from 'i18n';
import { Alert } from 'rsuite';

//moment.locale(i18n.t("LANG"));
moment.locale("tr");

export function dateformat(date) {
  // 25 Şub 2020 00:04
  return moment(date).format("lll");
}

export function dateformat2(date) {
  // 25 Şubat 2020
  return moment(date).format("LL");
}

export function dateformat3(date) {
  // SONNTAG, 22.09.2019
  return moment(date).format("dddd") + ", " + moment(date).format("L");
}

export function dateformat4(date) {
  //22.09.2019
  return moment(date).format("L");
}

export function serviceDateFormat(date) {
  // date = YYYY-MM-DD
  return moment(date).add(0, "days").format("YYYYMMDD");
}
export function updateServiceDateFormat(str) {
  // date = YYYY-MM-DD
  return moment(str, 'YYYYMMDD').toDate();
}

export function shortDate(date, type) {
  /*
  type: d-> day,  m-> month, y-> year
  default return year
  */
  let f = moment(date).format("ll");
  let _array = f.split(" ");
  let _type = type.toLowerCase();
  return _type == "d" ? _array[0] : _type == "m" ? _array[1] : _array[2];
}

export function getWeekDates() {
  let _array = [];
  for (let i = 0; i < 7; i++) {
    let _d = {
      name: (i == 0 ? i18n.t("Today") : "") +
        moment(new Date()).add(i, "days").format("dddd"),
      date: moment(new Date()).add(i, "days").format("YYYY-MM-DD"),
    };
    // debugger
    _array.push(_d);
  }
  return _array;
}

export function getDayName(_data) {
  if (moment(new Date()).format("dddd") == moment(_data).format("dddd")) {
    return i18n.t("Today2");
  } else {
    return moment(_data).add(0, "days").format("dddd");
  }
}

export function startOfDate(_date) {
  let _date3 = moment(_date).fromNow();
  return _date3;
}

export function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}
export function camelize(text) {
  return text.replace(/^([A-Z])|[\s-_]+(\w)/g, function(match, p1, p2, offset) {
      if (p2) return p2.toUpperCase();
      return p1.toLowerCase();        
  });
}
export function getGlobalYesNo(status, data) {
  try {
    if (data === null) return "-";
    return data.filter((x) => x.status === status)[0].typedValue[0].textValue;
  } catch (error) {
    console.log(error);
  }
}


export function decamelize(str, separator){
	separator = typeof separator === 'undefined' ? '_' : separator;

	return str
        .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
        .toLowerCase();
}
 
export function getMyIdealImage(_data, _ref,that) {
 const renderedImages = _data;
  let current = _ref.current;
//debugger
  that.setState({ loading: true, })
  if (!renderedImages) {
    that.setState({ loading: false, })
    return;
  }
  if (renderedImages == null) {
    that.setState({ img: null, loading: false, })
    return
  }
  else if (renderedImages.length == 0) {
    that.setState({ img: null, loading: false, })
    return
  }

  if (current != null) {
    let w = _ref.current.offsetWidth;
    let h = _ref.current.offsetHeight;
    if (renderedImages == null || renderedImages.length < 1) {
      that.setState({ loading: false, })
      return;
    }

    let closest = null;
    try {
      if (w > h) {
        closest = renderedImages.reduce(function (prev, curr) {
          let _x = curr.width;
          let _px = prev.width;
          return (Math.abs(_x - w) < Math.abs(_px - w) ? curr : prev);
        });
      } else {
        closest = renderedImages.reduce(function (prev, curr) {
          let _y = curr.height;
          let _py = prev.height;
          return (Math.abs(_y - h) < Math.abs(_py - h) ? curr : prev);
        });
      }
    } catch (error) {
      //console.log(error);

    }
    if (closest != null) {
      that.setState({ img: closest.url, loading: false, })
    }

  }

}