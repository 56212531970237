import {
    editorConstants
} from "../_constants";
import Emitter from "_actions/emitter";

const initialState = {
    editor: [],
    page: {},
    pages: [],
    currentPage: null,
};


export function editorReducer(state = initialState, {
    type,
    payload
}) {

    switch (type) {
        case editorConstants.SET_RESET:
            return {
                ...initialState
            };
        case editorConstants.UPDATE_LIST_SOCKET:
            return {
                ...state,
                editor: payload.editor,
            };
        case editorConstants.UPDATE_LIST:
            Emitter.emit("SET_AUTOSAVE", JSON.stringify(payload.editor));
            return {
                ...state,
                editor: payload.editor,
                /* page: {
                     ...state.page,
                     detail: payload.editor
                 }*/
            };
        case editorConstants.SET_PAGES:
            return {
                ...state,
                pages: payload.pages,
                currentPage: payload.pages[0].id // şuan mevcut sayfa 0. index
            };
        case editorConstants.SET_PAGE:
            return {
                ...state,
                page: payload.page,
                editor: payload.page.detail,
                currentPage: payload.page.id,
            };

        case editorConstants.UPDATE_CURRENT_PAGE: // şuan kullanılmıyor
            // debugger
            return {
                ...state,
                editor: state.pages.find(x => x.id == payload.currentPage).detail,
                currentPage: payload.currentPage
            };
        case editorConstants.ADD_NEW_PAGE:
            let newPage = {
                ...payload.newPage,
            }
            state.pages.push(newPage)
            return {
                ...state,
                pages: state.pages,
                currentPage: payload.newPage.id,
                editor: payload.newPage.detail,
                page: payload.newPage,
            };
        case editorConstants.CHANGE_PAGE_NAME:
            let changedPage = {
                ...payload.newPage,
            }
            let fIndex = state.pages.findIndex(x => x.id === changedPage.id);
            state.pages[fIndex].pageName = changedPage.pageName;
            return {
                ...state,
                pages: state.pages,
                page: state.pages[fIndex],
            };
            break;
        case editorConstants.UPDATE_PAGE_STYLE:
            return {
                ...state,
                page: {
                    ...state.page,
                    pageProperties: {
                        ...state.page.pageProperties,
                        style: payload.style,
                    }
                }


            };
        default:
            return state;
    }
}
/*
export undoable(componentReducer, {
    limit: 10 // set a limit for the size of the history
})*/