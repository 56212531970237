import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";

import {
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    Button,
    InputGroup,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap";

import { updateComponent, updateList } from '_actions';
import { findObjUpdate } from 'assets/util';



const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class NumaricDataProperty extends Component {

    constructor(props) {
        super(props)
        const { component, isType, defaultValue } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const _props = object.props;

        var num = (_props[props.propName] == undefined ? "" : _props[props.propName]);

        this.state = {
            component: component,
            type: isType ? ((num === undefined || num === null || num === "") ? "full" : "") : "",
            [props.propName]: (num === undefined || num === null || num === "") ? (defaultValue ? defaultValue : (isType ? null : "0")) : num,
        }

    }

    setProperties = () => {
        const { type } = this.state;
        //const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];


        let _clone = Object.assign({}, this.props.component);

        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props = {
                ..._clone.object.components[0].object.props,
                [this.props.propName]: type === "full" ? null : (this.state[this.props.propName])
            }
        } else {
            _clone.components[0].object.props = {
                ..._clone.components[0].object.props,
                [this.props.propName]: type === "full" ? null : (this.state[this.props.propName])
            }
        }

        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);


    }

    setProgressBarSize = () => {
        const { type } = this.state;
        let _clone = Object.assign({}, this.props.component);

        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props["size"] = (this.state[this.props.propName]);
            _clone.object.components[0].object.props = {
                ..._clone.object.components[0].object.props,
                [this.props.propName]: (type === "full" ? null : (this.state[this.props.propName])),
                style: {
                    ..._clone.object.components[0].object.props.style,
                    minHeight: this.state[this.props.propName] + "px",
                }
            }
        } else {
            _clone.components[0].object.props["size"] = (this.state[this.props.propName]);
            _clone.components[0].object.props = {
                ..._clone.components[0].object.props,
                [this.props.propName]: (type === "full" ? null : (this.state[this.props.propName])),
                style: {
                    ..._clone.components[0].object.props.style,
                    minHeight: this.state[this.props.propName] + "px",
                }
            }
        }

        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);

    }

    setNumaricValue = () => {
        const { type } = this.state;
        let _clone = Object.assign({}, this.props.component);

        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props["value"] = (this.state[this.props.propName]);
        } else {
            _clone.components[0].object.props["value"] = (this.state[this.props.propName]);
        }

        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);

    }

    setQRSizeProperties = () => {
        const { type } = this.state;
        let _clone = Object.assign({}, this.props.component);

        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props = {
                ..._clone.object.components[0].object.props,
                [this.props.propName]: (type === "full" ? null : (this.state[this.props.propName])),
                style: {
                    ..._clone.object.components[0].object.props.style,
                    minHeight: this.state[this.props.propName] + "px",
                    minWidth: this.state[this.props.propName] + "px"
                }
            }
        } else {
            _clone.components[0].object.props = {
                ..._clone.components[0].object.props,
                [this.props.propName]: (type === "full" ? null : (this.state[this.props.propName])),
                style: {
                    ..._clone.components[0].object.props.style,
                    minHeight: this.state[this.props.propName] + "px",
                    minWidth: this.state[this.props.propName] + "px"
                }
            }
        }

        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);


    }

    handleChange = (event) => {
        const { target } = event;
        var theEvent = event || window.event;

        clearTimeout(this.timer);

        var regex = /^[0-9]*$/;
        if (!regex.test(target.value)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        } else {
            this.setState({
                [this.props.propName]: event.target.value,
            });
        }



        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);

    }

    componentWillMount() {
        this.timer = null;
    }

    triggerChange = () => {
        const { isCustom } = this.props;
        if (isCustom == "qr-size") {
            this.setQRSizeProperties();
        } else if (isCustom == "progressbar-size") {
            this.setProgressBarSize();
        } else if (isCustom == "value") {
            this.setNumaricValue();
        }
        else {
            this.setProperties();
        }
    }

    componentDidUpdate = (prevProps) => {
        const { component, isType, defaultValue } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const _props = object.props;

        const num = (_props[this.props.propName] == undefined ? "" : _props[this.props.propName]);
        if (prevProps.component != component) {
            this.setState({
                component: component,
                type: isType ? ((num === undefined || num === null || num === "") ? "full" : "") : "",
                [this.props.propName]: (num === undefined || num === null || num === "") ? (defaultValue ? defaultValue : (isType ? "" : "0")) : num,
            }, () => {

            })
        }
    }

    handleChangeType = (e, selected) => {
        e.preventDefault()

        const _num = this.state[this.props.propName];

        this.setState({
            type: selected,
            [this.props.propName]: selected == "full" ? null : (_num === undefined || _num === null || _num === "") ? "130" : _num,
        }, () => {
            this.setProperties();
        })
    }


    onKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.triggerChange();
            event.preventDefault()
        }
    }

    onBlur = () => {
        this.triggerChange();
    }




    render() {
        const { component, propName, isType,withoutExtension } = this.props;
        const { type } = this.state;

        const { object } = component && component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];


        return (
            <Translation>
                {t => (
                    <div>

                        <InputGroup className={"mb-3"+" "+this.props.styleClassName}>
                            {
                                <Input
                                    pattern="[0-9]*"
                                    name={this.props.propName}
                                    value={this.state[this.props.propName]}
                                    onBlur={() => { }}
                                    onKeyDown={this.onKeyDown.bind(this)}
                                    onBlur={() => { this.onBlur() }}
                                    onInput={this.handleChange.bind(this)}
                                    className={"form-control-sm property-dropdown-input " + ((this.state.type !== "full") ? "" : "property-dropdown-input-display")}
                                    placeholder="" type="text" />
                            }
                            {
                                (this.props.propName != "value" && !this.props.withoutExtension) &&
                                <InputGroupAddon addonType="prepend" className={"property-dropdown"} >
                                    <UncontrolledDropdown group color="primary">
                                        <DropdownToggle caret color="primary" size="sm" className={isType ? "property-dropdown" : "property-dropdown-noType"}>
                                            {isType ?
                                                this.state.type === "" ? "px" : t("PROPERTY_CAROUSEL_WIDTH_FULL") :
                                                "px"
                                            }
                                        </DropdownToggle>
                                        {
                                            isType &&
                                            <DropdownMenu>
                                                <DropdownItem href="#type" style={this.state.type === "" ? { display: "none" } : {}} onClick={e => (this.handleChangeType(e, ""))}>
                                                    <span>px</span>
                                                </DropdownItem>

                                                <DropdownItem href="#type" style={this.state.type === "full" ? { display: "none" } : {}} onClick={e => (this.handleChangeType(e, "full"))}>
                                                    <span>{t("PROPERTY_CAROUSEL_WIDTH_FULL")}</span>
                                                </DropdownItem>


                                            </DropdownMenu>
                                        }

                                    </UncontrolledDropdown>

                                </InputGroupAddon>
                            }



                        </InputGroup>

                    </div>

                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        component,
        editor
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
}
export default connect(mapStateToProps, mapDispatchToProps)(NumaricDataProperty)
