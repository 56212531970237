import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { connect } from 'react-redux';

class CustomIcon extends Component {
    constructor(props) {
        super(props)
        this.state = {
            src: this.props.src,
            height: this.props.height,
            fill: this.props.fill,
        }
    }

    render() {

        const { src, height, fill } = this.props;
        const { data, component, main, isChild } = this.props;

        const _element = document.getElementsByClassName("deviceInside");
        const _elementW = _element[0].offsetWidth + "px";
        const _elementH = _element[0].offsetHeight + "px";
        const _elementW2 = _element[0].offsetWidth / 2 + "px";
        const _elementH2 = _element[0].offsetHeight / 2 + "px";

        const _h = (data.props.style.minHeight == "device" ? _elementH : (data.props.style.minHeight == "half_device" ? _elementH2 : data.props.style.minHeight));
        const _w = (data.props.style.minWidth == "device" ? _elementW : (data.props.style.minWidth == "half_device" ? _elementW2 : data.props.style.minWidth));


        //icon
        let iconData = data.props.style.iconData;
        let iconPosition = data.props.style.iconPosition;
        let iconType = data.props.style.iconType;
        let iconSize = data.props.style.iconSize;
        let iconColor = data.props.style.iconColor;

        let isElement = iconType == "inside" ? false : true;
        return (

            <ReactSVG
                src={iconData.url}
                afterInjection={(error, svg) => {
                    if (error) {
                    //    console.error(error)
                        return
                    }
                  //  console.log(svg)
                }}
                beforeInjection={(svg) => {
                    //svg.classList.add('svg-class-name')
                    svg.setAttribute('style', 'height: ' + (isElement?iconSize:height) + ';' + 'width: ' + (isElement?iconSize:height)  + ';' + ' fill:' + (isElement?iconColor:fill) )
                }}
                evalScripts="always"
                fallback={() => <span></span>}
                loading={() => <span>Loading</span>}
                renumerateIRIElements={false}
                className="element-icons"
                wrapper="span"
            />


        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;

    return {
        componentReducer,
        component,
    };
}
const mapDispatchToProps = {
    //  onUpdateComponent: updateComponent,

}
export default connect(mapStateToProps, mapDispatchToProps)(CustomIcon)
