import {
    globalpropsConstants
} from "../_constants";

const initialState = {
    globalProps: [],
    globalPropsTypes: [],

};

export function globalpropsReducer(state = initialState, {
    type,
    payload
}) {
    switch (type) {
        case globalpropsConstants.SET_RESET:
        return {
            ...initialState
        };
        case globalpropsConstants.CREATE_PROPS:
            let _newArray = [].concat(state.globalProps);
            _newArray.push(payload.data)
            return {
                ...state,
                globalProps: _newArray, //payload.
            };
        case globalpropsConstants.DELETE_PROPS:
            let _newArrayDeleted = [].concat(state.globalProps);
            let _index = _newArrayDeleted.findIndex(x => x.id === payload.id);
            _newArrayDeleted.splice(_index, 1)
            return {
                ...state,
                globalProps: _newArrayDeleted,
            };
        case globalpropsConstants.EDIT_PROPS:
            let _newArrayEdit = [].concat(state.globalProps);
            let _indexEdit = _newArrayEdit.findIndex(x => x.id === payload.data.id);

            _newArrayEdit[_indexEdit] = payload.data;
            return {
                ...state,
                globalProps: _newArrayEdit //payload.data
            };
        case globalpropsConstants.SET_PROP_TYPES:
            //debugger
            return {
                ...state,
                globalPropsTypes: payload.globalPropsTypes
            };
        case globalpropsConstants.SET_PROPS:
           // debugger
            return {
                ...state,
                globalProps: payload.globalProps
            };

        default:
            return state;
    }
}