import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import {
    FormGroup,
    Form,
    ButtonGroup,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input
} from "reactstrap";

import { updateComponent, updateList } from '_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';
import { fontList } from "assets/fontList";

import {
    PropertyTitle,
} from "../Core/";
import ColorPicker from "../Core/ColorPicker";


/* plugins */
import Slider from "nouislider";
import Select2 from "react-select2-wrapper";
import { SelectPicker } from 'rsuite';


class FontSettings extends Component {
    constructor(props) {
        super(props)
        this.fontSizeSlider = React.createRef();

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const style = object.props[props.hasOwnProperty("dataPath") ? props.dataPath : "style"];

        this.state = {
            componentClone: component,
            fontColor: component && (style["color"] == undefined ? "#000000" : style["color"]),
            fontSize: component && (style["fontSize"] == undefined ? "14px" : style["fontSize"]),
            value: component && object.props.value,
            bold: component && (style["fontWeight"] == undefined ? false : (style["fontWeight"] == "bold" ? true : false)),
            italic: component && (style["fontStyle"] == undefined ? false : (style["fontStyle"] == "italic" ? true : false)),
            underline: component && (style["textDecoration"] == undefined ? false : (style["textDecoration"] == "underline" ? true : false)),
            lineThrough: component && (style["textDecoration"] == undefined ? false : (style["textDecoration"] == "line-through" ? true : false)),
            textAlign: component && (style["textAlign"] == undefined ? "unset" : style["textAlign"]),
            label: component && (props.hasOwnProperty("dataPath") ? object.props.label : ""),
            fontFamily: component && (style["fontFamily"] == undefined ? "" : style["fontFamily"]),
        }

    }

    handleUpdateEditor = (_clone) => {

        /*
            state eski halinden farklı ise güncelleme gönderilmeli..

            
        */
        this.props.updateList(findNestedObjAndChangeALL(this.props.editor, _clone.id, _clone));
    }

    handleChangeFontColor = (color) => {
        const that = this;

        this.setState({
            fontColor: color,
        }, () => {
            const _style = that.props.hasOwnProperty("dataPath") ? that.props.dataPath : "style";

            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    color: `rgba(${this.state.fontColor.r}, ${this.state.fontColor.g}, ${this.state.fontColor.b}, ${this.state.fontColor.a})`

                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    color: `rgba(${this.state.fontColor.r}, ${this.state.fontColor.g}, ${this.state.fontColor.b}, ${this.state.fontColor.a})`
                }
            }

            //this.props.updateList(findNestedObjAndChangeALL(this.props.editor,_clone._id,_clone));
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

    handleChangeFontStyle = (event) => {
        const { currentTarget } = event;
        const { name } = currentTarget;
        this.setState({
            underline: name == "underline" ? !this.state[name] : (name == "lineThrough" ? (this.state.lineThrough ? this.state.underline : false) : this.state.underline),
            lineThrough: name == "lineThrough" ? !this.state[name] : (name == "underline" ? (this.state.underline ? this.state.lineThrough : false) : this.state.lineThrough),
            bold: name == "bold" ? !this.state[name] : this.state.bold,
            italic: name == "italic" ? !this.state[name] : this.state.italic,
        }, () => {
            let _clone = Object.assign({}, this.props.component);
            const _style = this.props.hasOwnProperty("dataPath") ? this.props.dataPath : "style";

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    fontWeight: this.state.bold ? "bold" : "normal",
                    fontStyle: this.state.italic ? "italic" : "normal",
                    textDecoration: this.state.underline ? "underline" : (this.state.lineThrough ? "line-through" : "none"),
                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    fontWeight: this.state.bold ? "bold" : "normal",
                    fontStyle: this.state.italic ? "italic" : "normal",
                    textDecoration: this.state.underline ? "underline" : (this.state.lineThrough ? "line-through" : "none"),
                }
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }

    handleChangeTextAlign = (event) => {
        const { currentTarget } = event;
        const { name } = currentTarget;
        const that = this;
        this.setState({
            textAlign: name == this.state.textAlign ? "unset" : name,
        }, () => {
            const _style = this.props.hasOwnProperty("dataPath") ? this.props.dataPath : "style";

            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    textAlign: this.state.textAlign,
                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    textAlign: this.state.textAlign,
                }
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }

    handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        //const that=this;
        await this.setState({
            [name]: value,
        });

    }

    handleOnBlur = () => {
        let _clone = Object.assign({}, this.props.component);

        let current = ""
        if (_clone.hasOwnProperty("componentOwner")) {
            current = _clone.object.components[0].object.props.label //= this.state.value;
        } else {
            current = _clone.components[0].object.props.label// = this.state.value;
        }

        if (this.state.label != current) {

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.label = this.state.label;
            } else {
                _clone.components[0].object.props.label = this.state.label;
            }


            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        }

    }



    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const style = object.props[this.props.hasOwnProperty("dataPath") ? this.props.dataPath : "style"];

        if (prevProps.component != component) {
            this.setState({
                componentClone: component,
                fontColor: style["color"] == undefined ? "#000000" : style["color"],
                bold: style["fontWeight"] == undefined ? false : (style["fontWeight"] == "bold" ? true : false),
                italic: style["fontStyle"] == undefined ? false : (style["fontStyle"] == "italic" ? true : false),
                underline: style["textDecoration"] == undefined ? false : (style["textDecoration"] == "underline" ? true : false),
                lineThrough: style["textDecoration"] == undefined ? false : (style["textDecoration"] == "line-through" ? true : false),
                fontSize: style["fontSize"] == undefined ? "14px" : style["fontSize"],
                textAlign: style["textAlign"] == undefined ? "unset" : style["textAlign"],
                label: this.props.hasOwnProperty("dataPath") ? object.props.label : "",
                fontFamily: (style["fontFamily"] == undefined ? "" : style["fontFamily"]),

            }, () => {
                //this.generateFontSizeInput();
                var _fontSizeSlider = this.fontSizeSlider.current;
                _fontSizeSlider.noUiSlider.set(this.state.fontSize)

            })
        }
    }

    generateFontSizeInput = () => {
        var _fontSizeSlider = this.fontSizeSlider.current;
        const that = this;
        Slider.create(_fontSizeSlider, {
            start: [this.state.fontSize],
            connect: [true, false],
            step: 1,
            tooltips: true,
            format: {
                to: function (value) {
                    return parseInt(value) + 'px';
                },
                from: function (value) {
                    return Number(value.replace('px', ''));
                }
            },
            range: { min: 5, max: 72 }
        });
        _fontSizeSlider.noUiSlider.on('change', function (values, handle) {
            that.setState({
                fontSize: values[handle],
            }, () => {
                const _style = that.props.hasOwnProperty("dataPath") ? that.props.dataPath : "style";

                let _clone = Object.assign({}, that.props.component);
                if (_clone.hasOwnProperty("componentOwner")) {
                    _clone.object.components[0].object.props[_style] = {
                        ..._clone.object.components[0].object.props[_style],
                        fontSize: that.state.fontSize,
                        globalFontSize: null,
                    }
                } else {
                    _clone.components[0].object.props[_style] = {
                        ..._clone.components[0].object.props[_style],
                        fontSize: that.state.fontSize,
                        globalFontSize: null,
                    }
                }

                let _newEditor = JSON.parse(JSON.stringify(that.props.editor))
                let _result = findObjUpdate(_newEditor, _clone);
                that.props.updateComponent(_clone);
                that.props.onUpdateList(_result);

            })
        });
    }


    handleGlobalFontSize = (_data) => {
        let propName = "FontSize";
        const that = this;
        this.setState({ fontSize: _data.value }, () => {
            const _style = this.props.hasOwnProperty("dataPath") ? this.props.dataPath : "style";

            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    ["global" + propName]: _data.id,
                    fontSize: _data.value,
                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    ["global" + propName]: _data.id,
                    fontSize: _data.value,
                }
            }

            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })

    }

    componentDidMount() {
        this.generateFontSizeInput();
    }

    handleSetFontFamily = (_value) => {

        const that = this;
        this.setState({
            fontFamily: _value,
        }, () => {
            const _style = this.props.hasOwnProperty("dataPath") ? this.props.dataPath : "style";

            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    fontFamily: this.state.fontFamily,
                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    fontFamily: this.state.fontFamily,
                }
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }



    render() {
        const { component, globalProps } = this.props;
        let _component;
        if (component.hasOwnProperty("componentOwner"))
            _component = component.object.components[0];
        else
            _component = component.components[0]

        const _style = this.props.hasOwnProperty("dataPath") ? this.props.dataPath : "style";



        return (
            <Translation>
                {t => (
                    <Form>

                        {
                            this.props.hasOwnProperty("dataPath") && (
                                this.props.editableLabelValue &&
                                <FormGroup className={"property-form-group"}>
                                    <label htmlFor="propertyValue-extralabel" className={"propertyTitle"}>{t("PROPERTY_CHECKBOX_LABEL")}
                                    </label>
                                    <Input
                                        //className="form-control-alternative"
                                        className="form-control-sm"
                                        //placeholder={t("PROPERTY_PLACEHOLDER_VALUE")}
                                        type="text"
                                        id={"propertyValue-extralabel"}
                                        name={"label"}
                                        value={this.state.label}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault()
                                                this.handleOnBlur()
                                            }
                                        }}
                                        onBlur={() => { this.handleOnBlur() }}
                                        onChange={(e) => {
                                            this.handleChange(e)
                                        }}
                                    />
                                </FormGroup>)
                        }

                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_FONT_COLOR")}
                                </label>
                                <ColorPicker isComponent={true} id={"checkbox-label-font-color"} color={this.state.fontColor} handle={this.handleChangeFontColor} />
                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            {
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>
                                        {t("PROPERTY_FONT_SIZE")}
                                    </label>
                                    <div>

                                        {
                                            this.props.globalProps &&
                                            ([].concat(this.props.globalProps).filter(x => x.type == 1).length > 0 ?
                                                <UncontrolledDropdown group>
                                                    <DropdownToggle caret color="secondary" className={"dropdown-global-fontsize"}>
                                                        <label className={"dropdown-global-fontsize-text"}>
                                                            {
                                                                component && (_component.object.props[_style]["globalFontSize"] ? (globalProps[globalProps.findIndex(x => x.id === _component.object.props[_style]["globalFontSize"])] ? globalProps[globalProps.findIndex(x => x.id === _component.object.props[_style]["globalFontSize"])].name : this.state.fontSize) : this.state.fontSize)
                                                            }
                                                        </label>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {
                                                            this.props.globalProps &&
                                                            ([].concat(this.props.globalProps).filter(x => x.type == 1)).map((item, key) => (
                                                                <DropdownItem href="#fontsizes" key={key} onClick={e => { e.preventDefault(); this.handleGlobalFontSize(item); }}>
                                                                    {item.name}
                                                                </DropdownItem>
                                                            ))
                                                        }
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                :
                                                <label className={"propertySubTitle"} >
                                                    {this.state.fontSize}
                                                </label>)

                                        }
                                    </div>
                                </div>
                            }
                            <div className="slider" ref={this.fontSizeSlider} />
                        </FormGroup>

                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_FONT_FAMILY")} subtitle={""} />
                            <SelectPicker
                                onSelect={(value, item, event) => {this.handleSetFontFamily(value)}}
                                onClean={() => { this.handleSetFontFamily(null)}}
                                style={{
                                    width: "100%",
                                    height: "45px",
                                }}
                                defaultValue={this.state.fontFamily}
                                renderMenuItem={(label, item) => {
                                    return (
                                        <p style={{ fontFamily: item.value, margin: 0 }}>
                                            {label}
                                        </p>
                                    );
                                }}
                                maxHeight={160}
                                placeholder={t("PROPERTY_FONT_FAMILY")}
                                data={fontList}
                            />
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_FONT_STYLE")} subtitle={""} />
                            <div className="bg-secondary">
                                <ButtonGroup>
                                    <Button name={"bold"} onClick={this.handleChangeFontStyle} className={"propertyFontStyleToggle " + (this.state.bold ? "active" : "")} color="secondary" type="button">
                                        <i className="fas fa-bold"></i>
                                    </Button>
                                    <Button name={"italic"} onClick={this.handleChangeFontStyle} className={"propertyFontStyleToggle " + (this.state.italic ? "active" : "")} color="secondary" type="button">
                                        <i className="fas fa-italic"></i>
                                    </Button>
                                    <Button name={"underline"} onClick={this.handleChangeFontStyle} className={"propertyFontStyleToggle " + (this.state.underline ? "active" : "")} color="secondary" type="button">
                                        <i className="fas fa-underline"></i>
                                    </Button>
                                    <Button name={"lineThrough"} onClick={this.handleChangeFontStyle} className={"propertyFontStyleToggle " + (this.state.lineThrough ? "active" : "")} color="secondary" type="button">
                                        <i className="fas fa-strikethrough"></i>
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </FormGroup>

                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_TEXT_ALIGN")} subtitle={""} />
                            <div className="bg-secondary">
                                <ButtonGroup>
                                    <Button name={"left"} onClick={this.handleChangeTextAlign} className={"propertyFontStyleToggle " + (this.state.textAlign == "left" ? "active" : "")} color="secondary" type="button">
                                        <i className="fas fa-align-left"></i>
                                    </Button>
                                    <Button name={"center"} onClick={this.handleChangeTextAlign} className={"propertyFontStyleToggle " + (this.state.textAlign == "center" ? "active" : "")} color="secondary" type="button">
                                        <i className="fas fa-align-center"></i>
                                    </Button>
                                    <Button name={"right"} onClick={this.handleChangeTextAlign} className={"propertyFontStyleToggle " + (this.state.textAlign == "right" ? "active" : "")} color="secondary" type="button">
                                        <i className="fas fa-align-right"></i>
                                    </Button>
                                    <Button name={"justify"} onClick={this.handleChangeTextAlign} className={"propertyFontStyleToggle " + (this.state.textAlign == "justify" ? "active" : "")} color="secondary" type="button">
                                        <i className="fas fa-align-justify"></i>
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </FormGroup>


                    </Form>
                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, globalpropsReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;

    const { globalProps } = globalpropsReducer;

    return {
        editor,
        component,
        globalProps
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,

}
export default connect(mapStateToProps, mapDispatchToProps)(FontSettings)
