import {
    //ServiceURLs,
    StorageEndPoints,
    GetBaseStorageURL,
    MakeReqestAndGetResponse,
    GetUserLang,
    UploadFile,
    UploadFileBase64,
    UploadVideo,
    UploadSound
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
   
  export const StorageService = {
    STORAGE_DELETE,
    STORAGE_REMOVE_PROJECT_ID_FROM_STREAM,
    STORAGE_ADD_PROJECTID_TO_STREAM,
    STORAGE_GET_BOTH_STREAM,
    STORAGE_GET_MY_PROJECT_STREAM,
    STORAGE_GET_MY_STREAM,
    uploadFileBase64,
    uploadFile,
    uploadSound,
    uploadVideo,
    STORAGE_GET_ADMIN_ONLY
  };

  function STORAGE_GET_ADMIN_ONLY(Page,RowPerCount,FirstIndexID,IsOrderByDesc,LastIndexId,SearchTerm) {
    var js = {
        Page: Page,
        RowPerCount: RowPerCount,
        FirstIndexID:FirstIndexID,// null,
        IsOrderByDesc: IsOrderByDesc,
        LastIndexId: LastIndexId,//null,
        SearchTerm: SearchTerm
    };
  
    return MakeReqestAndGetResponse(
      GetBaseStorageURL() + StorageEndPoints.STORAGE_GET_ADMIN_ONLY,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }


  function uploadVideo(file,Name,RelProjectId) {
    return UploadVideo(file,Name,RelProjectId)
    .then(data => {
      if (data.operationResult === true) {
        return Promise.resolve(data);
      }
    })
    .catch(err => {
      console.log(err);
      return Promise.reject(err);
    });
}

  function uploadSound(file,Name,RelProjectId) {
    return UploadSound(file,Name,RelProjectId)
    .then(data => {
      if (data.operationResult === true) {
        return Promise.resolve(data);
      }
    })
    .catch(err => {
      console.log(err);
      return Promise.reject(err);
    });
}

  
  function uploadFile(file,SettingID,Name,RelProjectId) {
    return UploadFile(file, SettingID,Name,RelProjectId)
    .then(data => {
      if (data.operationResult === true) {
        return Promise.resolve(data);
      }
    })
    .catch(err => {
      console.log(err);
      return Promise.reject(err);
    });
}

  function uploadFileBase64(file,SettingID,Name,RelProjectId) {
    return UploadFileBase64(file, SettingID,Name,RelProjectId)
    .then(data => {
      if (data.operationResult === true) {
        return Promise.resolve(data);
      }
    })
    .catch(err => {
      console.log(err);
      return Promise.reject(err);
    });
}
  
   
  function STORAGE_GET_MY_STREAM(Page,RowPerCount,FirstIndexID,IsOrderByDesc,LastIndexId,DynamicObj,SearchTerm) {
    var js = {
        Page: Page,
        RowPerCount: RowPerCount,
        FirstIndexID:FirstIndexID,// null,
        IsOrderByDesc: IsOrderByDesc,
        LastIndexId: LastIndexId,//null,
        DynamicObj:DynamicObj,
        SearchTerm: SearchTerm
    };
  
    return MakeReqestAndGetResponse(
      GetBaseStorageURL() + StorageEndPoints.STORAGE_GET_MY_STREAM,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function STORAGE_GET_MY_PROJECT_STREAM(Page,RowPerCount,FirstIndexID,IsOrderByDesc,LastIndexId,DynamicObj,SearchTerm) {
    var js = {
        Page: Page,
        RowPerCount: RowPerCount,
        FirstIndexID:FirstIndexID,// null,
        IsOrderByDesc: IsOrderByDesc,
        LastIndexId: LastIndexId,//null,
        DynamicObj:DynamicObj,
        SearchTerm: SearchTerm
    };
  
    return MakeReqestAndGetResponse(
      GetBaseStorageURL() + StorageEndPoints.STORAGE_GET_MY_PROJECT_STREAM,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }


  function STORAGE_GET_BOTH_STREAM(Page,RowPerCount,FirstIndexID,IsOrderByDesc,LastIndexId,DynamicObj,SearchTerm) {
    var js = {
        Page: Page,
        RowPerCount: RowPerCount,
        FirstIndexID:FirstIndexID,// null,
        IsOrderByDesc: IsOrderByDesc,
        LastIndexId: LastIndexId,//null,
        DynamicObj:DynamicObj,
        SearchTerm: SearchTerm
    };
  
    return MakeReqestAndGetResponse(
      GetBaseStorageURL() + StorageEndPoints.STORAGE_GET_BOTH_STREAM,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }




  function STORAGE_ADD_PROJECTID_TO_STREAM(ProjectId,StorageId) {
    var js = {
        StorageId:StorageId,
        ProjectId:ProjectId  
    };
  
    return MakeReqestAndGetResponse(
      GetBaseStorageURL() + StorageEndPoints.STORAGE_ADD_PROJECTID_TO_STREAM,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }


  function STORAGE_REMOVE_PROJECT_ID_FROM_STREAM(ProjectId,StorageId) {
    var js = {
        StorageId:StorageId,
        ProjectId:ProjectId  
    };
  
    return MakeReqestAndGetResponse(
      GetBaseStorageURL() + StorageEndPoints.STORAGE_REMOVE_PROJECT_ID_FROM_STREAM,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function STORAGE_DELETE(StorageId) {
    var js = {
        StorageId:StorageId  
    };
  
    return MakeReqestAndGetResponse(
      GetBaseStorageURL() + StorageEndPoints.STORAGE_DELETE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
    
  
  
  
   