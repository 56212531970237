import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { Alert, Loader } from 'rsuite';
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";

import {
    Button,
    Row,
    Col,
    ListGroup,
} from "reactstrap";
import QRCode from "react-qr-code";
import { setCustomFunctions, sweetAlertActions } from '_actions'

import { ButtonToolbar, ButtonGroup, Whisper, Tooltip, Dropdown, Icon, IconButton, FormGroup, Form } from 'rsuite';
import {
    CustomFunctionService,
} from "_services";

import CreateCustomFunction from 'components/Modals/CreateCustomFunction';
import CustomScript from 'components/Modals/CustomScript'

class ProjectSettings extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            isCreateFunction: false,
            isEditor: false,
            editorData: null,
        }
    }


    handleCreateModal = () => {
        this.setState({
            isCreateFunction: !this.state.isCreateFunction,
        })
    }

    handleSaveFunction = (_data) => {
        const { currentProjectId } = this.props;
        const that = this;
        const { editorData } = this.state;
        CustomFunctionService.UPDATE_FUNCTION(editorData.id, currentProjectId,_data.name,_data.script).then(data => {
            if (data.operationResult) {
                that.handleEditScript(null);
                that.getCustomFunctions();
                Alert.success(i18n.t("SUCCESS_SAVED"));
            }

        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

        });
    }

    getCustomFunctions = () => {
        const { setPage, setCustomFunctions, clearHistory, currentProjectId, socket } = this.props;
        this.setState({
            loading: true,
        })
        const that = this;
        CustomFunctionService.STEPBYLOAD(9999, currentProjectId, false, "-1", "").then(data => {
            if (data.operationResult) {
                setCustomFunctions(data.dynamicValue.dynamicValue);
            }
            that.setState({
                loading: false,
            })

        }).catch(err => {
            debugger
            that.setState({
                loading: false,
            })
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

        });
    }

    componentDidMount = () => {
        Emitter.on("handleNo", newValue =>
            Emitter.off("handleYes")
        );
    }
    componentWillUnmount() {
        Emitter.off("handleYes");
    }

    handleAskDelete = (_id) => {
        Emitter.on("handleYes", newValue => this.deleteCustomFunctions(_id)
        );

        sweetAlertActions.SHOW_ASK(
            "",
            "",
            "",
            i18n.t("MESSAGE_DELETE_FUNCTION_SUBTITLE"),
            i18n.t("MESSAGE_DELETE_FUNCTION_SUCCESS"),
            i18n.t("MESSAGE_DELETE_FUNCTION_UNSUCCESS")
        );
    }

    handleEditScript = (_data) => {
        this.setState({
            isEditor: !this.state.isEditor,
            editorData: _data
        })
    }

    deleteCustomFunctions = (_id) => {
        const { setPage, setCustomFunctions, clearHistory, currentProjectId, socket } = this.props;
        const that = this;
        CustomFunctionService.REMOVE_FUNCTION(_id, currentProjectId).then(data => {
            if (data.operationResult) {
                that.getCustomFunctions()
            }

        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

        });
    }

    render() {
        const { currentPage, currentProjectId, customFunctions } = this.props;

        return (
            <Translation>
                {t => (
                    <div>
                        <div className={"ho-header"}>
                            {t("TITLE_CUSTOM_FUNCTIONS")}
                            {
                                this.props.currentProjectId ?
                                    <><ButtonToolbar>
                                        <ButtonGroup>
                                            <IconButton appearance="ghost" onClick={() => this.handleCreateModal(null)} icon={
                                                <Whisper placement="auto" trigger="hover" speaker={
                                                    <Tooltip>
                                                        {t("BUTTON_ADD")}
                                                    </Tooltip>
                                                }>
                                                    <Icon icon="plus-square-o" />
                                                </Whisper>
                                            } />
                                            <IconButton appearance="ghost" onClick={() => {
                                                this.setState({ value: "" })
                                                this.getCustomFunctions()
                                            }} icon={
                                                <Whisper placement="auto" trigger="hover" speaker={
                                                    <Tooltip>
                                                        {t("BUTTON_REFRESH")}
                                                    </Tooltip>
                                                }>
                                                    <Icon icon="refresh" />
                                                </Whisper>
                                            } />
                                        </ButtonGroup>
                                    </ButtonToolbar></>
                                    : t("HAS_PROJECT_ERROR")
                            }
                        </div>
                        <hr />

                        {
                            customFunctions &&
                                customFunctions.length > 0 ?
                                <div >
                                    {
                                        customFunctions.map((item, key) => (
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                paddingBottom: "1em"
                                            }} key={key}>
                                                <div style={{
                                                    position: "relative",
                                                    overflow: "hidden", display: "flex", flexDirection: "row", alignItems: "center"
                                                }}>
                                                    <p style={{
                                                        margin: "0px",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        marginRight: "7px"
                                                    }}>
                                                        {item.name}
                                                    </p>
                                                </div>

                                                <Dropdown placement="bottomEnd" renderTitle={() => {
                                                    return <IconButton circle size="xs" icon={<Icon icon="ellipsis-v" />} circle />;
                                                }}>
                                                    <Dropdown.Item onClick={() => { this.handleEditScript(item) }}>
                                                        <Icon icon="edit2" /> {t("BUTTON_EDIT")}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => { this.handleAskDelete(item.id) }}>
                                                        <Icon icon="trash" /> {t("BUTTON_DELETE")}
                                                    </Dropdown.Item>
                                                </Dropdown>

                                            </div>
                                        ))
                                    }
                                </div>
                                : <p>{t("HAS_NO_CUSTOM_FUNCTIONS")}</p>

                        }
                        <CustomScript isActive={this.state.isEditor} data={this.state.editorData} handleToggle={()=>{this.handleEditScript(null)}} handleSave={this.handleSaveFunction} />
                        <CreateCustomFunction isActive={this.state.isCreateFunction}  handleToggle={this.handleCreateModal} />
                        {this.state.loading &&
                            <Loader backdrop vertical />}
                    </div>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, globalpropsReducer, currentstatesReducer, editorReducer } = state;
    const { component } = componentReducer;
    const { globalProps } = globalpropsReducer;
    const { currentProjectId, uiEditorStatus, customFunctions } = currentstatesReducer;
    const { present } = editorReducer;
    const { editor, pages, currentPage, page } = present;

    return {
        componentReducer,
        component,
        globalProps,
        currentProjectId,
        uiEditorStatus,
        currentPage,
        customFunctions
    };
}
const mapDispatchToProps = {
    setCustomFunctions,
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings)


