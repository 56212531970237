export const SocketOperations={
    SET_CURRENT_PAGE:"SET_CURRENT_PAGE",
    GET_CURRENT_PAGE_USERS:"GET_CURRENT_PAGE_USERS",
    SET_AUTO_SAVE:"SET_AUTO_SAVE",
    GET_AUTO_SAVE:"GET_AUTO_SAVE",
    CLEAR_AUTO_SAVED:"CLEAR_AUTO_SAVED",
    GIVE_DETAIL:"GIVE_DETAIL",
    PAGE_CONTROL_AVAILABLE_RESULT:"PAGE_CONTROL_AVAILABLE_RESULT",
    exportOperationsObserve:"exportOperationsObserve"
}

export const SocketOperationsLocal={
    SET_SOCKET_REF: "SET_SOCKET_REF",
}
