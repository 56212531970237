export const fontList = [
    {
        "label": "Arimo",
        "value": "Arimo-Regular"
    },
    {
        "label": "Arimo",
        "value": "Arimo-Bold"
    },
    {
        "label": "Arsenal",
        "value": "Arsenal-Regular"
    },
    {
        "label": "Arsenal",
        "value": "Arsenal-Bold"
    },
    {
        "label": "Arvo",
        "value": "Arvo"
    },
    {
        "label": "Arvo",
        "value": "Arvo-Bold"
    },
    {
        "label": "Big Shoulders Display",
        "value": "BigShouldersDisplay-Regular"
    },
    {
        "label": "Big Shoulders Display",
        "value": "BigShouldersDisplay-Bold"
    },
    {
        "label": "Bungee Shade",
        "value": "BungeeShade-Regular"
    },
    {
        "label": "Calligraffitti",
        "value": "Calligraffitti-Regular"
    },
    {
        "label": "Codystar",
        "value": "Codystar"
    },
    {
        "label": "Dancing Script",
        "value": "DancingScript-Regular"
    },
    {
        "label": "Dosis",
        "value": "Dosis-Regular"
    },
    {
        "label": "Dosis",
        "value": "Dosis-Bold"
    },
    {
        "label": "EB Garamond",
        "value": "EBGaramond-Regular"
    },
    {
        "label": "Fira Sans",
        "value": "FiraSans-Regular"
    },
    {
        "label": "Fira Sans",
        "value": "FiraSans-Bold"
    },
    {
        "label": "Heebo",
        "value": "Heebo-Regular"
    },
    {
        "label": "Heebo",
        "value": "Heebo-Bold"
    },
    {
        "label": "Hind",
        "value": "Hind-Regular"
    },
    {
        "label": "Hind",
        "value": "Hind-Bold"
    },
    {
        "label": "Lato",
        "value": "Lato-Regular"
    },
    {
        "label": "Lato",
        "value": "Lato-Bold"
    },
    {
        "label": "Londrina Outline",
        "value": "LondrinaOutline-Regular"
    },
    {
        "label": "Londrina Solid",
        "value": "LondrinaSolid-Regular"
    },
    {
        "label": "Martel",
        "value": "Martel-Regular"
    },
    {
        "label": "Martel",
        "value": "Martel-Bold"
    },
    {
        "label": "Megrim",
        "value": "Megrim"
    },
    {
        "label": "Monoton",
        "value": "Monoton-Regular"
    },
    {
        "label": "Montserrat",
        "value": "Montserrat-Regular"
    },
    {
        "label": "Montserrat",
        "value": "Montserrat-Bold"
    },
    {
        "label": "Noto Sans",
        "value": "NotoSans-Regular"
    },
    {
        "label": "Noto Sans",
        "value": "NotoSans-Bold"
    },
    {
        "label": "Nunito Sans",
        "value": "NunitoSans-Regular"
    },
    {
        "label": "Nunito Sans",
        "value": "NunitoSans-Bold"
    },
    {
        "label": "Open Sans",
        "value": "OpenSans-Regular"
    },
    {
        "label": "Open Sans",
        "value": "OpenSans-Bold"
    },
    {
        "label": "Oswald",
        "value": "Oswald-Regular"
    },
    {
        "label": "Oswald",
        "value": "Oswald-Bold"
    },
    {
        "label": "Pacifico",
        "value": "Pacifico-Regular"
    },
    {
        "label": "Playfair Display",
        "value": "PlayfairDisplay-Regular"
    },
    {
        "label": "Playfair Display",
        "value": "PlayfairDisplay-Bold"
    },
    {
        "label": "Poppins",
        "value": "Poppins-Regular"
    },
    {
        "label": "Poppins",
        "value": "Poppins-Bold"
    },
    {
        "label": "Quicksand",
        "value": "Quicksand-Regular"
    },
    {
        "label": "Quicksand",
        "value": "Quicksand-Bold"
    },
    {
        "label": "Quicksand Light",
        "value": "Quicksand-Light"
    },
    {
        "label": "Raleway Dots",
        "value": "RalewayDots-Regular"
    },
    {
        "label": "Roboto",
        "value": "Roboto-Regular"
    },
    {
        "label": "Roboto",
        "value": "Roboto-Bold"
    },
    {
        "label": "Roboto Condensed",
        "value": "RobotoCondensed-Regular"
    },
    {
        "label": "Roboto Condensed",
        "value": "RobotoCondensed-Bold"
    },
    {
        "label": "Saira",
        "value": "Saira-Regular"
    },
    {
        "label": "Saira",
        "value": "Saira-Bold"
    },
    {
        "label": "Satisfy",
        "value": "Satisfy-Regular"
    },
    {
        "label": "Sora",
        "value": "Sora-Regular"
    },
    {
        "label": "Sora",
        "value": "Sora-Bold"
    },
    {
        "label": "Source Sans Pro",
        "value": "SourceSansPro-Regular"
    },
    {
        "label": "Source Sans Pro",
        "value": "SourceSansPro-Bold"
    },
    {
        "label": "Work Sans",
        "value": "WorkSans-Regular"
    },
    {
        "label": "Work Sans",
        "value": "WorkSans-Bold"
    }
]