import { combineReducers } from "redux";
import undoable from 'redux-undo'

import {componentReducer} from './component.reducer';
import {editorReducer} from './editor.reducer';
import {globalpropsReducer} from './globalprops.reducer';
import {SocketReducer} from './socket.reducer';

import { authentication } from "./authentication.reducer";
import { currentstatesReducer } from "./currentstates.reducer";

 
const rootReducer = combineReducers({
    editorReducer: undoable(editorReducer, {
        limit: 31 // set a limit for the size of the history
      }),
    componentReducer,
    globalpropsReducer,
    authentication,
    SocketReducer,
    currentstatesReducer
});

export default rootReducer;
