import React, { Component } from 'react';
import { Translation } from "react-i18next";
import { connect } from 'react-redux';
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import Emitter from "_actions/emitter";
import SweetAlert from "react-bootstrap-sweetalert";

import Dropzone from "dropzone";
import { Divider, Pagination, Nav, Alert, Loader, Input, InputGroup, Icon } from 'rsuite';

import ImageBox from './ImageBox';
import GalleryTitle from './GalleryTitle';

import { findObjUpdate } from 'assets/util';
import { updateList, setMyStream, setSystemStream, setMyStreamLoading, setProjectStream, setProjectStreamLoading, setSystemStreamLoading, updateComponent } from "_actions";

import { StorageService } from "_services";

import {
    Row, Col
} from "reactstrap";

Dropzone.autoDiscover = false;

class GalleryBox extends Component {
    constructor(props) {
        super(props)
        this.myDropzoneRef = React.createRef();

        this.state = {
            activePage: 1,
            activeProjectPage: 1,
            activeSystemPage: 1,
            dropzone: null,
            activeTab: "my",
            alert: null,
        }
    }
    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };

    successAlert = (data) => {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={data.title}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText={i18n.t("BUTTON_OK")}
                    btnSize=""
                >
                    {data.desc}
                </SweetAlert>
            ),
        });
    };



    askAlert = (id) => {
        this.setState({
            alert: (
                <SweetAlert
                    custom
                    style={{ display: "block", marginTop: "-100px" }}
                    title={i18n.t("GALLERY_MODAL_REMOVE_IMAGE_QUESTION1")}
                    customIcon={
                        <div
                            className="swal2-icon swal2-question swal2-animate-question-icon"
                            style={{ display: "flex" }}
                        >
                            <span className="swal2-icon-text">?</span>
                        </div>
                    }
                    onConfirm={() => this._handleRemove(id)}
                    onCancel={() => this.hideAlert()}

                    showCancel

                    confirmBtnBsStyle={"danger"}
                    confirmBtnText={i18n.t("GALLERY_MODAL_REMOVE_IMAGE_YES")}

                    cancelBtnBsStyle={"secondary"}
                    cancelBtnText={i18n.t("BUTTON_CANCEL")}


                    btnSize=""
                >
                    {i18n.t("GALLERY_MODAL_REMOVE_IMAGE_QUESTION")}
                </SweetAlert>
            ),
        })
    }

    componentDidMount() {
        this.getMyStream("", 1);
        this.getProjectStream();
        this.getSystemStream();

        let currentSingleFile = undefined;
        const { handlePrepareDropzone } = this.props;

        setTimeout(
            () => {
                handlePrepareDropzone();
            },
            500
        );


    }
    _handleRemove = (id) => {
        const { setMyStreamLoading } = this.props;

        setMyStreamLoading(true);
        this.hideAlert();
        StorageService.STORAGE_DELETE(id).then(data => {
            this.getMyStream("", 1);
        }).catch(err => {
            setMyStreamLoading(false);
            Alert.error(ResponseStatusCode(err.statusCode));

        });
    }

    handleSelectedImage = (_item) => {

        let _url = _item.renderedImages[0].path;

        let _clone = Object.assign({}, this.props.component);
        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props.style = {
                ..._clone.object.components[0].object.props.style,
                backgroundImage: _url == null ? "unset" : ("url(" + _url + ")")
            }
        } else {
            _clone.components[0].object.props.style = {
                ..._clone.components[0].object.props.style,
                backgroundImage: _url == null ? "unset" : ("url(" + _url + ")")
            }
        }
        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);

    }

    handleSelect = (eventKey) => {
        const that = this;
        this.setState({ activePage: eventKey }, () => {
            that.getMyStream(null, that.state.activePage)
        });
    }
    handleSelectProject = (eventKey) => {
        const that = this;
        this.setState({ activeProjectPage: eventKey }, () => {
            that.getProjectStream(null, that.state.activeProjectPage)
        });
    }

    handleSelectSystem = (eventKey) => {
        const that = this;
        this.setState({ activeSystemPage: eventKey }, () => {
            that.getSystemStream(null, that.state.activeSystemPage)
        });
    }


    getMyStream = (_searchTerm = null, _page = 1) => {
        const { setMyStream, currentMyStream, myStream, setMyStreamLoading } = this.props;
        const { firstIndexID, lastIndexId, rowPerCount, isOrderByDesc, searchTerm } = currentMyStream;
        let st = _searchTerm != null ? _searchTerm.trim() : searchTerm;

        setMyStreamLoading(true);
        StorageService.STORAGE_GET_MY_STREAM(_page-1, rowPerCount, firstIndexID, isOrderByDesc, lastIndexId, this.props.currentProjectId, st).then(data => {
            setTimeout(function () {
                setMyStreamLoading(false);
            }, 1000);


            if (data.operationResult) {
                setMyStream(data.dynamicValue)
            }
        }).catch(err => {
            setMyStreamLoading(false);
            Alert.error(ResponseStatusCode(err.statusCode));

        });
    }

    getProjectStream = (_searchTerm = null, _page = 1) => {
        const { setProjectStream, currentProjectStream, setProjectStreamLoading } = this.props;
        const { firstIndexID, lastIndexId, rowPerCount, isOrderByDesc, searchTerm } = currentProjectStream;
        let st = _searchTerm != null ? _searchTerm.trim() : searchTerm;
        setProjectStreamLoading(true);
        StorageService.STORAGE_GET_MY_STREAM(_page-1, rowPerCount, firstIndexID, isOrderByDesc, lastIndexId, this.props.currentProjectId, st).then(data => {
            setTimeout(function () {
                setProjectStreamLoading(false);
            }, 1000);

            if (data.operationResult) {
                setProjectStream(data.dynamicValue)
            }
        }).catch(err => {
            setProjectStreamLoading(false);
            Alert.error(ResponseStatusCode(err.statusCode));

        });
    }

    getSystemStream = (_searchTerm = null, _page = 1) => {
        const { setSystemStream, currentSystemStream, systemStream, setSystemStreamLoading } = this.props;
        const that = this;

        const { firstIndexID, lastIndexId, rowPerCount, isOrderByDesc, searchTerm } = currentSystemStream;

        let st = _searchTerm != null ? _searchTerm.trim() : searchTerm;
        setSystemStreamLoading(true);
        StorageService.STORAGE_GET_ADMIN_ONLY(
            _page-1,
            rowPerCount,
            firstIndexID,
            isOrderByDesc,
            lastIndexId,
            st
        ).then(data => {
            setTimeout(function () {
                setSystemStreamLoading(false);
            }, 1000);
            if (data.operationResult) {
                setSystemStream(data.dynamicValue)
            }
        }).catch(err => {
            setSystemStreamLoading(false);
            Alert.error(ResponseStatusCode(err.statusCode));

        });
    }

    handleTabSelect = (activeKey) => {
        this.setState({ activeTab: activeKey });
    }

    render() {
        const { isActive, handleModalToggle, myStream, projectStream, currentMyStream, currentProjectStream, currentSystemStream, systemStream } = this.props;
        return (
            <Translation>
                {t => (
                    <div
                        className={this.props.className}
                        ref={this.props.ref}>

                        <GalleryTitle title={t("TITLE_IMAGE_GALLERY_DROPZONE")} />
                        <Row className={"gallery-modal-content"}>
                            <div className={"gallery-modal-container2"}>
                                <div className="dropzone dropzone-single mb-3" id="dropzone-single">
                                    <div className="fallback">
                                        <div className="custom-file">
                                            <input
                                                className="custom-file-input"
                                                id="projectCoverUploads"
                                                type="file"
                                            />
                                            <label
                                                className="custom-file-label"
                                                htmlFor="projectCoverUploads"
                                            >
                                                Choose file
                                                    </label>
                                        </div>
                                    </div>
                                    <div className="dz-preview dz-preview-single">
                                        <div className="dz-preview-cover">
                                            <img
                                                alt="..."
                                                className="dz-preview-img"
                                                data-dz-thumbnail=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <Divider />
                        </Row>

                        <Nav appearance="subtle" activeKey={this.state.activeTab} onSelect={this.handleTabSelect} style={{ marginBottom: 0 }}>
                            <Nav.Item eventKey="my"> My Images </Nav.Item>
                            <Nav.Item eventKey="project">Project Images</Nav.Item>
                        </Nav>

                        {
                            this.state.activeTab == "my" &&
                            <>
                                <GalleryTitle title={""}
                                    Child={
                                        <InputGroup style={{ marginBottom: 10, maxWidth: "200px" }} size="sm" >
                                            <InputGroup.Button style={{ color: "#B9B9B9" }}>
                                                <Icon icon="search" />
                                            </InputGroup.Button>
                                            <Input placeholder={t("PLACEHOLDER_SEARCH")}
                                                onChange={(value) => {
                                                    let _value = value.trim();
                                                    this.getMyStream(_value)

                                                }} />
                                        </InputGroup>
                                    } />
                                {
                                    myStream.length > 0 ?
                                        <div className={"gallery-modal-content"}>
                                            <Row className={"gallery-modal-container"}>
                                                {
                                                    myStream.map((item, key) => {
                                                        return <ImageBox
                                                            key={key}
                                                            src={item.url}
                                                            item={item}
                                                            isMine={true}
                                                            name={item.name}
                                                            handleRemove={this.askAlert}
                                                            handleModalToggle={handleModalToggle}
                                                            currentSelected={this.props.currentSelected}
                                                            onClick={this.handleSelectedImage} />
                                                    })

                                                }
                                            </Row>
                                            {
                                                currentMyStream.count > myStream.length &&
                                                <Pagination
                                                    pages={Math.ceil(currentMyStream.count / currentMyStream.rowPerCount)}
                                                    maxButtons={5}
                                                    activePage={currentMyStream.page+1}
                                                    onSelect={this.handleSelect}
                                                />
                                            }
                                            {
                                                this.props.isMyStreamLoading &&
                                                <div className={"modal-loading-container fade-in"}>
                                                    <Loader center size="md" />
                                                </div>
                                            }

                                        </div>
                                        :
                                        <div className={"notfound-stream-container"}>
                                            {t("NOT_FOUND_MYSTREAM")}
                                        </div>
                                }

                            </>
                        }
                        {
                            this.state.activeTab == "project" &&
                            <>
                                <GalleryTitle title={""}
                                    Child={
                                        <InputGroup style={{ marginBottom: 10, maxWidth: "200px" }} size="sm" >
                                            <InputGroup.Button style={{ color: "#B9B9B9" }}>
                                                <Icon icon="search" />
                                            </InputGroup.Button>
                                            <Input placeholder={t("PLACEHOLDER_SEARCH")}
                                                onChange={(value) => {
                                                    let _value = value.trim();
                                                    this.getProjectStream(_value)

                                                }} />
                                        </InputGroup>
                                    } />
                                {
                                    projectStream.length > 0 ?
                                        <div className={"gallery-modal-content"}>
                                            <Row className={"gallery-modal-container"}>
                                                {
                                                    projectStream.map((item, key) => {
                                                        return <ImageBox
                                                            key={key}
                                                            src={item.url}
                                                            item={item}
                                                            name={item.name}
                                                            handleModalToggle={handleModalToggle}
                                                            currentSelected={this.props.currentSelected}
                                                            onClick={this.handleSelectedImage} />
                                                    })

                                                }
                                            </Row>
                                            {
                                                currentProjectStream.count > projectStream.length &&
                                                <Pagination
                                                    pages={Math.ceil(currentProjectStream.count / currentProjectStream.rowPerCount)}
                                                    maxButtons={5}
                                                    activePage={currentProjectStream.page+1}
                                                    onSelect={this.handleSelectProject}
                                                />
                                            }
                                            {
                                                this.props.isProjectStreamLoading &&
                                                <div className={"modal-loading-container fade-in"}>
                                                    <Loader center size="md" />
                                                </div>
                                            }

                                        </div>
                                        :
                                        <div className={"notfound-stream-container"}>
                                            {t("NOT_FOUND_MYSTREAM")}
                                        </div>
                                }

                            </>
                        }



                        <Row>
                            <Divider />
                        </Row>

                        <GalleryTitle title={t("TITLE_IMAGE_GALLERY_SYSTEM")} Child={
                            <InputGroup style={{ marginBottom: 10, maxWidth: "200px" }} size="sm" >
                                <InputGroup.Button style={{ color: "#B9B9B9" }}>
                                    <Icon icon="search" />
                                </InputGroup.Button>
                                <Input placeholder={t("PLACEHOLDER_SEARCH")}
                                    onChange={(value) => {
                                        let _value = value.trim();
                                        this.getSystemStream(_value)
                                    }} />
                            </InputGroup>
                        } />

                        {
                            systemStream.length > 0 ?
                                <div className={"gallery-modal-content"}>
                                    <Row className={"gallery-modal-container"}>

                                        {
                                            systemStream.map((item, key) => {
                                                return <ImageBox
                                                    key={key}
                                                    src={item.url}
                                                    item={item}
                                                    name={item.name}
                                                    handleModalToggle={handleModalToggle}
                                                    currentSelected={this.props.currentSelected}
                                                    onClick={this.handleSelectedImage} />
                                            })

                                        }
                                    </Row>
                                    {
                                        currentSystemStream.count > systemStream.length &&
                                        <Pagination
                                            pages={Math.ceil(currentSystemStream.count / currentSystemStream.rowPerCount)}
                                            maxButtons={5}
                                            activePage={currentSystemStream.page+1}
                                            onSelect={this.handleSelectSystem}
                                        />
                                    }
                                    {
                                        this.props.isSystemStreamLoading &&
                                        <div className={"modal-loading-container fade-in"}>
                                            <Loader center size="md" />
                                        </div>
                                    }
                                </div>
                                :
                                <div className={"notfound-stream-container"}>
                                    {t("NOT_FOUND_SYSTEMSTREAM")}
                                </div>
                        }
                        <div></div>
                        {this.state.alert}

                    </div>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { currentstatesReducer, componentReducer, editorReducer } = state;
    const { currentProjectId, currentMyStream, currentProjectStream, currentSystemStream, isMyStreamLoading, isProjectStreamLoading, isSystemStreamLoading } = currentstatesReducer;
    const { dynamicValue } = currentMyStream;
    const systemStream = currentSystemStream.dynamicValue;
    const projectStream = currentProjectStream.dynamicValue;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;


    return {
        editor,
        currentMyStream,
        currentSystemStream,
        myStream: dynamicValue,
        currentProjectStream,
        projectStream,
        systemStream,
        isMyStreamLoading,
        isProjectStreamLoading,
        isSystemStreamLoading,
        component,
        currentProjectId
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    setMyStream,
    setSystemStream,
    setMyStreamLoading,
    setSystemStreamLoading,
    updateComponent,
    setProjectStream,
    setProjectStreamLoading

}
export default connect(mapStateToProps, mapDispatchToProps)(GalleryBox)
