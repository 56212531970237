import {
  //ServiceURLs,
  CreatorEndPoints,
  GetBaseCreatorURL,
  MakeReqestAndGetResponse,
  GetUserLang,
} from "./index";
import {
  HTTPOperationType
} from "./httpOperations";

export const CustomComponentService = {
  GetMycustomComponents,
  SaveCustomComponent,
  RemoveCustomComponent,
  SearchStepbyload,
};

//component id
function RemoveCustomComponent(_id) {
  var js = {
    id: _id
  };

  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.REMOVE_CUSTOM_COMPONENTS,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GetMycustomComponents() {
  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.GET_CUSTOM_COMPONENTS,
    HTTPOperationType.get
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function SaveCustomComponent(ComponentName, IsPublicAccess, _Object, id) {
  var js = {
    ComponentName: ComponentName,
    IsPublicAccess: IsPublicAccess,
    Object: _Object
  };

  if (id) {
    js["id"] = id
  };

  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.SAVE_CUSTOM_COMPONENTS,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}


function SearchStepbyload(SearchTerm, Count, LastIndexId, DynamicObj, IsOrderByDesc) {
  var js = {
    SearchTerm: SearchTerm,
    Count: Count,
    LastIndexId: LastIndexId,
    DynamicObj: DynamicObj,
    IsOrderByDesc: IsOrderByDesc
  };
  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.SEARCH_CUSTOM_COMPONENTS,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}