import React, { Component } from 'react'
import { Translation } from "react-i18next";
import { connect } from 'react-redux'

import { ReactSortable, ItemInterface } from "react-sortablejs";
import classnames from "classnames";
import { Popover, Whisper, Alert, Tooltip } from 'rsuite';

import { createId } from 'assets/util';



import {
    Collapse,
    NavItem,
    NavLink,
} from "reactstrap";


class SidebarNavItem extends Component {
    constructor(props) {
        super(props)

        //  this.props.data.data = this.props.data.data.map(obj => ({ ...obj, _id: createId() }))
        this.state = {
            popoverIsActive: false,
            list: this.props.data.data.map(obj => ({ ...obj, _id: createId() })), //Component listesinde ki elemanlar için kendi uniq idlerinimizi oluşturuyoruz.
            isActive: false, // Menü açık/değil durumu
        }
    }

    handleToggleMenu = () => {
        this.setState({
            isActive: !this.state.isActive,
        })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.data.data.length != this.state.list.length) {
            this.setState({
                list: this.props.data.data.map(obj => ({ ...obj, _id: createId() })),
            })
        }

        //this.props.data.data =  this.props.data.data.map(obj => ({ ...obj, _id: createId() }))
    }

    tooglePopover = () => {
        this.setState({
            popoverIsActive: !this.state.popoverIsActive
        })
    }

    render() {
        const { isActive, list, popoverIsActive } = this.state;
        const { sidebarStatus } = this.props;
        // debugger
        return (
            <Translation>
                {t => (
                    <NavItem>
                        <Whisper placement="rightStart" trigger="hover" enterable speaker={
                            <Popover>
                                <div className={"custom-popover-content"}>
                                    <h5>{t(this.props.data.nameCode)}</h5>
                                    <span>
                                        {this.props.data.categoryDescription.map((x, i) => {
                                            if (x.lang == t("LANG"))
                                                return x.textValue;
                                        })}
                                    </span>
                                </div>
                            </Popover>
                        }>
                            <NavLink
                                data-toggle="collapse"
                                href="#detail"
                                aria-expanded={isActive}
                                disabled={this.props.data ? (this.props.data.data.length > 0 ? false : true) : true}
                                className={classnames({
                                    active: sidebarStatus && isActive,
                                }, "nav-link2 " + (isActive ? "nav-link2-active" : ""))}
                                onClick={e => {
                                    e.preventDefault();
                                    this.handleToggleMenu();
                                }}
                            >
                                <i className={this.props.data.icon + " text-white " + (this.props.data.data.length > 0 ? "category-icon" : "category-icon-null")} />
                                <span className="nav-link-text category-name">{t(this.props.data.nameCode)}</span>
                            </NavLink>
                        </Whisper>

                        <Collapse isOpen={isActive}>
                            <div className="navbar-inner category-item">
                                <div className="ct-example-row">
                                    <div className={"row"}>
                                        <ReactSortable
                                             swapThreshold={0.02}
                                             invertSwap={false}
                                             easing={"cubic-bezier(1, 0, 0, 1)"}
                                             animation="0"
                                            className={(sidebarStatus ? "componentItemContainer" : "componentItemContainerMini")}
                                            group={{
                                                name: 'Kurnaz',
                                                pull: 'clone',
                                                put: false // Do not allow items to be put into this list
                                            }}
                                            chosenClass={"sortable-chosen-sidebar"}
                                            // forceFallback= {true}
                                            sort={false} // To disable sorting: set sort to false
                                            list={list.map(x => ({
                                                ...x,
                                                id: null,
                                            }))}//this.props.data ? this.props.data.data : []}
                                            clone={item => ({
                                                //Nesne kopyalanırken props falanda sıfırlanmalı yada patentten alınmalı
                                                ...item,
                                                id: null,
                                                _id: createId(),//this.generateComponentId(item.id),
                                                components: item.components.map((c, i) => i == 0 ? {
                                                    ...c,
                                                    object: {
                                                        ...c.object,
                                                        //data: [],
                                                        props: {
                                                            ...c.object.props,
                                                            // value: "Sample text..."
                                                        }
                                                    }

                                                } : c),

                                            }
                                            )
                                            }
                                            setList={newState => { this.setState({ list: newState }) }}
                                        >
                                            {this.props.data &&
                                                (list.map((item, key) => (
                                                    <Whisper key={key} placement="rightStart" trigger="active" enterable speaker={
                                                        <Popover>
                                                            {
                                                                // popoverIsActive &&
                                                                <img src={require('assets/gif/button.gif')} alt="loading..." className={"custom-popover-gif"} />
                                                            }
                                                            <div className={"custom-popover-content"}>
                                                                <h5>{item.componentName}</h5>
                                                                <span>
                                                                    {item.componentDescription.map((x, i) => {
                                                                        if (x.lang == t("LANG"))
                                                                            return x.textValue;
                                                                    })}
                                                                </span>
                                                                <div className={"custom-popover-more"}>
                                                                    <a onClick={() => {
                                                                        Alert.warning('It is not working', 1000)
                                                                    }}>{t("POPOVER_COMPONENT_BUTTON_SHOWMORE")}</a>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }>
                                                        <div
                                                            className={(sidebarStatus ? ("componentItem") : "componentItemMini") + " noselect"} key={item._id ? item._id : item.name} style={{
                                                                cursor: "pointer",
                                                            }}>
                                                            <div className={"componentItemIcon"}>
                                                                <i className={item.icon} />
                                                            </div>
                                                            <div className={"componentItemText"}>
                                                                <span>{item.componentName}</span>
                                                            </div>
                                                        </div>
                                                    </Whisper>

                                                )))
                                            }

                                        </ReactSortable>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </NavItem>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { editorReducer, currentstatesReducer } = state;
    const { present } = editorReducer;
    const { editor, pages, currentPage, page } = present;

    const { pageStatus, currentProjectId } = currentstatesReducer;

    return {
        editor,
        pages,
        currentPage,
        page,
        pageStatus,
        currentProjectId
    };
}
const mapDispatchToProps = {
    //  onUpdateComponent: updateComponent,

}
export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItem)
