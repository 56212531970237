import {
    globalpropsConstants
} from "../_constants";

export function editGlobalProps(_data) {
    return {
        type: globalpropsConstants.EDIT_PROPS,
        payload: {
            data: _data,
        }
    }
}

export function createGlobalProps(_data) {
    return {
        type: globalpropsConstants.CREATE_PROPS,
        payload: {
            data: _data,
        }
    }
}

export function deleteGlobalProps(_id) {
    return {
        type: globalpropsConstants.DELETE_PROPS,
        payload: {
            id: _id,
        }
    }
}
 
export function setGlobalPropTypes(_data) {
    return {
        type: globalpropsConstants.SET_PROP_TYPES,
        payload: {
            globalPropsTypes: _data,
        }
    }
}

export function setGlobalProps(_data) {
    return {
        type: globalpropsConstants.SET_PROPS,
        payload: {
            globalProps: _data,
        }
    }
}