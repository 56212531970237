import React, { Component } from 'react'
import { connect } from 'react-redux'

//import { CustomButton, CustomImage, CustomLabel,  } from './';

import CustomLabel from './CustomLabel';
import CustomButton from './CustomButton';
import CustomImage from './CustomImage';
import CustomLayout from './CustomLayout';
import CustomTextBox from './CustomTextBox';
import CustomMultilineTextBox from './CustomMultilineTextBox';
import CustomDataViewerList from './CustomDataViewerList';
import CustomCheckBox from './CustomCheckBox';
import CustomRadioButton from './CustomRadioButton';
import CustomDataCarousel from './CustomDataCarousel';
import CustomDataViewerGridList from './CustomDataViewerGridList';
import CustomQRCode from './CustomQRCode';
import CustomPageSwiper from './CustomPageSwiper';
import CustomSelectPicker from './CustomSelectPicker';
import CustomFabMenu from './CustomFabMenu';
import CustomFabButton from './CustomFabButton';
import CustomDatePicker from './CustomDatePicker';
import CustomBarcodeReader from './CustomBarcodeReader';
import CustomQRReader from './CustomQRReader';
import CustomProgressBar from './CustomProgressBar';
import CustomWebView from './CustomWebView';
import CustomChipList from './CustomChipList';
import CustomVideoPlayer from './CustomVideoPlayer';
import CustomTimeRangePicker from './CustomTimeRangePicker';
import CustomFlipLayout from './CustomFlipLayout';






import { updateComponent, updateComponentContext, removeComponent, setComponentNames } from '../../_actions';
import { createId } from '../../assets/util'

import { sweetAlertActions } from "_actions";
import Emitter from "_actions/emitter";
import i18n from 'i18n';

import { ComponentService } from '_services';

import { ResponseStatusCode } from "_helpers";
import { Placeholder, Alert } from 'rsuite';

class GenerateComponent extends Component {
    constructor(props) {
        super(props);
        let bb = props.data;
        // debugger
        this.state = {
            mantar: null,
            data: null,
            counter: 0,
        }

    }

    // Mevcut komponentler
    components = {
        label: CustomLabel,
        button: CustomButton,
        image: CustomImage,
        layout: CustomLayout,
        textbox: CustomTextBox,
        multiline: CustomMultilineTextBox,
        datalayout: CustomDataViewerList,
        checkbox: CustomCheckBox,
        radiobutton: CustomRadioButton,
        datacarousel: CustomDataCarousel,
        datagridlayout: CustomDataViewerGridList,
        qrlayout: CustomQRCode,
        pageswiper: CustomPageSwiper,
        selectpicker: CustomSelectPicker,
        fabmenu: CustomFabMenu,
        fabbutton: CustomFabButton,
        datepicker: CustomDatePicker,
        barcodereader: CustomBarcodeReader,
        qrreader: CustomQRReader,
        progressbar: CustomProgressBar,
        webview: CustomWebView,
        chiplist: CustomChipList,
        videoplayer: CustomVideoPlayer,
        rangetimepicker: CustomTimeRangePicker,
        fliplayout:CustomFlipLayout,
    }

    getComponentNames = async () => {
        const { setComponentNames } = this.props;
        await ComponentService.GetComponentNames(this.props.currentProjectId, this.props.currentPage).then(data => {
            if (data.operationResult) {
                setComponentNames(data.dynamicValue);
            }
        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
            //Alert.error(data.dynamicValue)
            return null;
        });
    }

    generateComponentId = async () => {
        const that = this;
        return await ComponentService.GenerateComponentId(this.props.currentProjectId, this.props.currentPage).then(data => {
            // Alert.success(data.dynamicValue)
            try { that.getComponentNames() } catch (error) { debugger }
            return data.dynamicValue;
        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
            //Alert.error(data.dynamicValue)
            return null;
        });
    }

    generateComponentIdList = async (_count) => {
        const that = this;
        return await ComponentService.GenerateComponentIdList(this.props.currentProjectId, this.props.currentPage, _count).then(data => {
            try { that.getComponentNames() } catch (error) { debugger }
            return data.dynamicValue;
        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
            //Alert.error(data.dynamicValue)
            return null;
        });
    }


    componentDidMount = () => {
        this.restoreComponent("componentDidMount");
        Emitter.on("handleNo", newValue =>
            Emitter.off("handleYes")
        );
    }
    componentWillUnmount() {
        Emitter.off("handleYes");
        this.setState = (state, callback) => {
            return;
        };
    }



    handleUpdateComponent = () => {
        if (!this.props.currentProjectId) { return }
        if (!this.props.pageStatus.status) { return }

        this.props.onUpdateConponent(this.props.data);
    }


    removeComponentDetailID = async (_newData) => {
        try {

        } catch (error) {

        }
        const { currentPage } = this.props;
        //komponent içerisinde ki id'ler oluşturuluyor.
        if (!this.props.currentProjectId) { return }
        if (!this.props.pageStatus.status) { return }

        let _newDataDetail = await this.convertComponentData(_newData);

        try {
            for (const item of _newDataDetail) {

                await ComponentService.RemoveComponentId(this.props.currentProjectId, currentPage, item.id).then(data => {
                    // console.log(item.id+" - SİLİNDİ")
                }).catch(err => {
                    debugger
                });
                try {
                    await this.generateNewID(item);
                } catch (error) {

                }
            }
            return _newDataDetail;

        } catch (error) {
            return _newDataDetail;

        }
        //debugger

    }


    removeComponentId = () => {
        const { currentPage, component } = this.props;
        if (!this.props.currentProjectId) { return }
        if (!this.props.pageStatus.status) { return }


        try {
            ComponentService.RemoveComponentId(this.props.currentProjectId, currentPage, component.id).then(data => {
                // Alert.success("silindi");
                try {
                    this.removeComponentDetailID(JSON.parse(JSON.stringify(component)));
                } catch (error) {
                    debugger
                }
                this.handleRemove()

            }).catch(err => {
                debugger
                let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
                Emitter.emit("SHOW_WARNING_MODAL", data);
            });
        } catch (error) {
            debugger
        }

    }

    handleRemove = () => {


        const { data, index, baseProgress, isChild } = this.props;
        let newArray = Object.values(JSON.parse(JSON.stringify(baseProgress.baseArray)));// new Array(...baseProgress.baseArray); 
        // debugger
        newArray.splice(index, 1);
        this.props.removeComponent();
        try { this.getComponentNames() } catch (error) { debugger }
        baseProgress.handleUpdate(newArray);


    }

    generateNewID = async (_newData) => {

        //komponent içerisinde ki id'ler oluşturuluyor.

        let _newDataDetail = await this.convertComponentData(_newData);
        //debugger
        for (const item of _newDataDetail) {
            item._id = createId();
            debugger
            item.id = await this.generateComponentId();
            try {
                await this.generateNewID(item);
            } catch (error) {

            }
        }

        return _newDataDetail;

    }

    convertComponentData = async (_newData) => {

        // fav komponent ve normal komponentler arasında model farklı olduğu için kontrol edilmesi gerekiyor.
        // data içeriği varsa geri dönüyor.
        try {
            if (_newData.hasOwnProperty("componentOwner")) {
                return _newData.object.components[0].object.hasOwnProperty("data") ? _newData.object.components[0].object.data : []
            } else {
                return _newData.components[0].object.hasOwnProperty("data") ? _newData.components[0].object.data : []
            }
        } catch (error) {
            return [];
        }



    }


    getObjects = async (obj, key, secondKey, ids) => {
        let _ids = ids;
        var objects = [];
        for (var i in obj) {
            if (!obj.hasOwnProperty(i)) continue;
            if (typeof obj[i] == 'object') {
                objects = objects.concat(await this.getObjects(obj[i], key, secondKey, _ids));
            } else if (i == secondKey) {
                //debugger
                obj[key] = _ids[0];
                _ids.shift();
            }
        }
        return obj;
    }
    removeAllIdObject = async (obj, key, secondKey) => {
        const that = this;

        var newValue = null;
        var objects = [];
        for (var i in obj) {

            if (!obj.hasOwnProperty(i)) continue;
            if (typeof obj[i] == 'object') {
                objects = objects.concat(await this.removeAllIdObject(obj[i], key, secondKey));
            } else if (i == secondKey) {
                //debugger
                obj[key] = newValue;
            }

        }


        return obj;

    }

    handleDublicate = async () => {
        // ilgili komponenti state içerisinde ki index +1 olarak klonlar
        const { data, index, baseProgress, isChild } = this.props;
        let newArray = Object.values(JSON.parse(JSON.stringify(baseProgress.baseArray)));// new Array(...baseProgress.baseArray); 

        let _newData = JSON.parse(JSON.stringify(newArray[index]));

        let _c = this.getPropValues(_newData, "componentName");
        let _ids = await this.generateComponentIdList(_c.length);
        _newData = await this.getObjects(_newData, "id", "componentName", _ids);




        newArray.splice(index, 0, _newData); // kopyalanan component

        baseProgress.handleUpdate(newArray);
    }

    handleKeyPress = (event) => {
        if (!this.props.currentProjectId) { return }

        if (!this.props.pageStatus.status) { return }

        if (event.keyCode == 46 || event.key == 'Delete') {

            Emitter.on("handleYes", newValue =>
                this.removeComponentId()
            );
            sweetAlertActions.SHOW_ASK(
                "",
                "",
                "",
                i18n.t("MESSAGE_DELETE_COMPONENT_FROM_EDITOR_SUBTITLE"),
                i18n.t("MESSAGE_DELETE_COMPONENT_FROM_EDITOR_SUCCESS"),
                i18n.t("MESSAGE_DELETE_COMPONENT_FROM_EDITOR_UNSUCCESS")
            );

        }
    }

    mouselog = (event) => {
        //  Mouse hover-leave aksiyonlarını yakamak için kullandığımız fonksiyon
        event.stopPropagation();
        // let d = new Date();
        //  let text = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()} | ${event.type} | ${this.props.data.name}`;

        if (event.type == "mouseover") {
            event.target.classList.add("component-hover");
        } else if (event.type == "mouseleave") {
            event.target.classList.remove("component-hover");

        } else {
            event.target.classList.remove("component-hover");

            //  text = event.type + ' !!!'
        }
        // console.log(text,event.target.classList);
    }

    childSizeProgress = (_item, _side) => {
        const _element = document.getElementsByClassName("deviceInside");
        //
        const _elementW = _element ?(_element[0].offsetWidth + "px"):"0px";
        const _elementH = _element ?(_element[0].offsetHeight + "px"):"0px";
        const _elementW2 = _element ?(_element[0].offsetWidth / 2 + "px"):"0px";
        const _elementH2 = _element ?(_element[0].offsetHeight / 2 + "px"):"0px";

        let _h = (_item.object.props.style.minHeight == "device" ? _elementH : (_item.object.props.style.minHeight == "half_device" ? _elementH2 : _item.object.props.style.minHeight));
        let _w = (_item.object.props.style.minWidth == "device" ? _elementW : (_item.object.props.style.minWidth == "half_device" ? _elementW2 : _item.object.props.style.minWidth));

        // debugger
        _h = _item.object.props.style.minHeight == "square" ? _w : _h;
        _w = _item.object.props.style.minWidth == "square" ? _h : _w;


        return _side == "w" ? _w : _h;

    }

    getPropValues = (o, prop) =>
        (res => (JSON.stringify(o, (key, value) =>
            (key === prop && res.push(value), value)), res))([]);

    pxToNumber = (_value) => {
        if (typeof _value === 'string' || _value instanceof String) {
            if (_value.includes("px")) { return parseInt(_value.replace(/px$/, '')) }
            else { return _value }
        } else { return _value }
    }

    handleGenerate = async () => {
        //Burada oluşturulan component Sayfanın main listesi index'i ne ait oluyor.

        const { index, baseProgress, isChild, path, page, componentNames } = this.props;
        let __data = this.props.data;
        let data = __data;


        if (data.hasOwnProperty("componentOwner")) {

            if ((data.object.id == null || data.id == null)) {
                // debugger
                let _c = this.getPropValues(data, "relCategoryId");
                let _ids = await this.generateComponentIdList(_c.length);
                data.id = _ids[0];
                data = await this.getObjects(data, "id", "relCategoryId", _ids);
            }
        } else {
            if (data.id == null) {
                let _id = await this.generateComponentId();
                data.id = _id;
            }
        }


        let _data;
        let _component;
        if (data.hasOwnProperty("componentOwner")) {
            _data = data.object.components[0].object;
            _component = data.object.components[0];
        } else {
            try {
                _data = data.components[0].object;
                _component = data.components[0]; 
            } catch (error) {
                debugger
            }
         
        }


        let component = this.components[_data.type];


        if (!component) {
            // debugger
            Alert.error(i18n.t("VERSION_ERROR_NEW_COMPONENT"));
            return null;

        }

        let newComponent = React.createElement(component, {
            ...data.props,
            isChild: isChild,
            path,
            processStep: data.id + "-" + this.state.counter,
            main: data,
            data: _data,
            index: index,
            useCustomStyle: this.props.useCustomStyle,
            baseProgress: baseProgress
        });


        const letrW = (_data.props.style.minWidth).match(/[a-zA-Z%]+/g)
        const letrH = (_data.props.style.minHeight).match(/[a-zA-Z%]+/g)

        let position = {};
        /*
        _component.object.props.position
        _component.object.props.orientation
        _component.object.props.verticalOrientation
        */
        if (_data && _component) {
            position = (_data.type !== "fabmenu" && _data.type !== "fabbutton") ? {} : {
                top: _component.object.props.verticalOrientation === "up" ? undefined : 0,
                bottom: _component.object.props.verticalOrientation === "up" ? 0 : undefined,
                left: _component.object.props.position === "center" ? "calc(50% - 30px)" : ((_component.object.props.position === "left") ? 0 : undefined),
                right: (_component.object.props.position === "right") ? 0 : undefined,
            }
        }


        return (
            <div
                className={baseProgress.isViewer ? "" : ((_data.type == "layout" || isChild == false) ? "dynamicComponent" : "dynamicSubComponent")}
                style={{

                    // height: this.childSizeProgress(_component, "h"),
                    //  width: letrW == "%" ? this.childSizeProgress(_component, "w") : this.childSizeProgress(_component, "w"),//_data.type != "layout" && 
                    //   minHeight: letrH == "%" ? "unset" : this.childSizeProgress(_component, "h"),
                    //   minWidth: letrW == "%" ? "unset" : this.childSizeProgress(_component, "w"),

                    height: this.childSizeProgress(_component, "h"),
                    width: this.childSizeProgress(_component, "w"),
                    
                    minHeight: (letrH[0] === "px" || letrH === "square") ? this.childSizeProgress(_component, "h") : "auto",// "auto", //letrH == "%" ? "auto" : this.childSizeProgress(_component, "h"),
                    minWidth: (letrW[0] === "px" || letrW === "square") ? this.childSizeProgress(_component, "w") : "auto", //letrW == "%" ? "auto" : this.childSizeProgress(_component, "w"),

                    paddingTop: _data.props.style["marginTop"],
                    paddingBottom: _data.props.style["marginBottom"],
                    paddingLeft: _data.props.style["marginLeft"],
                    paddingRight: _data.props.style["marginRight"],

                    flex: isChild ? (this.props.isHorizontal ? (letrW[0] == "%" ? parseInt(this.pxToNumber(_data.props.style["minWidth"])) / 100 : "unset") : (letrH[0] == "%" ? parseInt(this.pxToNumber(_data.props.style["minHeight"])) / 100 : "unset")) : "unset",
                    display: "flex",
                    position: (_data.type !== "fabmenu" && _data.type !== "fabbutton") ? "relative" : "absolute",
                    ...this.props.style,
                    ...position,
                }}>
                
                {newComponent}
                {
                    !baseProgress.isViewer &&
                    <div
                        className={"dragableComponent"} tabIndex="1"
                        onFocus={() => { this.handleUpdateComponent() }}
                        onKeyDown={(event) => this.handleKeyPress(event)}
                        onMouseLeave={(event) => { this.mouselog(event) }}
                        onMouseOver={(event) => { this.mouselog(event) }}
                        onContextMenu={(e) => {
                            //debugger;
                            let obj = {
                                clientX: e.clientX,
                                clientY: e.clientY,
                                // target:e.target,
                            }
                            if (!this.props.currentProjectId) { return }
                            if (!this.props.pageStatus.status) { return }

                            this.props.onUpdateComponentContext(obj, this.handleRemove, this.handleDublicate);
                            e.preventDefault();
                        }}>
                        {
                            /*
                             <div className={"dragableComponentMore"} >
                            <div className={"dragableComponentInfo"}>
                                {data.name}
                            </div>
                            </div>
                            */
                        }
                    </div>
                }


            </div>
        )
    }

    restoreComponent = async (_where) => {
        let _mantar = await this.handleGenerate();

        this.setState({
            mantar: _mantar,
            counter: this.state.counter + 1,
            data: JSON.parse(JSON.stringify(this.props.data)),
        }, () => {
            //console.log(this.props.data.id + " - restoreComponent - " + _where + " counter:" + this.state.counter)
        })
    }

    componentDidUpdate = (prevProps) => {
        const { component, data, componentNames, page } = this.props;
        if (component) {
            if (component.id == data.id) {


                let _data;
                let _sData;

                if (data.hasOwnProperty("componentOwner")) {


                    _data = data.object.components[0];
                    _sData = this.state.data && this.state.data.object.components[0];
                } else {

                    _data = data.components[0];
                    _sData = this.state.data && this.state.data.components[0]
                }
                if (JSON.stringify(_data) != JSON.stringify(_sData)) {

                    this.restoreComponent("componentDidUpdate");
                }
            }
        }

    }

    render() {
        const { mantar } = this.state;
        const { component } = this.props;
        return (
            <>
                {mantar ? mantar : <Placeholder.Graph style={{ height: "50px" }} active />}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { editorReducer, componentReducer, currentstatesReducer } = state;
    const { present } = editorReducer;
    const { editor, pages, currentPage, page } = present;
    const { component, componentContext } = componentReducer;

    const { pageStatus, currentProjectId, componentNames } = currentstatesReducer;

    return {
        currentPage,
        component,
        pageStatus,
        editor,
        page,
        currentProjectId,
        componentNames
    };
}
const mapDispatchToProps = {
    onUpdateConponent: updateComponent,
    onUpdateComponentContext: updateComponentContext,
    removeComponent,
    setComponentNames
}
export default connect(mapStateToProps, mapDispatchToProps)(GenerateComponent)
