import React, { Component } from 'react'

export class PropertyTitle extends Component {

    render() {
        const { title, subtitle } = this.props;
        return (
            <div className={"propertyTitleDiv"}>
                <label  className={"propertyTitle"}>{title}
                </label>
                <p className={"propertySubTitle"}>{subtitle}</p>
            </div>
        )
    }
}

export default PropertyTitle
