import {
    //ServiceURLs,
    CMSEndPoints,
    GetBaseCMSURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const CMSService = {
    GET_ALL_FOR_PANEL,
    GET_BY_ID,
    SAVE,
    SAVE_FROM_CREATOR
  };

//PAGE ID NULL VERIRSEN ALAYI GELIR.
  function GET_ALL_FOR_PANEL(ProjectID,PageID,Lang) {
    var js = {
        ProjectID:ProjectID ,
        PageID:PageID ,
        Lang:Lang
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCMSURL() + CMSEndPoints.GET_ALL_FOR_PANEL,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

 
  function GET_BY_ID(ProjectID,PageID,ObjectId,Lang) {
    var js = {
        ProjectID:ProjectID ,
        PageID:PageID ,
        ObjectId:ObjectId,
        Lang:Lang
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCMSURL() + CMSEndPoints.GET_BY_ID,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  //get by id den doneni yolla.
  function SAVE(data) {
   /*
    var js = {
        ProjectID:ProjectID ,
        PageID:PageID ,
        ObjectId:ObjectId,
        Lang:Lang
    };
  */
    return MakeReqestAndGetResponse(
      GetBaseCMSURL() + CMSEndPoints.SAVE,
      HTTPOperationType.post,
      data
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 
    
  function SAVE_FROM_CREATOR(id,RelProjectID,RelPageID,RelObjectId,ConvertedValueDirect) {
    var js = {
        id:id ,
        RelProjectID:RelProjectID ,
        RelPageID:RelPageID,
        RelObjectId:RelObjectId,
        ConvertedValueDirect:ConvertedValueDirect
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCMSURL() + CMSEndPoints.SAVE_FROM_CREATOR,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 