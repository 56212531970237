import React, { Component } from 'react'
import { Translation } from "react-i18next";
import ReactDOM from 'react-dom'


import { sweetAlertActions } from "_actions";
import Emitter from "_actions/emitter";
import i18n from 'i18n';

import AddFavModal from "components/Modals/AddFavModal";

export class HelperMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            visible: false,
            modalAddFavIsActive:false,

        }
        if (props.event)
            this._handleContextMenu(props.event);
    }

    componentDidMount() {
        // document.addEventListener('contextmenu', this._handleContextMenu);
        document.addEventListener('click', this._handleClick);
        document.addEventListener('scroll', this._handleScroll);

        Emitter.on("handleNo", newValue =>
            Emitter.off("handleYes")
        );

    };
    componentWillUnmount() {
        Emitter.off("handleYes");
    }

    componentWillUnmount() {
        // document.removeEventListener('contextmenu', this._handleContextMenu);
        document.removeEventListener('click', this._handleClick);
        document.removeEventListener('scroll', this._handleScroll);
    }


    componentDidUpdate(prevProps) {
        if (prevProps.event !== this.props.event) {
            this._handleContextMenu(this.props.event);
        } else if ((this.props.event !== undefined) && (this.state.visible == false)) {
            // this._handleContextMenu(this.props.event);
        }
    }

    _handleContextMenu = (event) => {
        if (event) {
            this.setState({ visible: true }, () => {
                //debugger;
                const clickX = event.clientX;
                const clickY = event.clientY;
                const screenW = window.innerWidth;
                const screenH = window.innerHeight;
                const rootW = this.root.offsetWidth;
                const rootH = this.root.offsetHeight;

                const right = (screenW - clickX) > rootW;
                const left = !right;
                const top = (screenH - clickY) > rootH;
                const bottom = !top;

                if (right) {
                    this.root.style.left = `${clickX + 5}px`;
                }

                if (left) {
                    this.root.style.left = `${clickX - rootW - 5}px`;
                }

                if (top) {
                    this.root.style.top = `${clickY + 5}px`;
                }

                if (bottom) {
                    this.root.style.top = `${clickY - rootH - 5}px`;
                }
            });
        }
    };

    _handleClick = (event) => {
        const { visible } = this.state;
        const wasOutside = !(event.target.contains === this.root);

        if (wasOutside && visible) this.setState({ visible: false, });
    };

    _handleScroll = () => {
        const { visible } = this.state;

        if (visible) this.setState({ visible: false, });
    };

    _handleRemove = () => {
        Emitter.on("handleYes", newValue =>{
            this.props.handleRemove()

        }

        );
        sweetAlertActions.SHOW_ASK(
            "",
            "",
            "",
            i18n.t("MESSAGE_DELETE_COMPONENT_FROM_EDITOR_SUBTITLE"),
            i18n.t("MESSAGE_DELETE_COMPONENT_FROM_EDITOR_SUCCESS"),
            i18n.t("MESSAGE_DELETE_COMPONENT_FROM_EDITOR_UNSUCCESS")
        );
    }

    _handleOpenEdit =()=>{
        Emitter.emit("OPEN_RIGTHSIDE_TAB",0)
    }

    _handleAddFavorite = (_name) => {
        this.props.handleAddFav(_name);
    }

    _handleToggleModalAddFav = () => {
        this.setState({
            modalAddFavIsActive:!this.state.modalAddFavIsActive
        })
    }

    render() {
        const { visible } = this.state;
        const { name, handleDublicate, handleRemove, handleAddFav } = this.props;
        return (
            <Translation>
                {t => (
                    <>
                        {(visible || null) && (

                            <div ref={ref => { this.root = ref }} className={"contextMenu " + this.props.className}>
                                <div className={"contextMenu--title"}>{name}</div>
                                <div className="contextMenu--separator" />

                                <div className="contextMenu--option" onClick={() => { this._handleOpenEdit();}} >{t("CANVAS_CONTEXT_MENU_EDIT")}</div>
                                <div className="contextMenu--option" onClick={() => { this._handleToggleModalAddFav();}}>{t("CANVAS_CONTEXT_MENU_ADDFAV")}</div>

                                <div className="contextMenu--option" onClick={() => { this._handleRemove() }}>{t("CANVAS_CONTEXT_MENU_DELETE")}</div>
                                <div className="contextMenu--option" onClick={() => { handleDublicate() }}>{t("CANVAS_CONTEXT_MENU_DUBLICATE")}</div>

                                {
                                    /*
                                        <div className="contextMenu--option contextMenu--option__disabled">Test</div>
                                    */
                                }
                            </div>
                        )}

                        <AddFavModal isActive={this.state.modalAddFavIsActive} handleToggle={this._handleToggleModalAddFav} />
                    </>


                )}
            </Translation>)
    };
}

export default HelperMenu
