import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DatePicker } from 'rsuite';

class DatePickerProperty extends Component {
    render() {
        return (
            <DatePicker
                className={"property-datepicker-long"}
                placement={"autoVerticalEnd"}
                placeholder={this.props.placeholder}
                onChange={this.props.handleChange}
                size="sm"
                value={this.props.data}
            />
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerProperty)
