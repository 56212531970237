import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { FormGroup, Form, Input, Row, Col } from "reactstrap";
import { updateComponent, updateList } from '_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';
import i18n from 'i18n';
import {
    PropertyTitle,
} from "../Core/";
import { Icon, Dropdown, List, Alert, Button, IconButton, ButtonToolbar, } from 'rsuite';
import ColorPicker from "../Core/ColorPicker";
import NumaricDataProperty from "../Core/NumaricDataProperty"
import { ComponentService } from '_services';
import { ResponseStatusCode } from "_helpers";
import StaticTextValue from '../Core/StaticTextValue';
import LocalScript from '../../Modals/LocalScript';

import IconDetailProperty from '../Core/IconDetailProperty'

const defautOrientationOptions = [
    {
        label: i18n.t("DROPDOWN_FAB_ORIENTATION_H"),
        value: "horizontal",
    },
    {
        label: i18n.t("DROPDOWN_FAB_ORIENTATION_V"),
        value: "vertical",
    }
]
const defautVerticalOrientationOptions = [
    {
        label: i18n.t("DROPDOWN_FAB_VERTICALORIENTATION_U"),
        value: "up",
    },
    {
        label: i18n.t("DROPDOWN_FAB_VERTICALORIENTATION_D"),
        value: "down",
    }
]
const defautPositionOptions = [
    {
        label: i18n.t("DROPDOWN_FAB_POSITION_L"),
        value: "left",
    },
    {
        label: i18n.t("DROPDOWN_FAB_POSITION_C"),
        value: "center",
    },
    {
        label: i18n.t("DROPDOWN_FAB_POSITION_R"),
        value: "right",
    }
]

const WAIT_INTERVAL = 500;

class DynamicPropsSettings extends Component {
    constructor(props) {
        super(props)
        this.fontSizeSlider = React.createRef();

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const _p = object.props;
        this.state = {
            componentClone: component,
            dynamicPropsScript: component && (_p["dynamicPropsScript"] == undefined ? "" : _p["dynamicPropsScript"]),
        }

    }
 

    updatePropEditor = (key, value) => {
        const that = this;
        let _clone = Object.assign({}, this.props.component);
        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props[key] = value
        } else {
            _clone.components[0].object.props[key] = value
        }

        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        debugger
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);
    }




    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const _p = object.props;

        if (prevProps.component != component) {
            this.setState({
                componentClone: component,
                dynamicPropsScript: component && (_p["dynamicPropsScript"] == undefined ? "" : _p["dynamicPropsScript"]),
            }, () => { })
        }
    }


    componentDidMount() {
    }



 

    handleOnBlur = (e, index) => {
        this.handleChange(e, index)
    }

    componentWillMount() {
        this.timer = null;
    }


    handleScriptEditorModal = () => {
        this.setState({
            scriptEditor: !this.state.scriptEditor,
        })
    }

    handleSave = (_value) => {
        this.updatePropEditor("dynamicPropsScript", _value);
        this.setState({
            scriptEditor: false
        })
    }

    render() {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const that = this;
        return (
            <Translation>
                {t => (
                    <>
                        <Form>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_DYNAMIC_SCRIPT")}
                                    </label>
                                    <Button onClick={() => this.handleScriptEditorModal()} size="xs">{t("BUTTON_SHOW")}</Button>
                                </div>
                            </FormGroup>
                        </Form>
                        {
                            <LocalScript handleSave={this.handleSave} dynamicPropsScript={this.state.dynamicPropsScript} isActive={this.state.scriptEditor} handleToggle={() => this.handleScriptEditorModal()} />
                        }
                    </>
                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { pageStatus, currentProjectId } = currentstatesReducer;

    const { present } = editorReducer;
    const { editor, currentPage } = present;
    return {
        editor,
        currentPage,
        component,
        currentProjectId
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,


}
export default connect(mapStateToProps, mapDispatchToProps)(DynamicPropsSettings)
