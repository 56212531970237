import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from 'i18n';

import { FormGroup, Form, Input, Row, Col, ButtonGroup, Button } from "reactstrap";
import { updateComponent, updateList } from '../../../_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';

import { Dropdown } from 'rsuite';

import FlexProperty from "../Core/FlexProperty";
import ImagePosition from "../Core/ImagePosition";
import ImageSize from "../Core/ImageSize";
import ImageProperty from "../Core/ImageProperty"
import DynamicFieldValue from "../Core/DynamicFieldValue"

import BlurSettings from './BlurSettings'

import {
    PropertyTitle,
} from "../Core/";


const defautValueOptions = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: true,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: false,
    }
]

class ImageSettings extends Component {
    constructor(props) {
        super(props);
        const { component } = props;
        const { object } = this.getComp(component);

        this.state = {
            showModal: component && (object.props.showModal ? (defautValueOptions.find(x => x.value === object.props.showModal)) : defautValueOptions[1]),
        }
    }

    componentDidMount() {

    }

    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = this.getComp(component);

        if (prevProps.component != component) {
            this.setState({
                showModal: component && (object.props.showModal ? (defautValueOptions.find(x => x.value === object.props.showModal)) : defautValueOptions[1]),
            }, () => {
            })
        }
    }


    getComp = (_data) => {
        try {
            if (_data.hasOwnProperty("componentOwner")) { return _data.object.components[0]; }
            else { return _data.components[0]; }
        } catch (error) {
            debugger
        }
    }
    handleUpdateValue = (_data, _name) => {

        this.setState({
            [_name]: _data,
        }, () => {
            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_name] = _data.value;
            } else {
                _clone.components[0].object.props[_name] = _data.value;
            }

            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }



    render() {
        const { component } = this.props;
        const _comp = this.getComp(component);
        const _type = _comp && _comp.object.type;
        return (
            <Translation>
                {t => (
                    <Form>
                        <FormGroup className={"property-form-group"}>
                            <DynamicFieldValue basicField={<ImageProperty />} />

                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_IMAGE_SIZE")} subtitle={""} />
                            <ImageSize />
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_IMAGE_REPEAT")} subtitle={""} />
                            <FlexProperty
                                propName={"backgroundRepeat"}
                                nullOption={"no-repeat"}
                                options={[
                                    "no-repeat",
                                    "repeat",
                                    "space",
                                ]} />
                        </FormGroup>

                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_IMAGE_POSITION")} subtitle={""} />
                            <ImagePosition />
                        </FormGroup>

                        {
                            _type &&
                            _type === "image" &&
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_IMAGE_SHOW_WITH_MODAL")}
                                    </label>
                                    {
                                        this.state["showModal"] &&
                                        <Dropdown size="xs" title={this.state["showModal"] ? (defautValueOptions.find(x => x.value === this.state["showModal"].value).label) : defautValueOptions[1].label}>
                                            {
                                                defautValueOptions.map((x, key) => (
                                                    x.value === this.state["showModal"].value ?
                                                        "" :
                                                        <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "showModal")}>{x.label}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown>
                                    }

                                </div>
                            </FormGroup>
                        }
                    </Form>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;
    return {
        editor,
        component,
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,

}
export default connect(mapStateToProps, mapDispatchToProps)(ImageSettings)
