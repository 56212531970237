import React, { Component } from 'react'
import { connect } from 'react-redux'
 import { updateComponent,updateList } from '_actions';
import { findObjUpdate } from 'assets/util';

import { Translation } from "react-i18next";
import { ButtonGroup, Button } from "reactstrap";

import { Top, Bottom, Left, Right, Center } from '../../../assets/icons/positions'

class ImagePosition extends Component {
    constructor(props) {
        super(props)
        const { component } = props;
        const  {object}  =  component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
         
        const { style,value } = object.props;
        this.state = {
            value: component && (style["backgroundPosition"] == undefined ? "unset" : style["backgroundPosition"])
        }
    }

    handleChangePosition = (event) => {
        const { currentTarget } = event;
        const { name } = currentTarget;

      
        this.setState({
            value: name,
        }, () => {
            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.style = {
                    ..._clone.object.components[0].object.props.style,
                    backgroundPosition: this.state.value,
                    backgroundPositionX: this.state.value,
                    backgroundPositionY: this.state.value,                }
            } else {
                _clone.components[0].object.props.style = {
                    ..._clone.components[0].object.props.style,
                    backgroundPosition: this.state.value,
                    backgroundPositionX: this.state.value,
                    backgroundPositionY: this.state.value,                }
            }
 
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }
    
    componentDidUpdate = (prevProps) => {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
         
        const { style,value } = object.props;

        if (prevProps.component != component) {
            this.setState({
                value: style["backgroundPosition"] == undefined ? "unset" : style["backgroundPosition"],
            })
        }
    }

    render() {
        return (
            <Translation>
                {t => (
                    <div className="bg-secondary">
                        <ButtonGroup>
                            <Button name={"left"} onClick={this.handleChangePosition} className={"button-group-button " + (this.state.value == "left" ? "active" : "")} color="secondary" type="button">
                                <Left className={"button-group-icons"} />
                            </Button>
                            <Button name={"right"} onClick={this.handleChangePosition} className={"button-group-button " + (this.state.value == "right" ? "active" : "")} color="secondary" type="button">
                                <Right className={"button-group-icons"} />
                            </Button>
                            <Button name={"center"} onClick={this.handleChangePosition} className={"button-group-button " + (this.state.value == "center" ? "active" : "")} color="secondary" type="button">
                                <Center className={"button-group-icons"} />
                            </Button>
                            <Button name={"bottom"} onClick={this.handleChangePosition} className={"button-group-button " + (this.state.value == "bottom" ? "active" : "")} color="secondary" type="button">
                                <Bottom className={"button-group-icons"} />
                            </Button>
                            <Button name={"top"} onClick={this.handleChangePosition} className={"button-group-button " + (this.state.value == "top" ? "active" : "")} color="secondary" type="button">
                                <Top className={"button-group-icons"} />
                            </Button>
                        </ButtonGroup>
                    </div>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    const { componentReducer,editorReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        component,
        editor
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
}
export default connect(mapStateToProps, mapDispatchToProps)(ImagePosition)
