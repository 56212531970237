import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from "classnames";
import Emitter from "_actions/emitter";
import { ActionCreators as UndoActionCreators } from 'redux-undo'

import i18n from "i18n";
import { Translation } from "react-i18next";
import { ResponseStatusCode } from "_helpers";

import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";

import CheckProjects from '../components/Modals/CheckProjects';
import RecoveryModal from 'components/Modals/RecoveryModal';

// core components


import MainNavbar from "components/Navbars/MainNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import MainHeader from "../components/Headers/MainHeader";

import Sidebar from "components/Sidebar/Sidebar";
import Canvas from "components/Screen/Canvas";

import { RigthSidebar } from 'components/RigthSidebar/RigthSidebar';
import { PrapareSocket } from "_socketOperations";
import {
    GetAuthURL,
    userService,
    ComponentService,
    globalPropService,
    pagesService,
    CustomFunctionService,
    ProjectService,
    CMSService,
    CustomComponentService,
    DynamicFunctionService,
    ExportService
} from "_services";
import {
    updateListSocket,
    authenticationAction,
    setComponentList,
    setMyComponentList,
    setGlobalPropTypes,
    setGlobalProps,
    setPages,
    setPage,
    updateCurrentPage,
    setRefSocketRef,
    SocketOperationFunctions,
    setPageStatus,
    setProjectId,
    setStaticCollections,
    setDynamicFuncs,
    setPageFuncs,
    setProjectExports,
    setMyProjects,
    setComponentNames,
    setCustomFunctions
} from "_actions";


import SweetModal from 'components/Modals/SweetModal';
import SelectProject from 'components/Modals/SelectProject';
import { Alert, Message } from 'rsuite';

import { setRef } from '@material-ui/core';
import { SocketOperations } from '_constants';
import UICanvas from 'components/Screen/UICanvas';

class Main extends Component {
    constructor(props) {
        super(props)
        const _online = window.navigator.onLine;

        this.state = {
            rigthMenuTab: 0,
            sidenavOpen: false,
            checkModal: false,
            alert: null,
            noProject: false,
            projects: null,
            noPage: false,
            recoveryModal: false,
            recoveryData: null,
            online: _online,
        }

    }

    handleSelectProject = () => {
        this.setState({
            noProject: !this.state.noProject,
        })
    }
    handleSelectProjectClose = () => {
        this.setState({
            noProject: false,
        })
    }

    handleRecoveryModalToggle = (_data) => {
        const { socket, currentProjectId, page } = this.props;

        if (!_data) {
            SocketOperationFunctions.ClearAutoSaved(socket, currentProjectId, page.id, page.buildNumber + "");
        }
        this.setState({
            recoveryData: _data,
            recoveryModal: !this.state.recoveryModal,
        })
    }



    successAlert = (data) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={data.title}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText={i18n.t("BUTTON_OK")}
                    btnSize=""
                >
                    {data.desc}
                </ReactBSAlert>
            ),
        });
    };
    warningAlert = (data) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={data.title}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText={i18n.t("BUTTON_OK")}
                    btnSize=""
                >
                    {data.desc}
                </ReactBSAlert>
            ),
        });
    };
    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };

    getGlobalPropsTypes = () => {
        const { setGlobalPropTypes } = this.props;
        globalPropService.GetGlobalPropTypes().then(data => {

            setGlobalPropTypes(data.dynamicValue);
        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
        });
    }

    getGlobalProps = () => {
        const { setGlobalProps } = this.props;
        globalPropService.GetGlobalProperties(this.props.currentProjectId).then(data => {

            setGlobalProps(data.dynamicValue);
        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

        });
    }

    getComponentList = () => {
        const { setComponentList } = this.props;
        ComponentService.GetComponents(null, true).then(data => {
            setComponentList(data.dynamicValue);
        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
        });
    }

    getMyComponentList = () => {
        const { setMyComponentList } = this.props;
        CustomComponentService.GetMycustomComponents().then(data => {
            setMyComponentList(data.dynamicValue);
        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
        });
    }

    getPagesThumb = () => {
        //getpagesall
        const { setPages } = this.props;
        const that = this;
        pagesService.GetPagesThumb(this.props.currentProjectId).then(data => {
            setPages(data.dynamicValue);
            this.getPageById(data.dynamicValue[0].id)
            // that.getPageComponentNames(data.dynamicValue[0].id)
        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            //Emitter.emit("SHOW_WARNING_MODAL", data);
            that.setState({ noPage: true })

        });
    }


    handleSetAutosave = (_newData) => {

        const { currentProjectId, socket, page, uiEditorStatus } = this.props;
        if (!uiEditorStatus) {
            SocketOperationFunctions.SetAutoSave(socket, currentProjectId, page.id, page.buildNumber + "", _newData);
        }

    }

    handleSetEditorFromSoket = (_newData) => {

        const { updateListSocket, socket, currentProjectId, page } = this.props;
        SocketOperationFunctions.ClearAutoSaved(socket, currentProjectId, page.id, page.buildNumber + "");
        updateListSocket(_newData);
        this.setState({
            recoveryData: null,
            recoveryModal: false,
        })
    }

    getPageComponentNames = (_pageId) => {
        const { setPage, clearHistory, currentProjectId, socket } = this.props;

        ComponentService.GetComponentNames(this.props.currentProjectId, _pageId).then(data => {
            debugger

        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

        });
    }

    getCustomFunctions = () => {
        const { setPage, setCustomFunctions, clearHistory, currentProjectId, socket } = this.props;

        CustomFunctionService.STEPBYLOAD(9999, currentProjectId, false, "-1", "").then(data => {
            if (data.operationResult) {
                setCustomFunctions(data.dynamicValue.dynamicValue);
            }

        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

        });
    }

    getPageById = (_pageId) => {
        const { setPage, setComponentNames, clearHistory, currentProjectId, socket } = this.props;
        const that = this;
        pagesService.GetPageByid(_pageId, this.props.currentProjectId).then(data => {
            setPage(data.dynamicValue);
            setComponentNames(data.dynamicValue.componentNames);
            SocketOperationFunctions.GetExistAutoSaved(socket, currentProjectId, data.dynamicValue.id, data.dynamicValue.buildNumber + "");
            clearHistory();


        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

        });
    }


    getPagesConfirmedOnly = () => {
        /* KULLANILMIYOR */
        pagesService.GetPagesconfirmedonly(this.props.currentProjectId).then(data => {

        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

        });
    }
    GetPagesUnconfirmedOnly = () => {
        /* KULLANILMIYOR */
        pagesService.GetPagesUnconfirmedOnly(this.props.currentProjectId).then(data => {

        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

        });
    }

    setCommit = (_pageId, _bulidName) => {
        /* KULLANILMIYOR */
        pagesService.SetCommit(_pageId, this.props.currentProjectId, _bulidName).then(data => {

        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

        });
    }


    getAllPages = () => {
        //getpagesall
        const { setPages } = this.props;
        pagesService.GetPagesAll(this.props.currentProjectId).then(data => {
            setPages(data.dynamicValue);
        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

        });
    }

    //Session Check
    sessionCheck = () => {
        const { loginSuccess, loginFailure, logout } = this.props;
        let res = localStorage.getItem("user");
        if (res === "null" || res === null || res === undefined) {
            userService.setRedirectTemp().then(data => {
                let token = data.dynamicValue;
                window.location.href = GetAuthURL() + token;
            }).catch(err => {

                //alert(err);
            });
        }
        else {

            //guncelle.
            userService.getMyInformation().then(mData => {
                let Data = mData.dynamicValue;
                let current = JSON.parse(res);
                Data.token = current.token;
                localStorage.setItem("user", JSON.stringify(Data));
                loginSuccess(Data);
                //debugger

                this.getProjectId();
                this.getProjets();
                this.getComponentList();
                this.getGlobalPropsTypes();


            }).catch(err => {

                //alert(err);
            });
        }
    }

    getProjets = () => {
        const that = this;
        ProjectService.GetPaging("-1", null, false, "0", "99999")
            .then(data => {
                //data.dynamicValue
                that.setState({
                    projects: data.dynamicValue,
                })
                that.props.setMyProjects(data.dynamicValue);

            }).catch(err => {
                debugger;
                Alert.error("Error code : " + err.statusCode);

            })
    }


    getStaticCollections = () => {
        const that = this;
        const { currentProjectId, setStaticCollections } = this.props;
        //debugger
        CMSService.GET_ALL_FOR_PANEL(currentProjectId, null, i18n.t("LANG"))
            .then(data => {
                //data.dynamicValue
                // debugger
                setStaticCollections(data.dynamicValue);
            }).catch(err => {
                debugger;
                Alert.error("Error code : " + err.statusCode);

            })
    }


    getMyFunctions = (_search = null) => {
        const { currentProjectId, setDynamicFuncs, dynamicFunctions } = this.props;

        const search = _search ? _search : dynamicFunctions.searchTerm;
        const lastIndexId = _search ? "-1" : dynamicFunctions.lastIndexId;

        DynamicFunctionService.STEPBYLOAD("9999", currentProjectId, false, lastIndexId, search)
            .then(data => {
                setDynamicFuncs(data.dynamicValue);
            }).catch(err => {
                debugger;
                Alert.error("Error code : " + err.statusCode);

            })
    }

    getFunctions = (_type = 0) => {
        const { setPageFuncs } = this.props;
        DynamicFunctionService.GET_EVENTS(_type)
            .then(data => {
                setPageFuncs(data.dynamicValue);
            }).catch(err => {
                debugger;
                Alert.error("Error code : " + err.statusCode);

            })
    }

    getProjectExports = () => {
        const { setProjectExports, currentProjectId } = this.props;
        ExportService.GET_MY_EXPORT(currentProjectId)
            .then(data => {
                setProjectExports(data.dynamicValue);
            }).catch(err => {
                debugger;
                Alert.error("Error code : " + err.statusCode);

            })
    }


    getProjectId = () => {
        const { updateCurrentPage } = this.props;
        let that = this;
        if (that.props.currentProjectId) {
            that.getStaticCollections();
            that.getPagesThumb();
            that.getGlobalProps();
            that.getMyComponentList();
            that.getMyFunctions("");
            that.getFunctions(1);
            that.getProjectExports();
            that.getCustomFunctions();

        } else {
            //  debugger
            Alert.warning(i18n.t("ERROR_NO_PROJECT"));
            this.setState({
                noProject: true,
            })


            /*
            if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
                ProjectService.GetPaging("-1", null, false, "1", "5").then(data => {
                    if (data.dynamicValue.dynamicValue.length < 1) {
                        // alert("proje yok");
                        let data = { title: i18n.t("warning"), desc: i18n.t("HAS_PROJECT_ERROR") };
                        Emitter.emit("SHOW_WARNING_MODAL", data);
                        return;
                    }
                    let projectID = data.dynamicValue.dynamicValue[0].id;
                    if (projectID !== null) {
                        that.props.setProjectId(projectID)

                        that.getPagesThumb();
                        that.getGlobalProps();
                        that.getMyComponentList();
                    }
                }).catch(err => {
                    let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
                    Emitter.emit("SHOW_WARNING_MODAL", data);
                });
            } else {
                Alert.warning("Proje yok");
                this.setState({
                    noProject: true,
                })
            }
            */
        }
    }

    componentDidMount(prevProps) {
        const that = this;




        // this.setupBeforeUnloadListener();

        const { setSocketRef } = this.props;
        PrapareSocket(setSocketRef);

        Emitter.on("SET_AUTOSAVE", (newValue) => this.handleSetAutosave(newValue));
        Emitter.on("UPDATE_PROJECT_ID", (newValue) => this.sessionCheck());
        Emitter.on("SHOW_WARNING_MODAL", (newValue) => this.warningAlert(newValue));
        Emitter.on("SHOW_SUCCESS_MODAL", (newValue) => this.successAlert(newValue));

        Emitter.on(SocketOperations.GET_AUTO_SAVE, (newValue) => this.GET_AUTO_SAVE_Result(newValue));
        Emitter.on(SocketOperations.GET_CURRENT_PAGE_USERS, (newValue) => this.GET_CURRENT_PAGE_USERS_Result(newValue));
        Emitter.on(SocketOperations.GIVE_DETAIL, (newValue) => this.GIVE_DETAIL(newValue));
        Emitter.on(SocketOperations.PAGE_CONTROL_AVAILABLE_RESULT, (newValue) => this.PAGE_CONTROL_AVAILABLE_RESULT(newValue));

        //export op
        //  Emitter.on(SocketOperations.exportOperationsObserve, (newValue) => this.exportOperationsObserve(newValue));

        this.sessionCheck(this);

        //burada patlama olabilir. eger socket null ise
        setTimeout(() => {
            this.InitsocketRecFunc();

        }, 750);

        window.addEventListener('offline', function (e) { that.setState({ online: false }) });

        window.addEventListener('online', function (e) {
            that.setState({ online: true })
            Alert.success(i18n.t("MESSAGE_NETWORK_REONLINE"));

            if (!that.props.currentProjectId) {
                PrapareSocket(setSocketRef);
                that.sessionCheck(that);
                setTimeout(() => {
                    that.InitsocketRecFunc();

                }, 750);
            }

        });
    }

    exportOperationsObserve = (data) => {
        alert("EXPORT ALERT");
        /* 
  public enum ExportTypes
  {
      WaitingProcess =0,
      RequestBeginProcess =1,
      PreparingInlineData =2,
      PreparingAPPSettings =3,
      Ready =4,
      Error =5
  }
*/
    }

    componentWillUnmount() {

        Emitter.off("SET_AUTOSAVE");
        Emitter.off("UPDATE_PROJECT_ID");
        Emitter.off("SHOW_WARNING_MODAL");
        Emitter.off("SHOW_SUCCESS_MODAL");
        Emitter.off(SocketOperations.GET_AUTO_SAVE);
        Emitter.off(SocketOperations.GET_CURRENT_PAGE_USERS);
        Emitter.off(SocketOperations.GIVE_DETAIL);
        Emitter.off(SocketOperations.PAGE_CONTROL_AVAILABLE_RESULT);
        //   Emitter.off(SocketOperations.exportOperationsObserve);
    }

    InitsocketRecFunc = () => {
        const { socket } = this.props;
        console.log("dalga");
    };

    PAGE_CONTROL_AVAILABLE_RESULT = (data) => {
        //  alert("page control available result geldi");
        console.log(data)
        const { setPageStatus } = this.props;
        setPageStatus(data);

        if (data.status) {
            // sayfa müsait
        } else {

            Emitter.emit("EMITTER_SET_PAGE_BUSY", "")
            // sayfa müsait değil

            // data.data.
            /*
                age: 18
                gender: 0
                id: "5ebee11749159d00014cfe17"
                name: "Abdullah"
                profilePhotoImages: []
                surname: null
                userName: "user-15bb05"                     
            */
        }
    }

    GIVE_DETAIL = (data) => {
        this.setCurrentPageClicked();
    }

    //bunlar her simdilik burada istenirse tasinabilir.
    GET_AUTO_SAVE_Result = (data) => {
        if (this.props.pageStatus.status)
            this.handleRecoveryModalToggle(JSON.parse(data))
    }

    GET_CURRENT_PAGE_USERS_Result = (data) => {
        alert("GET_CURRENT_PAGE_USERS geldi dataya bak.");

        //  console.log(data);
    }

    setAutoSaveClicked = () => {
        const { socket } = this.props;
        // SocketOperationFunctions.SetAutoSave(socket, "5ed7c6ceaba48a166a73d91f", "5ef86ca4cc29500001191e44", "1.0", "dalga 11");
        SocketOperationFunctions.SetAutoSave(socket, "ProjectID", "5ef86ca4cc29500001191e44", "1.0", "dalga 11");

    }

    GetAutoSaveClicked = () => {
        const { socket } = this.props;
        SocketOperationFunctions.GetExistAutoSaved(socket, "5ed7c6ceaba48a166a73d91f", "5ef86ca4cc29500001191e44", "1.0");

    }

    ClearAutoSaveClicked = () => {
        const { socket } = this.props;
        SocketOperationFunctions.ClearAutoSaved(socket, "5ed7c6ceaba48a166a73d91f", "5ef86ca4cc29500001191e44", "1.0");

    }

    setCurrentPageClicked = () => {
        const { socket, page, currentProjectId } = this.props;
        const projectId = currentProjectId;


        if (page != {} && projectId) {
            SocketOperationFunctions.SetCurrentPage(socket, projectId, page.id);
        } else {
            setTimeout(() => {
                this.setCurrentPageClicked();
                //debugger
            }, 1000);
        }


    }

    getCurrentPageClicked = () => {
        const { socket } = this.props;
        SocketOperationFunctions.GetCurrentPageUsers(socket, "5ed7c6ceaba48a166a73d91f", "5ef86ca4cc29500001191e44");

    }

    //------


    handleCheckProjects = () => {
        this.setState({
            checkModal: !this.state.checkModal,
        })
    }

    // toggles collapse between mini sidenav and normal
    toggleSidenav = e => {
        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.add("g-sidenav-hidden");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
        }
        this.setState({
            sidenavOpen: !this.state.sidenavOpen
        });
    };


    toggleNavs = (_index) => {
        let index = _index;
        if (_index == this.state.rigthMenuTab) { index = null; }
        this.setState({ rigthMenuTab: index, })
    }


    /*   setupBeforeUnloadListener = () => {
           window.addEventListener("beforeunload", function (e) {
               var confirmationMessage = "\o/";
   
               (e || window.event).returnValue = confirmationMessage; //Gecko + IE
               return confirmationMessage;                            //Webkit, Safari, Chrome
           });
       };
       */




    render() {
        const { online } = this.state;
        return (

            <>
                <Sidebar
                    toggleSidenav={this.toggleSidenav}
                    sidenavOpen={this.state.sidenavOpen}
                />
                {/* Orta alan */}
                <div
                    className="main-content"
                    ref="mainContent"
                    onClick={this.closeSidenav}>

                    <MainNavbar
                        {...this.props}
                        theme={"light"}
                        toggleSidenav={this.toggleSidenav}
                        sidenavOpen={this.state.sidenavOpen}
                        brandText={""}
                    />
                    <div className={"centerSide"}>
                        <div className={"centerSideMain"}>
                            {
                                this.props.uiEditorStatus ?
                                    <UICanvas noPage={this.state.noPage} />
                                    :
                                    <>
                                        <MainHeader handleCheckProjects={this.handleCheckProjects} handleIcon={this.handleIcon} />
                                        <Container id="canvas" className="mt--6" fluid>
                                            <Row>
                                                <Col sm={12} className={"mainCol"}>
                                                    <Canvas noPage={this.state.noPage} />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </>
                            }


                            <AdminFooter />
                        </div>
                        <RigthSidebar />

                    </div>

                </div>
                <div style={{
                    display: "none !important",
                    opacity: "0 !important"
                }} className={"ytp-chrome-controls"} />

                <RecoveryModal data={this.state.recoveryData} isActive={this.state.recoveryModal} handleToggle={this.handleRecoveryModalToggle} handleYes={this.handleSetEditorFromSoket} />

                <CheckProjects isActive={this.state.checkModal} handleToggle={this.handleCheckProjects} />
                <SweetModal />
                <SelectProject isActive={this.state.noProject} handleToggle={this.handleSelectProjectClose} projectList={this.state.projects} />
                {this.state.alert}

                {
                    !online &&
                    <Message style={{ zIndex: 9999 }} full showIcon title="Warning" type="warning" description={i18n.t("MESSAGE_NETWORK_OFFLINE")} />

                }

            </>

        )

    }
}

const mapStateToProps = (state, props) => {
    const { currentstatesReducer, componentReducer, editorReducer, authentication, SocketReducer } = state;
    //const { component } = componentReducer;
    const { currentProjectId, uiEditorStatus, pageStatus, dynamicFunctions } = currentstatesReducer;

    const { present } = editorReducer;
    const { editor, page } = present;
    const { loggedIn, LoginFailed, user } = authentication;
    const { socket } = SocketReducer;
    return {
        editorReducer,
        editor,//:JSON.parse(editor),
        page,
        loggedIn,
        LoginFailed,
        user,
        socket,
        currentProjectId,
        uiEditorStatus,
        pageStatus,
        dynamicFunctions

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateListSocket: (list) => dispatch(updateListSocket(list)),
        clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
        loginSuccess: (data) => dispatch(authenticationAction.LoginSuccess(data)),
        loginFailure: () => dispatch(authenticationAction.LoginFailure()),
        logout: () => dispatch(authenticationAction.logout()),
        setComponentList: (list) => dispatch(setComponentList(list)),
        setMyComponentList: (list) => dispatch(setMyComponentList(list)),
        setGlobalPropTypes: (_data) => dispatch(setGlobalPropTypes(_data)),
        setGlobalProps: (_data) => dispatch(setGlobalProps(_data)),
        setPages: (_data) => dispatch(setPages(_data)),
        setPage: (_data) => dispatch(setPage(_data)),
        updateCurrentPage: (_id) => dispatch(updateCurrentPage(_id)),
        setSocketRef: (_socket) => dispatch(setRefSocketRef(_socket)),
        setPageStatus: (_status) => dispatch(setPageStatus(_status)),
        setProjectId: (_id) => dispatch(setProjectId(_id)),
        setStaticCollections: (_data) => dispatch(setStaticCollections(_data)),
        setDynamicFuncs: (_data) => dispatch(setDynamicFuncs(_data)),
        setPageFuncs: (_data) => dispatch(setPageFuncs(_data)),
        setProjectExports: (_data) => dispatch(setProjectExports(_data)),
        setMyProjects: (_data) => dispatch(setMyProjects(_data)),
        setComponentNames: (_data) => dispatch(setComponentNames(_data)),
        setCustomFunctions: (_data) => dispatch(setCustomFunctions(_data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Main)
