import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";

import {
    FormGroup,
    Form,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap";

import { updateComponent,updateList } from '_actions';
import { findObjUpdate } from 'assets/util';

class FlexProperty extends Component {

    constructor(props) {
        super(props)
        const { component, nullOption } = props;
        // const { object } = component.components[0];
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];


        const { style, value } = object.props;
        this.state = {
            component: component,
            type: (style[props.propName] == undefined ? nullOption : style[props.propName]),
        }

    }


    setProperties = () => {
     
        let _clone = Object.assign({}, this.props.component);
        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props.style = {
                ..._clone.object.components[0].object.props.style,
                [this.props.propName]: (this.state.type),
            }
        } else {
            _clone.components[0].object.props.style = {
                ..._clone.components[0].object.props.style,
                [this.props.propName]: (this.state.type),
            }
        }
  
        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);
    }


    componentDidMount() {

    }
    componentDidUpdate = (prevProps) => {
        const { component, nullOption } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        const { style, value } = object.props;

        if (prevProps.component != component) {
            this.setState({
                component: component,
                type: (style[this.props.propName] == undefined ? nullOption : style[this.props.propName]),
            }, () => {
                //this.setProperties();
            })
        }
    }
    handleChangeType = (e, selected) => {
        e.preventDefault()
        this.setState({
            type: selected,
        }, () => {
            this.setProperties();
        })
    }



    render() {
        const { component, propName, options } = this.props;
        // debugger
        return (
            <Translation>
                {t => (

                    <div className={"property-dropdown"}>
                        <UncontrolledDropdown className={"property-dropdown-div"}>
                            <DropdownToggle caret color="second" size="sm" className={"property-dropdown property-dropdown2"} >
                                {this.state.type}
                            </DropdownToggle>
                            <DropdownMenu>
                                {
                                    //(options.filter(x => x != this.state.type)).map((item) => {
                                    (options.filter(x => x != this.state.type)).map((item) => {
                                        return (
                                            <DropdownItem key={propName + item} href="#type" onClick={e => (this.handleChangeType(e, item))}>
                                                {item}
                                            </DropdownItem>)
                                    })
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>


                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer,editorReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        component,
        editor
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
}
export default connect(mapStateToProps, mapDispatchToProps)(FlexProperty)
