import React, { Component } from 'react'
import { CirclePicker, SketchPicker } from 'react-color'
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import { colorToRgbA, lightOrDark } from "assets/util";
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { updatePageStyle } from '_actions';
import {
    Button,
    UncontrolledTooltip
} from "reactstrap";

class ColorPicker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            displayColorPicker: false,
            color: colorToRgbA(props.pageStyle ? props.pageStyle[this.getComponentPropName()]:"#FFFFFF"),
            previewColor: colorToRgbA(props.pageStyle ? props.pageStyle[this.getComponentPropName()]:"#FFFFFF"),
        }

    }

    componentDidUpdate = (prevProps) => {
        if(this.props.pageStyle==null){
            return
        }
        //debugger
        if (this.props.pageStyle[this.getComponentPropName()] != (prevProps.pageStyle == null ? null: prevProps.pageStyle[this.getComponentPropName()])) {
            this.setState({
                color: colorToRgbA(this.props.pageStyle[this.getComponentPropName()]),
                previewColor: colorToRgbA(this.props.pageStyle[this.getComponentPropName()]),
            })
        } else if ((this.getGlobalPropValue() != null) || (this.getGlobalPropValue() != undefined)) {
            const _c = colorToRgbA(this.getGlobalPropValue());
            if (JSON.stringify(this.state.color) != JSON.stringify(_c))
                this.setState({
                    color: colorToRgbA(this.getGlobalPropValue()),
                    previewColor: colorToRgbA(this.getGlobalPropValue()),
                })
        }
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    getGlobalPropValue = () => {

        let _id = this.getGlobalPropId().id;
        let _array = this.props.globalProps ? [].concat(this.props.globalProps) : [];
        let _index = _array.length > 0 ? _array.findIndex(x => x.id === _id) : -1;
        return _array[_index] ? _array[_index].value : null;


    }

    getGlobalPropId = () => {
        let propName = this.getComponentPropName();
        let _first = propName.substring(0, 1).toUpperCase();
        let _last = propName.substring(1, propName.length);

        propName = "global" + _first + _last;

        return {
            id: this.props.pageStyle?this.props.pageStyle[propName]:null,
            name: propName,
        };

    }

    getComponentPropName = () => {
        let propName = "";

        switch (this.props.id) {
            case "font-color":
                propName = "color"
                break;
            case "background-color":
                propName = "backgroundColor"
                break;
            case "border-color":
                propName = "borderColor"
                break;
            case "icon-color":
                propName = "iconColor"
                break;
            default:
                break;
        }

        return propName;
    }

    getComponentPropNameAsUpper = () => {
        let propName = "";

        switch (this.props.id) {
            case "font-color":
                propName = "Color"
                break;
            case "background-color":
                propName = "BackgroundColor"
                break;
            case "border-color":
                propName = "BorderColor"
                break;
            case "icon-color":
                propName = "IconColor"
                break;
            default:
                break;
        }

        return propName;
    }


    handleChange = (color) => {
        const { isComponent } = this.props;
        let _clone = Object.assign({}, this.props.pageStyle);
        let mainPropName = this.getComponentPropName();

        if (isComponent) {
            let propName = this.getComponentPropNameAsUpper();
            _clone["global" + propName] = null;

        }

        this.setState({ color: color.rgb }, () => {

            _clone[mainPropName] = `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`
            this.props.updatePageStyle(_clone);

        })

        //`rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`
    };

    handleSetColor = () => {
        this.setState({ color: this.state.previewColor }, () => {
        })
    }
    handleCancelColor = () => {
        this.setState({ previewColor: this.state.color }, () => {
        })
    }

    handleGlobalColor = (_data) => {
        let propName = this.getComponentPropNameAsUpper();

        this.setState({ color: colorToRgbA(_data.value) }, () => {
            let _clone = Object.assign({}, this.props.pageStyle);
            let mainPropName = this.getComponentPropName();

            _clone = {
                ..._clone,
                ["global" + propName]: _data.id,
                [mainPropName]: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`
            }
            this.props.updatePageStyle(_clone);

        })
    }

    render() {
        const { isComponent } = this.props;
        const _globalID = this.getGlobalPropId();
        return (
            <Translation>
                {t => (
                    <div>
                        <div
                            id={"tooltip" + this.props.id + "pagesStyle"}
                            style={{
                                padding: '5px',
                                background: '#fff',
                                borderRadius: '1px',
                                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                                display: 'inline-block',
                                cursor: 'pointer',
                            }} onClick={this.handleClick}>
                            <div style={{
                                width: '36px',
                                height: '14px',
                                borderRadius: '2px',
                                background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
                            }} />
                        </div>
                        <UncontrolledPopover
                            trigger="legacy"
                            placement="top"
                            target={"tooltip" + this.props.id + "pagesStyle"}
                            className="popover-colorpicker">
                            <PopoverBody className={"popover-colorpicker-body"}>
                                <div style={{ background: "white" }}>
                                    <SketchPicker 
                                     presetColors={['#e53935', '#d81b60', '#8e24aa', '#3949ab', '#039be5', '#00acc1', '#43a047', '#7cb342', '#fdd835', '#fb8c00', '#f4511e', '#6d4c41', '#757575', '#000000', '#FFFFFF',"#00000000"]}
                                    color={this.state.color} onChangeComplete={this.handleChange} />
                                    {
                                        isComponent &&
                                        this.props.globalProps &&
                                        ([].concat(this.props.globalProps).filter(x => x.type == 0)).length > 0 &&
                                        <div style={{ background: "white" }}>
                                            <p className={"mb-1 mt-1 text-nowrap text-uppercase text-muted"} style={{ fontSize: ".7rem", padding: "0px 10px" }}>
                                                {t("TITLE_POPOVER_COLOR_GLOBAL")}
                                            </p>
                                            <div className={"color-picker-globalcolors"}>
                                                {
                                                    this.props.globalProps &&
                                                    ([].concat(this.props.globalProps).filter(x => x.type == 0)).map((item, key) => (
                                                        <div key={key} className={"color-picker-globalcolors-item"}>
                                                            <span>
                                                                <div id={"color-" + item.id} onClick={() => { this.handleGlobalColor(item) }}
                                                                    style={{
                                                                        background: item.value,
                                                                        color: (lightOrDark(item.value) == "light" ? "#525f7f" : "#f6f9fc"),
                                                                        fontSize: "12px",
                                                                        textAlign: "center",
                                                                        border: "1px solid #525f7f",
                                                                    }}
                                                                    title={item.value}
                                                                    tabIndex="0" className={"color-picker-globalcolor-item-div"}>
                                                                    {
                                                                        item.id == _globalID.id &&
                                                                        <i className={"ni ni-check-bold"} />
                                                                    }
                                                                </div>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    placement="auto"
                                                                    target={"color-" + item.id}
                                                                >
                                                                    {item.name}
                                                                </UncontrolledTooltip>
                                                            </span>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    }

                                </div>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                )}
            </Translation>
        )
    }
}

//export default ColorPicker
const mapStateToProps = (state, props) => {
    const { globalpropsReducer, editorReducer } = state;
    const { globalProps } = globalpropsReducer;

    const { present } = editorReducer;
    const { pages, currentPage, page } = present;
//debugger
    const pageStyle = page.hasOwnProperty("pageProperties") ? page.pageProperties.style : null;


    return {
        globalProps,
        pageStyle
    };
}
const mapDispatchToProps = {
    updatePageStyle
}
export default connect(mapStateToProps, mapDispatchToProps)(ColorPicker)
