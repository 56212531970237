import React, { Component } from 'react'
import { Translation } from "react-i18next";
import classnames from "classnames";
import Emitter from "_actions/emitter";


import Properties from './Properties';
import Components from './Components';
import GlobalProperties from './GlobalProperties';
import LayoutSettings from './LayoutSettings';
import HttpObjects from './HttpObjects';
import Functions from './Functions';
import ProjectSettings from './ProjectSettings';

import Test from './Test';


import {
    Card,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane

} from "reactstrap";

export class RigthSidebar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            rigthMenuTab: 0,
        }
    }

    toggleNavs = (_index) => {
        let index = _index;
        if (_index == this.state.rigthMenuTab) { index = null; }
        this.setState({ rigthMenuTab: index, })
    }

    toggleNavsEmitter = (_index) => {
        let index = _index;
        this.setState({ rigthMenuTab: index, })
    }


    componentDidMount() {
        Emitter.on("OPEN_RIGTHSIDE_TAB", (newValue) => this.toggleNavsEmitter(newValue));

    }
    componentWillUnmount() {
        Emitter.off("OPEN_RIGTHSIDE_TAB");
    }



    render() {
        return (
            <Translation>
                {t => (
                    <div className={"rigthMenuSide"}>
                        <div className="navbar-inner rigthMenu-container">
                            <div className={"rigthMenuSideInside " + (this.state.rigthMenuTab == null ? "rigthMenuSidePasive" : "")}>
                                <Card className="rigth-tab-shadow">
                                    <CardBody>
                                        <TabContent activeTab={"tabs" + this.state.rigthMenuTab}>
                                            <TabPane tabId="tabs0" className={"rigth-tab-pane"}>
                                                {
                                                    <Properties />
                                                }
                                            </TabPane>
                                            <TabPane tabId="tabs1" className={"rigth-tab-pane"}>
                                                <Components handleProperty={this.toggleNavs} />
                                            </TabPane>
                                            <TabPane tabId="tabs2" className={"rigth-tab-pane"}>
                                                <Functions />
                                            </TabPane>
                                            <TabPane tabId="tabs3" className={"rigth-tab-pane"}>
                                                <GlobalProperties />
                                            </TabPane>
                                            <TabPane tabId="tabs4" className={"rigth-tab-pane"}>
                                                <LayoutSettings />
                                            </TabPane>
                                            <TabPane tabId="tabs5" className={"rigth-tab-pane"}>
                                                <HttpObjects />
                                            </TabPane>
                                            <TabPane tabId="tabs6" className={"rigth-tab-pane"}>
                                                <ProjectSettings />
                                            </TabPane>
                                            <TabPane tabId="tabs7" className={"rigth-tab-pane"}>
                                                <Test />
                                            </TabPane>

                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className={"rigthMenuSideTabs"}>
                            <div className="nav-wrapper2">
                                <Nav
                                    className="nav-fill flex-column flex-md-column"
                                    id="tabs-icons-text"
                                    pills
                                    role="tablist"
                                >
                                    <NavItem className={"rigthMenuTab"}>
                                        <NavLink
                                            aria-selected={this.state.rigthMenuTab == 0}
                                            className={classnames("mb-sm-3 mb-md-0 rigthMenuTabInside", {
                                                active: this.state.rigthMenuTab === 0
                                            })}
                                            onClick={() => this.toggleNavs(0)}
                                            role="tab"
                                        >
                                            {
                                                /*
                                                <i className="fas fa-magic"></i>
                                                <i className="fas fa-swatchbook"></i>
                                                     <i className="ni ni-ruler-pencil"></i>
    
                                                */
                                            }
                                            <i className="fas fa-swatchbook"></i>


                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={"rigthMenuTab"}>
                                        <NavLink
                                            aria-selected={this.state.rigthMenuTab === 1}
                                            className={classnames("mb-sm-3 mb-md-0 rigthMenuTabInside", {
                                                active: this.state.rigthMenuTab === 1
                                            })}
                                            onClick={() => this.toggleNavs(1)}
                                            role="tab"
                                        >

                                            <i className="fas fa-layer-group"></i>
                                        </NavLink>
                                    </NavItem>
                                   {
                                       /*
                                       
                                         <NavItem className={"rigthMenuTab"}>
                                        <NavLink
                                            aria-selected={this.state.rigthMenuTab === 2}
                                            className={classnames("mb-sm-3 mb-md-0 rigthMenuTabInside", {
                                                active: this.state.rigthMenuTab === 2
                                            })}
                                            onClick={() => this.toggleNavs(2)}
                                            role="tab"
                                        >
                                            <i className="fas fa-code"></i>
                                        </NavLink>
                                    </NavItem>
                                       */
                                   }
                                  

                                    <NavItem className={"rigthMenuTab"}>
                                        <NavLink
                                            aria-selected={this.state.rigthMenuTab === 3}
                                            className={classnames("mb-sm-3 mb-md-0 rigthMenuTabInside", {
                                                active: this.state.rigthMenuTab === 3
                                            })}
                                            onClick={() => this.toggleNavs(3)}
                                            role="tab"
                                        >
                                            <i className="fas fa-globe"></i>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={"rigthMenuTab"}>
                                        <NavLink
                                            aria-selected={this.state.rigthMenuTab === 4}
                                            className={classnames("mb-sm-3 mb-md-0 rigthMenuTabInside", {
                                                active: this.state.rigthMenuTab === 4
                                            })}
                                            onClick={() => this.toggleNavs(4)}
                                            role="tab"
                                        >
                                            <i className="fas fa-sliders-h"></i>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={"rigthMenuTab"}>
                                        <NavLink
                                            aria-selected={this.state.rigthMenuTab === 5}
                                            className={classnames("mb-sm-3 mb-md-0 rigthMenuTabInside", {
                                                active: this.state.rigthMenuTab === 5
                                            })}
                                            onClick={() => this.toggleNavs(5)}
                                            role="tab"
                                        >
                                            <i className="fas fa-network-wired"></i>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={"rigthMenuTab"}>
                                        <NavLink
                                            aria-selected={this.state.rigthMenuTab === 6}
                                            className={classnames("mb-sm-3 mb-md-0 rigthMenuTabInside", {
                                                active: this.state.rigthMenuTab === 6
                                            })}
                                            onClick={() => this.toggleNavs(6)}
                                            role="tab"
                                        >
                                            <i className="fas fa-code"></i>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className={"rigthMenuTab"}>
                                        <NavLink
                                            aria-selected={this.state.rigthMenuTab === 7}
                                            className={classnames("mb-sm-3 mb-md-0 rigthMenuTabInside", {
                                                active: this.state.rigthMenuTab === 7
                                            })}
                                            onClick={() => this.toggleNavs(7)}
                                            role="tab"
                                        >
                                            <i className="fas fa-democrat"></i>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>

                        </div>
                    </div>
                )}
            </Translation>
        )
    }
}

export default RigthSidebar
