import {
    //ServiceURLs,
    CustomFunctionsEndPoints,
    DynamicFunctionsEndPoints,
    GetBaseCreatorURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const CustomFunctionService = {
    CREATE_FUNCTION,
    UPDATE_FUNCTION,
    REMOVE_FUNCTION,
    STEPBYLOAD,
 
  };

//PAGE ID NULL VERIRSEN ALAYI GELIR.
  function CREATE_FUNCTION(RelProjectId,Name,Script) {
    var js = {
      RelProjectId:RelProjectId ,
      Name:Name ,
      Script:Script
    };
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CustomFunctionsEndPoints.CREATE_FUNCTION,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function UPDATE_FUNCTION(id,RelProjectId,Name,Script) {
    var js = {
      id:id,
      RelProjectId:RelProjectId ,
      Name:Name ,
      Script:Script
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CustomFunctionsEndPoints.UPDATE_FUNCTION,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function REMOVE_FUNCTION(id,RelProjectId) {
    var js = {
      id:id,
      RelProjectId:RelProjectId 
 
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CustomFunctionsEndPoints.REMOVE_FUNCTION,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  //DynamicObj = project id
  function STEPBYLOAD(Count,DynamicObj,IsOrderByDesc,LastIndexId,SearchTerm) {
    var js = {
        Count: Count,
        DynamicObj:DynamicObj,// null,
        IsOrderByDesc: IsOrderByDesc,
        LastIndexId: LastIndexId,//null,
        SearchTerm: SearchTerm
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CustomFunctionsEndPoints.STEPBYLOAD,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }



 