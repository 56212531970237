import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from 'i18n';

import {
    FormGroup,
    Form,

} from "reactstrap";

import { updateComponent, updateList } from '_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';

import {
    PropertyTitle,
} from "../Core/";
import StaticCMSSettings from "../Properties/StaticCMSSettings"
import StaticTextValue from '../Core/StaticTextValue'


import { SelectPicker, Dropdown, TagPicker, Slider, RangeSlider } from 'rsuite';

const WAIT_INTERVAL = 500;
const defautValueOptions = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: true,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: false,
    }
]

const defautBlurOptions = [
    {
        label: i18n.t("BLUR_TYPE_LIGTH"),
        value: "light",
    },
    {
        label: i18n.t("BLUR_TYPE_DARK"),
        value: "dark",
    }
]

class BlurSettings extends Component {
    constructor(props) {
        super(props)
        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        this.state = {
            isActive: component && object.props.blurview ? true : false,
            type: component && object.props.blurview ? (object.props.blurview.type ? defautBlurOptions.find(x => x.value === object.props.blurview.type).value : defautBlurOptions[0].value) : defautBlurOptions[0].value,
            amount: component && object.props.blurview ? (object.props.blurview.amount) : 10,
        }

    }

    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const style = object.props[this.props.dataPath ? this.props.dataPath : "style"];

        if (prevProps.component != component) {
            this.setState({
                isActive: component && object.props.blurview ? true : false,
                type: component && object.props.blurview ? (object.props.blurview.type ? defautBlurOptions.find(x => x.value === object.props.blurview.type).value : defautBlurOptions[0].value) : defautBlurOptions[0].value,
                amount: component && object.props.blurview ? (object.props.blurview.amount) : 10,
            })
        }
    }

    handleUpdateValue = (_data, _name) => {
        this.setState({
            [_name]: _data.value,
        }, () => {
            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
            } else {
                _clone.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

    handlIsActive = (_data) => {
        const that = this;
        this.setState({
            ["isActive"]: _data.value,
        }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";
            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.blurview = that.state.isActive ? {
                    type: "light",
                    amount: 3
                } : null;
            } else {
                _clone.components[0].object.props.blurview = that.state.isActive ? {
                    type: "light",
                    amount: 3
                } : null;
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

    handleChangeWithTime = (_value, _name) => {
        clearTimeout(this.timer);
        this.setState({
            [_name]: _value,
        });

        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);


    }

    triggerChange = () => {
        this.handleSetBlurAmount();
    }

    handleSetBlurAmount = () => {
        const _value = this.state.amount;
        const that = this;
        this.setState({
            amount: _value,
        }, () => {

            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.blurview = {
                    ..._clone.object.components[0].object.props.blurview,
                    amount: that.state.amount
                }
            } else {
                _clone.components[0].object.props.blurview = {
                    ..._clone.components[0].object.props.blurview,
                    amount: that.state.amount
                }
            }

            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }

    handleSetBlurType = (_value) => {
        const that = this;
        this.setState({
            type: _value,
        }, () => {
            const _style = this.props.hasOwnProperty("dataPath") ? this.props.dataPath : "style";

            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.blurview = {
                    ..._clone.object.components[0].object.props.blurview,
                    type: that.state.type
                }
            } else {
                _clone.components[0].object.props.blurview = {
                    ..._clone.components[0].object.props.blurview,
                    type: that.state.type
                }
            }

            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }

    render() {
        const { component, globalProps } = this.props;
        let _component;
        if (component.hasOwnProperty("componentOwner"))
            _component = component.object.components[0];
        else
            _component = component.components[0]


        //  secureTextEntry
        return (
            <Translation>
                {t => (
                    <Form>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_BLUR_ISACTIVE")}
                                </label>
                                {
                                    this.state["secureTextEntry"] !== null &&
                                    <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["isActive"]).label}>
                                        {
                                            defautValueOptions.map((x, key) => (
                                                x.value === this.state["isActive"] ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handlIsActive(x)}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                }
                            </div>
                        </FormGroup>
                        {
                            this.state.isActive &&
                            <>
                                <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}>
                                        <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_BLUR_TYPE")}
                                        </label>
                                    </div>
                                    <SelectPicker
                                        onSelect={(value, item, event) => {
                                            this.handleSetBlurType(value)
                                        }}
                                        onClean={() => { this.handleSetBlurType(null) }}
                                        style={{
                                            width: "100%",
                                            height: "45px",
                                        }}
                                        defaultValue={this.state.type}
                                        maxHeight={160}
                                        placeholder={t("PROPERTY_BLUR_TYPE")}
                                        data={defautBlurOptions}
                                    />
                                </FormGroup>
                            </>
                        }
                        {
                            this.state.isActive &&
                            <>
                                <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}>
                                        <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_BLUR_AMOUNT")}
                                        </label>
                                        <label className={"propertySubTitle"} >
                                                    {this.state.amount}
                                                </label>
                                    </div>
                                    <Slider
                                        progress
                                        defaultValue={this.state.amount}
                                        max={10}
                                        min={0}
                                        onChange={value => {
                                            this.handleChangeWithTime(value, "amount")
                                        }}
                                    />
                                </FormGroup>
                            </>
                        }
                    </Form>
                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, globalpropsReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;

    const { globalProps } = globalpropsReducer;

    return {
        editor,
        component,
        globalProps
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,

}
export default connect(mapStateToProps, mapDispatchToProps)(BlurSettings)
