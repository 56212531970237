import {
    currentstatesConstants
} from "../_constants";

export function setOpenedPage(_page) {
    return {
        type: currentstatesConstants.SET_CURRENTPAGE,
        payload: {
            page: _page,
        }
    }
}

export function setImageUploadLoading(_status) {
    return {
        type: currentstatesConstants.SET_UPLOADIMAGELOADING,
        payload: {
            data: _status,
        }
    }
}
export function setMyStream(_data) {
    return {
        type: currentstatesConstants.SET_MYSTREAM,
        payload: {
            data: _data,
        }
    }
}
export function setProjectStream(_data) {
    return {
        type: currentstatesConstants.SET_PROJECTSTREAM,
        payload: {
            data: _data,
        }
    }
}
export function setSystemStream(_data) {
    return {
        type: currentstatesConstants.SET_SYSTEMSTREAM,
        payload: {
            data: _data,
        }
    }
}

export function setMyStreamLoading(_status) {
    return {
        type: currentstatesConstants.SET_MYSTREAMLOADING,
        payload: {
            data: _status,
        }
    }
}
export function setProjectStreamLoading(_status) {
    return {
        type: currentstatesConstants.SET_PROJECTSTREAMLOADING,
        payload: {
            data: _status,
        }
    }
}


export function setSystemStreamLoading(_status) {
    return {
        type: currentstatesConstants.SET_SYSTEMSTREAMLOADING,
        payload: {
            data: _status,
        }
    }
}

export function setPageStatus(_status) {
    return {
        type: currentstatesConstants.SET_PAGESTATUS,
        payload: {
            data: _status,
        }
    }
}

export function setOnMove(_data) {
    return {
        type: currentstatesConstants.SET_ONMOVE_STATUS,
        payload: {
            status: true,
            data: _data,
        }
    }
}

export function setOnAdd() {
    return {
        type: currentstatesConstants.SET_ONADD_STATUS,
        payload: {
            data: true,
        }
    }
}
export function setOnAddUpdate(_objFrom, _currentEditor, _objFromId) {
    return {
        type: currentstatesConstants.SET_ONADD_UPDATE,
        payload: {
            objFrom: _objFrom,
            objFromId: _objFromId,
            currentEditor: _currentEditor,
        }
    }
}


export function setOnRemove() {
    return {
        type: currentstatesConstants.SET_ONREMOVE_STATUS,
        payload: {
            data: true,
        }
    }
}
export function setOnEnd() {
    return {
        type: currentstatesConstants.SET_ONEND_STATUS,
        payload: {
            data: true,
        }
    }
}

export function setProjectId(_id) {
    return {
        type: currentstatesConstants.SET_PROJECT_ID,
        payload: {
            id: _id,
        }
    }
}
export function setProjectData(_data) {
    return {
        type: currentstatesConstants.SET_PROJECT,
        payload: {
            data: _data,
        }
    }
}

export function setStaticCollections(_data) {
    return {
        type: currentstatesConstants.SET_PROJECT_STATIC_COLLECTIONS,
        payload: {
            data: _data,
        }
    }
}

export function setUIEditorStatus(_data,_component,field) {
    return {
        type: currentstatesConstants.SET_UI_EDITOR_STATUS,
        payload: {
            data: _data,
            component:_component,
            fromField:field
        }
    }
}
export function setUIEditorStatusBack(_data) {
    return {
        type: currentstatesConstants.SET_UI_EDITOR_STATUS_BACK,
        payload: {
            data: _data
           }
    }
}

export function setEnvironments(_data) {
    return {
        type: currentstatesConstants.SET_ENVIROMENTS,
        payload: {
            data: _data,
        }
    }
}
export function setHttpObjects(_data) {
    return {
        type: currentstatesConstants.SET_HTTPOBJECTS,
        payload: {
            data: _data,
        }
    }
}

export function setDynamicFuncs(_data) {
    return {
        type: currentstatesConstants.SET_DYNAMICFUNCS,
        payload: {
            data: _data,
        }
    }
}

export function setPageFuncs(_data) {
    return {
        type: currentstatesConstants.SET_PAGEFUNCS,
        payload: {
            data: _data,
        }
    }
}

export function setProjectExports(_data) {
    return {
        type: currentstatesConstants.SET_PROJECT_EXPORTS,
        payload: {
            data: _data,
        }
    }
}
export function setMyProjects(_data) {
    return {
        type: currentstatesConstants.SET_MY_PROJECTS,
        payload: {
            data: _data,
        }
    }
}
export function setReset() {
    return {
        type: currentstatesConstants.SET_RESET,
        payload: {}
    }
}

export function setComponentNames(_data) {
    return {
        type: currentstatesConstants.SET_COMPONENT_NAMES,
        payload: {
            data: _data,
        }
    }
}

export function setCustomFunctions(_data) {
    return {
        type: currentstatesConstants.SET_CUSTOM_FUNCTIONS,
        payload: {
            data: _data,
        }
    }
}
