import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from 'i18n';

import { FormGroup, Form, Input, Row, Col, ButtonGroup, Button } from "reactstrap";
import { updateComponent, updateList } from '../../../_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';

import NumaricDataProperty from "../Core/NumaricDataProperty"
import { Dropdown, TagPicker } from 'rsuite';
import ColorPicker from "../Core/ColorPicker";


import {
    PropertyTitle,
} from "../Core/";

const defautValueOptions = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: true,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: false,
    }
]
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class PageSwiperSettings extends Component {
    constructor(props) {
        super(props)
        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        this.state = {
            currentIndex: component && object.props.currentIndex,
            pages: component && object.props.pages,
            scrollEnabled: component && (defautValueOptions.find(x => x.value === object.props.scrollEnabled).value),
            showsButtons: component && (defautValueOptions.find(x => x.value === object.props.showsButtons).value),
            showsPagination: component && (defautValueOptions.find(x => x.value === object.props.showsPagination).value),
            loop: component && (defautValueOptions.find(x => x.value === object.props.loop).value),
            dotsColor: component && (object.props.dotsColor == undefined ? "rgba(10, 122, 255, 1)" : object.props.dotsColor),
            horizontal: component && object.props.hasOwnProperty("horizontal")?(defautValueOptions.find(x => x.value === object.props.horizontal).value):true,

        }

    }
    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const style = object.props[this.props.dataPath ? this.props.dataPath : "style"];

        if (prevProps.component != component) {
            this.setState({
                currentIndex: component && object.props.currentIndex,
                pages: component && object.props.pages,
                scrollEnabled: component && (defautValueOptions.find(x => x.value === object.props.scrollEnabled).value),
                showsButtons: component && (defautValueOptions.find(x => x.value === object.props.showsButtons).value),
                showsPagination: component && (defautValueOptions.find(x => x.value === object.props.showsPagination).value),
                loop: component && (defautValueOptions.find(x => x.value === object.props.loop).value),
                dotsColor: component && (object.props.dotsColor == undefined ? "rgba(10, 122, 255, 1)" : object.props.dotsColor),
                horizontal: component && object.props.hasOwnProperty("horizontal")?(defautValueOptions.find(x => x.value === object.props.horizontal).value):true,
            }, () => {
                //this.generateFontSizeInput();
                //   var _fontSizeSlider = this.fontSizeSlider.current;
                // _fontSizeSlider.noUiSlider.set(this.state.fontSize)

            })
        }
    }


    componentDidMount() {

    }

    handleChangeDotsColor=(color)=>{
        this.setState({
            dotsColor: color,
        }, () => {
            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props["dotsColor"] =  `rgba(${this.state.dotsColor.r}, ${this.state.dotsColor.g}, ${this.state.dotsColor.b}, ${this.state.dotsColor.a})`
            } else {
                _clone.components[0].object.props["dotsColor"] =  `rgba(${this.state.dotsColor.r}, ${this.state.dotsColor.g}, ${this.state.dotsColor.b}, ${this.state.dotsColor.a})`
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }

    handleUpdateValue = (_data, _name) => {

        this.setState({
            [_name]: _data.value,
        }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";
            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
            } else {
                _clone.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

    setProperties =()=>{
        const _name = "currentIndex";
        const _data = this.state[_name];

        const _style = this.props.dataPath ? this.props.dataPath : "style";
        let _clone = Object.assign({}, this.props.component);

        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
        } else {
            _clone.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
        }
        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);

    }

    componentWillMount() {
        this.timer = null;
    }


    handleChange = (event) => {
        const propName = "currentIndex";
        const { target } = event;
        var theEvent = event || window.event;

        clearTimeout(this.timer);

        var regex = /^[a-zA-Z][a-zA-Z0-9_]{0,30}$/;
        if(target.value.length === 0){
            this.setState({
                [propName]: event.target.value,
            });
        }
        else if (!regex.test(target.value)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        } else {
            this.setState({
                [propName]: event.target.value,
            });
        }

        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);


    }

    triggerChange = (_data ) => {
        this.setProperties();
    }
    onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }


    render() {
        const { component } = this.props;
        return (
            <Translation>
                {t => (
                    <Form>
                        {
                            /**
                             currentIndex : dynamicStore
                             pages : array
                             scrollEnabled: bool
                             showsButtons: bool
                             showsPagination: bool
                             loop: bool
                             horizontal: bool
                             */
                        }

                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_PAGE_SWIPER_PAGES")} subtitle={""} />
                            <TagPicker
                                data={this.props.pages.map((item, key) => {
                                    const _obj = {
                                        label: item.pageName,
                                        value: item.id
                                    }
                                    return _obj;
                                })}
                                cleanable={false}
                                defaultValue={this.state["pages"] && [...this.state["pages"]]}
                                disabledItemValues={[this.props.page.id]}
                                style={{ width: "100%" }}
                                menuStyle={{ width: 150 }}
                                onChange={(value) => this.handleUpdateValue(value, "pages")}
                            />
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_PAGE_SWIPER_CURRENTINDEX")} subtitle={""} />
                            <div>
                                <Input
                                    pattern="[0-9]*"
                                    //name={"currentIndex"}
                                    value={this.state["currentIndex"]}
                                    onBlur={() => { }}
                                    onKeyDown={this.onKeyDown.bind(this)}
                                    onInput={this.handleChange.bind(this)}
                                    className={"form-control-sm property-dropdown-input "}
                                    placeholder="" type="text" />
                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_PAGE_SWIPER_SCROLLABLE")}
                                </label>
                                {
                                    this.state["scrollEnabled"] !== null &&
                                    <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["scrollEnabled"]).label}>
                                        {
                                            defautValueOptions.map((x, key) => (
                                                x.value === this.state["scrollEnabled"] ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "scrollEnabled")}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                }

                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_PAGE_SWIPER_SHOWBUTTONS")}
                                </label>
                                {
                                    this.state["showsButtons"] !== null &&
                                    <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["showsButtons"]).label}>
                                        {
                                            defautValueOptions.map((x, key) => (
                                                x.value === this.state["showsButtons"] ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "showsButtons")}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                }

                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_PAGE_SWIPER_SHOWPAGINATION")}
                                </label>
                                {
                                    this.state["showsPagination"] !== null &&
                                    <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["showsPagination"]).label}>
                                        {
                                            defautValueOptions.map((x, key) => (
                                                x.value === this.state["showsPagination"] ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "showsPagination")}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                }

                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_PAGESWIPER_DOT_COLOR")}
                                </label>
                                <ColorPicker isComponent={true} id={"dotsColor"} color={this.state.dotsColor} handle={this.handleChangeDotsColor} />
                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_PAGE_SWIPER_LOOP")}
                                </label>
                                {
                                    this.state["loop"] !== null &&
                                    <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["loop"]).label}>
                                        {
                                            defautValueOptions.map((x, key) => (
                                                x.value === this.state["loop"] ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "loop")}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                }

                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_PAGE_SWIPER_HORIZONTAL")}
                                </label>
                                {
                                    this.state["horizontal"] !== null &&
                                    <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["horizontal"]).label}>
                                        {
                                            defautValueOptions.map((x, key) => (
                                                x.value === this.state["horizontal"] ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "horizontal")}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                }

                            </div>
                        </FormGroup>
                        


                        {
                            /*
                               <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_CAROUSEL_SHOWCOUNTER")}
                                </label>
                                {
                                    this.state["showCounter"] !== null &&
                                    <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["showCounter"]).label}>
                                        {
                                            defautValueOptions.map((x, key) => (
                                                x.value === this.state["showCounter"] ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "showCounter")}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                }

                            </div>
                        </FormGroup>

                            
                            */
                        }



                    </Form>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor, page, pages } = present;
    return {
        editor,
        component,
        page,
        pages
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,

}
export default connect(mapStateToProps, mapDispatchToProps)(PageSwiperSettings)
