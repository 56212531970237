import React, { Component } from 'react'

export class GalleryTitle extends Component {

    render() {
        const { title, Child } = this.props;
        return (
            <div className={"galleryTitleDiv"}>
                <label  className={"galleryTitle"}>{title}
                </label>
                {Child}
            </div>
        )
    }
}

export default GalleryTitle
