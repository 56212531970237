import {
    //ServiceURLs,
    CreatorEndPoints,
    GetBaseCreatorURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const pagesService = {
   AddPageToProject,
   GetPageByid,
   GetPagesAll,
   GetPagesThumb,
   GetPagesconfirmedonly,
   GetPagesUnconfirmedOnly,
   SetPageActiveStatus,
   ClonePage,
   SetCommit,
   IncBuildNumber,
   EditPageDetail,
   EditPageProperties,
   ChangePageName,
  };
  function ChangePageName(RelProjectID,PageName,id) {
    var js = {
        RelProjectID:RelProjectID,
        PageName:PageName,
        id:id
    };

    return MakeReqestAndGetResponse(
      GetBaseCreatorURL()+CreatorEndPoints.CHANGE_PAGE_NAME,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function AddPageToProject(RelProjectID,PageName) {
    var js = {
        RelProjectID:RelProjectID,
        PageName:PageName
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.ADD_PAGE_TO_PROJECT,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }
  function GetPageByid(id,RelProjectID) {
    var js = {
        RelProjectID:RelProjectID,
        id:id
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.GET_PAGE_BY_ID,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function GetPagesAll(RelProjectID) {
    var js = {
        RelProjectID:RelProjectID
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.GET_PAGES_ALL,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function GetPagesThumb(RelProjectID) {
    var js = {
        RelProjectID:RelProjectID
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.GET_PAGES_THUMB,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function GetPagesconfirmedonly(RelProjectID) {
    var js = {
        RelProjectID:RelProjectID
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.GET_PAGES_CONFIRMED_ONLY,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function GetPagesUnconfirmedOnly(RelProjectID) {
    var js = {
        RelProjectID:RelProjectID
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.GET_PAGES_UNCONFIRMED_ONLY,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function SetPageActiveStatus(id,RelProjectID,IsActive) {
    var js = {
        RelProjectID:RelProjectID,
        id:id,
        IsActive:IsActive
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.SET_PAGE_ACTIVE_STATUS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function ClonePage(id,RelProjectID,PageName) {
    var js = {
        RelProjectID:RelProjectID,
        id:id,
        PageName:PageName
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.CLONE_PAGE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function SetCommit(id,RelProjectID,BuildName) {
    var js = {
        RelProjectID:RelProjectID,
        id:id,
        BuildName:BuildName
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.SET_COMMIT_PAGE,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function IncBuildNumber(id,RelProjectID) {
    var js = {
        RelProjectID:RelProjectID,
        id:id
    };
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.INC_BUILD_NUMBER,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function EditPageDetail(id,RelProjectID,Detail,ComponentIds) {
    var js = {
        RelProjectID:RelProjectID,
        Detail:Detail,
        id:id,
        ComponentIds:ComponentIds
    };
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.EDIT_PAGE_DETAIL,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

  function EditPageProperties(id,RelProjectID,PageProperties) {
     var js = {
        RelProjectID:RelProjectID,
        PageProperties:JSON.stringify(PageProperties),
        id:id
    };
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.EDIT_PAGE_PROPERTIES,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }