import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { FormGroup, Form, Input, Row, Col, ButtonGroup, Button } from "reactstrap";
 
import { updateComponent,updateList } from '_actions';
import { findObjUpdate,findNestedObjAndChangeALL } from 'assets/util';

import {
    PropertyTitle,
} from "../Core/";

import ColorPicker from "../Core/ColorPicker";
import NumaricDataProperty from "../Core/NumaricDataProperty"
 

class QRSettings extends Component {
    constructor(props) {
        super(props)
        this.fontSizeSlider = React.createRef();

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;

        this.state = {
            componentClone: component,
            fontColor: component && (style["color"] == undefined ? "#000000" : style["color"]),
            backgroundColor: component && (style["backgroundColor"] == undefined ? "rgba(255, 255, 255, 0)" : style["backgroundColor"]),
        }
    }

    handleChangeFontColor = (color) => {
        const that = this;

        this.setState({
            fontColor: color,
        }, () => {
            const _style = that.props.hasOwnProperty("dataPath") ? that.props.dataPath : "style";

            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    color: `rgba(${this.state.fontColor.r}, ${this.state.fontColor.g}, ${this.state.fontColor.b}, ${this.state.fontColor.a})`

                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    color: `rgba(${this.state.fontColor.r}, ${this.state.fontColor.g}, ${this.state.fontColor.b}, ${this.state.fontColor.a})`
                }
            }

            //this.props.updateList(findNestedObjAndChangeALL(this.props.editor,_clone._id,_clone));
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }
   
    handleChangeBackgroundColor = (color) => {
        this.setState({
            backgroundColor: color,
        }, () => {
            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.style = {
                    ..._clone.object.components[0].object.props.style,
                    backgroundColor: `rgba(${this.state.backgroundColor.r}, ${this.state.backgroundColor.g}, ${this.state.backgroundColor.b}, ${this.state.backgroundColor.a})`
                }
            } else {
                _clone.components[0].object.props.style = {
                    ..._clone.components[0].object.props.style,
                    backgroundColor: `rgba(${this.state.backgroundColor.r}, ${this.state.backgroundColor.g}, ${this.state.backgroundColor.b}, ${this.state.backgroundColor.a})`
                }
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
            
        })
    }

   

    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;

        if (prevProps.component != component) {
            this.setState({
                fontColor: style["color"] == undefined ? "#000000" : style["color"],
                backgroundColor: style["backgroundColor"] == undefined ? "rgba(255, 255, 255, 0)" : style["backgroundColor"],
 
            }, () => {


            })
        }
    }


    componentDidMount() {
    }

    render() {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        return (
            <Translation>
                {t => (
                    <Form>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_LAYOUT_BACKGROUNDCOLOR")}
                                </label>
                                <ColorPicker isComponent={true} id={"background-color-qr"} color={this.state.backgroundColor} handle={this.handleChangeBackgroundColor} />
                            </div>
                        </FormGroup>

                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_FONT_COLOR")}
                                </label>
                                <ColorPicker isComponent={true} id={"checkbox-label-font-color"} color={this.state.fontColor} handle={this.handleChangeFontColor} />

                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                                <PropertyTitle title={t("PROPERTY_UI_DATA_GRID_SPACING")} subtitle={""} />
                                <NumaricDataProperty key={"itemwidth-qr-code"+component.id}  propName={"size"} isCustom={"qr-size"} isType={false} defaultValue={"100"}/>
                        </FormGroup>
                    </Form>
                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;
    return {
        editor,
        component,
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,

}
export default connect(mapStateToProps, mapDispatchToProps)(QRSettings)
