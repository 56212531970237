import {
  //ServiceURLs,
  CreatorEndPoints,
  GetBaseCreatorURL,
  MakeReqestAndGetResponse,
  GetUserLang,
} from "./index";
import { HTTPOperationType } from "./httpOperations";

export const globalPropService = {
 GetGlobalPropTypes,
 GetGlobalProperties,
 CreateGlobalProperties,
 UpdateGlobalProperties,
 RemoveGlobalProperties
 
};
 


function GetGlobalPropTypes() {
   return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.GET_GLOBAL_PROP_TYPES,
    HTTPOperationType.get,
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function GetGlobalProperties(RelProjectId) {
  var js = {
    RelProjectId:RelProjectId  
  };

  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.GET_GLOBAL_PROPERTIES,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function CreateGlobalProperties(RelProjectId,Name,Type,Value) {
  var js = {
    RelProjectId:RelProjectId,
    Name:Name,
    Type:Type,
    Value:Value  
  };

  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.CREATE_GLOBAL_PROPERTIES,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function UpdateGlobalProperties(RelProjectId,Name,Type,Value,id) {
  var js = {
    RelProjectId:RelProjectId,
    Name:Name,
    Type:Type,
    Value:Value,
    id:id  
  };

  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.UPDATE_GLOBAL_PROPERTIES,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
  //RemoveGlobalProperties
  function RemoveGlobalProperties(RelProjectId,id) {
    var js = {
      RelProjectId:RelProjectId,
      id:id  
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + CreatorEndPoints.REMOVE_GLOBAL_PROPERTIES,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }

 