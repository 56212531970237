import React, { Component } from 'react'
import { Button } from 'reactstrap';
import { Translation } from "react-i18next";
import { connect } from 'react-redux';
import CustomIcon from './CustomIcon';
import GenerateComponent from 'components/Custom/GenerateComponent'

class CustomPageSwiper extends Component {
    componentDidMount = () => {
        // debugger
        const that = this;
        const { editor, path, isChild, main } = that.props;
        if (isChild) {
            const hasObj = that.findNestedObj(editor, main.id);
            if (!hasObj)
                that.handleDirectSetID(editor, path);
        }
    }

    findNestedObj = (mainState, valToFind) => {
        if (mainState) {
            let foundObj;
            JSON.stringify(mainState, (_, nestedValue) => {
                if (nestedValue && nestedValue["id"] === valToFind) {
                    foundObj = nestedValue;
                }
                return nestedValue;
            });
            //debugger;

            return foundObj;
        } else {
            return null;
        }

    };


    getComp = (_data) => {
        try {
            if (_data.hasOwnProperty("componentOwner")) { return _data.object.components[0]; }
            else { return _data.components[0]; }
        } catch (error) {
            debugger
        }


    }

    handleDirectSetID = async (old, _path) => {
        const that = this;
        const { main } = this.props;

        const p = _path.split("-")

        let _old = Object.values(JSON.parse(JSON.stringify(old)));
        let _array2 = _old;

        let current = null;

        p.forEach((element, index) => {
            if (index === 0) {
                current = this.getComp(old[element]);
            } else if (index === p.length - 1) {
                //current.object.data[element].object.data
                try {

                    current.object.data.splice(element, 1);
                    current.object.data.splice(element, 0, main);

                } catch (error) {
                    debugger
                }
            }
            else {
                current = this.getComp(current.object.data[element]);
            }
        });



    }
    render() {
        const { data, component, main, isChild } = this.props;

        const _element = document.getElementsByClassName("deviceInside");
        const _elementW = _element[0].offsetWidth + "px";
        const _elementH = _element[0].offsetHeight + "px";
        const _elementW2 = _element[0].offsetWidth / 2 + "px";
        const _elementH2 = _element[0].offsetHeight / 2 + "px";

        const _h = (data.props.style.minHeight == "device" ? _elementH : (data.props.style.minHeight == "half_device" ? _elementH2 : data.props.style.minHeight));
        const _w = (data.props.style.minWidth == "device" ? _elementW : (data.props.style.minWidth == "half_device" ? _elementW2 : data.props.style.minWidth));


        /* GLOBAL PROPS */

        /*  GLOBAL Background Color */
        let gBGIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalBackgroundColor) : -1;
        let gBGColor = ((gBGIndex > -1) && (this.props.globalProps[gBGIndex].type == 0)) ? this.props.globalProps[gBGIndex].value : data.props.style.backgroundColor;
        let gBG = (data.props.style.hasOwnProperty("globalBackgroundColor")) ? gBGColor : data.props.style.backgroundColor;

        /*  GLOBAL Font Color */
        let gFCIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalColor) : -1;
        let gFCColor = ((gFCIndex > -1) && (this.props.globalProps[gFCIndex].type == 0)) ? this.props.globalProps[gFCIndex].value : data.props.style.color;
        let gFC = (data.props.style.hasOwnProperty("globalColor")) ? gFCColor : data.props.style.color;

        /*  GLOBAL Border Color */
        let gBCIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalBorderColor) : -1;
        let gBCColor = ((gBCIndex > -1) && (this.props.globalProps[gBCIndex].type == 0)) ? this.props.globalProps[gBCIndex].value : data.props.style.borderColor;
        let gBC = (data.props.style.hasOwnProperty("globalBorderColor")) ? gBCColor : data.props.style.borderColor;


        /*  GLOBAL Icon Color */
        let gICIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalBackgroundColor) : -1;
        let gICColor = ((gICIndex > -1) && (this.props.globalProps[gICIndex].type == 0)) ? this.props.globalProps[gICIndex].value : data.props.style.backgroundColor;
        let gIC = (data.props.style.hasOwnProperty("globalIconColor")) ? gICColor : data.props.style.backgroundColor;


        /*  GLOBAL Font Size */
        let gFSIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalFontSize) : -1;
        let gFSFontSize = ((gFSIndex > -1) && (this.props.globalProps[gFSIndex].type == 1)) ? this.props.globalProps[gFSIndex].value : data.props.style.fontSize;
        let gFS = (data.props.style.hasOwnProperty("globalFontSize")) ? gFSFontSize : data.props.style.fontSize;

        // debugger

        /* Standar ve cutom componentleri standart a çevirme işlemi */
        let _component;

        if (component) {
            if (component.hasOwnProperty("componentOwner"))
                _component = component.object.components[0];
            else
                _component = component.components[0]
        }

        return (
            <Translation>
                {t => (
                    <>
                        <div
                            style={{
                                ...data.props.style,
                                backgroundColor: gBG,
                                color: gFC,
                                borderColor: gBC,
                                fontSize: gFS,
                                minHeight: (isChild && (data.props.style.minHeight != "auto")) ? "100%" : isChild == false ? "100%" : (data.props.style.minHeight == "square" ? _w : _h),
                                minWidth: (isChild && (data.props.style.minWidth != "auto")) ? "100%" : isChild == false ? "100%" : (data.props.style.minWidth == "square" ? _h : _w),
                                marginTop: "0px !important",
                                marginBottom: "0px !important",
                                marginLeft: "0px !important",
                                marginRight: "0px !important",

                                padding: "2em",
                                justifyContent: "center",
                            }}
                            className={"noselect " + (component && (component.id == main.id ? "selected-component" : ""))} >
                            {
                                data.props.pages.length > 0 ?
                                    <>
                                        <p style={{
                                            color: "#a1a1a1",
                                            fontSize: "20px",
                                            marginBottom: "unset"
                                        }}>{t("PAGE_SWIPER_HAS_PAGES")}</p>
                                        <ul className={"swiper-pages-ul"}>
                                            {
                                                data.props.pages.map((item, key) => {
                                                    const _p = this.props.pages.findIndex(x => x.id === item);
                                                    debugger
                                                    if (_p != -1) {
                                                        return (
                                                            <li key={key}>{this.props.pages[this.props.pages.findIndex(x => x.id === item)].pageName}</li>
                                                        )
                                                    }
                                                    else {
                                                        return ("")
                                                    }
                                                })
                                            }
                                        </ul>
                                    </>
                                    :
                                    <p className={"swiper-pages-ul-not"}>
                                        {t("PAGE_SWIPER_HAS_NOT_PAGE")}
                                    </p>
                            }
                        </div>
                    </>

                )
                }
            </Translation>
        )
    }
}

//export default CustomButton
const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, globalpropsReducer } = state;
    const { component } = componentReducer;
    const { globalProps } = globalpropsReducer;
    const { present } = editorReducer;
    const { editor, pages } = present;

    return {
        componentReducer,
        component,
        globalProps,
        editor,//: JSON.parse(editor),
        pages

    };
}
const mapDispatchToProps = {
    //  onUpdateComponent: updateComponent,

}
export default connect(mapStateToProps, mapDispatchToProps)(CustomPageSwiper)