import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { addNewPage, removeComponent } from '_actions'
import { pagesService } from "_services";
import { Alert } from 'rsuite';

import {
    Button,
    Input,
    Modal,
    Container,
    Row, Col
} from "reactstrap";

class NewPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customStyles: {
                pageName: "",
                pageNameState: null,
                alert: null,
            }
        }
        this.myRef = React.createRef();


    }

    successAlert = (data) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={data.title}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText={i18n.t("BUTTON_OK")}
                    btnSize=""
                >
                    {data.desc}
                </ReactBSAlert>
            ),
        });
    };
    warningAlert = (data) => {
        this.setState({
            alert: (
                <div className={"modal-sweet-alert"}>
                    <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={data.title}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="warning"
                        confirmBtnText={i18n.t("BUTTON_OK")}
                        btnSize=""
                    >
                        {data.desc}
                    </ReactBSAlert>
                </div>

            ),
        });
    };
    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };


    handleAddNewPage = () => {
        const { clearHistory, addNewPage, handleToggle, removeComponent, AddPageToProject } = this.props;
        let _state = this.state.customStyles;

        pagesService.AddPageToProject(this.props.currentProjectId, _state.pageName).then(data => {

            addNewPage(data.dynamicValue);
            clearHistory();
            removeComponent();
            handleToggle();
        }).catch(err => {
            Alert.warning(ResponseStatusCode(err.statusCode))

            //  let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            //   this.warningAlert(data);
        });
    }

    customStylesForm = (e, stateName) => {
        let newState = this.state.customStyles;
        newState[stateName] = e.target.value;
        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }
        this.setState({
            customStyles: newState
        });
    };


    validateCustomStylesForm = () => {
        let newState = this.state.customStyles;
        newState.pageName = newState.pageName.trim();

        var regex = /^[a-zA-Z][a-zA-Z0-9_]{2,}$/;


        if (newState.pageName === "") {
            newState.pageNameState = "invalid";
        } else if (!regex.test(newState.pageName)) {
            newState.pageNameState = "invalid";
        }
        else {
            newState.pageNameState = "valid";
        }

        this.setState({
            customStyles: newState
        }, () => {
            if (newState.pageNameState == "valid") {
                this.handleAddNewPage()
            }
        });
    };
    resetContent = () => {
        this.setState({
            customStyles: {
                pageName: "",
                pageNameState: null,
                alert: null,
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.isActive)
            if (prevProps.isActive != this.props.isActive && this.props.isActive) {
                this.resetContent();
            }
    }



    render() {
        const { isActive, handleToggle } = this.props;
        return (
            <Translation>
                {t => (
                    <>
                        <Modal
                            className="modal-dialog-centered"
                            isOpen={isActive}
                        //size="lg"
                        // scrollable={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="CheckProjectModal">
                                    {t("MODAL_TITLE_NEWPAGE")}
                                </h5>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Container>
                                    <Row>
                                        <label
                                            className="form-control-label"
                                            htmlFor="validationCustomPagename"
                                        >
                                            {t("MODAL_CREATE_NEWPAGE_TEXTBOX_NAME")}
                                        </label>
                                        <Input
                                            innerRef={(input) => { setTimeout(() => { try { input.focus() } catch (error) { } }, 300); }}
                                            autoFocus
                                            aria-describedby="inputGroupPrepend"
                                            id="validationCustomPagename"
                                            placeholder={t("MODAL_CREATE_NEWPAGE_TEXTBOX_NAME_PLACEHOLDER")}
                                            type="text"
                                            defaultValue={this.state.customStyles.pageName}
                                            valid={
                                                this.state.customStyles.pageNameState === "valid"
                                            }
                                            invalid={
                                                this.state.customStyles.pageNameState ===
                                                "invalid"
                                            }
                                            onChange={e => this.customStylesForm(e, "pageName")}
                                        />

                                        <div className="invalid-feedback">
                                            {t("MODAL_CREATE_NEWPAGE_TEXTBOX_NAME_INVALID")}
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                            <div className="modal-footer">

                                <Button
                                    color="white"
                                    size={"sm"}
                                    data-dismiss="modal"
                                    disabled={this.state.loading}
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    {t("MODAL_CREATE_NEWPAGE_BUTTON_CANCEL")}
                                </Button>

                                <Button color="primary" size={"sm"} type="button" onClick={() => this.validateCustomStylesForm()}>
                                    {t("MODAL_CREATE_NEWPAGE_BUTTON_CREATE")}
                                </Button>

                                {
                                    /*
                                          <CButton
                                    color="primary"
                                    size={"sm"}
                                    loading={this.state.loading}
                                    disabled={(this.state.loading || (this.state.customStyles.nameState == "invalid"))}
                                    value={data ? t("BUTTON_UPDATE") : t("BUTTON_CREATE")}
                                    onClick={() => { this.validateCustomStylesForm() }}>
                                </CButton>
                                    */
                                }
                            </div>
                            {this.state.alert}
                        </Modal>

                    </>

                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { currentProjectId } = currentstatesReducer;

    const { component } = componentReducer;

    return {
        component,
        currentProjectId
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
        addNewPage: (data) => dispatch(addNewPage(data)),
        removeComponent: () => dispatch(removeComponent()),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewPage)
