import {
    componentConstants
} from "../_constants";

const initialState = {
    componentList: [], //testData.components,
    component: null,
    myComponentList: [],
    
};

export function componentReducer(state = initialState, {
    type,
    payload
}) {
    switch (type) {
        case componentConstants.SET_RESET:
        return {
            ...initialState
        };
        case componentConstants.UPDATE_COMPONENT:
            return {
                ...state,
                component: {
                    //...state.component,
                    ...payload.component
                }
            };
        case componentConstants.CONTEXT_COMPONENT:
            return {
                ...state,
                componentContext: payload.componentContext
            };
        case componentConstants.REMOVE_COMPONENT:
            return {
                ...state,
                component: null
            };


        case componentConstants.SET_COMPONENT_LIST:
            return {
                ...state,
                componentList: payload.componentList
            };
         
        case componentConstants.SET_MYCOMPONENT_LIST:
            return {
                ...state,
                myComponentList: payload.myComponentList
            };
        case componentConstants.ADD_FAV_COMPONENT:
            let _newArray = [].concat(state.myComponentList);
            if (state.component) {
                let _newClone = JSON.parse(JSON.stringify(state.component));
                _newClone.name = payload.name;
                _newArray.push(_newClone)

            }
            return {
                ...state,
                myComponentList: _newArray,
            };
        default:
            return state;
    }
}
/*
export undoable(componentReducer, {
    limit: 10 // set a limit for the size of the history
})*/