import React, { Component } from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import { updateComponent, updateList } from "_actions";
import { findObjUpdate } from "assets/util";

import HttpObjectSelector from "components/HttpObjects/HttpObjectSelector";

import {
  Card,
  CardBody,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
} from "reactstrap";

import { Button, IconButton, ButtonGroup, ButtonToolbar, Icon } from "rsuite";

class DynamicFieldValue extends Component {
  constructor(props) {
    super(props);
    const { component, basicField } = props;
    const _c = this.getComp(component);

    const _field = _c.object.type === "layout" ? "dynamicData" : "data";

    if (_c.object.hasOwnProperty(_field)) {
      if (_c.object.data) {
        this.state = {
          value: component && this.getComp(component).object.props.value,
          type: _c.object.data
            ? _c.object.data.hasOwnProperty("type")
              ? _c.object.data.type
              : basicField
              ? "basic"
              : "httpobject"
            : basicField
            ? "basic"
            : "httpobject",
        };
      } else {
        this.state = {
          value: component && this.getComp(component).object.props.value,
          type: basicField ? "basic" : "httpobject",
        };
      }
    } else {
      this.state = {
        value: component && this.getComp(component).object.props.value,
        type: basicField ? "basic" : "httpobject",
      };
    }
  }
  handleChangeType = (e, selected) => {
    e.preventDefault();
    const that = this;
    this.setState(
      {
        type: selected,
        value: "",
      },
      () => {
        that.handleOnBlur();
      }
    );
  };
  getComp = (_data) => {
    try {
      if (_data.hasOwnProperty("componentOwner")) {
        return _data.object.components[0];
      } else {
        return _data.components[0];
      }
    } catch (error) {
      debugger;
    }
  };

  componentDidUpdate = (prevProps) => {
    const { component, staticCollections, basicField } = this.props;

    if (prevProps.component != component) {
      const { component } = this.props;
      const _c = this.getComp(component);
      const _field = _c.object.type === "layout" ? "dynamicData" : "data";
      this.setState({
        value: this.getComp(component).object.props.value,
      });
      if (_c.object.hasOwnProperty(_field)) {
        if (_c.object.data) {
          this.setState({
            type: _c.object.data.hasOwnProperty("type")
              ? _c.object.data.type
              : basicField
              ? "basic"
              : "httpobject",
          });
        } else {
          this.setState({ type: basicField ? "basic" : "httpobject" });
        }
      } else {
        this.setState({ type: basicField ? "basic" : "httpobject" });
      }
      //   debugger
    }
  };
  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    //const that=this;

    await this.setState(
      {
        [name]: value,
      },
      () => {}
    );
  };

  handleOnBlur = () => {
    const { basicField } = this.props;
    let _clone = Object.assign({}, this.props.component);
    const _c = this.getComp(_clone);
    let current = "";
    let currentType = _c.object.data
      ? _c.object.data.hasOwnProperty("type")
        ? _c.object.data.type
        : basicField
        ? "basic"
        : "httpobject"
      : basicField
      ? "basic"
      : "httpobject";



    if (_clone.hasOwnProperty("componentOwner")) {
      current = _clone.object.components[0].object.props.value; //= this.state.value;
    } else {
      current = _clone.components[0].object.props.value; // = this.state.value;
    }
  //  debugger;
    if (this.state.value != current || this.state.type != currentType) {
      if (_clone.hasOwnProperty("componentOwner")) {
        _clone.object.components[0].object.props.value = this.state.value;
        _clone.object.components[0].object[
          _c.object.type === "layout" ? "dynamicData" : "data"
        ] = {
          type: this.state.type ,
          name: this.state.value,
          path:null
        };
      } else {
        _clone.components[0].object.props.value = this.state.value;
        _clone.components[0].object[
          _c.object.type === "layout" ? "dynamicData" : "data"
        ] = {
          type: this.state.type ,
          name: this.state.value,
         path:null
        };
      }

      let _newEditor = JSON.parse(JSON.stringify(this.props.editor));
      let _result = findObjUpdate(_newEditor, _clone);
      this.props.updateComponent(_clone);
      this.props.onUpdateList(_result);
    }
  };

  render() {
    const {
      uiEditorStatus,
      uiEditorComponent,
      httpObjects,
      basicField,
      dataType,
    } = this.props;
    const { type } = this.state;

    const object = this.props.component && this.getComp(this.props.component);
    const component = this.props.component ? object : null;
    const uiData =
      uiEditorComponent &&
      this.getComp(uiEditorComponent[uiEditorComponent.length - 1].current);
    return (
      <Translation>
        {(t) => (
          <div className={"dv-main"}>
            <div className={"dv-field"}>
              <div className={"dv-field-title"}>
                {this.state.type == "basic"
                  ? t("TITLE_DYNAMIC_VALUE_BASIC")
                  : this.state.type == "indexdata"
                  ? t("TITLE_DYNAMIC_VALUE_INDEXDATA")
                  : this.state.type == "httpobject"
                  ? t("TITLE_DYNAMIC_VALUE_HTTPOBJECT")
                  : this.state.type == "store" &&
                    t("TITLE_DYNAMIC_VALUE_STORE")}
              </div>
              <div>
                {this.state.type == "basic" ? (
                  basicField
                ) : this.state.type == "indexdata" ? (
                  <HttpObjectSelector
                    isProperty={true}
                    typeName={"indexdata"}
                    component={null}
                    data={
                      uiData.object.data
                        ? uiData.object.data.children[0].children
                        : []
                    }
                    dataType={"string"}
                    key={"1-http"}
                  />
                ) : this.state.type == "httpobject" ? (
                  <HttpObjectSelector
                    component={null}
                    typeName={"httpobject"}
                    data={httpObjects.dynamicValue}
                    dataType={
                      dataType
                        ? dataType
                        : object.type !== "datalayout"
                        ? "string"
                        : "array"
                    }
                    key={"2-http"}
                  />
                ) : this.state.type == "store" ? (
                  <Input
                    //className="form-control-alternative"
                    className="form-control-sm custom-fade-in"
                    placeholder={t("PROPERTY_PLACEHOLDER_VALUE")}
                    type="text"
                    id={"propertyValue"}
                    name={"value"}
                    value={this.state.value}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        this.handleOnBlur();
                      }
                    }}
                    onBlur={() => {
                      this.handleOnBlur();
                    }}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={"dv-field-setting"}>
              <UncontrolledDropdown group color="primary">
                <DropdownToggle
                  caret
                  color="default"
                  size="md"
                  className={"dv-dropdown"}
                >
                  <Icon icon="cog" style={{ color: "#2baebf" }} />
                </DropdownToggle>
                <DropdownMenu>
                  {basicField && (
                    <DropdownItem
                      href="#type"
                      style={
                        this.state.type == "basic" ? { display: "none" } : {}
                      }
                      onClick={(e) => this.handleChangeType(e, "basic")}
                    >
                      {t("TITLE_DYNAMIC_VALUE_BASIC")}
                    </DropdownItem>
                  )}

                  {uiEditorStatus &&
                    uiData &&
                    uiData.object.data &&
                    uiData.object.data.type == "httpobject" &&
                    (Array.isArray(uiData.object.data)
                      ? uiData.object.data.length > 0
                      : true) && (
                      <DropdownItem
                        href="#type"
                        style={
                          this.state.type == "indexdata"
                            ? { display: "none" }
                            : {}
                        }
                        onClick={(e) => this.handleChangeType(e, "indexdata")}
                      >
                        {t("TITLE_DYNAMIC_VALUE_INDEXDATA")}
                      </DropdownItem>
                    )}
                  {httpObjects &&
                    httpObjects.dynamicValue &&
                    httpObjects.dynamicValue.length > 0 && (
                      <DropdownItem
                        href="#type"
                        style={
                          this.state.type == "httpobject"
                            ? { display: "none" }
                            : {}
                        }
                        onClick={(e) => this.handleChangeType(e, "httpobject")}
                      >
                        {t("TITLE_DYNAMIC_VALUE_HTTPOBJECT")}
                      </DropdownItem>
                    )}
                  <DropdownItem
                    href="#type"
                    style={
                      this.state.type == "store" ? { display: "none" } : {}
                    }
                    onClick={(e) => this.handleChangeType(e, "store")}
                  >
                    {t("TITLE_DYNAMIC_VALUE_STORE")}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

//export default InputGenerator
const mapStateToProps = (state) => {
  const { componentReducer, editorReducer, currentstatesReducer } = state;
  const { component } = componentReducer;
  const { present } = editorReducer;
  const { editor } = present;
  const {
    pageStatus,
    currentProjectId,
    uiEditorStatus,
    uiEditorComponent,
    httpObjects,
  } = currentstatesReducer;

  return {
    component,
    editor,
    pageStatus,
    currentProjectId,
    uiEditorStatus,
    uiEditorComponent,
    httpObjects,
  };
};
const mapDispatchToProps = {
  onUpdateList: updateList,
  updateComponent,
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicFieldValue);
