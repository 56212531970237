import { createStore, applyMiddleware,compose  } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

//redux ile birlikte kurulacak
import rootReducer from "../_reducers";

//import { composeWithDevTools } from "redux-devtools-extension";
const loggerMiddleware = createLogger();



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
  applyMiddleware(thunkMiddleware, loggerMiddleware)
));
/*export const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware, loggerMiddleware),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);*/
