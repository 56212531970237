import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import Emitter from "_actions/emitter";
import i18n from "i18n";
import axios from "axios";

import { ResponseStatusCode } from "_helpers";
import { addNewPage, removeComponent, setProjectExports } from '_actions'
import { pagesService, ExportService } from "_services";
import {
    Input,
    Modal,
    Container,
    Row, Col
} from "reactstrap";
import ProjectCard from 'components/Projects/ProjectCard';
import { SocketOperations } from '_constants';

import { Button, InputNumber, Progress, Loader, SelectPicker, Alert, Schema, Form, FormGroup, FormControl, ControlLabel, DatePicker, Toggle, CheckboxGroup, Checkbox } from 'rsuite';
const { StringType, DateType, NumberType } = Schema.Types;
const modelNewExport = Schema.Model({
    versionName: NumberType()
        .isRequired(i18n.t("MODAL_EXPORT_ERROR_REQUIRED")),
    //  .minLength(2, i18n.t("MODAL_EXPORT_ERROR_MIN2"))
    //   .maxLength(20, i18n.t("MODAL_EXPORT_ERROR_MAX20")),
    exportType: StringType()
        .isRequired(i18n.t("MODAL_EXPORT_ERROR_REQUIRED"))
});

const modelOldExport = Schema.Model({
    selectedVersion: StringType()
        .isRequired(i18n.t("MODAL_EXPORT_ERROR_REQUIRED"))
});


class ExportModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formValue: {
                versionName: "",
                selectedVersion: "",
                exportType: "",
            },
            formError: {},
            oldVersions: [],
            exportTypes: [],
            isNew: false,
            status: null,
            percent: 0,
            isExportProgress: false
        }

    }


    componentWillUnmount = () => {
        Emitter.off(SocketOperations.exportOperationsObserve);

    }
    componentDidMount = () => {
        Emitter.on(SocketOperations.exportOperationsObserve, (newValue) => this.exportOperationsObserve(newValue));

    }
    componentDidUpdate = (prevProps) => {
        if ((prevProps.isActive != this.props.isActive) && this.props.isActive) {
            this.handleReset();
        }
        if (JSON.stringify(prevProps.currentProjectExports) !== JSON.stringify(this.props.currentProjectExports)) {

            let _array = [];
            this.props.currentProjectExports.map(x => {
                _array.push(
                    {
                        label: i18n.t("LABEL_EXPORT_TARGET_" + x.target) + " - " + x.version,
                        value: x.id,
                    },
                )
            })
            this.setState({
                oldVersions: _array,
            })

        }
    }

    customStylesForm = (e, stateName) => {
        let newState = this.state.customStyles;
        newState[stateName] = e.target.value;
        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }
        this.setState({
            customStyles: newState
        });
    };



    exportOperationsObserve = (data) => {
        //  alert("EXPORT ALERT");
        const { status } = this.state;
        const { isActive } = this.props;
        //data.ExportTypes

        let _state = {
            isExportProgress: true,
            //percent: this.state.percent + 1,
            status: null,
            progressText: i18n.t("PROCESS_EXPORT_" + data.ExportTypes)
        }

        if (status) {
            debugger
            return;
        }
        if (!isActive) {
            debugger
            return;
        }

        switch (data.ExportTypes) {
            case 0:
                //WaitingProcess
                _state = {
                    ..._state,
                    percent: this.state.percent + 1 > 99 ? this.state.percent : this.state.percent + 1,
                }
                break;
            case 1:
                //RequestBeginProcess
                _state = {
                    ..._state,
                    percent: this.state.percent + 2 > 99 ? this.state.percent : this.state.percent + 2,
                }
                break;
            case 2:
                //PreparingInlineData
                _state = {
                    ..._state,
                    percent: this.state.percent + 10 > 99 ? this.state.percent : this.state.percent + 7,
                }
                break;
            case 3:
                //PreparingAPPSettings
                _state = {
                    ..._state,
                    percent: this.state.percent + 8 > 99 ? 99 : this.state.percent + 11,
                }
                break;
            case 4:
                //Ready
                _state = {
                    ..._state,
                    percent: 100,
                    status: "success",
                }
                this.handleDownload(data);
                break;
            case 5:
                //Error
                _state = {
                    ..._state,
                    status: "fail"
                }
                break;

            default:
                break;
        }

        this.setState({
            ..._state,
        }, () => {
            // İşlem bitti
        })

    }

    toggleIsNew = () => {
        this.setState({
            isNew: !this.state.isNew,
        })
    }

    handleSubmit = (_func) => {
        const { formValue } = this.state;
        if (!this.form.check()) {
            //  console.error('Form Error');
            return;
        }
        _func();
    }



    handleReset = () => {
        this.setState({
            formValue: {
                versionName: "",
                selectedVersion: "",
                exportType: "",
            },
            formError: {},
            exportTypes: [
                {
                    label: i18n.t("LABEL_EXPORT_TARGET_0"),
                    value: "0"
                },
                {
                    label: i18n.t("LABEL_EXPORT_TARGET_1"),
                    value: "1"
                },
                {
                    label: i18n.t("LABEL_EXPORT_TARGET_2"),
                    value: "2"
                },

            ],
            isNew: this.props.currentProjectExports.length > 0 ? false : true,
            status: null,
            percent: 0,
            isExportProgress: false,
            progressText: "",
            loading: false,
        })
    }

    handleUpdate = () => {
        Alert.warning(i18n.t("ERROR_ITS_NOT_WORKING"))
    }
    handleExport = () => {
        const that = this;
        const { setProjectExports, currentProjectId } = this.props;
        const { formValue } = this.state;
        ExportService.EXPORT_PROJECT(currentProjectId, formValue.versionName, formValue.exportType)
            .then(data => {
                that.setState({
                    isExportProgress: true,
                    percent: that.state.percent + 1,
                    status: null,
                    loading: true,
                    progressText: i18n.t("PROCESS_EXPORT_0")
                })
                // setProjectExports(data.dynamicValue);
            }).catch(err => {
                debugger;
                Alert.error("Error code : " + err.statusCode);

            })
    }

    downloadStart = (url) => {

        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = url;
        tempLink.setAttribute('download', "mobilex360.zip");
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        debugger
        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);
        }, 200)


        // 'mobilex360-' + this.props.currentProject.projectName.replace(" ", "") + '.zip'
        // fileDownload(url, );
    }

    handleDownload = (data) => {
        const that = this;
        if (data) {
            this.getProjectExports(); // Yeni bir export daha eklendi
            debugger
            this.downloadStart(data.Link)
        } else {
            const { formValue } = this.state;
            const fData = this.props.currentProjectExports.find(x => x.id === formValue.selectedVersion)
            if (!fData) {
                Alert.error("Old versiyon bulunamadı");
                debugger;
                return;
            }
            this.downloadStart(fData.link);
        }
        setTimeout(() => {
            that.props.handleToggle();
        }, 300);
    }

    getProjectExports = () => {
        const { setProjectExports, currentProjectId } = this.props;
        ExportService.GET_MY_EXPORT(currentProjectId)
            .then(data => {
                setProjectExports(data.dynamicValue);
            }).catch(err => {
                debugger;
                Alert.error("Error code : " + err.statusCode);

            })
    }

    render() {
        const { isActive, handleToggle, data } = this.props;
        const { Circle, Line } = Progress;
        const { formValue, formError, loading, categories, percent, status, exportTypes, oldVersions, isNew } = this.state;

        return (
            <Translation>
                {t => (
                    <>
                        <Modal
                            className="modal-dialog-centered httpobject-modal"
                            size={this.state.isExportProgress ? "lg" : "md"}
                            isOpen={isActive}  >
                            <div className="modal-header">
                                <h5 className="modal-title" id="CheckProjectModal">
                                    {t("MODAL_TITLE_EXPORT_SETTINGS")}
                                </h5>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    disabled={loading}
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body ">
                                <Container>
                                    <Row>
                                        <Col>
                                            <div className="profile-settings-profile-photo-main">
                                                <ProjectCard isViewer={true} />
                                            </div>
                                            <Form
                                                ref={ref => (this.form = ref)}
                                                onChange={formValue => {
                                                    this.setState({ formValue });
                                                }}
                                                onCheck={formError => {
                                                    this.setState({ formError });
                                                }}
                                                formValue={formValue}
                                                model={this.state.isNew ? modelNewExport : modelOldExport}
                                                fluid
                                                className={"custom-form-submit"}>

                                                {
                                                    this.state.isNew ?
                                                        <>
                                                            <FormGroup >
                                                                <ControlLabel>{t("MODAL_TITLE_EXPORT_NEW_VERSION_NAME")}
                                                                    <span onClick={this.toggleIsNew} className={"export-tab-subtext"}>{t("MODAL_TITLE_EXPORT_NEW_VERSION_NAME_SUB")}</span>
                                                                </ControlLabel>
                                                                <FormControl disabled={loading} accepter={InputNumber} placeholder={t("MODAL_PLACEHOLDER_EXPORT_NEW_VERSION_NAME")} name="versionName" max={100000} min={1.0} step={0.1} />
                                                            </FormGroup>
                                                            <FormGroup >
                                                                <ControlLabel>{t("MODAL_TITLE_EXPORT_CHOOSE_TYPE")}</ControlLabel>
                                                                <FormControl
                                                                    searchable={false}
                                                                    name="exportType"
                                                                    placeholder={t("MODAL_PLACEHOLDER_EXPORT_CHOOSE_TYPE")}
                                                                    accepter={SelectPicker}
                                                                    maxHeight={200}
                                                                    className="export-dropdown"
                                                                    value={formValue.exportType}
                                                                    block={true}
                                                                    disabled={loading}
                                                                    data={exportTypes}
                                                                />
                                                            </FormGroup>
                                                        </>
                                                        :
                                                        <FormGroup >
                                                            <ControlLabel>{t("MODAL_TITLE_EXPORT_CHOOSE_VERSION")}
                                                                <span onClick={this.toggleIsNew} className={"export-tab-subtext"}>{t("MODAL_TITLE_EXPORT_CHOOSE_VERSION_SUB")}</span>
                                                            </ControlLabel>
                                                            <FormControl
                                                                searchable={false}
                                                                name="selectedVersion"
                                                                placeholder={t("MODAL_PLACEHOLDER_EXPORT_CHOOSE_VERSION")}
                                                                accepter={SelectPicker}
                                                                maxHeight={200}
                                                                className="export-dropdown"
                                                                value={formValue.selectedVersion}
                                                                block={true}
                                                                disabled={loading}
                                                                data={oldVersions}
                                                            />
                                                        </FormGroup>
                                                }
                                            </Form>
                                        </Col>
                                        {
                                            this.state.isExportProgress &&
                                            <Col>
                                                <div className={"export-modal-loading custom-fade-in"}>
                                                    <div style={{
                                                        justifyContent: "center", padding: "10px", width: "100%", display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center"
                                                    }}>
                                                        <div style={{ width: 120, display: 'inline-block', marginRight: 10 }}>
                                                            <Circle percent={percent} status={status} />
                                                        </div>
                                                        <span style={{ marginTop: "10px", color: "#e5e5ea", fontWeight: 100 }}>{this.state.progressText}</span>

                                                    </div>
                                                </div>

                                            </Col>
                                        }

                                    </Row>


                                </Container>

                            </div>
                            {
                                isNew ?
                                    <div className="modal-footer">
                                        <Button
                                            //color="white"
                                            size={"sm"}
                                            loading={loading}
                                            data-dismiss="modal"
                                            disabled={this.state.loading}
                                            type="button"
                                            onClick={handleToggle}
                                        >
                                            {t("BUTTON_CANCEL")}
                                        </Button>

                                        <Button loading={loading} disabled={!formValue.versionName || !formValue.exportType} appearance={'primary'} size={"sm"} type="button" onClick={() => this.handleSubmit(this.handleExport)}>
                                            {t("BUTTON_EXPORT")}
                                        </Button>
                                    </div>
                                    :
                                    <div className="modal-footer">
                                        <Button
                                            //  color="white"
                                            size={"sm"}
                                            loading={loading}
                                            data-dismiss="modal"
                                            disabled={this.state.loading}
                                            type="button"
                                            onClick={handleToggle}
                                        >
                                            {t("BUTTON_CANCEL")}
                                        </Button>
                                        <Button loading={loading} disabled={!formValue.selectedVersion} className={"btn-export-update"} size={"sm"} type="button" onClick={() => this.handleSubmit(this.handleUpdate)}>
                                            {t("BUTTON_UPDATE")}
                                        </Button>
                                        <Button loading={loading} disabled={!formValue.selectedVersion} size={"sm"} type="button" appearance={'primary'} onClick={() => this.handleSubmit(this.handleDownload)}>
                                            {t("BUTTON_DOWNLOAD")}
                                        </Button>
                                    </div>
                            }


                            {this.state.alert}
                        </Modal>

                    </>

                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { currentstatesReducer, componentReducer, editorReducer } = state;
    const { component } = componentReducer;
    const { currentProjectId, currentProjectExports, currentProject } = currentstatesReducer;

    return {
        component,
        currentProjectId,
        currentProjectExports,
        currentProject
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
        addNewPage: (data) => dispatch(addNewPage(data)),
        removeComponent: () => dispatch(removeComponent()),
        setProjectExports: (_data) => dispatch(setProjectExports(_data)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExportModal)
