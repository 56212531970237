import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from 'i18n';
import { FormGroup, Form, Input, Row, Col } from "reactstrap";
import { updateComponent, updateList } from '_actions';
import { findObjUpdate } from 'assets/util';
import { Alert } from 'rsuite';
import {
    CMSService,
} from "_services";

class StaticCMSSettings extends Component {
    constructor(props) {
        super(props)
        const { component } = props;
        this.state = {
            cmsData: null,
            value: component && (this.getComp(component)).object.props[props.propName ?props.propName:"value"]
        }

    }

    handleUpdateEditor = () => {

    }

    setStaticCMS = () => {
        const that = this;
        const { cmsData, value } = this.state;
        const { currentProjectId, component, currentPage } = this.props;
        CMSService.SAVE_FROM_CREATOR((cmsData ? cmsData.id : null), currentProjectId, currentPage.id, component.id, value)
            .then(data => {
                //data.dynamicValue
                //  setStaticCollections(data.dynamicValue);
                that.setState({ cmsData: data.dynamicValue })
            }).catch(err => {
                debugger;
                Alert.error("Error code : " + err.statusCode);

            })

    }

    getMyCollection = () => {
        const that = this;
        const { currentProjectId, component, currentPage } = this.props;
        CMSService.GET_BY_ID(currentProjectId, currentPage.id, component.id)
            .then(data => {
                //data.dynamicValue
                that.setState({
                    cmsData: data.dynamicValue,
                })
            }).catch(err => {
                debugger;
                Alert.error("Error code : " + err.statusCode);

            })
    }

    getComp = (_data) => {
        if (_data.hasOwnProperty("componentOwner")) { return _data.object.components[0]; }
        else { return _data.components[0]; }
    }

    handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        //const that=this;

        await this.setState({
            [name]: value,
        }, () => {

        });
    }

    componentDidUpdate = (prevProps) => {
        const { component, staticCollections } = this.props;

        if (prevProps.component != component) {
            this.setState({
                value: (this.getComp(component)).object.props[this.props.propName ?this.props.propName:"value"]
            }, () => {
                let currentCMS = staticCollections && staticCollections.find(x => x.relObjectId == this.state.value.id);

                if (currentCMS) {
                    this.setState({
                        cmsData: currentCMS ? currentCMS : null,
                    })
                } else {
                    // this.getMyCollection();
                }


            })
        }
    }

    handleOnBlur = () => {
        let _clone = Object.assign({}, this.props.component);

        let current = ""
        if (_clone.hasOwnProperty("componentOwner")) {
            current = _clone.object.components[0].object.props[this.props.propName ?this.props.propName:"value"] //= this.state.value;
        } else {
            current = _clone.components[0].object.props[this.props.propName ?this.props.propName:"value"]// = this.state.value;
        }

        if (this.state.value != current) {
            this.setStaticCMS();
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[this.props.propName ?this.props.propName:"value"] = this.state.value;
            } else {
                _clone.components[0].object.props[this.props.propName ?this.props.propName:"value"] = this.state.value;
            }


            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        }


        /*
              
                */

    }

    render() {
        return (
            <Translation>
                {t => (
                    <Input
                        //className="form-control-alternative"
                        className="form-control-sm custom-fade-in"
                        placeholder={t("PROPERTY_PLACEHOLDER_VALUE")}
                        type="text"
                        id={"propertyValue"}
                        name={"value"}
                        value={this.state.value}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                this.handleOnBlur();
                            }
                        }}
                        onBlur={() => { this.handleOnBlur() }}
                        onChange={(e) => {
                            this.handleChange(e)
                        }}
                    />
                )}
            </Translation>
        )
    }
}
try {

} catch (error) {

}
//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor, page } = present;
    const { currentProjectId, staticCollections } = currentstatesReducer;

    return {
        component,
        editor,
        currentPage: page,
        currentProjectId,
        staticCollections
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
}
export default connect(mapStateToProps, mapDispatchToProps)(StaticCMSSettings)
