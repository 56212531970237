import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { updateComponent } from "../../_actions"
import { FormGroup, Form } from "reactstrap";
import { IconButton, Icon } from 'rsuite';

import ColorPicker from "./LayoutSettings/Properties/ColorPicker"
import { PropertyCategory } from "./Core"
import NewScript from '../Modals/NewScript';
import { pagesService } from "_services";
import ChangePageName from "components/Modals/ChangePageName"

class LayoutSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scriptEditor: false,
            updatePageName: false,
        }
    }

    handleUpdatePageName = () => {
        this.setState({
            updatePageName: !this.state.updatePageName,
        })
    }

    handleScriptEditorModal = (_data) => {
        this.setState({
            scriptData: this.state.scriptEditor ? null : _data,
            scriptEditor: !this.state.scriptEditor,
        })
    }



    render() {
        const { pageFunctions, page } = this.props;
        return (
            <Translation>
                {t => (
                    <>
                        {t("RIGHT_SIDE_TITLE_LAYOUTSSETTINGS")}
                        <hr />
                        {
                            this.props.currentProjectId ? (
                                this.props.pageStatus.status ?
                                    <>
                                        <Form>
                                            <FormGroup className={"property-form-group"}>
                                                <div className={"propertyTitleDiv"}>
                                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_LAYOUT_PAGENAME")}
                                                    </label>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center"
                                                }}>
                                                    <span style={{wordBreak: "break-all"}}>{page.pageName}</span>
                                                    <IconButton circle size="sm" onClick={this.handleUpdatePageName} icon={<Icon icon="pencil" />} />

                                                </div>
                                            </FormGroup>
                                        </Form>
                                        <PropertyCategory title={t("LAYOUTSETTINGS_STYLE")} status={true}>
                                            <Form>
                                                <FormGroup className={"property-form-group"}>
                                                    <div className={"propertyTitleDiv"}>
                                                        <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_LAYOUT_BACKGROUNDCOLOR")}
                                                        </label>
                                                        <ColorPicker isComponent={true} id={"background-color"} />
                                                    </div>
                                                </FormGroup>
                                            </Form>
                                        </PropertyCategory>
                                        <PropertyCategory title={t("LAYOUTSETTINGS_FUNC")} status={true}>
                                            <Form>
                                                <FormGroup className={"property-form-group"}>
                                                    <div className={"custom-fade-in"}>
                                                        {
                                                            pageFunctions.map((item, key) => (
                                                                <p
                                                                    onClick={() => {
                                                                        this.handleScriptEditorModal(item);
                                                                    }}
                                                                    className={"clickable func-item"} key={key}>{item.eventName}</p>
                                                            ))

                                                        }
                                                    </div>
                                                </FormGroup>
                                            </Form>
                                        </PropertyCategory>
                                    </> :
                                    <p className={"text-muted"}>
                                        {t("PAGE_BUSY_ERROR")}
                                    </p>
                            )
                                :
                                <p className={"text-muted"}>
                                    {t("HAS_PROJECT_ERROR")}
                                </p>
                        }
                        <NewScript isPage={true} data={this.state.scriptData} isActive={this.state.scriptEditor} handleToggle={this.handleScriptEditorModal} />
                        <ChangePageName isActive={this.state.updatePageName} handleToggle={this.handleUpdatePageName} />
                    </>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;

    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor, page } = present;

    const { pageStatus, currentProjectId, pageFunctions } = currentstatesReducer;


    return {
        componentReducer,
        component,
        editor,
        pageStatus,
        currentProjectId,
        pageFunctions,
        page

    };
}
const mapDispatchToProps = {
    onUpdateConponent: updateComponent,
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSettings)
