import React, { Component } from 'react'
import { connect } from 'react-redux'

class TreeChild extends Component {
    render() {
        const { data, component, main, isChild,page,componentNames } = this.props;
        let _nameIndex = componentNames.findIndex(x => x.relComponentId === main.id);

        return (
            <div className={"noselect " + (component && (component.id == main.id ? "custom-tree-selected selected-component" : ""))} >
                { _nameIndex === -1 ? main.componentName:componentNames[_nameIndex].componentName}
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, globalpropsReducer,currentstatesReducer } = state;
    const { component } = componentReducer;
    const { globalProps } = globalpropsReducer;
    const { present } = editorReducer;
    const { editor,page } = present;
    const {componentNames}=currentstatesReducer;
    return {
        componentReducer,
        component,
        globalProps,
        page,
        componentNames

    };
}

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeChild)
