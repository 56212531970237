export const editorConstants = {
    UPDATE_LIST: 'UPDATE_LIST',
    UPDATE_CURRENT_PAGE: 'UPDATE_CURRENT_PAGE',
    ADD_NEW_PAGE: 'ADD_NEW_PAGE',
    REMOVE_PAGE: 'REMOVE_PAGE',
    REMOVE_PAGE: 'REMOVE_PAGE',
    UPDATE_PAGE_STYLE: 'UPDATE_PAGE_STYLE',
    SET_PAGES:'SET_PAGES',
    SET_PAGE:'SET_PAGE',
    UPDATE_LIST_SOCKET:'UPDATE_LIST_SOCKET',
    SET_RESET:"SET_RESET",
    CHANGE_PAGE_NAME:'CHANGE_PAGE_NAME'
 
}