import i18n from "i18next";
import {
    initReactI18next
} from "react-i18next";

import translationTR from "./assets/langs/tr.json"; //tr dil dosyasi
import translationEN from "./assets/langs/en.json"; //tr dil dosyasi
import translationDE from "./assets/langs/de.json"; //de dil dosyasi


const resources = {
    de: {
        translation: translationDE
    },
    en: {
        translation: translationEN
    },
    tr: {
        translation: translationTR
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;