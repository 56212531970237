import React, { Component } from 'react'
import { connect } from 'react-redux';
import UIHeader from 'components/Headers/UIHeader';
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import Emitter from "_actions/emitter";
import { HelperMenu } from './'

import { createId, getTime, scaleElement, buildFullTree } from 'assets/util';

import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";
import { ComponentService } from '_services';

import GenerateComponent from 'components/Custom/GenerateComponent';

import {
    updateList,
    addFavoriteComponent,
    setOnMove,
    setOnAdd,
    setOnAddUpdate,
    setOnRemove,
    setOnEnd
} from '_actions';

class UICanvas extends Component {
    constructor(props) {
        super(props)
        this.device = React.createRef();
    }

    updateDimensions = () => {
        /*
            Ekran boyutuna göre resize etmek için gerekli işlemleri burada uyguluyoruz
            üst div'in size bilgisi bizim max değerlerimiz oluyor ve device fix size değerlerine göre
            oran orantı uygulanıyor.
        */

        let _base = document.getElementsByClassName("mainCol")[0];
        let _heigth = _base.offsetHeight;
        let _width = _base.offsetWidth;
        //console.log(this.device)
        scaleElement(this.device.current, _width, _heigth)
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        this.setState = (state, callback) => {
            return;
        };

    }

    removeComponentId = () => {
        const { currentPage, component } = this.props;

        ComponentService.RemoveComponentId(this.props.currentProjectId, currentPage, component.id).then(data => {
            //Alert.success("silindi");
            this.props.componentContext.handleDelete()

        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
        });
    }

    render() {
        const { editor } = this.props;

        const baseObject = {
            baseArray: Object.assign({}, this.props.editor),
        }

        return (
            <>
                <UIHeader />
                <Container id="canvas" className="mt--6 custom-fade-in" fluid>
                    <Row>
                        <Col sm={12} className={"mainCol"}>
                            <div id={"device"} className={"device-ui"} ref={this.device}>
                                <div className={"deviceInside uiInside"}>
                                    {
                                        editor &&
                                        (
                                            editor.map((item, index) => {
                                                return(
                                                <GenerateComponent
                                                    isChild={false}
                                                    key={item.id}
                                                    index={index}
                                                    path={index + ""}
                                                    data={item}
                                                    baseProgress={baseObject} />
                                            )})
                                        )
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <HelperMenu
                            className={""}
                            event={this.props.componentContext && this.props.componentContext.newContext}
                            name={this.props.component && this.props.component.componentName}
                            handleAddFav={this.props.onAddFavComponent}
                            handleDublicate={this.props.componentContext && this.props.componentContext.handleDublicate}
                            handleRemove={this.removeComponentId} />
            </>

        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, globalpropsReducer, currentstatesReducer } = state;
    const { component, componentContext } = componentReducer;
    const { present } = editorReducer;

    const { editor, pages, currentPage, page } = present;

    const pageStyle = page.hasOwnProperty("pageProperties") ? page.pageProperties.style : null;
    const { globalProps } = globalpropsReducer;

    const { pageStatus, updateComponentPosition, currentProjectId } = currentstatesReducer;

    const { onMove, moveObj, isAdded, objFrom, currentEditor, isRemove, objFromId } = updateComponentPosition;

    return {
        editor,//:JSON.parse(editor),
        component,
        componentContext,
        pageStyle,
        globalProps,
        currentPage,
        pageStatus,

        onMove,
        isAdded,
        objFrom,
        currentEditor,
        isRemove,
        moveObj,
        currentProjectId,
        objFromId

    };
}
const mapDispatchToProps = {
    onUpdateEditorList: updateList,
    onAddFavComponent: addFavoriteComponent,
    setOnMove,
    setOnAdd,
    setOnAddUpdate,
    setOnRemove,
    setOnEnd
}
export default connect(mapStateToProps, mapDispatchToProps)(UICanvas)