import React, { Component } from 'react'
import { Translation } from "react-i18next";
import GlobalPropertyModal from "../Modals/GlobalPropertyModal";
import { lightOrDark } from "assets/util"
import {
    Button,
    Input,
    Modal,
    FormGroup,
    Form,
    ListGroupItem,
    Row,
    Col
} from "reactstrap";

class GlobalProperty extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isEditModalActive: false,
            color: null,
            fontSize: null,
            fontStyle: null,
            fontFamily: null,
        }
    }

    handleEditProperty = () => {
        this.setState({
            isEditModalActive: !this.state.isEditModalActive
        })
    }

    render() {
        const { isEditModalActive } = this.state;
        const { data } = this.props;

        return (
            <Translation>
                {t => (
                    <>
                        {
                            data &&
                            <>
                                <ListGroupItem
                                    className="px-0"
                                    href="#pablo"
                                    tag="a"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.handleEditProperty();
                                    }}>
                                    <Row className="align-items-center" style={{ marginLeft: "8px", minHeight: "45px" }}>
                                        <div className="col ml--2 global-props-list-item-container" style={{
                                            backgroundColor: data.type == 0 ? data.value : "#fff",
                                            maxWidth: data.type == 1 ? (parseInt(data.value.substring(0, data.value.length - 2)) + 40) + "px" : "44px",
                                            width: data.type == 1 ? (parseInt(data.value.substring(0, data.value.length - 2)) + 40) + "px" : "44px",
                                            border: data.type == 1 ? "1px solid #525f7f" : "unset",
                                            textAlign: "center",
                                        }}>
                                            <h4 className="mb-0 global-props-list-item-h4 text-uppercase" style={{
                                                color: data.type == 0 ? lightOrDark(data.value) == "light" ? "#525f7f" : "#f6f9fc" : "#525f7f",
                                                fontSize: data.type == 1 ? data.value : "14px",
                                            }}>
                                                {
                                                    data.hasOwnProperty("name") ?
                                                    data.name.substring(0, 1):"X"
                                                }
                                            </h4>
                                        </div>
                                        <div className="col">
                                            <p className="mb-0 text-nowrap text-uppercase text-muted font-weight-bold" style={{ fontSize: "14px" }}>
                                                {data.name}
                                            </p>
                                            <p className="mt-0 mb-0 text-muted " style={{ fontSize: "14px" }}>
                                                {
                                                    data.type == 0 ?
                                                        t("PROPERTY_COLOR") :
                                                        (data.type == 1 ?
                                                            t("PROPERTY_FONT_SIZE") :
                                                            (data.type == 2 ?
                                                                t("PROPERTY_FONT_FAMILY") :
                                                                ""))

                                                }
                                            </p>
                                            <p className="mb-0 text-nowrap text-muted " style={{ fontSize: "14px" }}>
                                                {data.value}
                                            </p>
                                        </div>
                                    </Row>
                                </ListGroupItem>

                            </>
                        }
                        {
                            /*
                            
                            */
                        }
                        <GlobalPropertyModal data={data} isActive={isEditModalActive} handleToggle={this.handleEditProperty} />
                    </>
                )}
            </Translation>
        )
    }
}

export default GlobalProperty
