import {
    //ServiceURLs,
    DynamicFunctionsEndPoints,
    GetBaseCreatorURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const DynamicFunctionService = {
    CREATE_FUNCTION,
    UPDATE_FUNCTION,
    REMOVE_FUNCTION,
    STEPBYLOAD,
    GET_EVENTS
  };

//PAGE ID NULL VERIRSEN ALAYI GELIR.
  function CREATE_FUNCTION(RelProjectId,Name,RelComponentId,RelEventListId,Script,Params,IsPage=false) {
    var js = {
      RelProjectId:RelProjectId ,
      Name:Name ,
      RelComponentId:RelComponentId,
      RelEventListId:RelEventListId,
      Script:Script,
      Params:Params,
      IsPage:IsPage
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + DynamicFunctionsEndPoints.CREATE_FUNCTION,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function UPDATE_FUNCTION(id,RelProjectId,Name,RelComponentId,RelEventListId,Script,Params,IsPage=false) {
    var js = {
      id:id,
      RelProjectId:RelProjectId ,
      Name:Name ,
      RelComponentId:RelComponentId,
      RelEventListId:RelEventListId,
      Script:Script,
      Params:Params,
      IsPage:IsPage
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + DynamicFunctionsEndPoints.UPDATE_FUNCTION,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function REMOVE_FUNCTION(id,RelProjectId) {
    var js = {
      id:id,
      RelProjectId:RelProjectId 
 
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + DynamicFunctionsEndPoints.REMOVE_FUNCTION,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function STEPBYLOAD(Count,DynamicObj,IsOrderByDesc,LastIndexId,SearchTerm) {
    var js = {
        Count: Count,
        DynamicObj:DynamicObj,// null,
        IsOrderByDesc: IsOrderByDesc,
        LastIndexId: LastIndexId,//null,
        SearchTerm: SearchTerm
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + DynamicFunctionsEndPoints.STEPBYLOAD,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }



  function GET_EVENTS(EventTypes) {
    var js = {
      EventTypes:EventTypes 
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + DynamicFunctionsEndPoints.GET_EVENTS,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 