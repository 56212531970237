import React, { Component } from "react";
import { connect } from "react-redux";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import { Translation } from "react-i18next";

import {
  updateComponent,
  updateListSocket,
  setPage,
  removeComponent,
  setUIEditorStatus,
} from "_actions";
import { TreePicker, Icon } from "rsuite";
import { findObjUpdate } from "assets/util";

class HttpObjectSelector extends Component {
  constructor(props) {
    super(props);

    const { component } = props;
    const { object } = component.hasOwnProperty("componentOwner")
      ? component.object.components[0]
      : component.components[0];
    const { style, value } = object.props;
    const _field = object.type === "layout" ? "dynamicData" : "data";

    const data = object[_field];
    // debugger
    this.state = {
      httpObjectsData: [],
      httpObjectDisabledData: [],
      data: data,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleOnExpand = this.handleOnExpand.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen = () => {
    setTimeout(async () => {
      const { component, data, dataType, isProperty } = this.props;
      let result = isProperty ? data : await this.prepareCascaderData(data);
      // debugger
      this.setState({
        httpObjectsData: result,
        httpObjectDisabledData: this.findDisabledData(result, dataType),
      });
    }, 50);

    const that = this;
    // debugger;
  };

  handleOnExpand = (expandItemValues, activeNode, concat) => {};

  componentDidMount = () => {
    setTimeout(async () => {
      const { component, data, dataType, isProperty } = this.props;
      let result = isProperty ? data : await this.prepareCascaderData(data);

      this.setState({
        //httpObjectsData: result,
        httpObjectDisabledData: this.findDisabledData(result, dataType),
      });
    }, 1);
  };

  handleClose = () => {
    /*    this.setState({
                httpObjectsData: [],
                httpObjectDisabledData: []
            })
            */
  };

  handleToggleHttpObject = (_data) => {
    const that = this;
    debugger;
    let _clone = Object.assign({}, this.props.component);
    let { object } = _clone.hasOwnProperty("componentOwner")
      ? _clone.object.components[0]
      : _clone.components[0];

    const _field = object.type === "layout" ? "dynamicData" : "data";
    object[_field] = _data;

    let _newEditor = JSON.parse(JSON.stringify(this.props.editor));
    let _result = findObjUpdate(_newEditor, _clone);

    this.props.updateComponent(_clone);
    this.props.onUpdateList(_result);

    this.setState({
      data: _data,
    });
  };

  componentDidUpdate = (prevProps) => {
    const { component } = this.props;
    const { object } = component.hasOwnProperty("componentOwner")
      ? component.object.components[0]
      : component.components[0];
    const { style } = object.props;

    const _field = object.type === "layout" ? "dynamicData" : "data";
    const data = object[_field];

    if (prevProps.component != component) {
       this.state = {
        data: data,
      };
    }
  };

  prepareCascaderData = async (data) => {
    let _data = [...data];
    let _array = [];

    await _data.forEach(async (item, key) => {
      let _obj = {
        value: key + "", //item.id,
        label: item.httpObjectName,
        children: [],
        path: item.httpObjectName,
      };
      let detail = JSON.parse(item.successDynamicObjects);
      if (Array.isArray(detail)) {
        _obj["type"] = "array";
        _obj["children"] = await this.generateCascaderChild(
          "array",
          detail,
          key,
          item.httpObjectName
        );
      } else if (typeof detail == "object") {
        _obj["type"] = typeof detail;
        _obj["children"] = await this.generateCascaderChild(
          "object",
          detail,
          key,
          item.httpObjectName
        );
      } else {
        _obj["type"] = typeof detail;
      }
      _array.push(_obj);
    });

    return _array;
  };

  generateCascaderChild = async (_type, _value, _parentKey, _httpName) => {
    let _array = [];

    await (_type == "array" ? _value : Object.values(_value)).forEach(
      async (item, key) => {
        let _obj = {
          //  label: "[index]",
          value: _parentKey + "-" + key,
        };
        if (Array.isArray(item)) {
          _obj.label = Object.keys(_value)[key];
          _obj.path = _httpName + "-" + Object.keys(_value)[key];

          //  debugger

          try {
            _obj["children"] =
              item[0] === null
                ? []
                : await this.generateCascaderChild(
                    "array",
                    item,
                    _parentKey + "-" + key,
                    _obj.path
                  );
          } catch (error) {
            debugger;
          }
          _obj["type"] = "array";
        } else if (typeof item == "object") {
          // debugger
          _obj.label = Object.keys(_value)[key];
          _obj.path = _httpName + "-" + Object.keys(_value)[key];
          _obj["type"] = typeof item;
          try {
            if (Object.keys(item).length > 0)
              _obj["children"] = await this.generateCascaderChild(
                "object",
                item,
                _parentKey + "-" + key,
                _obj.path
              );
          } catch (error) {
            debugger;
          }
        } else {
          _obj["label"] = Object.keys(_value)[key];
          _obj["type"] = typeof item;
          _obj.path = _httpName + "-" + Object.keys(_value)[key];
        }

        /*
                            if(_obj["type"] == "array"){
                                _obj["visible"]= false;
                            }
                            */
        _array.push(_obj);
      }
    );
    return _array;
  };

  findDisabledData = (mainState, valToFind) => {
    let _ms = mainState;

    if (mainState) {
      let foundObj = [];
      let aa = JSON.stringify(_ms, (_, nestedValue) => {
        if (
          nestedValue &&
          nestedValue.type &&
          !nestedValue.type.includes(valToFind)
        ) {
          foundObj.push(nestedValue.value);
        }
        return nestedValue;
      });
      return foundObj;
    } else {
      return null;
    }
  };

  render() {
    const { component, data, dataType, httpObjects, typeName } = this.props;
    const { httpObjectsData, httpObjectDisabledData } = this.state;

    return (
      <Translation>
        {(t) => (
          <>
            <TreePicker
              // defaultExpandAll
              //virtualized
              placement={"autoVerticalStart"}
              onOpen={this.handleOpen}
              //onExpand={this.handleOnExpand}
              onClose={this.handleClose}
              renderMenu={(menu) => {
                if (httpObjectsData.length === 0) {
                  return (
                    <p
                      style={{ padding: 4, color: "#999", textAlign: "center" }}
                    >
                      <Icon icon="spinner" spin />
                    </p>
                  );
                }
                return menu;
              }}
              className={"custom-fade-in"}
              //height={160}
              data={httpObjectsData}
              disabledItemValues={httpObjectDisabledData}
              style={{ width: "100%" }}
              defaultValue={this.state.data ? this.state.data.value : null}
              onSelect={(activeNode, value) => {
                debugger;
                const _p = activeNode.path.split("-");
                // const aa = httpObjects.dynamicValue.find(x => x.httpObjectName === _p[0]);
                let _obj = {
                  type: typeName,
                  name: _p[0],
                  path: activeNode.path,
                  value: value,
                  children: activeNode.children,
               
                };
                this.handleToggleHttpObject(_obj);
              }}
              onClean={() => {
                this.handleToggleHttpObject(null);
              }}
              renderTreeNode={(nodeData) => {
                let _nData = null;
                try {
                  _nData = (
                    <div className={"ho-tree-node"}>
                      <p>{nodeData.label}</p>
                      <span>{" (" + nodeData.type + ")"}</span>
                    </div>
                  );
                } catch (error) {
                  const _ccc = nodeData;
                  debugger;
                }

                return _nData;
              }}
            />
          </>
        )}
      </Translation>
    );
  }
}

//export default InputGenerator
const mapStateToProps = (state) => {
  const { componentReducer, editorReducer, currentstatesReducer } = state;
  const { component } = componentReducer;
  const { currentProjectId, uiEditorStatus, httpObjects } =
    currentstatesReducer;

  const { present } = editorReducer;
  const { editor, page } = present;
  return {
    page,
    editor,
    component,
    uiEditorStatus,
    currentProjectId,
    httpObjects,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
    removeComponent: () => dispatch(removeComponent()),
    setPage: (_data) => dispatch(setPage(_data)),
    onUpdateList: (_data) => dispatch(updateListSocket(_data)),
    updateComponent: (_data) => dispatch(updateComponent(_data)),
    setUIEditorStatus: (_data, _component) =>
      dispatch(setUIEditorStatus(_data, _component)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HttpObjectSelector);
