import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { Translation } from "react-i18next";
import { FormGroup, Form, Input, Row, Col, ButtonGroup, Button } from "reactstrap";

import { updateComponent, updateListSocket, setPage, removeComponent, setUIEditorStatus } from '_actions';
 import i18n from 'i18n';
import { Dropdown } from 'rsuite';
import DynamicFieldValue from "../Core/DynamicFieldValue"
import { findObjUpdate,  AreObjectsEqual} from 'assets/util';
import HttpObjectSelector from 'components/HttpObjects/HttpObjectSelector';
import HttpObjectSelectorDynamic from 'components/HttpObjects/HttpObjectSelectorDynamic';

import {
    PropertyTitle,
} from "../Core/";
 
const defautValueOptions = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: true,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: false,
    }
]
class ChipListSettings extends Component {
    constructor(props) {
        super(props)

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const { listItem } = object;

        this.state = {
             httpObjectsData: [],
            isHttpObjectsData: false,
            removable: component &&  object.props.hasOwnProperty("removable")?((defautValueOptions.find(x => x.value === object.props.removable).value)):true,

        }

    }

    handleToggleHttpObject = () => {
        const { isHttpObjectsData } = this.state;
        this.setState({
            isHttpObjectsData: !isHttpObjectsData,
        })
    }


    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
 
        if (prevProps.component != component) {
            const  _prevObject  = component.hasOwnProperty("componentOwner") ? prevProps.component.object.components[0].object : prevProps.component.components[0].object;

            if(!AreObjectsEqual(object.data,_prevObject.data)){
                const _style = this.props.dataPath ? this.props.dataPath : "style";
                let _clone = Object.assign({}, this.props.component);

                if (_clone.hasOwnProperty("componentOwner")) {
                    _clone.object.components[0].object.data = object.data;
                } else {
                    _clone.components[0].object.data = object.data;
                }
                let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
                let _result = findObjUpdate(_newEditor, _clone);
                this.props.updateComponent(_clone);
                this.props.onUpdateList(_result);
            }

            if(object.data && object.data.type === "httpobject" &&object.data.children.length< 1){
                this.setState({
                    isHttpObjectsData: false,
                })
            }else if(object.data && object.data.type === "httpobject" &&object.data.children.length>0){
                this.setState({
                    isHttpObjectsData: true,
                })
            }else{
                this.setState({
                    isHttpObjectsData: false,
                })
            }
           
        }
    }


    componentDidMount = async () => {
        const { httpObjects } = this.props;
        let result = await this.prepareCascaderData(httpObjects.dynamicValue);

        this.setState({
            httpObjectsData: result,
            httpObjectDisabledData: this.findDisabledData(result, "array")
        })

    }


    handleUpdateValue = (_data, _name) => {

        this.setState({
            [_name]: _data.value,
        }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";
            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
            } else {
                _clone.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

 

    prepareCascaderData = async (_data) => {
        let _array = [];

        await _data.map(async (item, key) => {
            let _obj = {
                "value": (key) + "",//item.id,
                "label": item.httpObjectName,
                "children": [],
            }
            let detail = JSON.parse(item.successDynamicObjects);
            if (Array.isArray(detail)) {
                _obj["type"] = "array";
                _obj["children"] = await this.generateCascaderChild("array", detail, key);
            } else if (typeof detail == "object") {
                _obj["type"] = typeof detail;
                _obj["children"] = await this.generateCascaderChild("object", detail, key);
            } else {
                _obj["type"] = typeof detail;
            }
            _array.push(_obj)
        })
        return _array;

    }

    generateCascaderChild = async (_type, _value, _parentKey) => {
        let _array = [];

        await (_type == "array" ? _value : Object.values(_value)).map(async (item, key) => {
            let _obj = {
                //  label: "[index]",
                value: _parentKey + "-" + (key),
            }
            if (Array.isArray(item)) {
                _obj.label = Object.keys(_value)[key];
                //  debugger

                try {
                    _obj["children"] = item[0] === null ? [] : await this.generateCascaderChild("array", item, _parentKey + "-" + key)
                } catch (error) {
                    debugger
                }
                _obj["type"] = "array";
            } else if (typeof item == "object") {
                // debugger
                _obj.label = Object.keys(_value)[key];
                _obj["type"] = typeof item;
                try {
                    if (Object.keys(item).length > 0)
                        _obj["children"] = await this.generateCascaderChild("object", item, _parentKey + "-" + key);
                } catch (error) {
                    debugger
                }
            } else {
                _obj["label"] = Object.keys(_value)[key];
                _obj["type"] = typeof item;
            }

            _array.push(_obj);
        })

        return _array;
    }

    findDisabledData = (mainState, valToFind) => {
        let _ms = mainState;

        if (mainState) {
            let foundObj = [];
            let aa = JSON.stringify(_ms, (_, nestedValue) => {
                if (nestedValue && nestedValue.type !== valToFind) {
                    foundObj.push(nestedValue.value);
                }
                return nestedValue;
            });
            return foundObj;
        } else {
            return null;
        }
    }

    render() {
        const { component, httpObjects } = this.props;
        const { httpObjectsData, httpObjectDisabledData,isHttpObjectsData } = this.state;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        return (
            <Translation>
                {t => (
                    <>
                        <Form>
                            <FormGroup className={"property-form-group"}>
                                <PropertyTitle title={t("PROPERTY_TAGS_COLLECTION")} subtitle={""} />
                                <DynamicFieldValue  dataType="array"/>
                            </FormGroup>
                            {
                                isHttpObjectsData &&
                                object.data &&
                                <FormGroup className={"property-form-group"}>
                                <PropertyTitle title={t("PROPERTY_TAGS_COLLECTION")} subtitle={""} />
                                <HttpObjectSelectorDynamic fieldName={"itemValue"} isProperty={true} typeName={"indexdata"} component={null} data={object.data ? (object.data.children.length > 0 ?object.data.children[0].children:[]) : []} dataType={"string"} key={"1-itemvalue"} />
                            </FormGroup>
                            }
                          
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_TAGS_COLLECTION_REMOVABLE")}
                                    </label>
                                    {
                                        this.state["removable"] !== null &&
                                        <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["removable"]).label}>
                                            {
                                                defautValueOptions.map((x, key) => (
                                                    x.value === this.state["removable"] ?
                                                        "" :
                                                        <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "removable")}>{x.label}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown>
                                    }

                                </div>
                            </FormGroup>
                             
                        </Form>
                    </>

                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { currentProjectId, uiEditorStatus, httpObjects } = currentstatesReducer;

    const { present } = editorReducer;
    const { editor, page } = present;
    return {
        page,
        editor,
        component,
        uiEditorStatus,
        currentProjectId,
        httpObjects
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
        removeComponent: () => dispatch(removeComponent()),
        setPage: (_data) => dispatch(setPage(_data)),
        updateComponent:(_data) => dispatch(updateComponent(_data)),
        onUpdateList: (_data) => dispatch(updateListSocket(_data)),
        setUIEditorStatus: (_data, _component) => dispatch(setUIEditorStatus(_data, _component)),
     }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChipListSettings)
