import React, { Component } from 'react'
import { ReactSortable, Sortable, Swap } from "react-sortablejs";
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";

import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    TabContent,
    TabPane,
} from "reactstrap";
import { Alert, Icon } from 'rsuite';

// kurnaz components
import GenerateComponent from 'components/Custom/GenerateComponent'
import { HelperMenu } from './'
import { createId, getTime, scaleElement, buildFullTree } from 'assets/util';
import {
    updateList,
    addFavoriteComponent,
    setOnMove,
    setOnAdd,
    setOnAddUpdate,
    setOnRemove,
    setOnEnd
} from '_actions';
import { ComponentService } from '_services';
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import Emitter from "_actions/emitter";


class Canvas extends Component {
    constructor(props) {
        super(props)
        this.device = React.createRef();
        ReactSortable.prototype.onChoose = () => { };
    }




    handleGetIndex = (eArray, index) => {
        let _array = [];
        try {
            if (index == -1) {

                debugger
            }
            else if (eArray[index].hasOwnProperty("componentOwner")) {

                _array = eArray[index].object.components[0].object.data;
            }
            else {
                _array = eArray[index].components[0].object.data;
            }
        } catch (error) {
            debugger;
            Alert.error("Hata alındı incelenmeli")
        }


        return _array;
    }

    handleSetArrayNewData = (_old, _new, _path) => {
        let indexesTarget = _path !== null ? _path.split("-") : [];

        let _array2 = _old;

        indexesTarget.map(item => {
            _array2 = this.handleGetIndex(_array2, item);
        })

        _array2.splice(0, _array2.length);
        _new.map(item => {
            _array2.push(item);
        })
    }




    findIndexesIds = (_path) => {
        const { objFromId } = this.props;
        let _newArray = Object.values(JSON.parse(JSON.stringify(this.props.editor)));
        //let indexesTarget = _path !== null ? _path.split("-") : [];
        const cloneObject = { id: "canvas", components: [{ object: { data: _newArray } }], }
        const _buildArray = ((buildFullTree(cloneObject, { id: objFromId }).map(node => node.id).join('-')).split("-"));
        const result = _buildArray.slice(1, _buildArray.length - 1);
        return result;

        // debugger
        /*
         let ids = [];
         indexesTarget.map((item, index) => {
 
 
             ids.push(_newArray[item].id);
             _newArray = this.handleGetIndex(_newArray, item);
 
             if (indexesTarget.length - 1 == index) {
                 if (objFromId != ids[ids.length - 1]) {
                     console.info("YANLIŞLIK VAR ID'LER UYUŞMUYOR", "TEST-1");
                 } else {
                     console.info("SIKINTI YOK", "TEST-1");
                 }
             }
 
             
         })
         
         debugger
         return ids;
         */
    }

    prepareParentArray = async (_pArray, _cArray, _childIds) => {
        const { objFromId } = this.props;
        // await this.handleSetArrayNewDataPath(_newArray, _pArray, _pPath);

        let _newParent = _pArray

        let aa = await _childIds.slice(0, _childIds.length).map(item => {
            let vv = item;
            _newParent = this.handleGetIndex(_newParent, _newParent.findIndex(xx => xx.id == item));
        })


        _newParent.splice(0, _newParent.length);
        let bb = await _cArray.map(item => {
            _newParent.push(item);
        })

        return _newParent;
    }

    updateState = async (_newState, status) => { // Kurnaz
        const { onMove, isAdded, objFrom, currentEditor, isRemove, editor } = this.props;
        const { moveObj } = this.props;
        const _ = require('lodash');
        const newState = _newState;
        // debugger


        if (!_.isEqual(newState, editor)) {//(JSON.stringify(newState) != JSON.stringify(editor)) {
            //  const newComponent = _.differenceBy(newState, list2, 'id');

            // debugger
            debugger
            if (onMove) {
                // Device içerisinde bulunan bir component için yer değiştirme işlemi başlatılmıştır.container
                if (isRemove) {

                    let childIds = await this.findIndexesIds(objFrom);
                    let _pArray1 = Object.values(JSON.parse(JSON.stringify(newState)));

                    await this.prepareParentArray(_pArray1, currentEditor, childIds)

                    // Kayıt işlemi sona ermiştir. Artık yapılması gereken currentEditor içerisinden gelen veri içerisine mevcut listemizi atamaktır.
                    this.props.onUpdateEditorList(_pArray1);
                    this.props.setOnEnd();
                }
                else if (objFrom) {
                    // c->e
                    // e->c
                    // debugger

                    let _array = Object.values(JSON.parse(JSON.stringify(newState)))
                    await this.handleSetArrayNewData(_array, currentEditor, objFrom)

                    this.props.onUpdateEditorList(_array);
                    this.props.setOnEnd();
                }
                else if (isAdded) {
                    //   debugger
                    // Komponent in alındığı alanda bulunuyorsun demektir. sende yapılan değişiklikleri ataman gerekiyor.
                    this.props.setOnAddUpdate(null, newState);
                } else {
                    //  debugger



                    this.props.onUpdateEditorList(newState);
                    this.props.setOnEnd();
                }
            }
            else {
                //  debugger
                // Bir component editor ekranına eklenmek istenmiştir.container
                this.props.onUpdateEditorList(newState);
                this.props.setOnEnd();
            }
        }
    }

    updateDimensions = () => {
        /*
            Ekran boyutuna göre resize etmek için gerekli işlemleri burada uyguluyoruz
            üst div'in size bilgisi bizim max değerlerimiz oluyor ve device fix size değerlerine göre
            oran orantı uygulanıyor.
        */

        let _base = document.getElementsByClassName("mainCol")[0];
        let _heigth = _base.offsetHeight;
        let _width = _base.offsetWidth;
        //console.log(this.device)
        scaleElement(this.device.current, _width, _heigth)
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        this.setState = (state, callback) => {
            return;
        };

    }

    removeComponentId = () => {
        const { currentPage, component } = this.props;

        ComponentService.RemoveComponentId(this.props.currentProjectId, currentPage, component.id).then(data => {
            //Alert.success("silindi");
            this.props.componentContext.handleDelete()

        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
        });
    }
    handleOnMove = (e) => {
        const that = this;
        const _data = {
            data: null,
            path: null,
            main: null,
        }
        this.props.setOnMove(_data);
        this.props.setOnMove(null);
    }
    handleOnAdd = (e) => {
        const that = this;
        this.props.setOnAdd();
    }
    handleOnRemove = (e) => {
        const that = this;
        this.props.setOnRemove();
    }
    render() {
        const baseObject = {
            handleUpdate: this.updateState,
            baseArray: Object.assign({}, this.props.editor),
        }


        /*  GLOBAL Page Background Color */
        let gBGIndex = this.props.pageStyle ? (this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === this.props.pageStyle.globalBackgroundColor) : -1) : -1;
        let gBGValue = ((gBGIndex > -1) && (this.props.globalProps[gBGIndex].type == 0)) ? this.props.globalProps[gBGIndex].value : (this.props.pageStyle ? this.props.pageStyle.backgroundColor : null);
        let gBGFinalValue = this.props.pageStyle ? ((this.props.pageStyle.hasOwnProperty("globalBackgroundColor")) ? gBGValue : this.props.pageStyle.backgroundColor) : null;


        const { editor, currentPage, isUIEditor } = this.props;

        return (
            <Translation>
                {t => (
                    <>
                        {
                            isUIEditor ?
                                <div id={"device"} className={"device-ui custom-fade-in"} ref={this.device}>
                                    <ReactSortable
                                        group={!this.props.currentProjectId ? 'noProject' : 'Kurnaz'}
                                        fallbackOnBody={true}
                                        // forceFallback= {true}
                                        animation="150"
                                        swapThreshold="0.35"
                                        list={this.props.editor}
                                        setList={this.updateState}
                                        className={"deviceInside uiInside"}
                                        style={{
                                            ...this.props.pageStyle,
                                            backgroundColor: gBGFinalValue,
                                        }}
                                        onStart={this.handleOnMove}
                                        onAdd={this.handleOnAdd}
                                        onRemove={this.handleOnRemove}
                                    >
                                        {editor && (
                                            editor.map((item, index) => {
                                                return (
                                                    <GenerateComponent
                                                        isChild={false}
                                                        key={item.id + "canvas" + item._id}
                                                        index={index}
                                                        path={index + ""}
                                                        data={item}
                                                        baseProgress={baseObject} />
                                                )
                                            })
                                        )
                                        }
                                    </ReactSortable>
                                </div>
                                :
                                <>
                                    <div id={"device"} className={"device custom-fade-in"}
                                        ref={this.device}>
                                        {

                                            (this.props.currentProjectId && currentPage && this.props.pageStatus.status) ?
                                                <ReactSortable
                                                    group={{
                                                        name: (this.props.currentProjectId) ? 'Kurnaz-Canvas' : 'busy-page2',
                                                        pull: true,
                                                        put: [
                                                            (this.props.currentProjectId) ? 'Kurnaz' : 'noProject',
                                                            (this.props.currentProjectId) ? 'Kurnaz-Canvas' : 'noProject',
                                                        ],
                                                    }}
                                                     
                                                    // animation="150"
                                                    //swapThreshold="0.35"
                                                    swapThreshold={0.35}
                                                    invertSwap={false}
                                                    animation={400}
                                                     easing={"cubic-bezier(1, 0, 0, 1)"}
                                                    list={this.props.editor}
                                                    setList={this.updateState}
                                                    className={"deviceInside"}
                                                    style={{
                                                        ...this.props.pageStyle,
                                                        backgroundColor: gBGFinalValue,
                                                    }}
                                                    ghostClass={""}
                                                    chosenClass={""}
                                                    
                                                    //emptyInsertThreshold={5}
                                                   // removeCloneOnHide= {true}
                                                    //removeCloneOnHide= {true}

                                                    onStart={this.handleOnMove}
                                                    onAdd={this.handleOnAdd}
                                                    onRemove={this.handleOnRemove}
                                                >
                                                    {editor && (
                                                        editor.map((item, index) => {
                                                            return (
                                                                <GenerateComponent
                                                                    isChild={false}
                                                                    key={item.id + "canvas" + item._id}
                                                                    index={index}
                                                                    path={index + ""}
                                                                    data={item}
                                                                    baseProgress={baseObject} />
                                                            )
                                                        })
                                                    )
                                                    }
                                                </ReactSortable> :
                                                <div className={"deviceInside"} style={{
                                                    ...this.props.pageStyle,
                                                    backgroundColor: gBGFinalValue,
                                                }}>
                                                    {
                                                        editor &&
                                                        (
                                                            editor.map((item, index) => (
                                                                <GenerateComponent
                                                                    isChild={false}
                                                                    key={item.id}
                                                                    index={index}
                                                                    path={index + ""}
                                                                    data={item}
                                                                    baseProgress={baseObject} />
                                                            ))
                                                        )

                                                    }
                                                    {
                                                        this.props.noPage &&
                                                        <div className={"notPage custom-fade-in"}>
                                                            <Icon icon='plus-square-o' size="4x" onClick={() => {
                                                                Emitter.emit("CREATE_NEW_PAGE", null)
                                                            }} />
                                                            {t("MESSAGE_NO_PAGE")}
                                                        </div>
                                                    }
                                                </div>
                                        }

                                        {
                                            /*
                                             <div className={"device-loading"}>
                                            <ClipLoader
                                                className={"c-button-container-loading"}
                                                size={50}
                                                color={"#28a1b3"}
                                                loading={true}
                                            />
                                            <h4 style={{ color: "#909faf" }} className="mt-4"> Yükleniyor...</h4>
                                            </div>
                                            
                                            */
                                        }
                                        {
                                            !this.props.currentProjectId &&
                                            <div className={"device-notworking"}>
                                                <i style={{ fontSize: "70px", color: "#7c7f80" }} className="fas fa-exclamation-circle"></i>
                                                <h4 style={{ color: "#909faf", fontSize: "18px" }} className="mt-4">{t("HAS_PROJECT_ERROR")}</h4>
                                            </div>

                                        }

                                        {
                                            this.props.pageStatus &&
                                            !this.props.pageStatus.status &&
                                            <div className={"device-busy"}>
                                                <div></div>
                                            </div>
                                        }

                                    </div>
                                </>
                        }




                        <HelperMenu
                            className={""}
                            event={this.props.componentContext && this.props.componentContext.newContext}
                            name={this.props.component && this.props.component.componentName}
                            handleAddFav={this.props.onAddFavComponent}
                            handleDublicate={this.props.componentContext && this.props.componentContext.handleDublicate}
                            handleRemove={this.removeComponentId} />
                    </>
                )}
            </Translation>
        )
    }
}

//export default Canvas
const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, globalpropsReducer, currentstatesReducer } = state;
    const { component, componentContext } = componentReducer;
    const { present } = editorReducer;

    const { editor, pages, currentPage, page } = present;

    const pageStyle = page.hasOwnProperty("pageProperties") ? page.pageProperties.style : null;
    const { globalProps } = globalpropsReducer;

    const { pageStatus, updateComponentPosition, currentProjectId } = currentstatesReducer;

    const { onMove, moveObj, isAdded, objFrom, currentEditor, isRemove, objFromId } = updateComponentPosition;

    return {
        editor,//:JSON.parse(editor),
        component,
        componentContext,
        pageStyle,
        globalProps,
        currentPage,
        pageStatus,

        onMove,
        isAdded,
        objFrom,
        currentEditor,
        isRemove,
        moveObj,
        currentProjectId,
        objFromId

    };
}
const mapDispatchToProps = {
    onUpdateEditorList: updateList,
    onAddFavComponent: addFavoriteComponent,
    setOnMove,
    setOnAdd,
    setOnAddUpdate,
    setOnRemove,
    setOnEnd
}
export default connect(mapStateToProps, mapDispatchToProps)(Canvas)
