import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
    Button,
    Input,
    Modal,
    FormGroup, Form,
    Row, Col
} from "reactstrap";

class CheckProjects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "obj",
        }

    }

    handleChange = (event) => {
        const { target } = event;
        const { name } = target;
        this.setState({
            value: name,
        })
    }

    render() {
        const { isActive, handleToggle } = this.props;
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={isActive}

            //toggle={() => handleToggle()}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="CheckProjectModal">
                        Check project
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => handleToggle()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="custom-control custom-radio custom-control-inline">
                        <input
                            className="custom-control-input"
                            id="customRadioInline1"
                            name="json"
                            checked={this.state.value == "json"}
                            type="radio"
                            onChange={(e) => {
                                this.handleChange(e)
                            }}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="customRadioInline1"
                        >
                            JSON
                         </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                        <input
                            className="custom-control-input"
                            id="customRadioInline2"
                            name="obj"
                            checked={this.state.value == "obj"}
                            type="radio"
                            onChange={(e) => {
                                this.handleChange(e)
                            }}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="customRadioInline2"
                        >
                            Object
                        </label>
                    </div>

                    <Input type="textarea" name="export" id="export" onChange={e => e.preventDefault} value={
                        this.state.value == "obj" ? String(this.props.component) : JSON.stringify(this.props.component)
                    } />
                </div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => handleToggle()}
                    >
                        Close
              </Button>
                    <Button color="primary" type="button" onClick={() => handleToggle()}>
                        Done
              </Button>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;

    return {
        component,
    };
}
const mapDispatchToProps = {
    //  onUpdateComponent: updateComponent,

}
export default connect(mapStateToProps, mapDispatchToProps)(CheckProjects)
