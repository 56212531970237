import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";
import { addNewPage, removeComponent } from '_actions'
import { pagesService } from "_services";
import { Alert, List } from 'rsuite';
import GenerateComponent from 'components/Custom/GenerateComponent'

import {
    Button,
    Input,
    Modal,
    Container,
    Row, Col
} from "reactstrap";

class ComponentSelector extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef();

        this.state = {

        }

    }



    componentDidUpdate(prevProps) {
        if (this.props.isActive)
            if (prevProps.isActive != this.props.isActive) {

                //this.myRef.current.focus()
            }
    }



    render() {
        const { isActive, handleToggle } = this.props;
        return (
            <Translation>
                {t => (
                    <>
                        <Modal
                            className="modal-dialog-centered"
                            isOpen={isActive}
                            //size="lg"
                            className={"component-selector-modal"}
                            scrollable={true}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="CheckProjectModal">
                                    {t("TITLE_MODAL_COMPONENT_SELECTOR")}
                                </h5>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => handleToggle()}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Container>
                                    <Row>
                                        <List hover className={"component-selector-list"}>
                                            {this.props.myComponentList.map((item, index) => {

                                                return (
                                                    <List.Item onClick={() => this.props.handleSelect(item)} key={index} index={index}>
                                                        <GenerateComponent
                                                            isChild={false}
                                                            path={0 + ""}
                                                            data={item}
                                                            baseProgress={{
                                                                handleUpdate: () => { },
                                                                baseArray: this.props.myComponentList,
                                                                isViewer: true,
                                                            }}
                                                        />
                                                    </List.Item>
                                                )
                                            })}
                                        </List>
                                    </Row>
                                </Container>
                            </div>

                        </Modal>

                    </>

                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { currentProjectId } = currentstatesReducer;

    const { component, myComponentList } = componentReducer;

    return {
        component,
        currentProjectId,
        myComponentList
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
        addNewPage: (data) => dispatch(addNewPage(data)),
        removeComponent: () => dispatch(removeComponent()),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ComponentSelector)
