import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { Translation } from "react-i18next";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { authenticationAction, setReset } from "_actions";
import { userService, ProjectService, Get_FRONTEND_CMS_URL, GetConsoleFrontEnd } from "_services";
import { Alert } from "rsuite";
import CImage from "components/UI/CImage";
import { dateformat } from 'assets/globalOps/global';
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import ProjectCard from 'components/Projects/ProjectCard';
import SelectProject from 'components/Modals/SelectProject';

class MainNavbar extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      time: new Date,
      selectProject: false,
    }

  }
  handleSelectProjectClose = () => {
    this.setState({
      selectProject: false,
    })
  }



  componentDidMount() {
    window.setInterval(function () {
      this.setState({
        time: new Date,
      });
    }.bind(this), 240000);
  }
  logoutClicked = () => {
    const { loginSuccess, loginFailure, logout } = this.props;
    userService
      .logout()
      .then((data) => {
        //ok olmussa
        logout();
        window.location.reload();
      })
      .catch((err) => { });
  };

  handleChangePlatform = (e) => {
    const { name } = e.currentTarget;
    /*
      - creator
      - console
      - cms
    */

    const { currentProjectId } = this.props;

    e.preventDefault();

    switch (name) {
      case "console":
        this.GotoConsole(currentProjectId);
        break;
      case "creator":
        alert("creator");
        break;
      case "cms":
        this.GotoCMS(currentProjectId);
        break;

      default:
        break;
    }

    // Alert.warning(`Go to ${name} page`);
  };

  //region GoTO
  GotoCMS = (ProjectID) => {
    if (ProjectID !== undefined) {
      ProjectService.SetProjectDetail(ProjectID).then(async (res) => {
        if (res.operationResult === true) {
          let prapareLink = Get_FRONTEND_CMS_URL() + "auth/" + res.dynamicValue;
          //  window.open(prapareLink,"_blank");
          window.location.href = prapareLink;

        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }
  GotoConsole = (ProjectID) => {
    if (ProjectID !== undefined) {
      ProjectService.SetProjectDetail(ProjectID).then(async (res) => {
        if (res.operationResult === true) {
          let prapareLink = GetConsoleFrontEnd() + "auth/" + res.dynamicValue;
          //  window.open(prapareLink,"_blank");
          window.location.href = prapareLink;

        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }
  //#endregion

  render() {
    const { currentProject } = this.props;
    return (
      <Translation>
        {t => (
          <>
            <Navbar
              className={classnames(
                "navbar-top navbar-expand border-bottom",
                "navbar-custom",
                { "navbar-dark bg-info": this.props.theme === "dark" },
                { "navbar-light bg-white": this.props.theme === "light" }
              )}
            >
              <Container fluid>
                <Collapse navbar isOpen={true}>
                  <p style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    color: "#a1b3b7"
                  }}>
                    {t("VERSION") + " - " + dateformat(this.state.time)}
                  </p>
                  <Nav className="align-items-center ml-md-auto" navbar>
                    <NavItem className="d-xl-none">
                      <div
                        className={classnames(
                          "pr-3 sidenav-toggler",
                          { active: !this.props.sidenavOpen },
                          { "sidenav-toggler-dark": this.props.theme === "dark" }
                        )}
                        onClick={this.props.toggleSidenav}
                      >
                        <div className="sidenav-toggler-inner">
                          <i className="sidenav-toggler-line" />
                          <i className="sidenav-toggler-line" />
                          <i className="sidenav-toggler-line" />
                        </div>
                      </div>
                    </NavItem>
                    {
                      currentProject &&
                      <Nav onClick={() => {
                       
                        this.setState({
                          selectProject: true,
                        })
                      }} className="align-items-center ml-auto ml-md-0 custom-fade-in clickable-nav" navbar>
                        <ProjectCard isViewer={true} isHeader={true} />
                      </Nav>
                    }
                    <UncontrolledDropdown nav>
                      <DropdownToggle className="nav-link" color="" tag="a">
                        <i className="ni ni-bell-55" />
                      </DropdownToggle>
                      <DropdownMenu
                        className="dropdown-menu-xl py-0 overflow-hidden"
                        right
                      >
                        <div className="px-3 py-3">
                          <h6 className="text-sm text-muted m-0">
                            You have <strong className="text-info">13</strong>{" "}
                            notifications.
                      </h6>
                        </div>

                        <ListGroup flush>
                          <ListGroupItem
                            className="list-group-item-action"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tag="a"
                          >
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  alt="..."
                                  className="avatar rounded-circle"
                                  src={require("assets/img/theme/team-1.jpg")}
                                />
                              </Col>
                              <div className="col ml--2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="mb-0 text-sm">John Snow</h4>
                                  </div>
                                  <div className="text-right text-muted">
                                    <small>2 hrs ago</small>
                                  </div>
                                </div>
                                <p className="text-sm mb-0">
                                  Let's meet at Starbucks at 11:30. Wdyt?
                            </p>
                              </div>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem
                            className="list-group-item-action"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tag="a"
                          >
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  alt="..."
                                  className="avatar rounded-circle"
                                  src={require("assets/img/theme/team-2.jpg")}
                                />
                              </Col>
                              <div className="col ml--2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="mb-0 text-sm">John Snow</h4>
                                  </div>
                                  <div className="text-right text-muted">
                                    <small>3 hrs ago</small>
                                  </div>
                                </div>
                                <p className="text-sm mb-0">
                                  A new issue has been reported for Argon.
                            </p>
                              </div>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem
                            className="list-group-item-action"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tag="a"
                          >
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  alt="..."
                                  className="avatar rounded-circle"
                                  src={require("assets/img/theme/team-3.jpg")}
                                />
                              </Col>
                              <div className="col ml--2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="mb-0 text-sm">John Snow</h4>
                                  </div>
                                  <div className="text-right text-muted">
                                    <small>5 hrs ago</small>
                                  </div>
                                </div>
                                <p className="text-sm mb-0">
                                  Your posts have been liked a lot.
                            </p>
                              </div>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem
                            className="list-group-item-action"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tag="a"
                          >
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  alt="..."
                                  className="avatar rounded-circle"
                                  src={require("assets/img/theme/team-4.jpg")}
                                />
                              </Col>
                              <div className="col ml--2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="mb-0 text-sm">John Snow</h4>
                                  </div>
                                  <div className="text-right text-muted">
                                    <small>2 hrs ago</small>
                                  </div>
                                </div>
                                <p className="text-sm mb-0">
                                  Let's meet at Starbucks at 11:30. Wdyt?
                            </p>
                              </div>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem
                            className="list-group-item-action"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tag="a"
                          >
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  alt="..."
                                  className="avatar rounded-circle"
                                  src={require("assets/img/theme/team-5.jpg")}
                                />
                              </Col>
                              <div className="col ml--2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="mb-0 text-sm">John Snow</h4>
                                  </div>
                                  <div className="text-right text-muted">
                                    <small>3 hrs ago</small>
                                  </div>
                                </div>
                                <p className="text-sm mb-0">
                                  A new issue has been reported for Argon.
                            </p>
                              </div>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>

                        <DropdownItem
                          className="text-center text-info font-weight-bold py-3"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          View all
                    </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                      <DropdownToggle className="nav-link" color="" tag="a">
                        <i className="ni ni-ungroup" />
                      </DropdownToggle>
                      <DropdownMenu
                        className="dropdown-menu-lg dropdown-menu-dark system-selector"
                        right
                      >
                        <Row className="shortcuts px-4">
                          <Col
                            className="shortcut-item"
                            //href="#creator"
                            name={"creator"}
                            // onClick={this.handleChangePlatform}
                            xs="4"
                            tag="a"
                          >
                            <span className="shortcut-media avatar rounded-circle active">
                              <i className="fas fa-code" />
                            </span>
                            <small>Creator</small>
                          </Col>
                          <Col
                            className="shortcut-item"
                            href="#console"
                            name={"console"}
                            onClick={this.handleChangePlatform}
                            xs="4"
                            tag="a"
                          >
                            <span className="shortcut-media avatar rounded-circle">
                              <i className="fas fa-toolbox" />
                            </span>
                            <small>Console</small>
                          </Col>
                          <Col
                            className="shortcut-item"
                            href="#cms"
                            name={"cms"}
                            onClick={this.handleChangePlatform}
                            xs="4"
                            tag="a"
                          >
                            <span className="shortcut-media avatar rounded-circle ">
                              <i className="fas fa-database" />
                            </span>
                            <small>CMS</small>
                          </Col>
                        </Row>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>


                  <Nav className="align-items-center ml-auto ml-md-0" navbar>
                    <UncontrolledDropdown nav>
                      <DropdownToggle className="nav-link pr-0" color="" tag="a">
                        <Media className="align-items-center">
                          <span className="avatar avatar-sm rounded-circle">
                            <CImage
                              images={
                                this.props.user &&
                                this.props.user.profilePhotoImages
                              }
                              single={false}
                              variant={"circle"}
                            />
                          </span>
                          <Media className="ml-2 d-none d-lg-block">
                            <span className="mb-0 text-sm font-weight-bold">
                              {this.props.user && this.props.user.name}
                            </span>
                          </Media>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem className="noti-title" header tag="div">
                          <h6 className="text-overflow m-0">Welcome!</h6>
                        </DropdownItem>
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="ni ni-single-02" />
                          <span>My profile</span>
                        </DropdownItem>
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="ni ni-settings-gear-65" />
                          <span>Settings</span>
                        </DropdownItem>
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="ni ni-calendar-grid-58" />
                          <span>Activity</span>
                        </DropdownItem>
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="ni ni-support-16" />
                          <span>Support</span>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          // href="#pablo"
                          onClick={(e) => {
                            this.logoutClicked();
                            e.preventDefault();
                          }}
                        >
                          <i className="ni ni-user-run" />
                          <span>Logout</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
            <SelectProject isChangeProject={true} isActive={this.state.selectProject} handleToggle={this.handleSelectProjectClose} projectList={this.props.myProjects} />
          </>
        )}
      </Translation>
    );
  }
}
MainNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark",
};
MainNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

//export default MainNavbar;

const mapStateToProps = (state, props) => {
  const { componentReducer, editorReducer, authentication, currentstatesReducer } = state;
  //const { component } = componentReducer;
  const { currentProjectId, currentProject, myProjects } = currentstatesReducer;
  const { present } = editorReducer;
  const { editor } = present;
  const { loggedIn, LoginFailed, user } = authentication;

  return {
    loggedIn,
    LoginFailed,
    user,
    currentProjectId,
    currentProject,
    myProjects
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authenticationAction.logout()),
    setReset: () => dispatch(setReset()),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MainNavbar);
