import React, { Component } from 'react'
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { Translation } from "react-i18next";
import { connect } from 'react-redux';
import { Nav, Dropdown, Input, Loader } from 'rsuite';
import { setEnvironments } from '_actions';
import { EnvironmentService } from '_services';
import { ResponseStatusCode } from "_helpers";
import Emitter from "_actions/emitter";

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';

import {
    Modal,
    Row,
} from "reactstrap";

class ManageEnvironmentsModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: []
        }
        this.getEnviroments();
    }
    componentDidUpdate = (prevProps) => {
        const { isActive } = this.props;
        if (prevProps.isActive !== isActive) {
            this.getEnviroments();
            this.setState({

            })
        }
    }
    getEnviroments = async () => {
        const that = this;
        const { currentProjectId, setEnvironments } = this.props;
        await EnvironmentService.STEPBYLOAD(
            "9999", currentProjectId, false, "-1", ""
        ).then(data => {
            const _data = data.dynamicValue;
            setEnvironments(_data);

        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
            //Alert.error(data.dynamicValue)
        });
    }
    createEnviroment = async (VariableName, VariableValue) => {
        const that = this;
        const { currentProjectId, setEnvironments } = this.props;
        await EnvironmentService.CREATE_ENVIRONMENT(
            currentProjectId, VariableName, VariableValue, true
        ).then(data => {
            const _data = data.dynamicValue;

            const { environments } = that.props;
            let _newArray = [...environments.dynamicValue];
            _newArray.push(_data)
            setEnvironments({
                ...environments,
                dynamicValue: _newArray
            });
        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
            //Alert.error(data.dynamicValue)
        });
    }
    updateEnviroment = async (_id, VariableName, VariableValue, IsActive) => {
        const that = this;
        const { currentProjectId, setEnvironments } = this.props;
        await EnvironmentService.UPDATE_ENVIRONMENT(
            _id, currentProjectId, VariableName, VariableValue, IsActive
        ).then(data => {
            const _data = data.dynamicValue;

            const { environments } = that.props;
            let _newArray = [...environments.dynamicValue];
            let fIndex = _newArray.findIndex(x => x.id == _data.id);
            if (fIndex != -1) {
                _newArray[fIndex] = _data;
                setEnvironments({
                    ...environments,
                    dynamicValue: _newArray
                });

            }

        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
            //Alert.error(data.dynamicValue)
        });
    }
    deleteEnviroment = async (_id) => {
        const that = this;
        const { currentProjectId, setEnvironments } = this.props;
        await EnvironmentService.REMOVE_ENVIRONMENT(
            _id, currentProjectId
        ).then(data => {
            // const _data = data.dynamicValue;
            debugger
        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
            //Alert.error(data.dynamicValue)
        });
    }
    removeLoading = (_key) => {
        const that = this;
        setTimeout(() => {
            let _new = that.state.isLoading;
            let _i = _new.findIndex(x => x == _key);
            if (_i > -1) {
                _new.splice(_i, 1);
            }
            that.setState({
                isLoading: _new,
            })
        }, 3000);
    }
    afterSaveCell = (oldValue, newValue, row, column) => {
        const that = this;
        //debugger


    }
    beforeSaveCell = (oldValue, newValue, row, column, done) => {
        if (oldValue !== newValue) {
            if (row.id === "") {
                const { data } = this.state;
                this.createEnviroment(column.dataField == "variableName" ? newValue : row.variableName, column.dataField == "variableValue" ? newValue : row.variableValue)
                done(false);
                return { async: true };
            }
            else {
                this.updateEnviroment(row.id, column.dataField == "variableName" ? newValue : row.variableName, column.dataField == "variableValue" ? newValue : row.variableValue, true)
                done(false);
                return { async: true };
            }
        }
        else {
            done(false);
            return { async: true };
        }

    }
    generateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            row.id ? (cell === "" ? (
                formatExtraData === i18n.t("PLACEHOLDER_ENVIRONMENT_ADDNEW") ?
                    <span className={"enviroment-new-add"}>{i18n.t("PLACEHOLDER_ENVIRONMENT_KEY")}</span> :
                    <span className={"enviroment-new-add"}>{i18n.t("PLACEHOLDER_ENVIRONMENT_VALUE")}</span>
            ) : <span className={"enviroment-cell"}>{cell}</span>) : <span className={"enviroment-new-add"}>{formatExtraData}</span>
        );
    }
    render() {
        const { isActive, handleToggle, environments } = this.props;
        const { isLoading, body, data } = this.state;

        const columns = [{
            dataField: 'isActive',
            type: 'bool',
            hidden: true,
            text: 'Is Active',

        }, {
            dataField: 'variableName',
            text: 'VARIABLE',
            formatter: this.generateFormatter,
            formatExtraData: i18n.t("PLACEHOLDER_ENVIRONMENT_ADDNEW"),
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                <CustomInput placeholder={i18n.t("PLACEHOLDER_ENVIRONMENT_KEY")} {...editorProps} value={value} />
            ),
            editorClasses: (cell, row, rowIndex, colIndex) =>
                ('rs-input rs-input-sm')
        }, {
            dataField: 'variableValue',
            formatter: this.generateFormatter,
            formatExtraData: "",//i18n.t("PLACEHOLDER_ENVIRONMENT_VALUE"),
            text: 'Value',
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                <CustomInput placeholder={i18n.t("PLACEHOLDER_ENVIRONMENT_VALUE")} {...editorProps} value={value} />
            ),
            editorClasses: (cell, row, rowIndex, colIndex) =>
                ('rs-input rs-input-sm')
        }];
        
        return (
            <Translation>
                {t => (
                    <Modal
                        isOpen={isActive}
                        size="lg"
                        scrollable={true}
                        className={"modal-dialog-centered httpobject-modal"}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="CheckProjectModal">
                                {t("MODAL_TITLE_MANAGE_ENVIRONMENTS")}
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => handleToggle()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <Row>
                                    <div className={"icons-modal-container httpobject-headers-table"}>
                                        <BootstrapTable
                                            style={{
                                                width: "100%"
                                            }}
                                            keyField="id"
                                            data={[
                                                ...environments.dynamicValue,
                                                {
                                                    id: "",
                                                    isActive: true,
                                                    variableName: "",
                                                    variableValue: ""
                                                }
                                            ]}
                                            columns={columns}
                                            cellEdit={cellEditFactory({ mode: 'click', blurToSave: true, afterSaveCell: this.afterSaveCell, beforeSaveCell: this.beforeSaveCell })}
                                        />
                                    </div>
                                </Row>
                            </div>
                        </div>

                    </Modal>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { environments, httpObjects, currentProjectId } = currentstatesReducer;

    return {
        component,
        environments,
        httpObjects,
        currentProjectId
    };
}
const mapDispatchToProps = {
    setEnvironments,
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageEnvironmentsModal)

class CustomInput extends React.Component {
    static propTypes = {
        value: PropTypes.number,
        onUpdate: PropTypes.func.isRequired
    }
    static defaultProps = {
        value: 0
    }
    getValue() {
        return (this.edittext.value);
    }
    render() {
        const { value, onUpdate, ...rest } = this.props;
        // debugger
        return (
            <input
                {...rest}
                className={'rs-input rs-input-sm'}
                key="edittext"
                ref={node => this.edittext = node}
            />)


    }
}

