import { userConstants } from "../_constants";


export const authenticationAction={
    LoginSuccess,
    LoginFailure,
    logout
};

  function LoginSuccess(_user) {
    return {
        type: userConstants.LOGIN_SUCCESS,
        user:_user
    }
}
  function LoginFailure() {
    return {
        type: userConstants.LOGIN_FAILURE,
        
    }
}

  function logout() {
    
    return {
        type: userConstants.LOGOUT,
    }
}
 

