import React, { Component } from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Collapse,
} from "reactstrap";


export class PropertyCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: props.status ? props.status : false,
        };
    }
    collapsesToggle = () => {
        this.setState({
            isActive: !this.state.isActive,
        })
    };
    render() {
        const { isActive } = this.state;
        const { children, title } = this.props;
        return (
            <>
                <div className="accordion">
                    <Card className="card-plain property-category-card">
                        <CardHeader
                            className={"property-category"}
                            role="tab"
                            onClick={this.collapsesToggle}
                            aria-expanded={this.state.isActive} >
                            <label className={"property-category-title"}>
                                {title}
                            </label>

                        </CardHeader>
                        <Collapse
                            role="tabpanel"
                            isOpen={this.state.isActive}>
                            <CardBody className={"property-category-body"}>
                                {/* Property Detail */}
                                {children}
                            </CardBody>
                        </Collapse>
                    </Card>


                </div>
            </>
        )
    }
}



export default PropertyCategory
