//import { ItemInterface } from 'react-json-view'

let id = 1;
export const createId = () => {
  return id++;
}

export const applyDrag = (arr, payload, addedIndex) => {

  if (addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const AreObjectsEqual = (first_object, second_object) => {

  return JSON.stringify(first_object) == JSON.stringify(second_object) ? true : false;

}

export const findNestedObj = (mainState, valToFind) => {
  if (mainState) {
    let foundObj;
    JSON.stringify(mainState, (_, nestedValue) => {
      if (nestedValue && nestedValue["_id"] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    //debugger;

    return foundObj;
  } else {
    return null;
  }

};




export const findObjUpdate = (mainState, valToFind) => {
  try {
    let _ms = mainState;
    let _status=true;
    if (mainState) {
      let foundObj;
      let aa = JSON.stringify(_ms, (_, nestedValue) => {
        if (nestedValue && nestedValue["id"] === valToFind.id && _status) {
          nestedValue = valToFind;
          _status=false;
        }
        return nestedValue;
       });
       return JSON.parse(aa);
    } else {
      return null;
    }
  } catch (error) {
     return null;
    

  }
 
};


export const findObjUpdateItemList = (mainState, valToFind, newValue) => {
  let _ms = mainState;
  if (mainState) {
    let foundObj;
    let aa = JSON.stringify(_ms, (_, nestedValue) => {
      if (nestedValue && nestedValue["id"] === valToFind.id) {
        if (nestedValue.hasOwnProperty("componentOwner")) {
          nestedValue.object.components[0].object.listItem = newValue
        } else {
          nestedValue.components[0].object.listItem = newValue
        }
      }
      return nestedValue;
    });
    return JSON.parse(aa);
  } else {
    return null;
  }
};



export const findPath = (node, category) => {
  try {
    if (node.id === category.id) {
      return [node]
    } else {
      const _component = (node.hasOwnProperty("componentOwner") ? node.object.components[0].object : node.components[0].object)
      const _data = _component.hasOwnProperty("data") ? _component.data : _component;
      if (_component.hasOwnProperty("data")) {
        for (const child of _data) {
          const childPath = findPath(child, category)
          if (Array.isArray(childPath)) {
            childPath.unshift(child)
            return childPath
          }
        }
      }

    }
  } catch (error) {
    //debugger
  }

}
export const buildFullTree = (departmentTree, category, data = []) => {
  const foundPath = findPath(departmentTree, category)
  if (Array.isArray(foundPath)) {
    data.push(departmentTree)
    data.push(...foundPath)
  }
  return data
}


export const findNestedObjAndChange = (mainState, valToFind, newObj) => {
  if (mainState) {
    let _array = mainState;
    let foundObj;
    debugger
    _array.map(item => {
      JSON.stringify(item, (_, nestedValue) => {

        if (nestedValue && (nestedValue["id"] === valToFind)) {
          foundObj = nestedValue;
          debugger
          if (nestedValue.hasOwnProperty("componentOwner")) {
            nestedValue.object.components[0].object.data.splice(0, nestedValue.object.components[0].object.data.length);
            newObj.map(item => {
              nestedValue.object.components[0].object.data.push(item);
            })
          } else {
            nestedValue.components[0].object.data.splice(0, nestedValue.components[0].object.data.length);
            newObj.map(item => {
              nestedValue.components[0].object.data.push(item);
            })
          }
          debugger;
        }

      });
    })

    debugger
    return _array;
  } else {
    return null;
  }

};

export const findNestedObjAndChangeALL = (mainState, valToFind, newObj) => {
  if (mainState) {
    let _array = JSON.parse(JSON.stringify(mainState));
    let foundObj;
    JSON.stringify(mainState, (_, nestedValue) => {
      if (nestedValue && nestedValue["id"] === valToFind) {
        foundObj = nestedValue;
        //debugger
        nestedValue = newObj;
        // debugger;
      }
      return nestedValue;
    });
    return _array;
  } else {
    return null;
  }

};

export const getTime = () => {
  let d = new Date();
  let text = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
  return text;
}

var basePage = {
  width: 407,
  height: 787,
  scale: 1,
  scaleX: 1,
  scaleY: 1
};

export const scaleElement = (page, maxWidth, maxHeight) => {

  var scaleX = 1,
    scaleY = 1;
  scaleX = maxWidth / basePage.width;
  scaleY = maxHeight / basePage.height;
  basePage.scaleX = scaleX;
  basePage.scaleY = scaleY;
  basePage.scale = (scaleX > scaleY) ? scaleY : scaleX;

  var newLeftPos = Math.abs(Math.floor(((basePage.width * basePage.scale) - maxWidth) / 2));
  var newTopPos = Math.abs(Math.floor(((basePage.height * basePage.scale) - maxHeight) / 2));


  //webkitTransform
  page.style.webkitTransform = 'scale(' + basePage.scale + ')';
  page.style.transform = 'scale(' + basePage.scale + ')';
  //page.style.left = newLeftPos + 'px';
  //page.style.top = newTopPos + 'px';


}


export const extractRgba = (css) => {
  let _color = css.match(/[0-9.]+/gi)

  return {
    r: _color[0],
    g: _color[1],
    b: _color[2],
    a: css.includes("rgba") ? _color[3] : 1,
  }
}


export const colorToRgbA = (hex) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return {
      r: (c >> 16) & 255,
      g: (c >> 8) & 255,
      b: c & 255,
      a: '1'
    };

  } else if (typeof hex == "object") {
    return hex;
  } else if (hex.includes("rgb")) {
    return extractRgba(hex);
  }
  return {
    r: '4',
    g: '4',
    b: '4',
    a: '1',
  }
  // throw new Error('Bad Hex');
}


export const lightOrDark = (color) => {

  let
    r,
    g,
    b,
    hsp = null;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {

    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'
    ));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {

    return 'light';
  } else {

    return 'dark';
  }
}