import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { FormGroup, Form, Input, Row, Col } from "reactstrap";
import { updateComponent, updateList } from '_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';
import i18n from 'i18n';
import {
    PropertyTitle,
} from "../Core/";
import { Icon, Dropdown, List, Alert, Button, IconButton, ButtonToolbar, } from 'rsuite';
import ColorPicker from "../Core/ColorPicker";
import NumaricDataProperty from "../Core/NumaricDataProperty"
import { ComponentService } from '_services';
import { ResponseStatusCode } from "_helpers";
import StaticTextValue from '../Core/StaticTextValue';
import NewScript from '../../Modals/NewScript';

import IconDetailProperty from '../Core/IconDetailProperty'

const defautValueOptions = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: true,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: false,
    }
]
const defautValueOptions2 = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: false,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: true,
    }
]
const WAIT_INTERVAL = 500;

class VideoPlayerSettings extends Component {
    constructor(props) {
        super(props)
        this.fontSizeSlider = React.createRef();

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const _p = object.props;
        this.state = {
            componentClone: component,
            url: component && object.props.url ? object.props.url : "",
            title: component && object.props.title ? object.props.title : "",
            autoPlay: component && object.props.autoPlay ? true : false,
            moreButton: component && object.props.moreButton ? true : false,
            volume: component && object.props.volume ? true : false,
            replay: component && object.props.replay ? true : false,

        }


    }




    updatePropEditor = (key, value) => {
        const that = this;
        let _clone = Object.assign({}, this.props.component);
        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props[key] = value
        } else {
            _clone.components[0].object.props[key] = value
        }

        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);
    }




    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const _p = object.props;

        if (prevProps.component != component) {
            this.setState({
                componentClone: component,
                url: component && object.props.url ? object.props.url : "",
                title: component && object.props.title ? object.props.title : "",
                autoPlay: component && object.props.autoPlay ? true : false,
                moreButton: component && object.props.moreButton ? true : false,
                volume: component && object.props.volume ? true : false,
                replay: component && object.props.replay ? true : false,

                // orientation: component && (defautOrientationOptions.find(x => x.value === _p.orientation).value),
                // verticalOrientation: component && (defautVerticalOrientationOptions.find(x => x.value === _p.verticalOrientation).value),
                // position: component && (defautPositionOptions.find(x => x.value === _p.position).value),
            }, () => { })
        }
    }


    componentDidMount() {
    }



    handleChange = async (event, index) => {
        const that = this;
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        clearTimeout(this.timer);

        let _list = Object.values(JSON.parse(JSON.stringify(this.state.menu)));
        _list[index].title = value;

        await this.setState({
            ["menu"]: _list,
        }, () => {
            //that.updatePropEditor("menu", that.state.menu)
        });
        this.timer = setTimeout(() => { that.updatePropEditor("menu", that.state.menu) }, WAIT_INTERVAL);

    }


    componentWillMount() {
        this.timer = null;
    }

    handleDropdown = (_data,key) => {

      
        const that = this;
        this.setState({
            [key]: _data.value,
        }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";
            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[key] = that.state[key];
            } else {
                _clone.components[0].object.props[key] = that.state[key];
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

    render() {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];


        const that = this;
        return (
            <Translation>
                {t => (
                    <>
                        <Form>
                        <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}>
                                        <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_VIDEOPLAYER_URL")}
                                        </label>

                                    </div>
                                    <StaticTextValue propName={"url"} />
                                </FormGroup>
                                <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}>
                                        <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_VIDEOPLAYER_TITLE")}
                                        </label>

                                    </div>
                                    <StaticTextValue propName={"title"} />
                                </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="vplayer-autoplay" className={"propertyTitle"}>{t("PROPERTY_VIDEOPLAYER_AUTOPLAY")}
                                    </label>
                                    {
                                        this.state["autoPlay"] !== null &&
                                        <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["autoPlay"]).label}>
                                            {
                                                defautValueOptions.map((x, key) => (
                                                    x.value === this.state["autoPlay"] ?
                                                        "" :
                                                        <Dropdown.Item key={key} onSelect={() => this.handleDropdown(x,"autoPlay")}>{x.label}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown>
                                    }
                                </div>
                            </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="vplayer-moreButton" className={"propertyTitle"}>{t("PROPERTY_VIDEOPLAYER_MOREBUTTON")}
                                    </label>
                                    {
                                        this.state["moreButton"] !== null &&
                                        <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["moreButton"]).label}>
                                            {
                                                defautValueOptions.map((x, key) => (
                                                    x.value === this.state["moreButton"] ?
                                                        "" :
                                                        <Dropdown.Item key={key} onSelect={() => this.handleDropdown(x,"moreButton")}>{x.label}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown>
                                    }
                                </div>
                            </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="vplayer-volume" className={"propertyTitle"}>{t("PROPERTY_VIDEOPLAYER_MUTE")}
                                    </label>
                                    {
                                        this.state["volume"] !== null &&
                                        <Dropdown size="xs" title={defautValueOptions2.find(x => x.value === (this.state["volume"])).label}>
                                            {
                                                defautValueOptions2.map((x, key) => (
                                                    x.value === (this.state["volume"]) ?
                                                        "" :
                                                        <Dropdown.Item key={key} onSelect={() => this.handleDropdown(x,"volume")}>{x.label}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown>
                                    }
                                </div>
                            </FormGroup>


                        </Form>

                    </>

                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { pageStatus, currentProjectId } = currentstatesReducer;

    const { present } = editorReducer;
    const { editor, currentPage } = present;
    return {
        editor,
        currentPage,
        component,
        currentProjectId
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,


}
export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayerSettings)
