import Emitter from "./emitter";
export const sweetAlertActions = {
  SHOW_ASK,
  SHOW_SUCCESS,
  SHOW_UNSUCCESS,
  clear
};


function SHOW_ASK(
    title_ask,
    title_succ,
    title_unsucc,
    detail_ask,
    detail_succ,
    detail_unsucces
  ) {
    let payload = {
      title_ask: title_ask,
      detail_ask: detail_ask,
      title_succ: title_succ,
      detail_succ: detail_succ,
      title_unsucc: title_unsucc,
      detail_unsucces: detail_unsucces
    };
    Emitter.emit("SHOW_ASK", payload);
  }
  
  function SHOW_SUCCESS(title_succ, detail_succ) {
    let payload = {
      title: title_succ,
      detail: detail_succ
    };
  
    Emitter.emit("SHOW_SUCCESS", payload);
  }
  
  function SHOW_UNSUCCESS(title_unsucc, detail_unsucces) {
    let payload = {
      title: title_unsucc,
      detail: detail_unsucces
    };
    Emitter.emit("SHOW_UNSUCCESS", payload);
  }
  
  function clear() {
    Emitter.emit("SWEETALERT_CLEAR", null);
  }
  