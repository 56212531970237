import React, { Component } from "react";
import PropTypes, { array } from "prop-types";

import { connect } from "react-redux";
import { Translation } from "react-i18next";
import i18n from "i18n";
import Emitter from "_actions/emitter";
import { ResponseStatusCode } from "_helpers";
import {
  CheckTreePicker,
  Nav,
  Dropdown,
  Alert,
  Loader,
  Steps,
  Panel,
  Navbar,
  ButtonGroup,
  Message,
  Icon,
  Popover,
  Whisper,
  Tooltip,
} from "rsuite";

import { loader } from "@monaco-editor/react";
import Editor from "@monaco-editor/react";
import { CustomMakeReqestAndGetResponse } from "_services/httpOperations";
import { HttpObjectService } from "_services";
import { setHttpObjects } from "_actions";

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

import {
  Button,
  Input,
  Modal,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

const types = {
  post: 0,
  get: 1,
  delete: 2,
  put: 3,
};
class HttpObjectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",

      proUrl: "",
      proBody: "",
      proHeaders: [
        {
          id: "***contetType-aj-default****",
          variableName: "Content-Type",
          variableValue: "application/json",
        },
      ],

      url: "",
      type: "get", // get
      body: "",
      headers: [
        {
          id: "***contetType-aj-default****",
          variableName: "Content-Type",
          variableValue: "application/json",
        },
      ],
      httpResponse: "",

      successObject: "",
      successScript: `// data is http object response
// SetDynamicStore("key","value"); 
// GetDynamicStore("key");
`,

      failObject: "",
      failScript: `// data is http object response
// SetDynamicStore("key","value"); 
// GetDynamicStore("key");
`,

      isLoading: [],
      activeTab: "Body",
      step: 0,
      pOrder: 780,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.editor = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    const that = this;
    const { isActive, selectedHttpObject } = this.props;
    if (prevProps.isActive !== isActive) {
      if (isActive && selectedHttpObject) {
        //   debugger
        //     this.prepareEditor();
        const httpTypes = {
          0: "post",
          1: "get",
          2: "delete",
          3: "put",
        };
        //      debugger

        this.setState(
          {
            name: selectedHttpObject.httpObjectName,

            proUrl: selectedHttpObject.url,
            proBody: selectedHttpObject.body,
            proHeaders: selectedHttpObject.headers.map((x, key) => ({
              id: key + "-*-" + selectedHttpObject.headers.length,
              variableName: x.key,
              variableValue: x.value,
            })),

            url: selectedHttpObject.devURL,
            type: httpTypes[selectedHttpObject.httpType],
            body: selectedHttpObject.devBody,
            headers: selectedHttpObject.devHeaders.map((x, key) => ({
              id: key + "-*-" + selectedHttpObject.devHeaders.length,
              variableName: x.key,
              variableValue: x.value,
            })),
            successObject: selectedHttpObject.successDynamicObjects,
            successScript: selectedHttpObject.successScript,
            failObject: selectedHttpObject.failDynamicObjects,
            failScript: selectedHttpObject.failScript,

            httpResponse: "",
            isLoading: [],
            activeTab: "Body",
            step: 2,
            pOrder: 780,
          },
          () => {
            that.handleGenerateFailScriptEditor("successScript");
            that.handleGenerateFailScriptEditor("failScript");
          }
        );
      } else {
        this.setState({
          name: "",

          proUrl: "",
          proBody: "",
          proHeaders: [
            {
              id: "***contetType-aj-default****",
              variableName: "Content-Type",
              variableValue: "application/json",
            },
          ],

          url: "",
          type: "get", // get
          body: "",
          headers: [
            {
              id: "***contetType-aj-default****",
              variableName: "Content-Type",
              variableValue: "application/json",
            },
          ],
          httpResponse: "",

          successObject: "",
          successScript: `// data is http object response
// SetDynamicStore("key","value"); 
// GetDynamicStore("key");
`,

          failObject: "",
          failScript: `// data is http object response
// SetDynamicStore("key","value"); 
// GetDynamicStore("key");
`,

          isLoading: [],
          activeTab: "Body",
          step: 0,
          pOrder: 321,
        });
      }
    }
  };

  handleUpdateType = (_type) => {
    this.setState({
      type: _type,
    });
  };

  handleSend = async () => {
    const { isLoading } = this.state;
    const that = this;
    isLoading.push("send");
    this.setState({
      isLoading,
    });
    const { type, body, authorization, url } = this.state;

    let _url = this.findVariable(url);
    let _headers = this.findVariableInHeaders();
    let _body = this.findVariable(body);

    if (!_url.status) {
      let errorText = _url.result.map((x, k) => " " + x);
      Alert.error(errorText + i18n.t("NOT_FOUND_VARIABLES"));
      this.removeLoading("send");
      return;
    }

    let isURL = this.validURL(_url.result);
    isURL = true;
    if (!isURL) {
      Alert.error(i18n.t("ERROR_VALIDATE_URL"));
      this.removeLoading("send");
      return;
    }

    if (!_body.status) {
      let errorText = _body.result.map((x, k) => " " + x);
      Alert.error(errorText + i18n.t("NOT_FOUND_VARIABLES"));
      this.removeLoading("send");
      return;
    }

    if (!_headers.status) {
      let errorText = _headers.result.map((x, k) => " " + x);
      Alert.error(errorText + i18n.t("NOT_FOUND_VARIABLES"));
      this.removeLoading("send");
      return;
    }
    const _result = await CustomMakeReqestAndGetResponse(
      _url.result,
      type,
      _headers.result,
      _body.result
    );

    if (_result) {
      if (_result.status > 99 && _result.status < 200) {
        //Informational responses (100–199)
        Alert.info(
          _result.status + " - " + i18n.t("HTTPSTATUSCODE" + _result.status)
        );
      } else if (_result.status > 199 && _result.status < 300) {
        //Successful responses (200–299)
        Alert.success(
          _result.status + " - " + i18n.t("HTTPSTATUSCODE" + _result.status)
        );
      } else if (_result.status > 299 && _result.status < 400) {
        //Redirects (300–399)
        Alert.warning(
          _result.status + " - " + i18n.t("HTTPSTATUSCODE" + _result.status)
        );
      } else if (_result.status > 399 && _result.status < 500) {
        //Client errors (400–499)
        Alert.error(
          _result.status + " - " + i18n.t("HTTPSTATUSCODE" + _result.status)
        );
      } else if (_result.status > 499 && _result.status < 600) {
        //Server errors (500–599)
        Alert.error(
          _result.status + " - " + i18n.t("HTTPSTATUSCODE" + _result.status)
        );
      }
      let _resultData = _result.data;

      that.setState(
        {
          step: 1,
          httpResponse: _result,
        },
        () => {
          loader
            .init()
            .then((monaco) => {
              monaco.languages.typescript.javascriptDefaults.setExtraLibs({});
              monaco.languages.typescript.javascriptDefaults.setCompilerOptions(
                {
                  //target: monaco.languages.typescript.ScriptTarget.ES6,
                  allowNonTsExtensions: true,
                  noLib: true,
                  //  target: monaco.languages.typescript.ScriptTarget.ES2015,
                  lib: [],
                }
              );
              monaco.languages.typescript.javascriptDefaults.addExtraLib(
                ["var Toast =(message:string,type:toastTypes) =>{", "}"].join(
                  "\n"
                )
              );
              monaco.languages.typescript.javascriptDefaults.addExtraLib(
                [
                  'var toastTypes = {success:"success",warning:"warning",danger:"danger"}',
                ].join("\n")
              );
            })
            .catch((error) =>
              console.error(
                "An error occurred during initialization of Monaco: ",
                error
              )
            );
        }
      );
    }

    this.removeLoading("send");
  };
  handleSave = async () => {
    const { isLoading } = this.state;
    const that = this;
    isLoading.push("save");

    this.setState({
      isLoading,
    });
    this.removeLoading("save");
  };

  findVariable = (_string) => {
    const hasVariable = _string.match(/<<(.*?)>>/g);

    let currentURL = _string;
    let hasError = [];
    const that = this;
    let matches = [];

    if (hasVariable) {
      matches = hasVariable.map(function (val) {
        return val.replace(/</g, "").replace(/>/g, "");
      });
      matches.map((item) => {
        let _result = this.props.environments.dynamicValue.find(
          (x) => x.variableName == item
        );
        // _result.variableValue
        if (!_result) {
          hasError.push(item);
        } else {
          currentURL = currentURL.replace(
            "<<" + item + ">>",
            _result.variableValue
          );
        }
      });
    }

    if (hasError.length > 0) {
      // Kayıtlı olmayan değişkenler var
      return {
        status: false,
        result: hasError,
      };
    } else {
      return {
        status: true,
        result: currentURL,
      };
    }
  };
  findVariableInHeaders = () => {
    let currentHeaders = this.state.headers;
    let hasError = [];
    const that = this;

    if (currentHeaders.length > 0) {
      currentHeaders.map((x, i) => {
        Object.entries(x).forEach(([key, value]) => {
          let matches = [];
          const hasVariable = value.match(/<<(.*?)>>/g);
          if (hasVariable) {
            matches = hasVariable.map(function (val) {
              return val.replace(/</g, "").replace(/>/g, "");
            });
            matches.map((item) => {
              let _result = this.props.environments.dynamicValue.find(
                (x) => x.variableName == item
              );
              // _result.variableValue
              if (!_result) {
                hasError.push(item);
              } else {
                let _current = value.replace(
                  "<<" + item + ">>",
                  _result.variableValue
                );
                currentHeaders[i][key] = _current;
              }
            });
          }
        });
      });
    }

    if (hasError.length > 0) {
      // Kayıtlı olmayan değişkenler var
      return {
        status: false,
        result: hasError,
      };
    } else {
      let _obj = {};
      currentHeaders.map((x) => (_obj[x.variableName] = x.variableValue));
      return {
        status: true,
        result: _obj,
      };
    }
  };

  removeLoading = (_key) => {
    const that = this;
    let _new = that.state.isLoading;
    let _i = _new.findIndex((x) => x == _key);
    if (_i > -1) {
      _new.splice(_i, 1);
    }
    that.setState({
      isLoading: _new,
    });
  };

  handleSelect(activeKey) {
    this.setState({ activeTab: activeKey });
  }

  onChangeBody = (value, ev) => {
    // debugger
    this.setState({
      body: value,
      proBody: value,
    });
  };
  onChangeProBody = (value, ev) => {
    //   debugger
    this.setState({
      proBody: value,
    });
  };

  handleEditorDidMount = (editor, monaco) => {
    //debugger
    this.editor.current = editor;
    editor.getAction("editor.action.formatDocument").run();
    // monaco.updateOptions({ readOnly: true})
    this.prepareResponseObject(editor.getValue());
  };

  prepareResponseObject = async (_editor) => {
    let result = await this.generateObjectTypes(
      JSON.parse((" " + _editor).slice(1))
    );
    debugger;
    this.setState({
      successObject: JSON.stringify(result),
      failObject: JSON.stringify(result),
    });
  };

  generateObjectTypes = async (_data) => {
    let result = {};
    try {
      if (Array.isArray(_data)) {
        result = [];
        if (_data.length > 0) {
          let limit = 5;

          for (
            let index = 0;
            index < (_data.length > limit ? limit : _data.length);
            index++
          ) {
            result.push({});
            result[index] = {};
            //debugger
            if (
              Object.keys(_data)[index] === "0" &&
              typeof Object.values(_data)[index] != "object"
            ) {
              result[index] = null;
            } else {
              for (var key in _data[index]) {
                if (Array.isArray(_data[index][key])) {
                  result[index][key] = await this.generateObjectTypes(
                    _data[index][key]
                  );
                } else if (typeof _data[index][key] == "object") {
                  result[index][key] = await this.generateObjectTypes(
                    _data[index][key]
                  );
                } else {
                  result[index][key] = typeof _data[index][key];
                }
              }
            }
          }
          debugger;
          return result;
        } else {
          return [{}];
        }
      } else {
        for (var key in _data) {
          if (Array.isArray(_data[key])) {
            //debugger
            result[key] = await this.generateObjectTypes(_data[key]);
          } else if (typeof _data[key] == "object") {
            // debugger
            result[key] = await this.generateObjectTypes(_data[key]);
          } else {
            result[key] = typeof _data[key];
          }
        }
      }
    } catch (error) {
      debugger;
    }

    return result;
  };

  prepareObjectDeclare = (_value, _key) => {
    const that = this;
    var keys = [];
    const aa = JSON.parse(
      that.state.successObject ? that.state.successObject : ""
    );
    Object.keys(aa).forEach(function (key) {
      var value = aa[key];
      // if ()
      keys.push(key);
      // ...
    });
  };

  handleGenerateFailScriptEditor = (_where) => {
    const that = this;
    const _monaco = loader;
    _monaco.init().then((monaco) => {
      monaco.languages.typescript.javascriptDefaults.setExtraLibs({});

      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        allowNonTsExtensions: true,
        //  target: monaco.languages.typescript.ScriptTarget.ES2015,
        noLib: true,
        //  target: monaco.languages.typescript.ScriptTarget.ES2015,
        lib: [],
      });

      monaco.languages.typescript.javascriptDefaults.addExtraLib(
        ["var data =", that.state.successObject].join("\n")
      );
      // debugger

      monaco.languages.typescript.javascriptDefaults.addExtraLib(
        ["function GetDynamicStore(key){", "}"].join("\n")
      );
      monaco.languages.typescript.javascriptDefaults.addExtraLib(
        ["function SetDynamicStore(key,value){", "}"].join("\n")
      );
      monaco.languages.typescript.javascriptDefaults.addExtraLib(
        ["var Toast =(message:string,type:toastTypes) =>{", "}"].join(
          "\n"
        )
      );
      monaco.languages.typescript.javascriptDefaults.addExtraLib(
        [
          'var toastTypes = {success:"success",warning:"warning",danger:"danger"}',
        ].join("\n")
      );

      let _monaco = monaco.editor.create(document.getElementById(_where), {
        loading: <Loader />,
        //onChange: that.onChangeBody,
        value: that.state[_where],
        language: "javascript",
        height: "15vh",
      });

      _monaco.onDidChangeModelContent(function (e, v) {
        const BAD_WORD = "eval";
        const WARNING_MESSAGE = "";

        const value = _monaco.getValue();
        let fIndex = value.indexOf(BAD_WORD) - 1;
        let newValue = value;
        if (fIndex > 0) {
          //  debugger
          if (newValue.codePointAt(fIndex) < 33) {
            //Control characters
            // debugger
            newValue = newValue.replace(BAD_WORD, WARNING_MESSAGE);
          }
        }

        if (newValue !== that.state[_where]) {
          that.setState(
            {
              [_where]: newValue,
            },
            () => {
              _monaco.setValue(newValue);
            }
          );
        }
      });
    });
  };

  afterSaveCell = (oldValue, newValue, row, column) => {
    const that = this;
  };
  beforeSaveCell = (oldValue, newValue, row, column, done) => {
    if (oldValue !== newValue) {
      if (row.id === "") {
        const { headers } = this.state;

        let _variableName =
          column.dataField == "variableName" ? newValue : row.variableName;
        let _variableValue =
          column.dataField == "variableValue" ? newValue : row.variableValue;
        const newA = JSON.parse(JSON.stringify(headers));
        let _newArray = Object.values(newA);

        _newArray.push({
          id: this.state.pOrder + "",
          variableName: _variableName,
          variableValue: _variableValue,
        });

        this.setState({
          headers: _newArray,
          proHeaders: _newArray,
          pOrder: this.state.pOrder + 1,
        });
        //this.createEnviroment()
        done(false);
        return { async: true };
      } else {
        //this.updateEnviroment(row.id, column.dataField == "variableName" ? newValue : row.variableName, column.dataField == "variableValue" ? newValue : row.variableValue, true)
        const { headers } = this.state;
        const newA = JSON.parse(JSON.stringify(headers));
        let _newArray = Object.values(newA);

        let fIndex = _newArray.findIndex((x) => x.id == row.id);

        if (fIndex != -1) {
          _newArray[fIndex][column.dataField] = newValue;
          if (
            _newArray[fIndex].variableName.trim().length == 0 &&
            _newArray[fIndex].variableValue.trim().length == 0
          ) {
            _newArray.splice(fIndex, 1);
          }
          this.setState({
            headers: _newArray,
            proHeaders: _newArray,
            pOrder: this.state.pOrder + 1,
          });
        }
        done(false);
        return { async: true };
      }
    } else {
      done(false);
      return { async: true };
    }
  };
  beforeSaveCellPRO = (oldValue, newValue, row, column, done) => {
    if (oldValue !== newValue) {
      if (row.id === "") {
        const headers = this.state.proHeaders;

        let _variableName =
          column.dataField == "variableName" ? newValue : row.variableName;
        let _variableValue =
          column.dataField == "variableValue" ? newValue : row.variableValue;

        const newA = JSON.parse(JSON.stringify(headers));
        let _newArray = Object.values(newA);

        _newArray.push({
          id: this.state.pOrder + "",
          variableName: _variableName,
          variableValue: _variableValue,
        });

        this.setState({
          proHeaders: _newArray,
          pOrder: this.state.pOrder + 1,
        });
        //this.createEnviroment()
        done(false);
        return { async: true };
      } else {
        //this.updateEnviroment(row.id, column.dataField == "variableName" ? newValue : row.variableName, column.dataField == "variableValue" ? newValue : row.variableValue, true)
        const headers = this.state.proHeaders;
        const newA = JSON.parse(JSON.stringify(headers));
        let _newArray = Object.values(newA);

        let fIndex = _newArray.findIndex((x) => x.id == row.id);

        if (fIndex != -1) {
          _newArray[fIndex][column.dataField] = newValue;
          if (
            _newArray[fIndex].variableName.trim().length == 0 &&
            _newArray[fIndex].variableValue.trim().length == 0
          ) {
            _newArray.splice(fIndex, 1);
          }
          this.setState({
            proHeaders: _newArray,
            pOrder: this.state.pOrder + 1,
          });
        }
        done(false);
        return { async: true };
      }
    } else {
      done(false);
      return { async: true };
    }
  };
  generateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.id ? (
      cell === "" ? (
        formatExtraData === i18n.t("PLACEHOLDER_ENVIRONMENT_KEY") ? (
          <span className={"enviroment-new-add"}>
            {i18n.t("PLACEHOLDER_ENVIRONMENT_KEY")}
          </span>
        ) : (
          <span className={"enviroment-new-add"}>
            {i18n.t("PLACEHOLDER_ENVIRONMENT_VALUE")}
          </span>
        )
      ) : (
        <span className={"enviroment-cell"}>{cell}</span>
      )
    ) : (
      <span className={"enviroment-new-add"}>{formatExtraData}</span>
    );
  };

  handleKeyPress = (target) => {
    if (target.charCode == 13) {
      this.handleSend();
    }
  };

  onChangeStep = (nextStep) => {
    const that = this;
    this.setState(
      {
        step: nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep,
      },
      () => {
        switch (that.state.step) {
          case 2:
            that.handleGenerateFailScriptEditor("successScript");
            that.handleGenerateFailScriptEditor("failScript");
            break;
          default:
            break;
        }
      }
    );
  };
  onNext = () => this.onChangeStep(this.state.step + 1);
  onPrevious = () => this.onChangeStep(this.state.step - 1);

  validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  checkValidation = async () => {
    const {
      name,
      proUrl,
      proBody,
      proHeaders,
      url,
      type,
      body,
      headers,
      successObject,
      successScript,
      failObject,
      failScript,
    } = this.state;
    const { currentProjectId } = this.props;

    if (name.trim().length < 3) {
      Alert.error(i18n.t("ERROR_HTTPOBJECT_NAME_MIN3"));
      return false;
    }
    const testName = /^[a-zA-Z][a-zA-Z0-9$;]+$/;

    if (!testName.test(name)) {
      Alert.error(i18n.t("ERROR_HTTPOBJECT_NAME_NOT_AVAILABLE"));
      return false;
    }

    if (proUrl.trim().length === 0) {
      Alert.error(i18n.t("ERROR_HTTPOBJECT_PROURL"));
      return false;
    }

    return true;
  };
  onUpdate = async () => {
    const that = this;
    const {
      name,
      proUrl,
      proBody,
      proHeaders,
      url,
      type,
      body,
      headers,
      successObject,
      successScript,
      failObject,
      failScript,
    } = this.state;
    const { currentProjectId, selectedHttpObject } = this.props;

    if (!selectedHttpObject.id) {
      return;
    }

    let status = await this.checkValidation();
    //  return;
    if (!status) {
      return;
    }

    const { isLoading } = this.state;

    isLoading.push("creating");
    this.setState({
      isLoading,
    });

    HttpObjectService.UPDATE_HTTP_OBJECT(
      selectedHttpObject.id,
      currentProjectId,
      name.trim(),
      proHeaders.map((x) => ({
        Key: x.variableName,
        Value: x.variableValue,
        Description: x.variableValue,
      })),
      proBody,
      headers.map((x) => ({
        Key: x.variableName,
        Value: x.variableValue,
        Description: x.variableValue,
      })),
      body,
      successScript,
      failScript,
      successObject,
      failObject,
      proUrl,
      url,
      types[type]
    )
      .then((data) => {
        const _data = data.dynamicValue;

        let newArray = [...that.props.httpObjects.dynamicValue];
        const fIndex = newArray.findIndex(
          (x) => x.id === selectedHttpObject.id
        );

        newArray[fIndex] = _data;

        let newObj = {
          ...that.props.httpObjects,
          dynamicValue: newArray,
        };

        that.props.setHttpObjects(newObj);

        Alert.success(i18n.t("UPDATED_HTTPOBJECT_CREATED"));
        that.props.handleToggle();
      })
      .catch((err) => {
        debugger;
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        Emitter.emit("SHOW_WARNING_MODAL", data);
        that.removeLoading("creating");

        //Alert.error(data.dynamicValue)
      });
  };
  onCreate = async () => {
    /*
        CHECK NAME -- MİN3 TRİM
        CHECK URL IS NULL
        */
    const that = this;
    const {
      name,
      proUrl,
      proBody,
      proHeaders,
      url,
      type,
      body,
      headers,
      successObject,
      successScript,
      failObject,
      failScript,
    } = this.state;
    const { currentProjectId } = this.props;

    let status = await this.checkValidation();
    //  return;
    if (!status) {
      return;
    }

    const { isLoading } = this.state;

    isLoading.push("creating");
    this.setState({
      isLoading,
    });

    //        this.removeLoading("creating");

    HttpObjectService.CREATE_HTTP_OBJECT(
      currentProjectId,
      name.trim(),
      proHeaders.map((x) => ({
        Key: x.variableName,
        Value: x.variableValue,
        Description: x.variableValue,
      })),
      proBody,
      headers.map((x) => ({
        Key: x.variableName,
        Value: x.variableValue,
        Description: x.variableValue,
      })),
      body,
      successScript,
      failScript,
      successObject,
      failObject,
      proUrl,
      url,
      types[type]
    )
      .then((data) => {
        const _data = data.dynamicValue;

        let newArray = [...that.props.httpObjects.dynamicValue];
        newArray.push(_data);

        let newObj = {
          ...that.props.httpObjects,
          dynamicValue: newArray,
        };

        that.props.setHttpObjects(newObj);

        Alert.success(i18n.t("SUCCESS_HTTPOBJECT_CREATED"));
        that.props.handleToggle();
      })
      .catch((err) => {
        debugger;
        let data = {
          title: i18n.t("warning"),
          desc: ResponseStatusCode(err.statusCode),
        };
        Emitter.emit("SHOW_WARNING_MODAL", data);
        that.removeLoading("creating");

        //Alert.error(data.dynamicValue)
      });
  };

  render() {
    const { isActive, handleToggle, environments, selectedHttpObject } =
      this.props;
    const { isLoading, body, httpResponse, failScript, step } = this.state;

    const columns = [
      {
        dataField: "isActive",
        type: "bool",
        hidden: true,
        text: "Is Active",
      },
      {
        dataField: "variableName",
        text: i18n.t("PLACEHOLDER_ENVIRONMENT_KEY"), //'VARIABLE',
        formatter: this.generateFormatter,
        formatExtraData: i18n.t("PLACEHOLDER_ENVIRONMENT_KEY"),
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <CustomInput
            placeholder={i18n.t("PLACEHOLDER_ENVIRONMENT_KEY")}
            {...editorProps}
            value={value}
          />
        ),
        editorClasses: (cell, row, rowIndex, colIndex) =>
          "rs-input rs-input-sm",
      },
      {
        dataField: "variableValue",
        formatter: this.generateFormatter,
        formatExtraData: i18n.t("PLACEHOLDER_ENVIRONMENT_VALUE"),
        text: i18n.t("PLACEHOLDER_ENVIRONMENT_VALUE"), //'Value',
        editorRenderer: (
          editorProps,
          value,
          row,
          column,
          rowIndex,
          columnIndex
        ) => (
          <CustomInput
            placeholder={i18n.t("PLACEHOLDER_ENVIRONMENT_VALUE")}
            {...editorProps}
            value={value}
          />
        ),
        editorClasses: (cell, row, rowIndex, colIndex) =>
          "rs-input rs-input-sm",
      },
    ];

    return (
      <Translation>
        {(t) => (
          <Modal
            isOpen={isActive}
            size="xl"
            scrollable={true}
            className={"modal-dialog-centered httpobject-modal"}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="CheckProjectModal">
                {t("MODAL_TITLE_HTTP_OBJECT")}
                <p style={{ marginBottom: "0px" }}>
                  {step < 2
                    ? t("TITLE_HTTPOBJECT_DEV_MODE")
                    : t("TITLE_HTTPOBJECT_PRO_MODE")}
                </p>
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                disabled={isLoading.length > 0}
                onClick={() => {
                  handleToggle();
                }}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <Row>
                  <div className={"icons-modal-container"}>
                    {step < 2 ? (
                      <div style={{ width: "100%" }}>
                        <div className={"httpobject-inputbar"}>
                          <InputGroup>
                            <InputGroupAddon
                              className={"input-group-httpobject"}
                              addonType="prepend"
                            >
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  disabled={isLoading.length > 0 || step > 0}
                                  caret
                                  outline
                                  color="primary"
                                >
                                  {this.state.type.toUpperCase()}
                                </DropdownToggle>
                                <DropdownMenu>
                                  {this.state.type !== "post" && (
                                    <DropdownItem
                                      href="#pablo"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handleUpdateType("post");
                                      }}
                                    >
                                      POST
                                    </DropdownItem>
                                  )}
                                  {this.state.type !== "get" && (
                                    <DropdownItem
                                      href="#pablo"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handleUpdateType("get");
                                      }}
                                    >
                                      GET
                                    </DropdownItem>
                                  )}
                                  {this.state.type !== "delete" && (
                                    <DropdownItem
                                      href="#pablo"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handleUpdateType("delete");
                                      }}
                                    >
                                      DELETE
                                    </DropdownItem>
                                  )}
                                  {this.state.type !== "put" && (
                                    <DropdownItem
                                      href="#pablo"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handleUpdateType("put");
                                      }}
                                    >
                                      PUT
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </InputGroupAddon>
                            <Input
                              value={this.state.url}
                              onKeyPress={this.handleKeyPress}
                              onChange={(e) => {
                                this.setState({
                                  url: e.target.value,
                                  proUrl: e.target.value,
                                });
                              }}
                              disabled={isLoading.length > 0 || step > 0}
                              placeholder={t("PLACEHOLDER_REQUEST_URL")}
                              type="text"
                            />
                            <InputGroupAddon
                              className={"input-group-httpobject"}
                              addonType="append"
                            >
                              <Button
                                onClick={this.handleSend}
                                disabled={isLoading.length > 0 || step > 0}
                                color="primary"
                                outline
                                type="button"
                              >
                                {t("BUTTON_SEND")}
                                {isLoading.findIndex((x) => x == "send") !==
                                  -1 && (
                                  <Loader
                                    size="xs"
                                    className={"input-group-httpobject-loader"}
                                  />
                                )}
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                          <div className={"httpobject-show-varables"}>
                            <Whisper
                              trigger="hover"
                              enterable
                              placement={"auto"}
                              speaker={
                                <Popover
                                  title={t("MODAL_TITLE_MANAGE_ENVIRONMENTS")}
                                  style={{ padding: "1rem " }}
                                >
                                  <div
                                    className={
                                      "httpobject-headers-table httpobject-show-varables-modal"
                                    }
                                  >
                                    <BootstrapTable
                                      style={{ width: "100%" }}
                                      keyField="id"
                                      data={[...environments.dynamicValue]}
                                      columns={columns}
                                    />
                                    {environments.dynamicValue.length === 0 && (
                                      <div
                                        style={{ textAlign: "center" }}
                                        className={"ho-noresult"}
                                      >
                                        {t("MESSAGE_HTTPOBJECT_NOT_FOUNT")}
                                      </div>
                                    )}
                                  </div>
                                </Popover>
                              }
                            >
                              <Icon icon="eye" className={"clickable"} />
                            </Whisper>
                          </div>
                        </div>
                        <hr />
                      </div>
                    ) : (
                      step === 3 && (
                        <div className={"httpobject-production"}>
                          <Form>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label for="httpObjectName" sm={12}>
                                    {t("TITLE_HTTPOBJECT_NAME")}
                                  </Label>
                                  <Col sm={12}>
                                    <Input
                                      type="text"
                                      name="httpObjectName"
                                      id="httpObjectName"
                                      placeholder={t(
                                        "PLACEHOLDER_HTTPOBJECT_NAME"
                                      )}
                                      bsSize="sm"
                                      value={this.state.name}
                                      onChange={(e) => {
                                        this.setState({
                                          name: e.target.value,
                                        });
                                      }}
                                    />
                                  </Col>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="requestUrl" sm={12} size="md">
                                    {t("TITLE_HTTPOBJECT_REQUEST_URL")}
                                  </Label>
                                  <Col sm={12}>
                                    <Input
                                      type="url"
                                      name="requestUrl"
                                      id="requestUrl"
                                      placeholder={t("PLACEHOLDER_REQUEST_URL")}
                                      bsSize="sm"
                                      value={this.state.proUrl}
                                      onChange={(e) => {
                                        this.setState({
                                          proUrl: e.target.value,
                                        });
                                      }}
                                    />
                                  </Col>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                          <hr />
                        </div>
                      )
                    )}

                    <div className={"httpObject-Body"}>
                      <Steps current={this.state.step} small>
                        <Steps.Item
                          className={0 < this.state.step ? "clickable" : ""}
                          onClick={() => {
                            this.setState({
                              step: 0 < this.state.step ? 0 : this.state.step,
                            });
                          }}
                        />
                        <Steps.Item
                          className={1 < this.state.step ? "clickable" : ""}
                          onClick={() => {
                            this.setState({
                              step: 1 < this.state.step ? 1 : this.state.step,
                            });
                          }}
                        />
                        <Steps.Item
                          className={2 < this.state.step ? "clickable" : ""}
                          onClick={() => {
                            this.setState({
                              step: 2 < this.state.step ? 2 : this.state.step,
                            });
                          }}
                        />
                        <Steps.Item
                          className={3 < this.state.step ? "clickable" : ""}
                          onClick={() => {
                            this.setState({
                              step: 3 < this.state.step ? 3 : this.state.step,
                            });
                          }}
                        />
                      </Steps>
                      {httpResponse && (
                        <div className={"httpobject-status"}>
                          <span className={"httpobject-status-part"}>
                            <Whisper
                              trigger="hover"
                              placement={"auto"}
                              speaker={
                                <Popover
                                  title={t("TITLE_RESPONSE")}
                                  style={{ padding: "1rem " }}
                                >
                                  <span className={"httpobject-status-popover"}>
                                    {t("TITLE_URL")}:{" "}
                                    <span>
                                      {httpResponse.hasOwnProperty("config")
                                        ? httpResponse.config.url
                                        : httpResponse.hasOwnProperty(
                                            "__sentry_xhr__"
                                          )
                                        ? httpResponse.__sentry_xhr__.url
                                        : ""}
                                    </span>{" "}
                                  </span>
                                  <span className={"httpobject-status-popover"}>
                                    {t("TITLE_METHOD")}:{" "}
                                    <span>
                                      {httpResponse.hasOwnProperty("config")
                                        ? httpResponse.config.method
                                        : httpResponse.hasOwnProperty(
                                            "__sentry_xhr__"
                                          )
                                        ? httpResponse.__sentry_xhr__.method
                                        : ""}
                                    </span>{" "}
                                  </span>
                                </Popover>
                              }
                            >
                              <Icon icon="globe" />
                            </Whisper>
                          </span>
                          <span className={"httpobject-status-part"}>
                            {t("TITLE_STATUS")}:
                            {
                              <span
                                className={
                                  httpResponse.status < 300
                                    ? httpResponse.status > 199
                                      ? "success-color"
                                      : "warring-color"
                                    : "fail-color"
                                }
                              >
                                {httpResponse.status}
                              </span>
                            }
                          </span>
                        </div>
                      )}
                      <div>
                        {this.state.step === 0 && (
                          <>
                            <Nav
                              appearance="subtle"
                              activeKey={this.state.activeTab}
                              onSelect={this.handleSelect}
                              style={{ margin: "10px 0" }}
                            >
                              <Nav.Item eventKey="Headers">
                                {t("TITLE_HEADERS")}
                              </Nav.Item>
                              <Nav.Item eventKey="Body">
                                {t("TITLE_BODY")}
                              </Nav.Item>
                            </Nav>
                            <div style={{ padding: "0px 15px" }}>
                              <>
                                {this.state.activeTab == "Headers" && (
                                  <div className={"httpobject-headers-table"}>
                                    <BootstrapTable
                                      style={{
                                        width: "100%",
                                      }}
                                      keyField="id"
                                      data={[
                                        ...this.state.headers,
                                        {
                                          id: "",
                                          isActive: true,
                                          variableName: "",
                                          variableValue: "",
                                        },
                                      ]}
                                      columns={columns}
                                      cellEdit={cellEditFactory({
                                        mode: "click",
                                        blurToSave: true,
                                        afterSaveCell: this.afterSaveCell,
                                        beforeSaveCell: this.beforeSaveCell,
                                      })}
                                    />
                                  </div>
                                )}
                                {this.state.activeTab == "Body" && (
                                  <div
                                    className={"monaco-wrapper"}
                                    style={{
                                      border: "1px solid #dee2e6",
                                      height: "100%",
                                    }}
                                  >
                                    {
                                      <Editor
                                        language="json"
                                        theme="light"
                                        loading={<Loader />}
                                        onChange={this.onChangeBody}
                                        value={body}
                                        height="25vh"
                                        options={{
                                          automaticLayout: true,
                                          scrollBeyondLastLine: false,
                                        }}
                                      />
                                    }
                                  </div>
                                )}
                              </>
                            </div>
                          </>
                        )}
                        {this.state.step === 1 && (
                          <>
                            <div style={{ padding: "0px 15px" }}>
                              {httpResponse ? (
                                httpResponse.hasOwnProperty("data") ? (
                                  <>
                                    <div className={"ho-response-div"}>
                                      <Editor
                                        language="json"
                                        theme="light"
                                        loading={<Loader />}
                                        // onChange={()=>{ return JSON.stringify(httpResponse.data)}}
                                        value={JSON.stringify(
                                          httpResponse.data
                                        )}
                                        height="25vh"
                                        onMount={this.handleEditorDidMount}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className={"httpobject-response"}>
                                    <i className="fas fa-rocket ilt-in-fwd-bl "></i>
                                    <p>{t("ERROR_HTTP_OBJECT_COULD_NOT")}</p>
                                    {httpResponse && (
                                      <Message
                                        type={
                                          httpResponse.status < 300
                                            ? httpResponse.status > 199
                                              ? "success"
                                              : "warning"
                                            : "error"
                                        }
                                        description={t(
                                          "HTTPSTATUSCODE" + httpResponse.status
                                        )}
                                      />
                                    )}
                                  </div>
                                )
                              ) : (
                                <div className={"httpobject-response"}>
                                  <i className="fas fa-rocket ilt-in-fwd-bl "></i>
                                  <p>{t("ERROR_HTTP_OBJECT_NO_RESPONSE")}</p>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                        {this.state.step === 2 && (
                          <Row>
                            <Col sm={12}>
                              <p className={"script-title"}>
                                {t("TITLE_HTTPOBJECT_SUCCESS_SCRIPT")}
                                <Whisper
                                  trigger="hover"
                                  //enterable
                                  placement={"bottom"}
                                  speaker={
                                    <Tooltip>
                                      {t("DESC_HTTPOBJECT_SUCCESS_SCRIPT")}
                                    </Tooltip>
                                  }
                                >
                                  <Icon
                                    className={"script-title-info"}
                                    icon={"info"}
                                  />
                                </Whisper>
                              </p>
                              <span className={"script-text script-text-blue"}>
                                {"function "}
                                <span
                                  className={"script-text script-text-black"}
                                >
                                  {"("}
                                </span>
                                <Whisper
                                  trigger="hover"
                                  enterable
                                  placement={"auto"}
                                  speaker={
                                    <Popover
                                      title={"data"}
                                      style={{
                                        padding: "1rem",
                                        maxWidth: "50vh",
                                        textAlign: "center",
                                      }}
                                    >
                                      <span>
                                        {t("TOOL_TIP_SCRIPT_HTTPOBJECT_DATA")}
                                      </span>
                                    </Popover>
                                  }
                                >
                                  <span
                                    className={"script-text script-text-grey"}
                                  >
                                    {"data"}
                                  </span>
                                </Whisper>
                                <span
                                  className={"script-text script-text-black"}
                                >
                                  {") {"}
                                </span>
                              </span>
                              <div
                                id={"successScript"}
                                style={{
                                  height: "15vh",
                                  border: "1px solid #dee2e6",
                                }}
                              ></div>
                              <span className={"script-text script-text-black"}>
                                {" }"}
                              </span>
                            </Col>
                            <Col sm={12}>
                              <p className={"script-title"}>
                                {t("TITLE_HTTPOBJECT_FAIL_SCRIPT")}
                                <Whisper
                                  trigger="hover"
                                  //enterable
                                  placement={"autoVertical"}
                                  speaker={
                                    <Tooltip>
                                      {t("DESC_HTTPOBJECT_FAIL_SCRIPT")}
                                    </Tooltip>
                                  }
                                >
                                  <Icon
                                    className={"script-title-info"}
                                    icon={"info"}
                                  />
                                </Whisper>
                              </p>

                              <span className={"script-text script-text-blue"}>
                                {"function "}
                                <span
                                  className={"script-text script-text-black"}
                                >
                                  {"("}
                                </span>
                                <Whisper
                                  trigger="hover"
                                  enterable
                                  placement={"auto"}
                                  speaker={
                                    <Popover
                                      title={"data"}
                                      style={{
                                        padding: "1rem",
                                        maxWidth: "50vh",
                                        textAlign: "center",
                                      }}
                                    >
                                      <span>
                                        {t("TOOL_TIP_SCRIPT_HTTPOBJECT_DATA")}
                                      </span>
                                    </Popover>
                                  }
                                >
                                  <span
                                    className={"script-text script-text-grey"}
                                  >
                                    {"data"}
                                  </span>
                                </Whisper>
                                <span
                                  className={"script-text script-text-black"}
                                >
                                  {") {"}
                                </span>
                              </span>
                              <div
                                id={"failScript"}
                                style={{
                                  height: "15vh",
                                  border: "1px solid #dee2e6",
                                }}
                              ></div>
                              <span className={"script-text script-text-black"}>
                                {" }"}
                              </span>
                            </Col>
                          </Row>
                        )}
                        {step === 3 && (
                          <>
                            <Nav
                              appearance="subtle"
                              activeKey={this.state.activeTab}
                              onSelect={this.handleSelect}
                              style={{ margin: "10px 0" }}
                            >
                              <Nav.Item eventKey="Headers">
                                {t("TITLE_HEADERS")}
                              </Nav.Item>
                              <Nav.Item eventKey="Body">
                                {t("TITLE_BODY")}
                              </Nav.Item>
                            </Nav>
                            <div style={{ padding: "0px 15px" }}>
                              <>
                                {this.state.activeTab == "Headers" && (
                                  <div className={"httpobject-headers-table"}>
                                    <BootstrapTable
                                      style={{
                                        width: "100%",
                                      }}
                                      keyField="id"
                                      data={[
                                        ...this.state.proHeaders,
                                        {
                                          id: "",
                                          isActive: true,
                                          variableName: "",
                                          variableValue: "",
                                        },
                                      ]}
                                      columns={columns}
                                      cellEdit={cellEditFactory({
                                        mode: "click",
                                        blurToSave: true,
                                        afterSaveCell: this.afterSaveCell,
                                        beforeSaveCell: this.beforeSaveCellPRO,
                                      })}
                                    />
                                  </div>
                                )}
                                {this.state.activeTab == "Body" && (
                                  <div
                                    className={"monaco-wrapper"}
                                    style={{
                                      border: "1px solid #dee2e6",
                                      height: "100%",
                                    }}
                                  >
                                    <Editor
                                      language="json"
                                      theme="light"
                                      loading={<Loader />}
                                      onChange={this.onChangeProBody}
                                      value={this.state.proBody}
                                      height="25vh"
                                      options={{
                                        automaticLayout: true,
                                        scrollBeyondLastLine: false,
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            </div>
                          </>
                        )}
                      </div>
                      {step !== 0 && (
                        <ButtonGroup
                          className={"httpobject-status"}
                          style={{ paddingTop: "10px" }}
                        >
                          <Button
                            size="sm"
                            onClick={this.onPrevious}
                            disabled={this.state.step === 0}
                          >
                            {t("BUTTON_PREVIOUS")}
                          </Button>

                          {step == 3 ? (
                            selectedHttpObject ? (
                              <Button
                                color={"primary"}
                                size="sm"
                                className={"custom-fade-in"}
                                onClick={this.onUpdate}
                              >
                                {t("BUTTON_UPDATE")}
                              </Button>
                            ) : (
                              <Button
                                color={"primary"}
                                size="sm"
                                className={"custom-fade-in"}
                                onClick={this.onCreate}
                              >
                                {t("BUTTON_CREATE")}
                              </Button>
                            )
                          ) : (
                            <Button
                              size="sm"
                              onClick={this.onNext}
                              disabled={
                                httpResponse &&
                                !(
                                  httpResponse.status > 199 &&
                                  httpResponse.status < 300
                                )
                              }
                            >
                              {t("BUTTON_NEXT")}
                            </Button>
                          )}
                        </ButtonGroup>
                      )}
                    </div>
                  </div>
                </Row>
              </div>

              {isLoading.findIndex((x) => x == "creating") > -1 && (
                <Loader backdrop vertical />
              )}
            </div>
          </Modal>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { componentReducer, editorReducer, currentstatesReducer } = state;
  const { component } = componentReducer;
  const { environments, httpObjects, currentProjectId } = currentstatesReducer;

  return {
    component,
    environments,
    httpObjects,
    currentProjectId,
  };
};
const mapDispatchToProps = {
  //  onUpdateComponent: updateComponent,
  setHttpObjects,
};
export default connect(mapStateToProps, mapDispatchToProps)(HttpObjectModal);

class CustomInput extends React.Component {
  static propTypes = {
    value: PropTypes.number,
    onUpdate: PropTypes.func.isRequired,
  };
  static defaultProps = {
    value: 0,
  };
  getValue() {
    return this.edittext.value;
  }
  render() {
    const { value, onUpdate, ...rest } = this.props;
    // debugger
    return (
      <input
        {...rest}
        className={"rs-input rs-input-sm"}
        key="edittext"
        ref={(node) => (this.edittext = node)}
      />
    );
  }
}
