import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { updateComponent,updateList } from '_actions';
import { findObjUpdate } from 'assets/util';

import { ButtonGroup, Button, UncontrolledTooltip } from "reactstrap";

import { Cover, Contain } from '../../../assets/icons/size'

class ImageSize extends Component {
    constructor(props) {
        super(props)
        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style,value } = object.props;
        this.state = {
            value: component && (style["backgroundSize"] == undefined ? "unset" : style["backgroundSize"])
        }
    }

    handleChangeSize = (event) => {
        const { currentTarget } = event;
        const { name } = currentTarget;

        this.setState({
            value: name,
        }, () => {
             let _clone = Object.assign({}, this.props.component);
             if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.style = {
                    ..._clone.object.components[0].object.props.style,
                    backgroundSize: this.state.value,
                }
            } else {
                _clone.components[0].object.props.style = {
                    ..._clone.components[0].object.props.style,
                    backgroundSize: this.state.value,
                }
            }

           
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }

    componentDidUpdate = (prevProps) => {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        const { style,value } = object.props;

        if (prevProps.component != component) {
            this.setState({
                value: style["backgroundSize"] == undefined ? "unset" : style["backgroundSize"],
            })
        }
    }

    render() {
        return (
            <Translation>
                {t => (
                    <div className="bg-secondary">
                        <ButtonGroup>
                            <Button  id="tooltip-image-size-cover" name={"cover"} onClick={this.handleChangeSize} className={"button-group-button " + (this.state.value == "cover" ? "active" : "")} color="secondary" type="button">
                                <Cover className={"button-group-icons"} />
                            </Button>
                            <UncontrolledTooltip
                                delay={1}
                                placement="bottom"
                                target="tooltip-image-size-cover"
                            >
                                Cover
                            </UncontrolledTooltip>
                            <Button  id="tooltip-image-size-contain" name={"contain"} onClick={this.handleChangeSize} className={"button-group-button " + (this.state.value == "contain" ? "active" : "")} color="secondary" type="button">
                                <Contain className={"button-group-icons"} />
                            </Button>
                            <UncontrolledTooltip
                                delay={1}
                                placement="bottom"
                                target="tooltip-image-size-contain"
                            >
                                Contain
                            </UncontrolledTooltip>

                        </ButtonGroup>
                    </div>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    const { componentReducer,editorReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        component,
        editor
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
}
export default connect(mapStateToProps, mapDispatchToProps)(ImageSize)
