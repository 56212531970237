var crypto = require('crypto');
var key = 'f4gb68a-26f-77c8-n06a-6a99fcgf0c'; //replace with your key
var iv = 'ty-9345-g9fo874g'; //replace with your IV


exports.guid = function () {
    function s4() {
        return Math.floor(1 + Math.random() * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + s4();
}

function EncryptAES(Data) {
    var cipher = crypto.createCipheriv('aes256', key, iv)
    var crypted = cipher.update(Data, 'utf8', 'base64')
    crypted += cipher.final('base64');
    return crypted;
}

function EnCryptMD5(data) {
    return crypto.createHash('md5').update(data).digest("hex").toLowerCase();
}

function EnCryptForHandShake(data) {
    return EnCryptMD5(EncryptAES(data));
}

exports.EncryptAES = EncryptAES
exports.EnCryptMD5 = EnCryptMD5
exports.EnCryptForHandShake = EnCryptForHandShake
