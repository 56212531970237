import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from 'i18n';

import {
    FormGroup,
    Form,

} from "reactstrap";

import { updateComponent, updateList } from '_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';

import {
    PropertyTitle,
} from "../Core/";
import StaticCMSSettings from "../Properties/StaticCMSSettings"
import StaticTextValue from '../Core/StaticTextValue'


import { SelectPicker, Dropdown, TagPicker } from 'rsuite';

const defautValueOptions = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: true,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: false,
    }
]

const defautKeyboardOptions = [
    {
        label: i18n.t("KEYBOARD_TYPE_DEFAULT"),
        value: "default",
    },
    {
        label: i18n.t("KEYBOARD_TYPE_NUMERIC"),
        value: "numeric",
    },
    {
        label: i18n.t("KEYBOARD_TYPE_EMAIL"),
        value: "email-address",
    },
    {
        label: i18n.t("KEYBOARD_TYPE_PHONE"),
        value: "phone-pad",
    }
]

const defautReturnKeyTypeOptions = [
    {
        label: i18n.t("KEYBOARD_RETURN_KEY_TYPE_DONE"),
        value: "done",
    },
    {
        label: i18n.t("KEYBOARD_RETURN_KEY_TYPE_GO"),
        value: "go",
    },
    {
        label: i18n.t("KEYBOARD_RETURN_KEY_TYPE_SEARCH"),
        value: "search",
    },
    {
        label: i18n.t("KEYBOARD_RETURN_KEY_TYPE_NEXT"),
        value: "next",
    },
    {
        label: i18n.t("KEYBOARD_RETURN_KEY_TYPE_SEND"),
        value: "send",
    }
]

class TextInputSettings extends Component {
    constructor(props) {
        super(props)
        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        this.state = {
            secureTextEntry: component && object.props.hasOwnProperty("secureTextEntry") ? (defautValueOptions.find(x => x.value === object.props.secureTextEntry).value) : defautValueOptions[1].value,
            keyboardType: component && (object.props.hasOwnProperty("keyboardType") ? object.props.keyboardType : defautKeyboardOptions[0].value),
            returnKeyType: component && (object.props.hasOwnProperty("returnKeyType") ? object.props.returnKeyType : defautReturnKeyTypeOptions[0].value),
        }

    }

    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const style = object.props[this.props.dataPath ? this.props.dataPath : "style"];

        if (prevProps.component != component) {
            this.setState({
                secureTextEntry: component && object.props.hasOwnProperty("secureTextEntry") ? (defautValueOptions.find(x => x.value === object.props.secureTextEntry).value) : defautValueOptions[1].value,
                keyboardType: component && (object.props.hasOwnProperty("keyboardType") ? object.props.keyboardType : defautKeyboardOptions[0].value),
                returnKeyType: component && (object.props.hasOwnProperty("returnKeyType") ? object.props.returnKeyType : defautReturnKeyTypeOptions[0].value),

            })
        }
    }

    handleUpdateValue = (_data, _name) => {

        this.setState({
            [_name]: _data.value,
        }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";
            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
            } else {
                _clone.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

    handleSetKeyboardType = (_value) => {

        const that = this;
        this.setState({
            keyboardType: _value,
        }, () => {
            const _style = this.props.hasOwnProperty("dataPath") ? this.props.dataPath : "style";

            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.keyboardType = this.state.keyboardType
            } else {
                _clone.components[0].object.props.keyboardType = this.state.keyboardType
            }

            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }

    handleSetReturnKeyType = (_value) => {

        const that = this;
        this.setState({
            returnKeyType: _value,
        }, () => {
            const _style = this.props.hasOwnProperty("dataPath") ? this.props.dataPath : "style";

            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.returnKeyType = this.state.returnKeyType
            } else {
                _clone.components[0].object.props.returnKeyType = this.state.returnKeyType
            }

            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }


    render() {
        const { component, globalProps } = this.props;
        let _component;
        if (component.hasOwnProperty("componentOwner"))
            _component = component.object.components[0];
        else
            _component = component.components[0]


        //  secureTextEntry
        return (
            <Translation>
                {t => (
                    <Form>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_PLACEHOLDER")}
                                </label>

                            </div>
                            <StaticCMSSettings propName={"placeholder"} />

                        </FormGroup>
                        {
                            _component &&
                            _component.object.type !== "multiline" &&
                            <>
                                <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}>
                                        <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_TEXT_INPUT_ISPASSWORD")}
                                        </label>
                                        {
                                            this.state["secureTextEntry"] !== null &&
                                            <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["secureTextEntry"]).label}>
                                                {
                                                    defautValueOptions.map((x, key) => (
                                                        x.value === this.state["secureTextEntry"] ?
                                                            "" :
                                                            <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "secureTextEntry")}>{x.label}</Dropdown.Item>
                                                    ))
                                                }
                                            </Dropdown>
                                        }

                                    </div>
                                </FormGroup>
                                <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}>
                                        <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_TEXT_INPUT_MASK")}
                                        </label>

                                    </div>
                                    <StaticTextValue propName={"mask"} />
                                </FormGroup>
                                <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}>
                                        <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_TEXT_INPUT_KEYBOARD")}
                                        </label>
                                    </div>
                                    <SelectPicker
                                        onSelect={(value, item, event) => {
                                            this.handleSetKeyboardType(value)
                                        }}
                                        cleanable={false}
                                        onClean={() => { this.handleSetKeyboardType(null) }}
                                        style={{
                                            width: "100%",
                                            height: "45px",
                                        }}
                                        defaultValue={this.state.keyboardType}
                                        maxHeight={160}
                                        placeholder={t("PROPERTY_TEXT_INPUT_KEYBOARD")}
                                        data={defautKeyboardOptions}
                                    />

                                </FormGroup>

                                 <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}>
                                        <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_TEXT_RETURN_KEY")}
                                        </label>
                                    </div>
                                    <SelectPicker
                                        onSelect={(value, item, event) => {
                                            this.handleSetReturnKeyType(value)
                                        }}
                                        cleanable={false}
                                        onClean={() => { this.handleSetReturnKeyType(null) }}
                                        style={{
                                            width: "100%",
                                            height: "45px",
                                        }}
                                        defaultValue={this.state.returnKeyType}
                                        maxHeight={160}
                                        placeholder={t("PROPERTY_TEXT_RETURN_KEY")}
                                        data={defautReturnKeyTypeOptions}
                                    />

                                </FormGroup>
                            </>
                        }





                    </Form>
                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, globalpropsReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;

    const { globalProps } = globalpropsReducer;

    return {
        editor,
        component,
        globalProps
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,

}
export default connect(mapStateToProps, mapDispatchToProps)(TextInputSettings)
