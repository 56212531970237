import React from "react";
import { Translation } from "react-i18next";
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { connect } from 'react-redux'
import { updateCurrentPage, removeComponent, setPage, setPages, SocketOperationFunctions, setComponentNames } from '_actions';
import Select2 from "react-select2-wrapper";

import { Alert, Tooltip, Whisper, Dropdown, ButtonToolbar, Icon,SelectPicker } from 'rsuite';

import { pagesService, DynamicFunctionService } from "_services";


// reactstrap components
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { ResponseStatusCode } from "_helpers";

import NewPage from "../Modals/NewPage";
import PageBusy from "../Modals/PageBusy";
import ExportModal from "../Modals/ExportModal";

class MainHeader extends React.Component {

  constructor(props) {
    super(props)
    this.refPageSelector = React.createRef();

    this.state = {
      newPageModal: false,
      editor: props.editor,
      pageBusy: false,
      exportModal: false,
    }
  }
  handleNewPage = () => {
    if (this.checkHasProject())
      this.setState({
        newPageModal: !this.state.newPageModal,
      })
  }

  handleExportModal = () => {
    if (this.checkHasProject())
      this.setState({
        exportModal: !this.state.exportModal,
      })
  }

  handlePageBusy = () => {
    this.setState({
      pageBusy: !this.state.pageBusy,
    })
  }

  setPageBusy = (_status) => {
    this.setState({
      pageBusy: _status,
    })
  }


  checkHasProject = () => {
    if (this.props.currentProjectId) {
      return true;
    }
    else {
      let data = { title: i18n.t("warning"), desc: i18n.t("HAS_PROJECT_ERROR") };
      Emitter.emit("SHOW_WARNING_MODAL", data);
      return false;
    }
  }


  handleChangeCurrentPage = (id) => {
   //let _item = this.props.pages.find(x=>x.id == id);
    let _c = this.props.currentPage;

    if (id != this.props.currentPage) {
      this.getPageById(id)
    }

  }

  getFunctions = (_type = 0) => {
    DynamicFunctionService.GET_EVENTS(_type)
      .then(data => {
        debugger
        //setDynamicFuncs(data.dynamicValue);
      }).catch(err => {
        debugger;
        Alert.error("Error code : " + err.statusCode);

      })
  }


  getPageById = (_pageId) => {
    const { setPage, socket, currentProjectId, setComponentNames } = this.props;
    const projectId = localStorage.ProjectId;
    SocketOperationFunctions.SetCurrentPage(socket, projectId, _pageId);
    pagesService.GetPageByid(_pageId, this.props.currentProjectId).then(data => {
      setPage(data.dynamicValue);
      setComponentNames(data.dynamicValue.componentNames)
      SocketOperationFunctions.GetExistAutoSaved(socket, currentProjectId, data.dynamicValue.id, data.dynamicValue.buildNumber + "");

      // this.props.updateCurrentPage(_item.id);
      this.props.clearHistory(); // Sayfa değişimi yapıldığında geçmiş silinir.
      this.props.removeComponent();

    }).catch(err => {
      let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
      Emitter.emit("SHOW_WARNING_MODAL", data);
      this.getPagesThumb();

    });
  }

  handleClearAutoSave = () => {
    const { socket, page } = this.props;
    //debugger
    const projectId = localStorage.ProjectId;
    SocketOperationFunctions.ClearAutoSaved(socket, projectId, page.id, "1.0");

    console.info("ClearAutoSave")

  }

  getPagesThumb = () => {
    //getpagesall
    const { setPages } = this.props;
    pagesService.GetPagesThumb(this.props.currentProjectId).then(data => {
      setPages(data.dynamicValue);
      this.getPageById(data.dynamicValue[0].id)
    }).catch(err => {
      let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
      Emitter.emit("SHOW_WARNING_MODAL", data);

    });
  }

  setPageProperties = () => {
    const { page } = this.props;

    pagesService.EditPageProperties(page.id, this.props.currentProjectId, page.pageProperties).then(data => {
      Alert.success(i18n.t("SUCCESS_SAVED"))

      this.handleClearAutoSave();

    }).catch(err => {
      debugger
      let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
      Emitter.emit("SHOW_WARNING_MODAL", data);

    });
  }

  
  getObjectsID = async (obj, key, secondKey, _a) => {
    let _array = _a;
    var objects = [];
    for (var i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      if (typeof obj[i] == 'object') {
        objects = objects.concat(await this.getObjectsID(obj[i], key, secondKey, _array));
      } else if (i == secondKey) {
        _array.push(obj[key])
      }
    }
    return _array;
  }

 //nestedValue.components[0].object.type === valToFind

 
 findObjectByLabel =(_list,_comValue)=>{
  const fComp = _list.find(x=>x.components[0].object.type ===_comValue);
  return fComp;

 }

    
  getUpdateEditorFields = async (obj, key, secondKey, _a) => {
    const { page, componentNames,componentList } = this.props;
    let _array = _a;
    var objects = [];
    let _comList = []; // All component list


    componentList.forEach(element => {
      _comList=_comList.concat(element.data);
    });
 
    for (var i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      if (typeof obj[i] == 'object') {
        objects = objects.concat(await this.getUpdateEditorFields(obj[i], key, secondKey, _array));
       } else if (i == secondKey) {
          
         if(obj.hasOwnProperty("_id")){
            
          const _cName = await componentNames.find(x => x.relComponentId === obj.id);
          const _cNewField = {
            pageId: page.id,
            pageName: page.pageName,
            componentName: _cName ? _cName.componentName : null
          }
          if(obj.hasOwnProperty("componentOwner")){

            let refComp = await this.findObjectByLabel(_comList, obj.object.components[0].object.type);
            if(refComp ){
              obj.relEventIds = refComp.relEventIds;
              obj.eventLists = refComp.eventLists;
            }
            obj.object.components[0].object = {
              ...obj.object.components[0].object,
              ..._cNewField
            };

          }else{
            let refComp = await  this.findObjectByLabel(_comList,obj.components[0].object.type);
            if(refComp){
              obj.relEventIds = refComp.relEventIds;
              obj.eventLists = refComp.eventLists;
            }
          
            obj.components[0].object = {
              ...obj.components[0].object,
              ..._cNewField
            };
          }
        }
        _array.push(obj[key])
      }
    }
    return _array;
  }
//relEventIds
//eventLists
  setPageDetail = async () => {
    const { editor, page ,componentList} = this.props;
    const that = this;

    
    let ids = await this.getUpdateEditorFields([...editor], "id", "componentName", []);
    //let test_result = await this.findNestedObjAndChange([...editor]);
 

    pagesService.EditPageDetail(page.id, this.props.currentProjectId, editor, ids).then(data => {
      that.setPageProperties();

    }).catch(err => {
      debugger
      let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
      Emitter.emit("SHOW_WARNING_MODAL", data);

    });
  }

  handleSavePage = () => {
    this.setPageDetail();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.pageStatus.status != this.props.pageStatus.status) {
      this.setPageBusy(!this.props.pageStatus.status);
    }

  }

  componentDidMount() {
    Emitter.on("CREATE_NEW_PAGE", (newValue) => this.handleNewPage());
    Emitter.on("EMITTER_SET_PAGE_BUSY", (newValue) => this.setPageBusy(true));


  }
  componentWillUnmount() {
    Emitter.off("CREATE_NEW_PAGE");
    Emitter.on("EMITTER_SET_PAGE_BUSY", (newValue) => this.handleNewPage());


  }


  render() {
    const { canUndo, canRedo, onUndo, onRedo, pages, currentPage } = this.props;
    //debugger;
    return (
      <Translation>
        {t => (
          <>
            <div className="header pb-6">
              <Container fluid>
                <div className="header-body">
                  <Row className="custom-header align-items-center py-4">
                    <Col lg="4" xs="4">

                      <Whisper placement="bottom" trigger="hover" speaker={
                        <Tooltip>
                          {t("TOOLTIP_UNDO")}
                        </Tooltip>
                      }>
                        <Button
                          id={"tooltipUndo"}
                          color="primary" outline type="button" size="sm"
                          onClick={onUndo} disabled={!canUndo}>
                          <i className="fas fa-undo-alt"></i>
                        </Button>
                      </Whisper>
                      <Whisper placement="bottom" trigger="hover" speaker={
                        <Tooltip>
                          {t("TOOLTIP_REDO")}
                        </Tooltip>
                      }>
                        <Button
                          id={"tooltipRedo"}
                          color="primary" outline type="button" size="sm"
                          onClick={onRedo} disabled={!canRedo}>
                          <i className="fas fa-redo"></i>
                        </Button>
                      </Whisper>
                    </Col>
                    <Col lg="4" xs="4">
                      {
                        this.props.pages != null &&
                        this.props.pages.length > 0 &&
                        <SelectPicker
                        cleanable={false}
                        block={true}
                        onSelect={(value,item,event)=>{
                         // debugger
                           this.handleChangeCurrentPage(value);
                        }}
                        placeholder={"Select Page"}
                        value={this.props.page.id}
                        data={
                          this.props.pages.map((item, key) => {
                            return (
                              {
                                value: item.id,
                                label: item.pageName,
                                // disabled: !item.isActive,
                              }
                            )
                          })
                        }
                        >

                        </SelectPicker>
                      
                          /*
                           <Form>
                          <Select2
                            onSelect={(e) => {
                              let _id = e.currentTarget.selectedIndex;
                              e.preventDefault()
                              this.handleChangeCurrentPage(_id);
                            }}
                            className="form-control-sm"
                            data-minimum-results-for-search="Infinity"
                            value={this.props.page.id}
                            options={{
                              placeholder: "Select"
                            }}
                            data={
                              this.props.pages.map((item, key) => {
                                return (
                                  {
                                    id: item.id,
                                    text: item.pageName,
                                    // disabled: !item.isActive,
                                  }
                                )
                              })
                            }
                          />
                        </Form>
                           */
                        
                       
                      }
                    </Col>
                    <Col className="text-right" lg="4" xs="4">
                      {
                        this.props.currentProjectId &&
                        <>
                          <Whisper placement="bottom" trigger="hover" speaker={
                            <Tooltip>
                              {t("TOOLTIP_NEWPAGE")}
                            </Tooltip>
                          }>
                            <Button
                              id={"tooltipNewPage"}
                              color="primary" outline type="button" size="sm"
                              onClick={this.handleNewPage} >
                              <i className="fas fa-plus"></i>
                            </Button>
                          </Whisper>
                          {
                            this.props.currentProjectId &&
                            this.props.pageStatus.status &&
                            <Whisper placement="bottom" trigger="hover" speaker={
                              <Tooltip>
                                {t("TOOLTIP_SAVE_PAGE")}
                              </Tooltip>
                            }>
                              <Button
                                id={"tooltipSAVE"}
                                color="primary" outline type="button" size="sm"
                                onClick={this.handleSavePage} >
                                <i className="fas fa-save"></i>
                              </Button>
                            </Whisper>
                          }


                          {
                            <Dropdown
                              renderTitle={() => {
                                return (
                                  <Button
                                    color="primary" outline type="button" size="sm"
                                    onClick={() => { }} >
                                    <i className="fas fa-ellipsis-h"></i>
                                  </Button>)
                              }}
                            >
                              <Dropdown.Item className={"header-more-dropdown-item"} disabled onClick={() => Alert.warning(t("ERROR_ITS_NOT_WORKING"))}>
                                <i className="fas fa-upload"></i> {t("TOOLTIP_PUBLISH")}
                              </Dropdown.Item>
                              <Dropdown.Item className={"header-more-dropdown-item"} onSelect={() => this.handleExportModal()}>
                                <i className="fas fa-download"></i> {t("TOOLTIP_EXPORT_PROJECT")}
                              </Dropdown.Item>
                            </Dropdown>
                          }



                          {
                            /*
                             <Whisper placement="bottom" trigger="hover" speaker={
                                                      <Tooltip>
                                                        {t("TOOLTIP_PUBLISH")}
                                                      </Tooltip>
                                                    }>
                                                      <Button
                                                        color="primary" outline type="button" size="sm"
                                                        onClick={() => {
                                                          Alert.warning(t("ERROR_ITS_NOT_WORKING"))
                                                        }} >
                                                        <i className="fas fa-upload"></i>
                                                      </Button>
                                                    </Whisper>
                            */
                          }


                          {
                            /*
                            this.props.currentProjectId &&
                            this.props.pageStatus.status &&
                            <Whisper placement="bottom" trigger="hover" speaker={
                              <Tooltip>
                                {t("TOOLTIP_EXPORT_PROJECT")}
                              </Tooltip>
                            }>
                              <Button
                                id={"tooltipExport"}
                                color="primary" outline type="button" size="sm"
                                onClick={this.handleExportModal} >
                                <i className="fas fa-download"></i>
                              </Button>
                            </Whisper>
                          */
                          }
                        </>
                      }


                      {/*
                        <Button
                        className="btn-neutral"
                        color=""
                        //href="#pablo"
                        onClick={this.props.handleCheckProjects}
                        size="sm"
                      >
                        Check
                     </Button>
                     */}
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
            <NewPage isActive={this.state.newPageModal} handleToggle={this.handleNewPage} />
            {
              this.props.currentProjectId &&
              <ExportModal isActive={this.state.exportModal} handleToggle={this.handleExportModal} />

            }
            {
              <PageBusy isActive={this.state.pageBusy} handleToggle={this.handlePageBusy} />

            }
          </>
        )}
      </Translation>
    );
  }
}


const mapStateToProps = (state) => {
  const { editorReducer, currentstatesReducer, SocketReducer,componentReducer } = state;
  const { present } = editorReducer;
  const { editor, pages, currentPage, page } = present;

  const { pageStatus, currentProjectId, componentNames } = currentstatesReducer;
  const { socket } = SocketReducer;
  const { componentList } = componentReducer;

  return {
    canUndo: state.editorReducer.past.length > 0,
    canRedo: state.editorReducer.future.length > 0,
    editor, pages, currentPage, page,
    pageStatus,
    socket,
    currentProjectId,
    componentNames,
    componentList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUndo: () => dispatch(UndoActionCreators.undo()),
    onRedo: () => dispatch(UndoActionCreators.redo()),
    clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
    updateCurrentPage: (id) => dispatch(updateCurrentPage(id)),
    removeComponent: () => dispatch(removeComponent()),
    setPage: (_data) => dispatch(setPage(_data)),
    setPages: (_data) => dispatch(setPages(_data)),
    setComponentNames: (_data) => dispatch(setComponentNames(_data)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader)
