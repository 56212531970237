import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { updateComponent } from "../../_actions"
import GlobalProperty from "./GlobalProperties/Components/GlobalProperty";
import GlobalPropertyModal from "./GlobalProperties/Modals/GlobalPropertyModal";

import {
    Button,
    Row,
    Col,
    ListGroup,
} from "reactstrap";

class GlobalProperties extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isCreateModalActive: false,
        }
    }

    handleCreateProperty = () => {
        this.setState({
            isCreateModalActive: !this.state.isCreateModalActive
        })
    }

    render() {
        const { isCreateModalActive } = this.state;
 
        return (
            <Translation>
                {t => (
                    <>
                        {t("RIGHT_SIDE_TITLE_GLOBAL")}
                        <hr />
                        {
                            this.props.currentProjectId ?
                                <>
                                    <Row>
                                        <Col>
                                            <button
                                                onClick={this.handleCreateProperty}
                                                type="button"
                                                className="btn btn-info btn-sm btn-block">
                                                {t("BUTTON_ADDNEW")}
                                            </button>
                                        </Col>
                                    </Row>

                                    <hr style={{
                                        marginBottom: ".5rem",
                                        marginTop: ".5rem"
                                    }} />


                                    {
                                        this.props.globalProps != null &&
                                        this.props.globalProps.length == 0 &&
                                        <p className="text-muted">
                                            {t("MESSAGE_GLOBALPROPERTY_HAS_NOT")}
                                        </p>

                                    }

                                    <ListGroup className="list my--3" flush>
                                        {
                                            this.props.globalProps &&
                                            this.props.globalProps.map((item, key) => (
                                                <GlobalProperty key={key} data={item} />)
                                            )
                                        }
                                    </ListGroup>



                                    <GlobalPropertyModal data={null} isActive={isCreateModalActive} handleToggle={this.handleCreateProperty} />

                                </> : t("HAS_PROJECT_ERROR")

                        }

                    </>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, globalpropsReducer ,currentstatesReducer} = state;
    const { component } = componentReducer;
    const { globalProps } = globalpropsReducer;
    const { currentProjectId } = currentstatesReducer;

    return {
        componentReducer,
        component,
        globalProps,
        currentProjectId
    };
}
const mapDispatchToProps = {
    onUpdateConponent: updateComponent,

}
export default connect(mapStateToProps, mapDispatchToProps)(GlobalProperties)
