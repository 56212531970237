import React, { Component } from 'react'
import { connect } from 'react-redux'
import Emitter from "_actions/emitter";
import i18n from "i18n";
import { Translation } from "react-i18next";

import { ResponseStatusCode } from "_helpers";

import { updateComponent, updateList } from '_actions';
import { findObjUpdate } from 'assets/util';

import GenerateComponent from 'components/Custom/GenerateComponent'
import ComponentSelector from 'components/Modals/ComponentSelector';

import { Alert, Tooltip, Whisper, SelectPicker } from 'rsuite';

// reactstrap components
import {
  Button,
} from "reactstrap";


export class UISelector extends Component {
  constructor(props) {
    super(props)

    const { component } = props;
    const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
    const { listItem } = object;
    this.state = {
      isComponentSelector: false,
      selectedUI: props.currentUI,
    }


  }


  componentDidUpdate = (prevProps) => {
    const { component, currentUI } = this.props;
    const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

    const { listItem } = object;

    if (prevProps.component != component) {
      this.setState({
        selectedUI: currentUI,
      })
    }
  }



  handleCompnentSelector = () => {
    this.setState({
      isComponentSelector: !this.state.isComponentSelector
    })
  }

  handleSetComponent = (listItem) => {
    const that = this;

    this.setState({
      isComponentSelector: false,
      selectedUI: listItem,
    }, () => {
      let _clone = Object.assign({}, this.props.component);

      if (_clone.hasOwnProperty("componentOwner")) {
        _clone.object.components[0].object.listItem = that.state.selectedUI
      } else {
        _clone.components[0].object.listItem = that.state.selectedUI
      }

      let _newEditor = JSON.parse(JSON.stringify(this.props.editor))

      let _result = findObjUpdate(_newEditor, _clone);
      this.props.updateComponent(_clone);
      this.props.onUpdateList(_result);

    })
  }

  render() {
    const { currentUI } = this.props;
    return (
      <Translation>
        {t => (
          <div>
            {
              true ?
                <SelectPicker
                  block
                  defaultValue={currentUI && currentUI.componentName}
                  data={
                    this.props.myComponentList.map((x, key) => (
                      {
                        "label": "Eugenia" + key,
                        "value": x.componentName,
                        "role": "Master",
                        "component": x,
                      }
                    ))
                  }
                  placement="auto"
                  //groupBy="role"
                  cleanable={false}
                  placeholder={t("PLACEHOLDER_SELECT_UI")}
                  menuClassName={"compenent-selector"}
                  onSelect={(value, item, event) => {
                    this.handleSetComponent(item.component)
                  }}
                  menuStyle={{
                    width: "407px",
                    left: "442px !important"
                  }}
                  renderMenuItem={(label, item) => {
                    return (
                      <div >
                        <GenerateComponent
                          isChild={false}
                          path={0 + ""}
                          key={item.component.id + "ui" + item.component._id}
                          data={item.component}
                          baseProgress={{
                            handleUpdate: () => { },
                            baseArray: this.props.myComponentList,
                            isViewer: true,
                          }}
                        />
                      </div>
                    );
                  }}
                  renderMenuGroup={(label, item) => {
                    return (
                      <div>
                        {label} - ({item.children.length})
                              </div>
                    );
                  }}
                  renderValue={(value, item) => {

                    return (
                      <div>
                        <GenerateComponent
                          key={value}
                          isChild={false}
                          path={0 + ""}
                          data={item.component}
                          baseProgress={{
                            handleUpdate: () => { },
                            baseArray: this.props.myComponentList,
                            isViewer: true,
                          }}
                        />
                      </div>

                    );
                  }}
                />
                :
                <Button
                  color="primary"
                  onClick={this.handleCompnentSelector}
                  outline type="button" size="sm"
                >
                  Select Component
                          </Button>
            }
            <ComponentSelector isActive={this.state.isComponentSelector} handleToggle={this.handleCompnentSelector} handleSelect={this.handleSetComponent} />

          </div>
        )}
      </Translation>
    )
  }
}

const mapStateToProps = (state) => {
  const { currentstatesReducer, componentReducer, editorReducer } = state;
  const { pageStatus, currentProjectId } = currentstatesReducer;
  const { myComponentList, component } = componentReducer;
  const { present } = editorReducer;
  const { editor } = present;

  return {
    currentProjectId,
    myComponentList,
    component,
    editor
  }
}

const mapDispatchToProps = {
  onUpdateList: updateList,
  updateComponent,
}

export default connect(mapStateToProps, mapDispatchToProps)(UISelector)
