import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { FormGroup, Form, Input, Row, Col } from "reactstrap";
import { updateComponent, updateList } from '_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';
import i18n from 'i18n';
import {
    PropertyTitle,
} from "../Core/";
import { Icon, Dropdown, List, Alert, Button, IconButton, ButtonToolbar, } from 'rsuite';
import ColorPicker from "../Core/ColorPicker";
import NumaricDataProperty from "../Core/NumaricDataProperty"
import { ComponentService } from '_services';
import { ResponseStatusCode } from "_helpers";
import StaticTextValue from '../Core/StaticTextValue';
import NewScript from '../../Modals/NewScript';

import IconDetailProperty from '../Core/IconDetailProperty'

const defautOrientationOptions = [
    {
        label: i18n.t("DROPDOWN_FAB_ORIENTATION_H"),
        value: "horizontal",
    },
    {
        label: i18n.t("DROPDOWN_FAB_ORIENTATION_V"),
        value: "vertical",
    }
]
const defautVerticalOrientationOptions = [
    {
        label: i18n.t("DROPDOWN_FAB_VERTICALORIENTATION_U"),
        value: "up",
    },
    {
        label: i18n.t("DROPDOWN_FAB_VERTICALORIENTATION_D"),
        value: "down",
    }
]
const defautPositionOptions = [
    {
        label: i18n.t("DROPDOWN_FAB_POSITION_L"),
        value: "left",
    },
    {
        label: i18n.t("DROPDOWN_FAB_POSITION_C"),
        value: "center",
    },
    {
        label: i18n.t("DROPDOWN_FAB_POSITION_R"),
        value: "right",
    }
]

const WAIT_INTERVAL = 500;

class FabMenuSettings extends Component {
    constructor(props) {
        super(props)
        this.fontSizeSlider = React.createRef();

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const _p = object.props;
        this.state = {
            componentClone: component,
            fontColor: component && (style["color"] == undefined ? "#000000" : style["color"]),
            buttonColor: component && (_p["buttonColor"] == undefined ? "rgba(255, 255, 255, 0)" : _p["buttonColor"]),
            btnOutRange: component && (_p["btnOutRange"] == undefined ? "rgba(255, 255, 255, 0)" : _p["btnOutRange"]),
            orientation: component && (defautOrientationOptions.find(x => x.value === object.props.orientation).value),
            verticalOrientation: component && (defautVerticalOrientationOptions.find(x => x.value === object.props.verticalOrientation).value),
            position: component && (defautPositionOptions.find(x => x.value === object.props.position).value),
            menu: _p.hasOwnProperty("menu") ? _p.menu : [],
            scriptEditor: false,
            selectedScriptIndex: null
        }
        this.handleSortEnd = this.handleSortEnd.bind(this);

    }

    generateComponentId = async () => {
        return await ComponentService.GenerateComponentId(this.props.currentProjectId, this.props.currentPage).then(data => {
            return data.dynamicValue;
        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Alert.warning(data.desc)
            return null;
        });
    }
    removeComponentId = async (_id) => {
        const { component, currentPage } = this.props;
        return await ComponentService.RemoveComponentId(this.props.currentProjectId, currentPage, _id).then(data => {
        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Alert.warning(data.desc)
            return null;
        });
    }

    updatePropEditor = (key, value) => {
        const that = this;
        let _clone = Object.assign({}, this.props.component);
        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props[key] = value
        } else {
            _clone.components[0].object.props[key] = value
        }

        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);
    }

    handleChangeButtonColor = (color) => {
        const that = this;
        this.setState({
            buttonColor: color,
        }, () => { that.updatePropEditor("buttonColor", `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`) })
    }
    handleChangeBtnOutRange = (color) => {
        const that = this;
        this.setState({
            btnOutRange: color,
        }, () => { that.updatePropEditor("btnOutRange", `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`) })
    }
    handleUpdateOrientation = (_data) => {
        const that = this;
        this.setState({
            orientation: _data.value,
        }, () => {
            that.updatePropEditor("orientation", _data.value)
        })
    }
    handleUpdateVerticalOrientation = (_data) => {
        const that = this;
        this.setState({
            verticalOrientation: _data.value,
        }, () => {
            that.updatePropEditor("verticalOrientation", _data.value)
        })
    }
    handleUpdatePosition = (_data) => {
        const that = this;
        this.setState({
            position: _data.value,
        }, () => {
            that.updatePropEditor("position", _data.value)
        })
    }


    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const _p = object.props;

        if (prevProps.component != component) {
            this.setState({
                componentClone: component,
                fontColor: style["color"] == undefined ? "#FFFFFF" : style["color"],
                buttonColor: component && (_p["buttonColor"] == undefined ? "rgba(255, 255, 255, 0)" : _p["buttonColor"]),
                btnOutRange: component && (_p["btnOutRange"] == undefined ? "rgba(255, 255, 255, 0)" : _p["btnOutRange"]),
                orientation: component && (defautOrientationOptions.find(x => x.value === _p.orientation).value),
                verticalOrientation: component && (defautVerticalOrientationOptions.find(x => x.value === _p.verticalOrientation).value),
                position: component && (defautPositionOptions.find(x => x.value === _p.position).value),
                menu: _p.hasOwnProperty("menu") ? _p.menu : [],
            }, () => { })
        }
    }


    componentDidMount() {
    }

    handleSortEnd({ oldIndex, newIndex }) {
        if (oldIndex === newIndex) {
            return;
        }
        const that = this;
        this.setState(({ menu }) => {
            const moveData = menu.splice(oldIndex, 1);
            const newData = [...menu];
            newData.splice(newIndex, 0, moveData[0]);
            return {
                menu: newData
            };
        }, () => { that.updatePropEditor("menu", that.state.menu) });
    }

    handleChange = async (event, index) => {
        const that = this;
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        clearTimeout(this.timer);

        let _list = Object.values(JSON.parse(JSON.stringify(this.state.menu)));
        _list[index].title = value;

        await this.setState({
            ["menu"]: _list,
        }, () => {
            //that.updatePropEditor("menu", that.state.menu)
        });
        this.timer = setTimeout(() => { that.updatePropEditor("menu", that.state.menu) }, WAIT_INTERVAL);

    }

    handleOnBlur = (e, index) => {
        this.handleChange(e, index)
    }

    componentWillMount() {
        this.timer = null;
    }
    handleAddFabItem = async () => {
        const that = this;
        let _obj = {
            id: await that.generateComponentId(),
            componentName: "fabmenuitem",
            icon: {
                id: 88,
                name: 'Document',
                type: 'regular',
                url: 'https://mobilex360-storage.s3.amazonaws.com/icons/Document.svg'
            },
            title: "Option",
            script: ""
        }
        let _list = Object.values(JSON.parse(JSON.stringify(this.state.menu)));
        _list.push(_obj);
        this.setState({ menu: _list }, () => that.updatePropEditor("menu", that.state.menu));
    }

    handleRemoveFabItem = async (index) => {
        const that = this;
        let _list = Object.values(JSON.parse(JSON.stringify(this.state.menu)));
        await this.removeComponentId(_list[index].id);
        _list.splice(index, 1);
        this.setState({ menu: _list }, () => that.updatePropEditor("menu", that.state.menu));
    }

    handleUpdateFabItem = (index, data) => {
        const that = this;
        let _list = Object.values(JSON.parse(JSON.stringify(this.state.menu)));
        _list[index].icon = data;
        this.setState({ menu: _list }, () => that.updatePropEditor("menu", that.state.menu));
    }
    handleChangeFontColor = (color) => {
        const that = this;

        this.setState({
            fontColor: color,
        }, () => {
            const _style = that.props.hasOwnProperty("dataPath") ? that.props.dataPath : "style";

            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    color: `rgba(${this.state.fontColor.r}, ${this.state.fontColor.g}, ${this.state.fontColor.b}, ${this.state.fontColor.a})`

                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    color: `rgba(${this.state.fontColor.r}, ${this.state.fontColor.g}, ${this.state.fontColor.b}, ${this.state.fontColor.a})`
                }
            }

            //this.props.updateList(findNestedObjAndChangeALL(this.props.editor,_clone._id,_clone));
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }


    handleScriptEditorModal = (indexData) => {

        if (indexData !== undefined) {
            //update or create
            this.setState({
                scriptEditor: true,
                selectedScriptIndex: indexData
            })
        } else {
            //close
            this.setState({
                scriptEditor: false,
                selectedScriptIndex: null
            })
        }
    }

    render() {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];


        const that = this;
        return (
            <Translation>
                {t => (
                    <>
                        <Form>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_FABMENU_BUTTON_COLOR")}
                                    </label>
                                    <ColorPicker isComponent={true} id={"fab-buttonColor"} color={this.state.buttonColor} handle={this.handleChangeButtonColor} />
                                </div>
                            </FormGroup>
                            {
                                 component &&
                                 object &&
                                 object.type === "fabmenu" &&
                                 <FormGroup className={"property-form-group"}>
                                 <div className={"propertyTitleDiv"}>
                                     <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_FABMENU_BTNOUTRANGE_COLOR")}
                                     </label>
                                     <ColorPicker isComponent={true} id={"fab-btnOutRange"} color={this.state.btnOutRange} handle={this.handleChangeBtnOutRange} />
                                 </div>
                             </FormGroup>
 
                            }
                           
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_FONT_COLOR")}
                                    </label>
                                    <ColorPicker isComponent={true} id={"checkbox-label-font-color"} color={this.state.fontColor} handle={this.handleChangeFontColor} />

                                </div>
                            </FormGroup>

                            {
                                component &&
                                object &&
                                object.type === "fabmenu" &&
                                <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}><label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_FABMENU_ORIENTATION")}</label>
                                        <Dropdown size="xs" title={defautOrientationOptions.find(x => x.value === this.state.orientation).label}>
                                            {
                                                defautOrientationOptions.map((x, key) => (
                                                    x.value === this.state.orientation ?
                                                        "" :
                                                        <Dropdown.Item key={key} onSelect={() => this.handleUpdateOrientation(x)}>{x.label}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown>
                                    </div>
                                </FormGroup>


                            }


                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}><label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_FABMENU_VERTICAL")}</label>
                                    <Dropdown size="xs" title={defautVerticalOrientationOptions.find(x => x.value === this.state.verticalOrientation).label}>
                                        {
                                            defautVerticalOrientationOptions.map((x, key) => (
                                                x.value === this.state.verticalOrientation ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handleUpdateVerticalOrientation(x)}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                </div>

                            </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}><label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_FABMENU_POSITION")}</label>
                                    <Dropdown size="xs" title={defautPositionOptions.find(x => x.value === this.state.position).label}>
                                        {
                                            defautPositionOptions.map((x, key) => (
                                                x.value === this.state.position ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handleUpdatePosition(x)}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                </div>

                            </FormGroup>
                            {

                                component &&
                                object &&
                                object.type === "fabmenu" &&
                                <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}><label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_FABMENU_OPTIONS")}</label>
                                        <IconButton onClick={() => {
                                            this.handleAddFabItem();
                                        }} size="xs" icon={<Icon icon="plus" />} appearance="default" />
                                    </div>
                                    <List size="sm" bordered sortable onSort={this.handleSortEnd}>
                                        {
                                            this.state.menu.length === 0 &&
                                            <div style={{ display: "flex", padding: 8, justifyContent: "center", alignItems: "center", textAlign: "center", fontSize: ".8em" }}>
                                                {t("MESSAGE_HAS_NO_FABITEM")}
                                            </div>
                                        }
                                        {this.state.menu.map((item, index) => (
                                            <List.Item style={{ padding: "8px" }} key={index} index={index}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column"
                                                }}>
                                                    <ButtonToolbar style={{ display: "flex", justifyContent: "flex-end" }}>
                                                        <IconButton onClick={() => { this.handleRemoveFabItem(index) }} size="xs" icon={<Icon icon="trash-o" />} appearance="default" />
                                                    </ButtonToolbar>
                                                    <div style={{ flex: 1 }}>
                                                        <Form>
                                                            <FormGroup className={"property-form-group"}>
                                                                <div className={"propertyTitleDiv"}><label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_PLACEHOLDER_FABTITLE")}</label></div>
                                                                <Input
                                                                    //className="form-control-alternative"
                                                                    className="form-control-sm custom-fade-in"
                                                                    placeholder={t("PROPERTY_PLACEHOLDER_FABTITLE")}
                                                                    type="text"
                                                                    id={"propertyValue"}
                                                                    name={"value"}
                                                                    value={item.title}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            e.preventDefault();
                                                                            this.handleOnBlur(e, index);
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => { this.handleOnBlur(e, index) }}
                                                                    onChange={(e) => {
                                                                        this.handleChange(e, index)
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup className={"property-form-group"}>
                                                                <div className={"propertyTitleDiv"}>
                                                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_ICON")}
                                                                    </label>
                                                                    <IconDetailProperty handleUpdateFabItem={this.handleUpdateFabItem} index={index} icon={item.icon} />
                                                                </div>
                                                            </FormGroup>
                                                            <FormGroup className={"property-form-group"}>
                                                                <div className={"propertyTitleDiv"}>
                                                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_FABMENU_ON_CLICK")}
                                                                    </label>
                                                                    <Button onClick={() => this.handleScriptEditorModal(index)} size="xs">{t("BUTTON_SHOW")}</Button>
                                                                </div>
                                                            </FormGroup>

                                                        </Form>
                                                    </div>
                                                </div>
                                            </List.Item>
                                        ))}
                                    </List>
                                </FormGroup>
                            }

                        </Form>
                        {
                            <NewScript fieldData={this.state.selectedScriptIndex !== null && this.state.menu[this.state.selectedScriptIndex]} isFiledEvent={true} data={object.props.eventId} isActive={this.state.scriptEditor} handleToggle={() => this.handleScriptEditorModal()} />

                        }
                    </>

                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { pageStatus, currentProjectId } = currentstatesReducer;

    const { present } = editorReducer;
    const { editor, currentPage } = present;
    return {
        editor,
        currentPage,
        component,
        currentProjectId
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,


}
export default connect(mapStateToProps, mapDispatchToProps)(FabMenuSettings)
