import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";

import {
    Button,
    FormGroup,
    Form,
    Row,
    Col,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    UncontrolledTooltip
} from "reactstrap";

import { updateComponent,updateList } from '_actions';
import { findObjUpdate } from 'assets/util';

class MarginProperty extends Component {

    constructor(props) {
        super(props)
        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style, value } = object.props;

        this.state = {
            isAll: true,
            marginTop: component && parseInt(style["marginTop"] == undefined ? "0px" : style["marginTop"]),
            marginBottom: component && parseInt(style["marginBottom"] == undefined ? "0px" : style["marginBottom"]),
            marginRight: component && parseInt(style["marginRight"] == undefined ? "0px" : style["marginRight"]),
            marginLeft: component && parseInt(style["marginLeft"] == undefined ? "0px" : style["marginLeft"]),
        }

    }

    checkIsEqual = () => {
        const { marginTop, marginBottom, marginRight, marginLeft } = this.state;
        if (marginTop == marginBottom == marginRight == marginLeft) {
            return true;
        } else {
            return false;
        }
    }


    setProperties = () => {
        let _clone = Object.assign({}, this.props.component);

        const that = this;

        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props.style = {
                ..._clone.object.components[0].object.props.style,
                marginTop: that.state.marginTop + "px",
                marginBottom: that.state.marginBottom + "px",
                marginRight: that.state.marginRight + "px",
                marginLeft: that.state.marginLeft + "px",
            }
        } else {
            _clone.components[0].object.props.style = {
                ..._clone.components[0].object.props.style,
                marginTop: that.state.marginTop + "px",
                marginBottom: that.state.marginBottom + "px",
                marginRight: that.state.marginRight + "px",
                marginLeft: that.state.marginLeft + "px",
            }
        }
        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);
    }


    handleChange = (event) => {
        const { target } = event;
        const { name } = target;
        let value = (event.target.validity.valid) ? event.target.value : (this.state.isAll ? this.state.marginTop : this.state[name]);
        const that = this;

        if (value.length < 1) { value = "0" }

        value = parseInt(value);

        if (this.state.isAll) {
            this.setState({
                marginTop: value,
                marginBottom: value,
                marginRight: value,
                marginLeft: value,
            }, () => {
                that.setProperties();
            });
        } else {
            this.setState({
                [name]: value,
            }, () => {
                that.setProperties();
            });
        }


    }

    componentDidMount() {
        this.setState({
            isAll: this.checkIsEqual(),
        })
    }
    componentDidUpdate = (prevProps) => {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style, value } = object.props;

        if (prevProps.component != component) {
            this.setState({
                //isAll: this.checkIsEqual(),
                marginTop: parseInt(style["marginTop"] == undefined ? "0px" : style["marginTop"]),
                marginBottom: parseInt(style["marginBottom"] == undefined ? "0px" : style["marginBottom"]),
                marginRight: parseInt(style["marginRight"] == undefined ? "0px" : style["marginRight"]),
                marginLeft: parseInt(style["marginLeft"] == undefined ? "0px" : style["marginLeft"]),
            })
        }
    }
    handleChangeType = () => {
        this.setState({
            isAll: !this.state.isAll,
            marginTop: !this.state.isAll ? this.state.marginTop : this.state.marginTop,
            marginBottom: !this.state.isAll ? this.state.marginTop : this.state.marginBottom,
            marginRight: !this.state.isAll ? this.state.marginTop : this.state.marginRight,
            marginLeft: !this.state.isAll ? this.state.marginTop : this.state.marginLeft,
        }, () => {
            this.setProperties();
        })
    }

    onKeyDown = (event) => {
        if (event.key === 'Enter') { event.preventDefault() }
    }



    render() {
        const { component } = this.props;
        const { isAll } = this.state;
        return (
            <Translation>
                {t => (
                    <div>

                        <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                                <Button onClick={this.handleChangeType} size="sm" color="primary" outline={!isAll} type="button">
                                    <i className="far fa-square"></i>
                                </Button>
                                <Button onClick={this.handleChangeType} size="sm" color="primary" outline={isAll} type="button">
                                    <i className="fas fa-expand"></i>
                                </Button>
                            </InputGroupAddon>
                            {
                                isAll ? (
                                    <Input
                                        pattern="[0-9]*"
                                        name={"marginLeft"}
                                        value={this.state.marginLeft}
                                        onBlur={() => { }}
                                        onKeyDown={this.onKeyDown.bind(this)}
                                        onInput={this.handleChange.bind(this)}
                                        className="form-control-sm"
                                        placeholder="" type="text" />
                                ) : (
                                        <>
                                            <Input
                                                pattern="[0-9]*"
                                                id={"margin-left-weigth"}
                                                name={"marginLeft"}
                                                value={this.state.marginLeft}
                                                onKeyDown={this.onKeyDown.bind(this)}
                                                onBlur={() => { }}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                className="form-control-sm"
                                                placeholder="" type="text" />
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target="margin-left-weigth">
                                                {t("PROPERTY_LAYOUT_MARGIN_LEFT")}
                                            </UncontrolledTooltip>
                                            <Input
                                                pattern="[0-9]*"
                                                id={"margin-right-weigth"}
                                                name={"marginRight"}
                                                value={this.state.marginRight}
                                                onKeyDown={this.onKeyDown.bind(this)}
                                                onBlur={() => { }}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                className="form-control-sm"
                                                placeholder="" type="text" />
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target="margin-right-weigth">
                                                {t("PROPERTY_LAYOUT_MARGIN_RIGHT")}
                                            </UncontrolledTooltip>
                                            <Input
                                                pattern="[0-9]*"
                                                id={"margin-top-weigth"}
                                                name={"marginTop"}
                                                value={this.state.marginTop}
                                                onKeyDown={this.onKeyDown.bind(this)}
                                                onBlur={() => { }}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                className="form-control-sm"
                                                placeholder="" type="text" />
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="top"
                                                target="margin-top-weigth">
                                                {t("PROPERTY_LAYOUT_MARGIN_TOP")}
                                            </UncontrolledTooltip>
                                            <Input
                                                pattern="[0-9]*"
                                                id={"margin-bottom-weigth"}
                                                name={"marginBottom"}
                                                value={this.state.marginBottom}
                                                onKeyDown={this.onKeyDown.bind(this)}
                                                onBlur={() => { }}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                                className="form-control-sm"
                                                placeholder="" type="text" />
                                            <UncontrolledTooltip
                                                delay={3}
                                                placement="top"
                                                target="margin-bottom-weigth">
                                                {t("PROPERTY_LAYOUT_MARGIN_BOTTOM")}
                                            </UncontrolledTooltip>
                                        </>
                                    )
                            }



                        </InputGroup>

                    </div>

                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer,editorReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        component,
        editor
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
}
export default connect(mapStateToProps, mapDispatchToProps)(MarginProperty)
