import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from 'i18n';

import {
    FormGroup,
    Form,

} from "reactstrap";

import { updateComponent, updateList } from '_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';

import {
    PropertyTitle,
} from "../Core/";
import StaticCMSSettings from "../Properties/StaticCMSSettings"
import StaticTextValue from '../Core/StaticTextValue'


import { SelectPicker, Dropdown, TagPicker } from 'rsuite';

const defautValueOptions = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: true,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: false,
    }
]
  
class WebviewSettings extends Component {
    constructor(props) {
        super(props)
        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        this.state = {
            desktopMode: component && object.props.hasOwnProperty("desktopMode") ? (defautValueOptions.find(x => x.value === object.props.desktopMode).value) : defautValueOptions[1].value,
        }

    }

    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const style = object.props[this.props.dataPath ? this.props.dataPath : "style"];

        if (prevProps.component != component) {
             
            this.setState({
                desktopMode: component && object.props.hasOwnProperty("desktopMode") ? (defautValueOptions.find(x => x.value === object.props.desktopMode).value) : defautValueOptions[1].value,
 
            })
        }
    }

    handleUpdateValue = (_data, _name) => {

        this.setState({
            [_name]: _data.value,
        }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";
            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
            } else {
                _clone.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

 

    render() {
        const { component, globalProps } = this.props;
        let _component;
        if (component.hasOwnProperty("componentOwner"))
            _component = component.object.components[0];
        else
            _component = component.components[0]


        //  desktopMode
        return (
            <Translation>
                {t => (
                    <Form>
                        <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"} style={{
                                            marginTop: "1.5em"
                                    }}>
                                        <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_TEXT_WEBVIEW_DESKTOP_MODE")}
                                        </label>
                                        {
                                            this.state["desktopMode"] !== null &&
                                            <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["desktopMode"]).label}>
                                                {
                                                    defautValueOptions.map((x, key) => (
                                                        x.value === this.state["desktopMode"] ?
                                                            "" :
                                                            <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "desktopMode")}>{x.label}</Dropdown.Item>
                                                    ))
                                                }
                                            </Dropdown>
                                        }

                                    </div>
                                </FormGroup>
                       
                    </Form>
                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, globalpropsReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;

    const { globalProps } = globalpropsReducer;

    return {
        editor,
        component,
        globalProps
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,

}
export default connect(mapStateToProps, mapDispatchToProps)(WebviewSettings)
