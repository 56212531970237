import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { updateComponent, updateList } from '_actions';
import { findObjUpdate } from 'assets/util';

import Slider from "nouislider";


class SliderProperty extends Component {
    constructor(props) {
        super(props)
        this.currentSlider = React.createRef();

        const { component, isCustom } = props;

        let object = null;
        let style = null;

        if (component != null) {
            object = component.hasOwnProperty("componentOwner") ? component.object.components[0].object : component.components[0].object;
            style = object.props.style;
        }

        this.state = {
            componentClone: component,
            [props.propName]: isCustom ? props.current : (style[props.propName] == undefined ? props.current : style[props.propName])
        }

    }


    customParse = (_value) => {
        const { valueType } = this.props;
        switch (valueType) {
            case "int":
                return parseInt(_value)
            default:
                return parseFloat(_value)
        }
    }

    generateInput = () => {

        const { propName, min, max, step, type, current, isCustom, customFunction } = this.props;

        var _currentSlider = this.currentSlider.current;
        const that = this;
        Slider.create(_currentSlider, {
            start: [this.state[propName]],
            connect: [true, false],
            step: step,
            tooltips: true,
            format: {
                to: function (value) {
                    return that.customParse(value) + type;
                },
                from: function (value) {
                    return that.customParse(value.replace(type, ''));
                }
            },
            range: { min: min, max: max }
        });
        _currentSlider.noUiSlider.on('change', function (values, handle) {
            that.setState({
                [propName]: values[handle],
            }, () => {

                if (isCustom) { customFunction(that.state[propName]) }
                else {

                    let _clone = Object.assign({}, that.props.component);
                    if (_clone.hasOwnProperty("componentOwner")) {
                        _clone.object.components[0].object.props.style = {
                            ..._clone.object.components[0].object.props.style,
                            [propName]: that.state[propName],
                        }
                    } else {
                        _clone.components[0].object.props.style = {
                            ..._clone.components[0].object.props.style,
                            [propName]: that.state[propName],
                        }
                    }


                    //that.props.updateList(findNestedObjAndChangeALL(that.props.editor,_clone._id,_clone));
                    let _newEditor = JSON.parse(JSON.stringify(that.props.editor))
                    let _result = findObjUpdate(_newEditor, _clone);
                    that.props.updateComponent(_clone);
                    that.props.onUpdateList(_result);

                }


            })
        });
    }

    componentDidMount() {
        this.generateInput();
    }


    componentDidUpdate = (prevProps) => {
        const { component, propName, current } = this.props;


        let object = null;
        let style = null;

        if (component != null) {
            object = component.hasOwnProperty("componentOwner") ? component.object.components[0].object : component.components[0].object;
            style = object.props.style;
        }



        if (prevProps.component != component) {
            this.setState({
                componentClone: component,
                [propName]: style[propName] == undefined ? current : style[propName]
            }, () => {
                //this.generateInput();
                var _currentSlider = this.currentSlider.current;
                _currentSlider.noUiSlider.set(this.state[propName])

            })
        }
    }

    render() {
        return (
            <Translation>
                {t => (
                    <div className="slider" ref={this.currentSlider} />
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        component,
        editor
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
}
export default connect(mapStateToProps, mapDispatchToProps)(SliderProperty)
