export const componentConstants = {

    UPDATE_COMPONENT: 'UPDATE_COMPONENT',
    REMOVE_COMPONENT: 'REMOVE_COMPONENT',
    HOVERED_COMPONENT: 'HOVERED_COMPONENT',
    CONTEXT_COMPONENT: 'CONTEXT_COMPONENT',

    ADD_FAV_COMPONENT: 'ADD_FAV_COMPONENT',
    SET_COMPONENT_LIST: 'SET_COMPONENT_LIST',
    
    SET_MYCOMPONENT_LIST: 'SET_MYCOMPONENT_LIST',
    CREATE_MYCOMPONENT: 'CREATE_MYCOMPONENT',
    REMOVE_MYCOMPONENT: 'REMOVE_MYCOMPONENT',
    UPDATE_MYCOMPONENT: 'UPDATE_MYCOMPONENT',
    SET_RESET:"SET_RESET"

}