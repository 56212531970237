import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import Slider from "nouislider";
import Select2 from "react-select2-wrapper";
import { lightOrDark } from "assets/util"

import { editGlobalProps, createGlobalProps, deleteGlobalProps, setGlobalProps } from "_actions"
import { ResponseStatusCode } from "_helpers";
import Emitter from "_actions/emitter";

import { globalPropService, } from "_services";
import { CButton } from "../../../UI";
import i18n from "i18n";

import {
    Button,
    Input,
    Modal,
    Container,
    Row, Col,
    Form,
} from "reactstrap";

import { PropertyTitle, } from "../../Core";
import { SelectPicker } from 'rsuite';
import ColorPicker from "../../Core/ColorPicker";
import SliderProperty from "../../Core/SliderProperty";
import { fontList } from "assets/fontList";

class GlobalPropertyModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: null,
            customStyles: {
                name: "Test",
                nameState: null,
            },
        }

    }

    /*
        Bu modal'ın kullanılabileceği amaçlar;
        - Yeni kayıt oluşturma
        - Olan kaydı güncelleme
        
        Eğer props data'nın içi doluyor ise bu güncellenecek bir içeriktir.

        Örnek veri ;

        id: 0,
        name: "Primary",
        type: 0, ->
        value: "#2baebf" -> 
        
    */


    componentDidUpdate = (prevProps) => {
        const { isActive, data, globalPropsTypes } = this.props;
        if (prevProps.isActive != isActive && isActive) {
            if (data) {
                this.setState({
                    loading: false,
                    loadingDelete: false,
                    value: data.value,
                    type: data.type,
                    customStyles: {
                        name: data.name,
                        nameState: null,
                    }
                })
            } else {
                this.setState({
                    loading: false,
                    value: globalPropsTypes[0].defaultValue,
                    type: globalPropsTypes[0].typeID,
                    customStyles: {
                        name: i18n.t("TITLE_MODAL_GLOBAL_TEXT_CREATE_NAME"),
                        nameState: null,
                    }
                })
            }

        }
    }


    handleChangecolor = (color) => {
        this.setState({
            value: 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')',
        })
    }


    customStylesForm = (e, stateName) => {
        let newState = this.state.customStyles;
        newState[stateName] = e.target.value.trim();
        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value.trim() === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }
        this.setState({
            customStyles: newState
        });
    };

    validateCustomStylesForm = () => {
        let newState = this.state.customStyles;

        newState.name = newState.name.trim();

        if (newState.name === "") {
            newState.nameState = "invalid";
        } else {
            newState.nameState = "valid";
        }

        this.setState({
            customStyles: newState,
        }, () => {
            if (this.state.customStyles.nameState == "valid") {
                this.props.data != null ? this.handleUpdate() : this.handleCreate()
            }
        });
    };

    updateSlider = (_value) => {
        this.setState({
            value: _value
        })
    }


    handleClose = () => {
        const { isActive, handleToggle } = this.props;
        this.setState({
            value: null,
            type: null,
            customStyles: {
                name: "Test",
                nameState: null,
            }

        })

    }

    createGlobalProp = () => {
        //const { setGlobalProps } = this.props;
        const { customStyles, type, value } = this.state;

        const that = this;

        globalPropService.CreateGlobalProperties(this.props.currentProjectId, customStyles.name, type, value).then(data => {

            that.getGlobalProps();
            that.handleClose();
            that.setState({
                loading: false,
            })
            that.props.handleToggle();
        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
            that.setState({
                loading: false,
            })
        });
    }

    updateGlobalProp = () => {
        //const { setGlobalProps } = this.props;
        const { customStyles, type, value } = this.state;
        //const { data } = this.props;
        const that = this;

        globalPropService.UpdateGlobalProperties(this.props.currentProjectId, customStyles.name, type, value, this.props.data.id).then(data => {

            that.getGlobalProps();
            that.handleClose();
            that.setState({
                loading: false,
            })
            that.props.handleToggle();
        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
            that.setState({
                loading: false,
            })
        });
    }

    deleteGlobalProp = () => {
        //const { setGlobalProps } = this.props;
        const { customStyles, type, value } = this.state;
        //  const { data } = this.props;
        const that = this;

        globalPropService.RemoveGlobalProperties(this.props.currentProjectId, this.props.data.id).then(data => {

            that.getGlobalProps();
            that.handleClose();
            that.setState({
                loading: false,
            })
            that.props.handleToggle();
        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);
            that.setState({
                loading: false,
            })
        });
    }

    getGlobalProps = () => {
        const { setGlobalProps } = this.props;
        globalPropService.GetGlobalProperties(this.props.currentProjectId).then(data => {
            setGlobalProps(data.dynamicValue);
        }).catch(err => {
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

        });
    }

    handleUpdate = () => {
        this.setState({
            loading: true,
        }, () => {
            this.updateGlobalProp();
        })
    }

    handleCreate = () => {
        this.setState({
            loading: true,
        }, () => {
            this.createGlobalProp();
        })
    }

    handleDelete = () => {
        this.setState({
            loadingDelete: true,
        }, () => {
            this.deleteGlobalProp();
        })
    }

    handleUpdateType = (_id) => {
        const { globalPropsTypes } = this.props;

        this.setState({
            type: _id,
            value: globalPropsTypes[_id].defaultValue,
        })
    }

    handleSetFontFamily = (_value) => {
        this.setState({
            value: _value,
        })
    }

    render() {
        const { isActive, handleToggle, data } = this.props;
        // debugger
        return (
            <Translation>
                {t => (
                    <Modal
                        className="modal-dialog-centered"
                        //size="lg"
                        isOpen={isActive}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="CheckProjectModal">
                                {data ? t("TITLE_MODAL_GLOBAL_TEXT_PROPERTY_UPDATE") : t("TITLE_MODAL_GLOBAL_TEXT_PROPERTY_CREATE")}
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                disabled={this.state.loading}
                                type="button"
                                onClick={() => handleToggle()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ padding: "0rem 1.5rem" }}>

                            <Container>

                                <Row>
                                    <PropertyTitle title={t("TITLE_MODAL_GLOBAL_TEXT_PROPERTY_PREVIEW")} subtitle={""} />
                                </Row>
                                <Row className="justify-content-md-center mb-3" style={{ minHeight: "45px" }}>
                                    <Col className="ml--0 global-props-list-item-container" style={{
                                        backgroundColor: this.state.type == 0 ? this.state.value : "#fff",
                                        //  maxWidth: data.type == 1 ? (parseInt(data.value.substring(0, data.value.length - 2)) + 40) + "px" : "44px",
                                        //   width: data.type == 1 ? (parseInt(data.value.substring(0, data.value.length - 2)) + 40) + "px" : "44px",
                                        border: this.state.type == 1 ? "1px solid #525f7f" : "unset",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <h4 className="mb-0 global-props-list-item-h4 text-uppercase" style={{
                                            color: this.state.type == 0 ? lightOrDark(this.state.value) == "light" ? "#525f7f" : "#f6f9fc" : "#525f7f",
                                            fontSize: this.state.type == 1 ? this.state.value : "14px",
                                        }}>
                                            {

                                                this.state.customStyles.hasOwnProperty("name") ? (
                                                    this.state.customStyles.name ? this.state.customStyles.name.substring(0, 1) : "X"
                                                )
                                                    : "X"
                                            }
                                        </h4>
                                    </Col>
                                    <Col>
                                        <p className="mb-0 text-nowrap text-uppercase text-muted font-weight-bold" style={{ fontSize: "14px" }}>
                                            {this.state.customStyles.name}
                                        </p>
                                        <p className="mt-0 mb-0 text-muted " style={{ fontSize: "14px" }}>
                                            {
                                                this.state.type == 0 ?
                                                    t("PROPERTY_COLOR") :
                                                    (this.state.type == 1 ?
                                                        t("PROPERTY_FONT_SIZE") :
                                                        (this.state.type == 2 ?
                                                            t("PROPERTY_FONT_FAMILY") :
                                                            ""))

                                            }
                                        </p>
                                        <p className="mb-0 text-nowrap text-muted " style={{ fontSize: "14px" }}>
                                            {this.state.value}
                                        </p>
                                    </Col>
                                </Row>
                                <Row className={"global-property-content md-3"}>
                                    <Col xs={"12"} className={"global-property-content"}>
                                        <PropertyTitle title={t("TITLE_MODAL_GLOBAL_PROPERTY_TYPE")} subtitle={""} />
                                        <div className="bg-secondary">
                                            <Form className={"select-font-size"}>
                                                <Select2
                                                    className="form-control"
                                                    defaultValue={this.state.type}
                                                    onSelect={(e) => {
                                                        let _id = e.currentTarget.selectedIndex;
                                                        e.preventDefault()
                                                        this.handleUpdateType(_id);
                                                    }}
                                                    data-minimum-results-for-search="Infinity"
                                                    options={{
                                                        placeholder: t("TITLE_MODAL_GLOBAL_PROPERTY_TYPE")
                                                    }}
                                                    data={
                                                        this.props.globalPropsTypes &&
                                                        this.props.globalPropsTypes.map((item, key) => {
                                                            return {
                                                                id: item.typeID,
                                                                text: t(item.nameCode)
                                                            }
                                                        })
                                                    }
                                                />
                                            </Form>

                                        </div>
                                    </Col>
                                </Row>
                                <Row className={"global-property-content md-3"}>
                                    <PropertyTitle title={t("TITLE_MODAL_GLOBAL_TEXT_PROPERTY_NAME")} subtitle={""} />
                                    <Input
                                        aria-describedby="inputGroupPrepend"
                                        id="validationCustomname"
                                        placeholder={t("PLACEHOLDER_MODAL_GLOBAL_TEXT_PROPERTY_NAME")}
                                        type="text"
                                        defaultValue={this.state.customStyles.name}
                                        valid={
                                            this.state.customStyles.nameState === "valid"
                                        }
                                        invalid={
                                            this.state.customStyles.nameState ===
                                            "invalid"
                                        }
                                        onChange={e => this.customStylesForm(e, "name")}
                                    />

                                    <div className="invalid-feedback">
                                        {t("IVALID_MODAL_GLOBAL_TEXT_PROPERTY_NAME")}
                                    </div>
                                </Row>
                                <Row className={"global-property-content"}>

                                    {
                                        this.state.type == 0 &&
                                        <Col xs={"12"} md={"6"} className={"global-property-content"}>
                                            <PropertyTitle title={t("PROPERTY_COLOR")} subtitle={""} />
                                            <ColorPicker id={"value"} color={this.state.value} handle={this.handleChangecolor} />
                                        </Col>
                                    }
                                    {
                                        this.state.type == 1 &&
                                        <Col xs={"12"} className={"global-property-content"}>
                                            <PropertyTitle title={t("PROPERTY_FONT_SIZE")} subtitle={this.state.value} />
                                            <SliderProperty propName={"fontSize"} isCustom={true} valueType={"int"} customFunction={this.updateSlider} min={5} max={72} step={1} type={"px"} current={this.state.value} />
                                        </Col>
                                    }
                                    {
                                        this.state.type == 2 &&
                                        <Col xs={"12"} className={"global-property-content"}>
                                            <PropertyTitle title={t("PROPERTY_FONT_FAMILY")} subtitle={""} />
                                            <div >
                                                <SelectPicker
                                                    onSelect={(value, item, event) => { this.handleSetFontFamily(value) }}
                                                    onClean={() => { this.handleSetFontFamily(null) }}
                                                    style={{
                                                        width: "100%",
                                                        height: "45px",
                                                    }}
                                                    defaultValue={this.state.fontFamily}
                                                    renderMenuItem={(label, item) => {
                                                        return (
                                                            <p style={{ fontFamily: item.value, margin: 0 }}>
                                                                {label}
                                                            </p>
                                                        );
                                                    }}
                                                    maxHeight={160}
                                                    placeholder={t("PROPERTY_FONT_FAMILY")}
                                                    data={fontList}
                                                />
                                            </div>
                                        </Col>

                                    }
                                </Row>
                            </Container>
                        </div>

                        <div className="modal-footer">
                            <Button
                                color="white"
                                size={"sm"}
                                data-dismiss="modal"
                                disabled={this.state.loading}
                                type="button"
                                onClick={() => handleToggle()}
                            >
                                {t("BUTTON_CANCEL")}
                            </Button>
                            {
                                data &&
                                <CButton
                                    color="info"
                                    size={"sm"}
                                    loading={this.state.loadingDelete}
                                    disabled={(this.state.loadingDelete || this.state.loading)}
                                    value={t("BUTTON_DELETE")}
                                    onClick={() => this.handleDelete()}
                                >
                                </CButton>
                            }
                            <CButton
                                color="primary"
                                size={"sm"}
                                loading={this.state.loading}
                                disabled={(((this.state.loadingDelete || this.state.loading) || (this.state.customStyles.nameState == "invalid")) ? true : false)}
                                value={data ? t("BUTTON_UPDATE") : t("BUTTON_CREATE")}
                                onClick={() => { this.validateCustomStylesForm() }}>
                            </CButton>
                        </div>
                    </Modal>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { currentstatesReducer, globalpropsReducer } = state;
    const { globalProps, globalPropsTypes } = globalpropsReducer;
    const { currentProjectId } = currentstatesReducer;

    return {
        globalProps,
        globalPropsTypes,
        currentProjectId
    };
}
const mapDispatchToProps = {
    editGlobalProps,
    createGlobalProps,
    deleteGlobalProps,
    setGlobalProps

}
export default connect(mapStateToProps, mapDispatchToProps)(GlobalPropertyModal)
