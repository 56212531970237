import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateComponent, updateList } from '_actions';
import { findObjUpdate } from 'assets/util';
import { ReactSVG } from 'react-svg'

import IconsModal from '../../../components/Modals/IconsModal';
import { Alert, Loader } from 'rsuite';

class IconDetailProperty extends Component {
    constructor(props) {
        super(props)
     
        this.state = {
            iconsModal: false,
            file: props.icon
        }
    }

    handleIcon = () => {
        this.setState({
            iconsModal: !this.state.iconsModal,
        })
    }
    handleClose = () => {
        this.setState({
            iconsModal: false,
        })
    }

    componentDidUpdate = (prevProps) => {
        const { icon,component } = this.props;
        if (prevProps.component != component) {
            this.setState({
                file: icon,
            })
        }
    }

    handleClick = (e) => {
        e.preventDefault()
        this.handleIcon();
    }

    handleSelectIcon = (_data) => {
        const that=this;
        const {index,handleUpdateFabItem}=this.props;
        this.setState({
            file: _data,
        }, () => {
            handleUpdateFabItem(index,that.state.file);
        })
    }

    render() {
        const {index,handleUpdateFabItem}=this.props;
        return (
            <>
                <div  style={{ position: "relative" }}>
                    <button  className={"property-icon"}
                        onClick={this.handleClick}>
                        <ReactSVG
                            src={this.state.file ? this.state.file.url : ""}
                            afterInjection={(error, svg) => {
                                if (error) {
                                    //console.error(error)
                                    return
                                }
                                //console.log(svg)
                            }}
                            beforeInjection={(svg) => {
                                //svg.classList.add('svg-class-name')
                                svg.setAttribute('style', 'height:26px  ; fill:#2baebf')
                            }}
                            evalScripts="always"
                            fallback={() => <span></span>}
                            loading={() => <Loader />}
                            renumerateIRIElements={false}
                            wrapper="span"
                        />

                    </button>
                    {
                        (this.state.file != null) &&
                        <div onClick={() => {
                            this.setState({
                                file: null
                            }, () => {
                                handleUpdateFabItem(index,this.state.file);
                            })
                        }} className={"property-image-remove"}>
                            <i className="fas fa-times"></i>
                        </div>
                    }


                </div>
                <IconsModal
                    isActive={this.state.iconsModal}
                    handleToggle={this.handleClose}
                    currentSelected={this.state.file && this.state.file.id}
                    handleSelectIcon={this.handleSelectIcon} />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        component,
        editor
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
}
export default connect(mapStateToProps, mapDispatchToProps)(IconDetailProperty)
