import {
  //ServiceURLs,
  CreatorEndPoints,
  GetBaseCreatorURL,
  MakeReqestAndGetResponse,
  GetBaseConnectURL,
  ProjectEntPoints,
  GetUserLang,
} from "./index";
import { HTTPOperationType } from "./httpOperations";

export const ProjectService = {
 Create,
 Save,
 GetProjectSetting,
 SetProjectSetting,
 CheckIsHaveProjectSetting,
 GetPaging,
 SetProjectDetail,
 Getprojectbyid,
};
function Getprojectbyid(RelProjectId) {
  var js = {
    id:RelProjectId  
  };

  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.GET_PROJECT_BY_ID,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function SetProjectDetail(ProjectID) {
  var js = {
    ProjectID:ProjectID,
    Token : JSON.parse(localStorage.getItem("user")).token
  };

  return MakeReqestAndGetResponse(
    GetBaseConnectURL() + ProjectEntPoints.SET_PROJECT_DETAIL,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
} 
function Create(ProjectName) {
  var js = {
    ProjectName:ProjectName  
  };

  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.CREATE_PROJECT,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
//duruma gore degistirebiliriz.
function Save(data) {
  /*
  var js = {
    ProjectName:ProjectName  
  };
  */

  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.SAVE_PROJECT,
    HTTPOperationType.post,
    data
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}


function GetProjectSetting(ProjectID) {
  var js = {
    id: ProjectID
  };

  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.GET_PROJECT_SETTING,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function SetProjectSetting(ProjectID,Setting) {
  var js = {
    id: ProjectID,
    setting:  Setting
}

  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.SET_PROJECT_SETTING,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}

function CheckIsHaveProjectSetting(ProjectID) {
  var js =  {
    id: ProjectID
    
}

  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.CHECK_IS_HAVE_PROJECT_SETTING,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
//5ebee11749159d00014cfe17
function GetPaging(_FirstIndexID,_LastIndexId,_IsOrderByDesc,Page,RowPerCount) {
  var js =  {
    Page: Page,
    RowPerCount: RowPerCount,
    FirstIndexID :_FirstIndexID,
    IsOrderByDesc: _IsOrderByDesc,
    LastIndexId: _LastIndexId
}
  return MakeReqestAndGetResponse(
    GetBaseCreatorURL() + CreatorEndPoints.GET_PAGING,
    HTTPOperationType.post,
    js
  ).then((res) => {
    if (res.operationResult === false) {
      return Promise.reject(res);
    } else {
      return res;
    }
  });
}
