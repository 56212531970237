import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { Alert } from 'rsuite';
import {
    updateList,
    addFavoriteComponent,
    setOnMove,
    setOnAdd,
    setOnAddUpdate,
    setOnRemove,
    setOnEnd
} from "../../_actions"

import { ReactSortable, Sortable, Swap } from "react-sortablejs";
import GenerateTree from 'components/Tree/GenerateTree';
import { createId, getTime, scaleElement, buildFullTree } from 'assets/util';

class Components extends Component {
    constructor(props) {
        super(props)
        ReactSortable.prototype.onChoose = () => { };
    }
    handleGetIndex = (eArray, index) => {
        let _array = [];
        try {
            if (index == -1) {

                debugger
            }
            else if (eArray[index].hasOwnProperty("componentOwner")) {

                _array = eArray[index].object.components[0].object.data;
            }
            else {
                _array = eArray[index].components[0].object.data;
            }
        } catch (error) {
            debugger;
            Alert.error("Hata alındı incelenmeli")
        }


        return _array;
    }

    handleSetArrayNewData = (_old, _new, _path) => {
        let indexesTarget = _path !== null ? _path.split("-") : [];

        let _array2 = _old;

        indexesTarget.map(item => {
            _array2 = this.handleGetIndex(_array2, item);
        })

        _array2.splice(0, _array2.length);
        _new.map(item => {
            _array2.push(item);
        })
    }




    findIndexesIds = (_path) => {
        const { objFromId } = this.props;
        let _newArray = Object.values(JSON.parse(JSON.stringify(this.props.editor)));
        //let indexesTarget = _path !== null ? _path.split("-") : [];
        const cloneObject = { id: "canvas", components: [{ object: { data: _newArray } }], }
        const _buildArray = ((buildFullTree(cloneObject, { id: objFromId }).map(node => node.id).join('-')).split("-"));
        const result = _buildArray.slice(1, _buildArray.length - 1);
        return result;

        // debugger
        /*
         let ids = [];
         indexesTarget.map((item, index) => {
 
 
             ids.push(_newArray[item].id);
             _newArray = this.handleGetIndex(_newArray, item);
 
             if (indexesTarget.length - 1 == index) {
                 if (objFromId != ids[ids.length - 1]) {
                     console.info("YANLIŞLIK VAR ID'LER UYUŞMUYOR", "TEST-1");
                 } else {
                     console.info("SIKINTI YOK", "TEST-1");
                 }
             }
 
             
         })
         
         debugger
         return ids;
         */
    }

    prepareParentArray = async (_pArray, _cArray, _childIds) => {
        const { objFromId } = this.props;
        // await this.handleSetArrayNewDataPath(_newArray, _pArray, _pPath);

        let _newParent = _pArray

        let aa = await _childIds.slice(0, _childIds.length).map(item => {
            let vv = item;
            _newParent = this.handleGetIndex(_newParent, _newParent.findIndex(xx => xx.id == item));
        })


        _newParent.splice(0, _newParent.length);
        let bb = await _cArray.map(item => {
            _newParent.push(item);
        })

        return _newParent;
    }

    updateState = async (_newState, status) => { // Kurnaz
        const { onMove, isAdded, objFrom, currentEditor, isRemove, editor } = this.props;
        const { moveObj } = this.props;
        const _ = require('lodash');
        const newState = _newState;
        // debugger


        if (!_.isEqual(newState, editor)) {//(JSON.stringify(newState) != JSON.stringify(editor)) {
            //  const newComponent = _.differenceBy(newState, list2, 'id');

            // debugger
            debugger
            if (onMove) {
                // Device içerisinde bulunan bir component için yer değiştirme işlemi başlatılmıştır.container
                if (isRemove) {

                    let childIds = await this.findIndexesIds(objFrom);
                    let _pArray1 = Object.values(JSON.parse(JSON.stringify(newState)));

                    await this.prepareParentArray(_pArray1, currentEditor, childIds)

                    // Kayıt işlemi sona ermiştir. Artık yapılması gereken currentEditor içerisinden gelen veri içerisine mevcut listemizi atamaktır.
                    this.props.onUpdateEditorList(_pArray1);
                    this.props.setOnEnd();
                }
                else if (objFrom) {
                    // c->e
                    // e->c
                    // debugger

                    let _array = Object.values(JSON.parse(JSON.stringify(newState)))
                    await this.handleSetArrayNewData(_array, currentEditor, objFrom)

                    this.props.onUpdateEditorList(_array);
                    this.props.setOnEnd();
                }
                else if (isAdded) {
                    //   debugger
                    // Komponent in alındığı alanda bulunuyorsun demektir. sende yapılan değişiklikleri ataman gerekiyor.
                    this.props.setOnAddUpdate(null, newState);
                } else {
                    //  debugger



                    this.props.onUpdateEditorList(newState);
                    this.props.setOnEnd();
                }
            }
            else {
                //  debugger
                // Bir component editor ekranına eklenmek istenmiştir.container
                this.props.onUpdateEditorList(newState);
                this.props.setOnEnd();
            }
        }
    }

    handleOnMove = (e) => {
        const that = this;
        const _data = {
            data: null,
            path: null,
            main: null,
        }
        this.props.setOnMove(_data);
        this.props.setOnMove(null);
    }
    handleOnAdd = (e) => {
        const that = this;
        this.props.setOnAdd();
    }
    handleOnRemove = (e) => {
        const that = this;
        this.props.setOnRemove();
    }

    render() {
        const { component } = this.props;
        const baseObject = {
            handleUpdate: this.updateState,
            baseArray: Object.assign({}, this.props.editor),
        }
        const { editor } = this.props;

        return (
            <Translation>
                {t => (
                    <>
                        {t("RIGHT_SIDE_TITLE_COMPONENTS")}
                        <hr />
                        {
                            (this.props.currentProjectId && this.props.pageStatus.status) ?
                            <>
                           { this.props.editor &&
                                this.props.editor.length > 0 ?
                                <div className="custom-tree-canvas">

                                
                                    <ReactSortable
                                         group={{
                                            name: (this.props.currentProjectId && this.props.pageStatus.status) ? 'Kurnaz-Tree' : 'noProject-busy-page2-tree',
                                            pull: true,
                                            put: [
                                                (this.props.currentProjectId && this.props.pageStatus.status) ? 'Kurnaz' : 'noProject-busy-page2-tree',
                                                (this.props.currentProjectId && this.props.pageStatus.status) ? 'Kurnaz-Tree' : 'noProject-busy-page2-tree2'
                                            ],
                                        }}
                                        swapThreshold={0.02}
                                        invertSwap={false}
                                        easing={"cubic-bezier(1, 0, 0, 1)"}
                                        animation="0"
                                        list={this.props.editor}
                                        setList={this.updateState}
                                        onStart={this.handleOnMove}
                                        onAdd={this.handleOnAdd}
                                        onRemove={this.handleOnRemove}
                                    >
                                        {   editor && (
                                            editor.map((item, index) => {
                                                return (
                                                    <GenerateTree
                                                        isChild={false}
                                                        key={item.id + "tree" + item._id}
                                                        index={index}
                                                        path={index + ""}
                                                        data={item}
                                                        baseProgress={baseObject} />
                                                )
                                            })
                                        )
                                        }
                                    </ReactSortable>
                                </div>
                                :
                                <p className="text-muted">
                                    {t("MESSAGE_COMPONENTS_HAVE_NOT_COMPONENT")}
                                </p>}
                            </>:
                                <p className="text-muted">
                                {t("PAGE_BUSY_ERROR")}
                                </p>}
                            
                            
                    </>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, globalpropsReducer, currentstatesReducer } = state;
    const { component, componentContext } = componentReducer;
    const { present } = editorReducer;

    const { editor, pages, currentPage, page } = present;

    const pageStyle = page.hasOwnProperty("pageProperties") ? page.pageProperties.style : null;
    const { globalProps } = globalpropsReducer;

    const { pageStatus, updateComponentPosition, currentProjectId } = currentstatesReducer;

    const { onMove, moveObj, isAdded, objFrom, currentEditor, isRemove, objFromId } = updateComponentPosition;

    return {
        editor,//:JSON.parse(editor),
        component,
        componentContext,
        pageStyle,
        globalProps,
        currentPage,
        pageStatus,

        onMove,
        isAdded,
        objFrom,
        currentEditor,
        isRemove,
        moveObj,
        currentProjectId,
        objFromId

    };
}
const mapDispatchToProps = {
    onUpdateEditorList: updateList,
    onAddFavComponent: addFavoriteComponent,
    setOnMove,
    setOnAdd,
    setOnAddUpdate,
    setOnRemove,
    setOnEnd
}
export default connect(mapStateToProps, mapDispatchToProps)(Components)
