import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./_helpers";
import * as Sentry from '@sentry/react';

import "./i18n";

// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
import "assets/css/App.css"

import 'assets/css/rsTheme.css'


import Main from "./layouts/Main";
import Auth from "views/auth";

Sentry.init({dsn: "https://2cc22743e4104ae798ec486726a19bac@o431334.ingest.sentry.io/5384538"});
var style1 = 'color: #9edbe3; background: #ccecf042; -webkit-text-stroke: 1px #2baebf; font-size: 30px;';
console.log('%cHi, Welcome to Mobile X 360', style1 );

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
      <Route path="/auth/:token" render={props => <Auth {...props} />} />
      <Route path="/setprojectid/:projectid" render={props => <Auth {...props} />} />
        <Route path="/" render={props => <Main {...props} />} />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
