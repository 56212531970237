import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from 'i18n';
import Emitter from "_actions/emitter";
import { ResponseStatusCode } from "_helpers";
import { setHttpObjects, sweetAlertActions } from '_actions';
import { HttpObjectService } from '_services'

import { Alert, Loader, Whisper, Tooltip, ButtonToolbar, IconButton, Icon, ButtonGroup, Input, InputGroup } from 'rsuite';
import {
    Row,
    Col,
    ListGroup,
} from "reactstrap";

import HttpObjectModal from 'components/Modals/HttpObjectModal';
import ManageEnvironmentsModal from 'components/Modals/ManageEnvironmentsModal';
import HttpObjectCard from 'components/HttpObjects/HttpObjectCard';

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;
class HttpObjects extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isHttpObjectModal: false,
            isEnvironmentsModal: false,
            selectedHttpObject: null,
            search: "",
            loading: false,
        }
    }
    componentDidMount = () => {

    }

    componentDidUpdate = (prevProps) => {
        const { httpObjects, currentProjectId } = this.props;
        if (prevProps.currentProjectId !== currentProjectId && currentProjectId) {
            this.getMyHttpObjects();
        }
        else if (prevProps.httpObjects !== httpObjects) {
            this.setState({
                search: httpObjects.searchTerm,
            })
        }
    }

    handleHttpObjectModal = (_data = null) => {
        const that = this;
        this.setState({
            selectedHttpObject: _data,
        }, () => {
            that.setState({
                isHttpObjectModal: !that.state.isHttpObjectModal,
            })
        })

    }

    handleEnvironmentsModal = () => {
        this.setState({
            isEnvironmentsModal: !this.state.isEnvironmentsModal,
        })
    }

    getMyHttpObjects = (_search = "") => {
        const that = this;
        const { currentProjectId, httpObjects, setHttpObjects } = this.props;
        const { search } = this.state;
        this.setState({ loading: true });

        HttpObjectService.STEPBYLOAD(
            1000, currentProjectId, httpObjects.isOrderByDesc, "-1", _search
        ).then(data => {

            const _data = data.dynamicValue;
            setHttpObjects(_data);
            that.setState({ loading: false });


        }).catch(err => {
            debugger
            that.setState({ loading: false });

            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

            //Alert.error(data.dynamicValue)
        });
    }

    handleChange = (event) => {
        clearTimeout(this.timer);
        this.setState({
            value: event.target.value,
        });
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    }

    setSearch = () => {
        const { value } = this.state;
        //this.getMyHttpObjects(value);
    }
    triggerChange = () => {
        this.setSearch();
    }

    componentWillMount() {
        this.timer = null;
    }

    onKeyDown = (event) => {
        if (event.key === 'Enter') { 
            event.preventDefault();
         }
    }


    render() {
        const { currentPage, httpObjects } = this.props;

        return (
            <Translation>
                {t => (
                    <>
                        <div className={"ho-header"}>
                            {t("TITLE_HTTP_OBJECTS")}
                            {
                                this.props.currentProjectId &&
                                <ButtonToolbar>
                                    <ButtonGroup>
                                        <IconButton appearance="ghost" onClick={() => this.handleHttpObjectModal(null)} icon={
                                            <Whisper placement="auto" trigger="hover" speaker={
                                                <Tooltip>
                                                    {t("TOOLTIP_HTTPOBJECT_CREATE")}
                                                </Tooltip>
                                            }>
                                                <Icon icon="plus-square-o" />
                                            </Whisper>
                                        } />
                                        <IconButton appearance="ghost" onClick={this.handleEnvironmentsModal} icon={
                                            <Whisper placement="auto" trigger="hover" speaker={
                                                <Tooltip>
                                                    {t("TOOLTIP_HTTPOBJECT_ENVIRONMENTS")}
                                                </Tooltip>
                                            }>
                                                <Icon icon="eye" />
                                            </Whisper>
                                        } />
                                        <IconButton appearance="ghost" onClick={() => {
                                            this.setState({value:""})
                                            this.getMyHttpObjects()}} icon={
                                            <Whisper placement="auto" trigger="hover" speaker={
                                                <Tooltip>
                                                    {t("TOOLTIP_HTTPOBJECT_REFRESH")}
                                                </Tooltip>
                                            }>
                                                <Icon icon="refresh" />
                                            </Whisper>
                                        } />

                                    </ButtonGroup>
                                </ButtonToolbar>
                            }

                        </div>
                        <hr />


                        {
                            this.props.currentProjectId ?
                                <>
                                    <InputGroup inside  >
                                        <Input
                                            value={this.state.value}
                                            onBlur={() => { }}
                                            onKeyDown={this.onKeyDown.bind(this)}
                                            onInput={this.handleChange.bind(this)}
                                            className={"form-control-sm property-dropdown-input " + ((this.state.type == "px" || this.state.type == "%") ? "" : "property-dropdown-input-display")}
                                            placeholder={t("PLACEHOLDER_SEARCH")}
                                            type="text" />
                                        <InputGroup.Button>
                                            <Icon icon="search" />
                                        </InputGroup.Button>
                                    </InputGroup>
                                    {
                                        currentPage &&
                                        <div>
                                            <Row>
                                                <Col>


                                                </Col>
                                            </Row>

                                            <div style={{ position: "relative" }}>
                                                {
                                                    httpObjects &&
                                                        httpObjects.dynamicValue.length > 0 ?
                                                        (httpObjects.dynamicValue.filter((i)=>i.httpObjectName.toLowerCase().includes(this.state.value?this.state.value.toLowerCase():""))).map((item, key) => (
                                                            <HttpObjectCard data={item} key={key} handleOpenModal={this.handleHttpObjectModal} />
                                                        ))
                                                        :
                                                        <div className={"ho-noresult"}>{t("MESSAGE_HTTPOBJECT_NOT_FOUNT")}</div>
                                                }
                                                {
                                                    this.state.loading &&
                                                    <Loader backdrop vertical />

                                                }
                                            </div>
                                            <HttpObjectModal selectedHttpObject={this.state.selectedHttpObject} isActive={this.state.isHttpObjectModal} handleToggle={this.handleHttpObjectModal} />
                                            <ManageEnvironmentsModal isActive={this.state.isEnvironmentsModal} handleToggle={this.handleEnvironmentsModal} />
                                        </div>
                                    }

                                </> : t("HAS_PROJECT_ERROR")

                        }

                    </>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, globalpropsReducer, currentstatesReducer, editorReducer } = state;
    const { component } = componentReducer;
    const { globalProps } = globalpropsReducer;
    const { environments, httpObjects, currentProjectId, uiEditorStatus } = currentstatesReducer;
    const { present } = editorReducer;
    const { editor, pages, currentPage, page } = present;

    return {
        componentReducer,
        component,
        globalProps,
        currentProjectId,
        uiEditorStatus,
        currentPage,
        environments,
        httpObjects,
    };
}
const mapDispatchToProps = {
    setHttpObjects,
    sweetAlertActions
}
export default connect(mapStateToProps, mapDispatchToProps)(HttpObjects)


