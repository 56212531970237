import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'


export class IconBox extends Component {
    render() {
        const { src, name, onClick, currentSelected, item,handleModalToggle } = this.props;
        return (
            <div
                tabIndex="1"
                onClick={() => {
                    onClick(item);
                    handleModalToggle();
                }}
                className={
                    "custom-fade-in  "+
                    "icons-modal-box"
                    + (item.id == currentSelected ? " icons-modal-box-active":"")
                }>
                <ReactSVG
                    src={src}
                    afterInjection={(error, svg) => {
                        if (error) {
                            console.error(error)
                            return
                        }
                    }}
                    beforeInjection={(svg) => {
                        svg.setAttribute('style', 'height:20px; fill:#8898aa')
                    }}
                    evalScripts="always"
                    fallback={() => <span></span>}
                    loading={() => <span>Loading</span>}
                    renumerateIRIElements={false}
                    className="icons-modal-box-icon"
                    wrapper="span"
                />
                <span className={"icons-modal-box-span"}>{name}</span>
            </div>

        )
    }
}

export default IconBox
