import React, { Component } from 'react'
import { Button } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";


export class CButton extends Component {
    render() {
        const { value, loading, color, size, onClick,disabled } = this.props;
        return (

            <Button
                color={color}
                size={size}
                onClick={onClick}
                disabled={disabled}
                type="button">
                <div className={"c-button-container-text"}>
                    {
                        loading ?
                            <ClipLoader
                                className={"c-button-container-loading"}
                                size={15}
                                color={"#FFF"}
                                loading={loading}
                            /> :
                            value
                    }
                </div>
            </Button>
        )
    }
}

export default CButton
