import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from 'i18n';

import { FormGroup, Form, Input, Row, Col, ButtonGroup, Button } from "reactstrap";
import { updateComponent, updateList } from '../../../_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';
 
import NumaricDataProperty from "../Core/NumaricDataProperty"
import { Dropdown } from 'rsuite';


import {
    PropertyTitle,
} from "../Core/";

const defautValueOptions = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: true,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: false,
    }
]

class CarouselSettings extends Component {
    constructor(props) {
        super(props)
        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        
        this.state = {
            autoplay: component && (defautValueOptions.find(x => x.value === object.props.autoplay).value),
            showCounter: component && (defautValueOptions.find(x => x.value === object.props.showCounter).value),
        }

    }
    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const style = object.props[this.props.dataPath ? this.props.dataPath : "style"];

        if (prevProps.component != component) {
            this.setState({
                autoplay: (defautValueOptions.find(x => x.value === object.props.autoplay).value),
                showCounter: (defautValueOptions.find(x => x.value === object.props.showCounter).value),

            }, () => {
                //this.generateFontSizeInput();
                //   var _fontSizeSlider = this.fontSizeSlider.current;
                // _fontSizeSlider.noUiSlider.set(this.state.fontSize)

            })
        }
    }


    componentDidMount() {

    }

    handleUpdateValue = (_data, _name) => {

        this.setState({
            [_name]: _data.value,
        }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";
            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_name] = _data.value;
            } else {
                _clone.components[0].object.props[_name] = _data.value;
            }

            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

    render() {
        const { component } = this.props;
        return (
            <Translation>
                {t => (
                    <Form>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_CAROUSEL_AUTOPLAY")}
                                </label>
                                {
                                    this.state["autoplay"] !== null &&
                                    <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["autoplay"]).label}>
                                        {
                                            defautValueOptions.map((x, key) => (
                                                x.value === this.state["autoplay"] ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "autoplay")}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                }

                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_CAROUSEL_PAGEWIDTH")} subtitle={""} />
                            <NumaricDataProperty key={"pageWidth-carousel"+component.id} propName={"pageWidth"} isType={true}/>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_CAROUSEL_SPACINGS")} subtitle={""} />
                            <NumaricDataProperty key={"pageSpacings-carousel"+component.id}  propName={"itemSpacings"} isType={false}/>
                        </FormGroup>
                        
                        {
                            /*
                               <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_CAROUSEL_SHOWCOUNTER")}
                                </label>
                                {
                                    this.state["showCounter"] !== null &&
                                    <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["showCounter"]).label}>
                                        {
                                            defautValueOptions.map((x, key) => (
                                                x.value === this.state["showCounter"] ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "showCounter")}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                }

                            </div>
                        </FormGroup>

                            
                            */
                        }



                    </Form>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;
    return {
        editor,
        component,
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,

}
export default connect(mapStateToProps, mapDispatchToProps)(CarouselSettings)
