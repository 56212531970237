import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { Translation } from "react-i18next";
import { FormGroup, Form, Input, Row, Col, ButtonGroup, Button } from "reactstrap";

import { updateComponent, updateListSocket, setPage, removeComponent, setUIEditorStatus } from '_actions';
import HttpObjectSelector from 'components/HttpObjects/HttpObjectSelector';
import i18n from 'i18n';
import { Dropdown } from 'rsuite';
import DynamicFieldValue from "../Core/DynamicFieldValue"
import { findObjUpdate, } from 'assets/util';

import {
    PropertyTitle,
} from "../Core/";


import UISelector from 'components/RigthSidebar/UISettings/UISelector';

const defautValueOptions = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: true,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: false,
    }
]
class ListItemSettings extends Component {
    constructor(props) {
        super(props)

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const { listItem } = object;

        this.state = {
            ui: listItem,
            httpObjectsData: [],
            httpObjectDisabledData: [],
            isHttpObjectsData: false,
            scrollEnabled: component && object.props.hasOwnProperty("scrollEnabled") ? ((defautValueOptions.find(x => x.value === object.props.scrollEnabled).value)) : true,
            inverted: component && object.props.hasOwnProperty("inverted") ? ((defautValueOptions.find(x => x.value === object.props.inverted).value)) : false,

        }

    }

    handleToggleHttpObject = () => {
        const { isHttpObjectsData } = this.state;
        this.setState({
            isHttpObjectsData: !isHttpObjectsData,
        })
    }


    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const { listItem } = object;

        if (prevProps.component != component) {
            this.state = {
                ui: listItem,
            }
        }
    }


    componentDidMount = async () => {
        const { httpObjects } = this.props;
        let result = await this.prepareCascaderData(httpObjects.dynamicValue);

        this.setState({
            httpObjectsData: result,
            httpObjectDisabledData: this.findDisabledData(result, "array")
        })

    }


    handleUpdateValue = (_data, _name) => {

        this.setState({
            [_name]: _data.value,
        }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";
            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
            } else {
                _clone.components[0].object.props[_name] = _data.hasOwnProperty("value") ? _data.value : _data;
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }



    handleTest = (_field = "listItem") => {
        const {
            setUIEditorStatus,
            uiEditorStatus,
            onUpdateList,
            clearHistory,
            setPage,
            component,
            removeComponent,
            page,
            editor
        } = this.props;

        const _currentEditor = Object.assign({}, editor);
        const _currentPage = Object.assign({}, page);
        const _currentComponent = Object.assign({}, component);

        let _currentComponentData = null;

        if (component.hasOwnProperty("componentOwner")) {
            _currentComponentData = Object.assign({}, component.object.components[0]);
        } else {
            _currentComponentData = Object.assign({}, component.components[0]);
        }

        if (uiEditorStatus) {
            /*
                ! UI Editor Kapatılacaktır.
                Mevcut sayfa içeriği bir diğer değişken içerisine veri kaybı 
                olmaması için aktarılacaktır.
            */
            _currentPage.detail = _currentEditor;
            //  setPage(_currentPage); // Editor
            onUpdateList(_currentComponentData.object[_field] ? [_currentComponentData.object[_field]] : []);
            clearHistory();
            removeComponent();
            setUIEditorStatus(true, { ..._currentComponent, _field: _field }, _field);
        } else {
            /*
                ! UI Editor Açılacak.
                Mevcut sayfa içeriği bir diğer değişken içerisine veri kaybı 
                olmaması için aktarılacaktır.
            */
             debugger
            _currentPage.detail = _currentEditor;
            setPage(_currentPage); // Editor
            onUpdateList(_currentComponentData.object[_field] ? [_currentComponentData.object[_field]] : []);
            clearHistory();
            removeComponent();
            setUIEditorStatus(true, { ..._currentComponent, _field: _field }, _field);

        }

    }

    prepareCascaderData = async (_data) => {
        let _array = [];

        await _data.map(async (item, key) => {
            let _obj = {
                "value": (key) + "",//item.id,
                "label": item.httpObjectName,
                "children": [],
            }
            let detail = JSON.parse(item.successDynamicObjects);
            if (Array.isArray(detail)) {
                _obj["type"] = "array";
                _obj["children"] = await this.generateCascaderChild("array", detail, key);
            } else if (typeof detail == "object") {
                _obj["type"] = typeof detail;
                _obj["children"] = await this.generateCascaderChild("object", detail, key);
            } else {
                _obj["type"] = typeof detail;
            }
            _array.push(_obj)
        })
        return _array;

    }

    generateCascaderChild = async (_type, _value, _parentKey) => {
        let _array = [];

        await (_type == "array" ? _value : Object.values(_value)).map(async (item, key) => {
            let _obj = {
                //  label: "[index]",
                value: _parentKey + "-" + (key),
            }
            if (Array.isArray(item)) {
                _obj.label = Object.keys(_value)[key];
                //  debugger

                try {
                    _obj["children"] = item[0] === null ? [] : await this.generateCascaderChild("array", item, _parentKey + "-" + key)
                } catch (error) {
                    debugger
                }
                _obj["type"] = "array";
            } else if (typeof item == "object") {
                // debugger
                _obj.label = Object.keys(_value)[key];
                _obj["type"] = typeof item;
                try {
                    if (Object.keys(item).length > 0)
                        _obj["children"] = await this.generateCascaderChild("object", item, _parentKey + "-" + key);
                } catch (error) {
                    debugger
                }
            } else {
                _obj["label"] = Object.keys(_value)[key];
                _obj["type"] = typeof item;
            }

            _array.push(_obj);
        })

        return _array;
    }

    findDisabledData = (mainState, valToFind) => {
        let _ms = mainState;

        if (mainState) {
            let foundObj = [];
            let aa = JSON.stringify(_ms, (_, nestedValue) => {
                if (nestedValue && nestedValue.type !== valToFind) {
                    foundObj.push(nestedValue.value);
                }
                return nestedValue;
            });
            return foundObj;
        } else {
            return null;
        }
    }

    render() {
        const { component, httpObjects } = this.props;
        const { httpObjectsData, httpObjectDisabledData } = this.state;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        return (
            <Translation>
                {t => (
                    <>
                        <Form>
                            <FormGroup className={"property-form-group"}>
                                <PropertyTitle title={t("PROPERTY_UI_ITEM")} subtitle={""} />
                                <button
                                    onClick={()=>this.handleTest()}
                                    type="button"
                                    className="btn btn-info btn-sm btn-block">
                                    {"Open UI Editor"}
                                </button>
                                {
                                    /*
                                     <UISelector currentUI={this.state.ui} propName={"listItem"} />
                                    */
                                }
                            </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <PropertyTitle title={t("PROPERTY_UI_DATA_COLLECTION")} subtitle={""} />
                                <DynamicFieldValue dataType="array" />
                            </FormGroup>

                            {
                                object.type == "datalayout" &&
                                <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}>
                                        <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_PAGE_SWIPER_SCROLLABLE")}
                                        </label>
                                        {
                                            this.state["scrollEnabled"] !== null &&
                                            <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["scrollEnabled"]).label}>
                                                {
                                                    defautValueOptions.map((x, key) => (
                                                        x.value === this.state["scrollEnabled"] ?
                                                            "" :
                                                            <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "scrollEnabled")}>{x.label}</Dropdown.Item>
                                                    ))
                                                }
                                            </Dropdown>
                                        }

                                    </div>
                                </FormGroup>
                            }


                            {
                                object.type == "datalayout" &&
                                <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}>
                                        <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_PAGE_SWIPER_INVERTED")}
                                        </label>
                                        {
                                            this.state["inverted"] !== null &&
                                            <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["inverted"]).label}>
                                                {
                                                    defautValueOptions.map((x, key) => (
                                                        x.value === this.state["inverted"] ?
                                                            "" :
                                                            <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x, "inverted")}>{x.label}</Dropdown.Item>
                                                    ))
                                                }
                                            </Dropdown>
                                        }

                                    </div>
                                </FormGroup>
                            }

                        </Form>
                        {
                            /*
                                <HttpObjectSelector isActive={this.state.isHttpObjectsData} handleToggle={this.handleToggleHttpObject} />
                            */
                        }
                    </>

                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { currentProjectId, uiEditorStatus, httpObjects } = currentstatesReducer;

    const { present } = editorReducer;
    const { editor, page } = present;
    return {
        page,
        editor,
        component,
        uiEditorStatus,
        currentProjectId,
        httpObjects
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
        removeComponent: () => dispatch(removeComponent()),
        setPage: (_data) => dispatch(setPage(_data)),
        updateComponent: (_data) => dispatch(updateComponent(_data)),
        onUpdateList: (_data) => dispatch(updateListSocket(_data)),
        setUIEditorStatus: (_data, _component) => dispatch(setUIEditorStatus(_data, _component)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ListItemSettings)
