import {
    //ServiceURLs,
    ExportEndPoints,
    GetBaseCreatorURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const ExportService = {
    EXPORT_PROJECT,
    GET_MY_EXPORT
  };

  

  function GET_MY_EXPORT(RelProjectId) {
    var js = {
      RelProjectId:RelProjectId 
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + ExportEndPoints.GET_MY_EXPORT,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 


  /*  target
        iOSProject =0,
        AndroidProject =1,
        APKFile =2
  */

  // //

  /* 
    public enum ExportTypes
    {
        WaitingProcess =0,
        RequestBeginProcess =1,
        PreparingInlineData =2,
        PreparingAPPSettings =3,
        Ready =4,
        Error =5
    }
  */

  function EXPORT_PROJECT(RelProjectId,Version,Target) {
    var js = {
      RelProjectId:RelProjectId,
      Version,
      Target
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + ExportEndPoints.EXPORT_PROJECT,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 