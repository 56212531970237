//connection bilgileri burada dir.
import socketIOClient from "socket.io-client";
import { EnCryptForHandShake } from "./external";
import { GetBaseSocketURL } from "_services/_serviceSettings";
import {SocketOperations} from "_constants";
import Emitter from "_actions/emitter";
//import { SocketOperationsLocal } from "_constants";
var socket = null;
var prop = null;
export function PrapareSocket(refOBJ) {
  var token = {
    token: "ananinamiankaragucu"
  };
  socket = socketIOClient(GetBaseSocketURL(), {
    query: token
  });
  

  socket.on("securequestion", securequestionAnswer);
  socket.on("giveMeToken", sendToken);

  /*
  dispatch({
    type: SocketOperationsLocal.SET_SOCKET_REF,
    socket: socket
  });
*/
refOBJ(socket);


socket.on(SocketOperations.GET_AUTO_SAVE,GET_AUTO_SAVE);
socket.on(SocketOperations.GET_CURRENT_PAGE_USERS,GET_CURRENT_PAGE_USERS);
socket.on(SocketOperations.GIVE_DETAIL,GIVE_DETAIL);
socket.on(SocketOperations.PAGE_CONTROL_AVAILABLE_RESULT,PAGE_CONTROL_AVAILABLE_RESULT);

//export dalgasi
socket.on(SocketOperations.exportOperationsObserve,exportOperationsObserve);
}

function exportOperationsObserve(data){
  Emitter.emit(SocketOperations.exportOperationsObserve,data);
  }


function PAGE_CONTROL_AVAILABLE_RESULT(data){
  Emitter.emit(SocketOperations.PAGE_CONTROL_AVAILABLE_RESULT,data);
  }

function GIVE_DETAIL(data){
  Emitter.emit(SocketOperations.GIVE_DETAIL,data);
  }
function GET_AUTO_SAVE(data){
Emitter.emit(SocketOperations.GET_AUTO_SAVE,data);
}

function GET_CURRENT_PAGE_USERS(data){
Emitter.emit(SocketOperations.GET_CURRENT_PAGE_USERS,data);
}





function sendToken() {
  var token =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).token;
  socket.emit("giveMeToken", {
    Response: token
  });
}

function securequestionAnswer(data) {
  var calc = EnCryptForHandShake(data.Request);
  socket.emit("securequestion", {
    Response: calc
  });
}

export function GetSocket() {
  return socket;
}
