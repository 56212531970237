import React, { Component } from 'react'
import { Translation } from "react-i18next";
import { connect } from 'react-redux'
import QRCode from "react-qr-code";

class CustomProgressBar extends Component {

    componentDidMount = () => {
        // debugger
        const that = this;
        const { editor, path, isChild, main } = that.props;
        if (isChild) {
            const hasObj = that.findNestedObj(editor, main.id);
            if (!hasObj)
                that.handleDirectSetID(editor, path);
        }
    }

    findNestedObj = (mainState, valToFind) => {
        if (mainState) {
            let foundObj;
            JSON.stringify(mainState, (_, nestedValue) => {
                if (nestedValue && nestedValue["id"] === valToFind) {
                    foundObj = nestedValue;
                }
                return nestedValue;
            });
            //debugger;

            return foundObj;
        } else {
            return null;
        }

    };


    getComp = (_data) => {
        try {
            if (_data.hasOwnProperty("componentOwner")) { return _data.object.components[0]; }
            else { return _data.components[0]; }
        } catch (error) {
            debugger
        }


    }

    handleDirectSetID = async (old, _path) => {
        const that = this;
        const { main } = this.props;

        const p = _path.split("-")
        let current = null;

        p.forEach((element, index) => {
            if (index === 0) {
                current = this.getComp(old[element]);
            } else if (index === p.length - 1) {
                //current.object.data[element].object.data
                try {

                    current.object.data.splice(element, 1);
                    current.object.data.splice(element, 0, main);

                } catch (error) {
                    debugger
                }
            }
            else {
                current = this.getComp(current.object.data[element]);
            }
        });



    }


    render() {
        const { data, component, main, isChild } = this.props;


        /* GLOBAL PROPS */

        /*  GLOBAL Background Color */
        let gBGIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalBackgroundColor) : -1;
        let gBGColor = ((gBGIndex > -1) && (this.props.globalProps[gBGIndex].type == 0)) ? this.props.globalProps[gBGIndex].value : data.props.bgColor;
        let gBG = (data.props.style.hasOwnProperty("globalBackgroundColor")) ? gBGColor : data.props.bgColor;


        /*  GLOBAL Font Color */
        let gFCIndex = this.props.globalProps ? this.props.globalProps.findIndex(x => x.id === data.props.style.globalProgressBackgroundColor) : -1;
        let gFCColor = ((gFCIndex > -1) && (this.props.globalProps[gFCIndex].type == 0)) ? this.props.globalProps[gFCIndex].value : data.props.pbgColor;
        let gFC = (data.props.style.hasOwnProperty("globalProgressBackgroundColor")) ? gFCColor : data.props.pbgColor;



        /* Standar ve cutom componentleri standart a çevirme işlemi */

        let _component;
        if (component) {
            if (component.hasOwnProperty("componentOwner"))
                _component = component.object.components[0];
            else
                _component = component.components[0]
        }

        //data.props.size
        return (
            <Translation>
                {t => (
                    <div style={{
                        display: "flex",
                        backgroundColor: gBG ? gBG : "",
                        width: "100%",
                        position:"relative",
                        overflow:"hidden",
                        height: data.props.size ? data.props.size : "5px",
                        borderRadius: "10px",
                    }}>
                        <div style={{
                            width: data.props.value ? (data.props.value + "%") : "50%",
                            height: "100%",
                            backgroundColor: gFC ? gFC : "",
                        }}></div>
                    </div>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, globalpropsReducer } = state;
    const { component } = componentReducer;
    const { globalProps } = globalpropsReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        componentReducer,
        component,
        globalProps,
        editor,//: JSON.parse(editor),

    };
}
const mapDispatchToProps = {
    //  onUpdateComponent: updateComponent,

}
export default connect(mapStateToProps, mapDispatchToProps)(CustomProgressBar)
