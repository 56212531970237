import React, { Component } from 'react'
import classnames from "classnames";
import { connect } from 'react-redux'
import PerfectScrollbar from "react-perfect-scrollbar";
//import { components } from '../../assets/test/components'
import i18n from "i18n";
import Emitter from "_actions/emitter";
import { ResponseStatusCode } from "_helpers";

import {
  GetAuthURL,
  userService,
  ComponentService,
  globalPropService,
  pagesService,
  ProjectService,
  CustomComponentService
} from "_services";

import {
  setMyComponentList,
} from "_actions";

import {
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
} from "reactstrap";
import SidebarNavItem from './SidebarNavItem';
import SidebarNavItemCustom from './SidebarNavItemCustom';

class Sidebar extends Component {

  constructor(props) {
    super(props)

    this.state = {
      sidenavOpen: true,

    }
  }

  getMyComponentList = () => {
    const { setMyComponentList } = this.props;
    CustomComponentService.GetMycustomComponents().then(data => {
      setMyComponentList(data.dynamicValue);
    }).catch(err => {
      let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
      Emitter.emit("SHOW_WARNING_MODAL", data);
    });
  }



  onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      // document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };

  // toggles collapse between mini sidenav and normal
 /*
  toggleSidenav = e => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    });
  };

  */


  render() {
    const { sidenavOpen } = this.props;
    const _logo = require("assets/img/brand/m360logo.png");
     const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center hamburgerMenu-Header">
          {(
            <NavbarBrand>
              <img
                alt={"MobileX360"}
                className="navbar-brand-img"
                src={_logo&&_logo.default}
              />
            </NavbarBrand>
          )}
          <div className="ml-auto">
            <div
              className={classnames("sidenav-toggler d-none  d-xl-block", {
                active: !this.props.sidenavOpen
              })}
              onClick={this.props.toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>

        {
          /* Menü seçenekleri buraya gelmeli */
          <div className="navbar-inner">
            <Collapse navbar isOpen={true}>
              <Nav className="mb-md-3" navbar>
                {
                  this.props.myComponentList &&
                  (
                    <SidebarNavItemCustom key={"mysidebar-01-mycomponent"} data={this.props.myComponentList} sidebarStatus={!sidenavOpen} />

                  )
                }
                {
                  this.props.componentList && (
                    <>
                      {
                        this.props.componentList.map((obj, key) => {
                          return (
                            <SidebarNavItem key={"sidebar-01" + key} data={obj} sidebarStatus={!sidenavOpen} />
                          )
                        })
                      }
                    </>

                  )
                }
              </Nav>
            </Collapse>
          </div>
        }

      </div>
    );
    return (
      <>
        <Navbar
          onMouseEnter={this.onMouseEnterSidenav}
          onMouseLeave={this.onMouseLeaveSidenav}
          className={"sidenav navbar-vertical navbar-expand-xs navbar-light bg-white mobile-sidenav "
            + ("fixed-left")
          }>
          {navigator.platform.indexOf("Win") > -1 ? (
            <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
          ) : (
              scrollBarInner
            )}
        </Navbar>

        {
          /*
          this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null
        */
        }
      </>


    )
  }
}

const mapStateToProps = (state, props) => {
  const { componentReducer } = state;
  const { componentList, myComponentList } = componentReducer;

  return {
    componentList,
    myComponentList,
  };
}
const mapDispatchToProps = {
  //  onUpdateComponent: updateComponent,
  setMyComponentList,

}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)


