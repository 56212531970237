import React, { Component } from 'react'
import { Translation } from "react-i18next";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Emitter from "_actions/emitter";

import { sweetAlertActions } from '_actions'

export class SweetModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            alert: null,
        }
    }

    componentDidMount() {
        Emitter.on("SHOW_ASK", payload =>
            this.setState(
                {
                    titleAsk: payload.title_ask,
                    titleSuccess: payload.title_succ,
                    titleUnsuccess: payload.title_unsucc,
                    detailAsk: payload.detail_ask,
                    detailSuccess: payload.detail_succ,
                    detailUnsuccess: payload.detail_unsucces
                },
                function () {
                    this.setState({ alert: this.getAskAlert() });
                }
            )
        );

        Emitter.on("SHOW_SUCCESS", payload =>
            this.setState(
                {
                    titleSuccess: payload.title,
                    detailSuccess: payload.detail
                },
                function () {
                    this.setState({ alert: this.getSuccessAlert() });
                }
            )
        );

        Emitter.on("SHOW_UNSUCCESS", payload =>
            this.setState(
                {
                    titleUnsuccess: payload.title,
                    detailUnsuccess: payload.detail
                },
                function () {
                    this.setState({ alert: this.getUnsuccessAlert() });
                }
            )
        );

        Emitter.on("SWEETALERT_CLEAR", any => this.setState({ alert: null }));

    }

    componentWillUnmount() {
        Emitter.off("SHOW_ASK");
        Emitter.off("SHOW_SUCCESS");
        Emitter.off("SHOW_UNSUCCESS");
        Emitter.off("SWEETALERT_CLEAR");
    }

    getAskAlert = (title, desc) => {

        return (
            <ReactBSAlert
                custom
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.titleAsk}
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => this.handleAlertYes()}
                onCancel={() => this.handleAlertCancel()}

                showCancel

                confirmBtnBsStyle={"danger"}
                confirmBtnText="Yes, delete it!"

                cancelBtnBsStyle={"secondary"}
                cancelBtnText="Cancel"


                btnSize=""
            >
                {this.state.detailAsk}
            </ReactBSAlert>
        );
    };
    getSuccessAlert = () => {
        const { classes, data } = this.props;
        return (
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.titleSuccess}
                onConfirm={() => this.handleHideAlert()}
                onCancel={() => this.handleHideAlert()}
                confirmBtnBsStyle={"secondary"}
                btnSize=""
            >
                {this.state.detailSuccess}
            </ReactBSAlert>
        );
    };
    getUnsuccessAlert = () => {
        const { classes, data } = this.props;
        return (
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.titleUnsuccess}
                onConfirm={() => this.handleHideAlert()}
                onCancel={() => this.handleHideAlert()}
                confirmBtnBsStyle={"secondary"}
                btnSize=""
            >
                {this.state.detailUnsuccess}
            </ReactBSAlert>
        );
    };

    handleHideAlert = () => {
        this.setState({ alert: null });
    };
    handleAlertYes = () => {
        Emitter.emit("handleYes", null);
        this.setState({
            alert: this.getSuccessAlert()
        });
    };
    handleAlertCancel = () => {
        Emitter.emit("handleNo", null);
        this.setState({
            alert: this.getUnsuccessAlert()
        });
    };


    handleShowAlert = () => {
        sweetAlertActions.SHOW_ASK(
            "title ask",
            "basarili title",
            "basarisiz title",
            "detay ask",
            "detay basarili",
            "detay basarisiz"
        );

        /*
        this.setState({
          alert: this.getAskAlert()
        });
        */
    };

    render() {
        return (
            <>
                {this.state.alert}
            </>
        )
    }
}

export default SweetModal
