export const currentstatesConstants = {

    SET_CURRENTPAGE: 'SET_CURRENTPAGE',
    SET_MYSTREAM: 'SET_MYSTREAM',
    SET_PROJECTSTREAM: 'SET_PROJECTSTREAM',
    SET_SYSTEMSTREAM: 'SET_SYSTEMSTREAM',


    SET_EDITORLOADING: 'SET_EDITORLOADING',
    SET_COMPONENTLISTLOADING: 'SET_COMPONENTLISTLOADING',
    SET_MYCOMPONENTLISTLOADING: 'SET_MYCOMPONENTLISTLOADING',
    SET_UPLOADIMAGELOADING: 'SET_UPLOADIMAGELOADING',
    SET_MYSTREAMLOADING: 'SET_MYSTREAMLOADING',
    SET_PROJECTSTREAMLOADING: 'SET_PROJECTSTREAMLOADING',
    SET_SYSTEMSTREAMLOADING: 'SET_SYSTEMSTREAMLOADING',
    SET_PAGESTATUS: 'SET_PAGESTATUS',


    SET_ONMOVE_STATUS: 'SET_ONMOVE_STATUS',
    SET_ONADD_STATUS: 'SET_ONADD_STATUS',
    SET_ONADD_UPDATE:'SET_ONADD_UPDATE',
    SET_ONREMOVE_STATUS: 'SET_ONREMOVE_STATUS',
    SET_ONEND_STATUS: "SET_ONEND_STATUS",

    SET_PROJECT_ID:"SET_PROJECT_ID",
    SET_PROJECT:"SET_PROJECT",

    SET_PROJECT_STATIC_COLLECTIONS:'SET_PROJECT_STATIC_COLLECTIONS',

    SET_UI_EDITOR_STATUS:'SET_UI_EDITOR_STATUS',
    SET_UI_EDITOR_COMPONENT:'SET_UI_EDITOR_COMPONENT',
    SET_UI_EDITOR_STATUS_BACK:'SET_UI_EDITOR_STATUS_BACK',

    SET_ENVIROMENTS:'SET_ENVIROMENTS',
    SET_HTTPOBJECTS:'SET_HTTPOBJECTS',
    SET_DYNAMICFUNCS:'SET_DYNAMICFUNCS',
    SET_PAGEFUNCS:'SET_PAGEFUNCS',

    SET_PROJECT_EXPORTS:'SET_PROJECT_EXPORTS',
    SET_MY_PROJECTS:'SET_MY_PROJECTS',
    SET_RESET:"SET_RESET",

    SET_COMPONENT_NAMES:"SET_COMPONENT_NAMES",
    SET_CUSTOM_FUNCTIONS:"SET_CUSTOM_FUNCTIONS"



}