import {
    currentstatesConstants
} from "../_constants";

const initialState = {
    //Proje içerisinde değişiklik varmı durumu konrolu yapmak için tutulan stateler
    myProjects: null,
    currentProjectId: null,
    currentProject: null,
    currentProjectExports: [],
    openedPages: [],
    staticCollections: null,
    currentMyStream: {
        count: 0,
        dynamicValue: [], // Array list
        isOrderByDesc: false,
        page: 0,
        rowPerCount: 10,
        searchTerm: "",
        lastIndexId: null,
        firstIndexID: "-1"
    },

    currentProjectStream: {
        count: 0,
        dynamicValue: [], // Array list
        isOrderByDesc: false,
        page: 0,
        rowPerCount: 10,
        searchTerm: "",
        lastIndexId: null,
        firstIndexID: "-1"
    },

    currentSystemStream: {
        page: 0,
        rowPerCount: 5,
        count: 0,
        isOrderByDesc: false,
        searchTerm: "",
        dynamicValue: [], // Array list
        lastIndexId: null,
        firstIndexID: "-1"
    },

    //Sayfa içersinde loading durumları aşağıda ki state değerlerine göre kontrol edeceğiz
    isEditorLoading: false,
    isComponentListLoading: false,
    isMyComponentListLoading: false,
    isImageUploadLoading: false,

    isMyStreamLoading: true,
    isProjectStreamLoading: true,
    isSystemStreamLoading: true,

    pageStatus: {
        status: true, // true is free
        data: {
            age: "",
            gender: "", // 0-1
            id: "",
            name: "",
            profilePhotoImages: [],
            surname: null,
            userName: "",
        }

    },

    updateComponentPosition: {
        onMove: false,
        moveObj: null,

        isAdded: false,
        objFrom: null, // Eğer null ise base işlemidir. gelen değer örn: "2-1"
        objFromId: null,
        currentEditor: null,

        isRemove: false,

    },
    uiEditorStatus: false,
    uiEditorComponent: null,
    environments: {
        count: 9999,
        dynamicValue: [],
        isOrderByDesc: false,
        searchTerm: "",
    },
    httpObjects: {
        searchTerm: "",
        count: 20,
        lastIndexId: "-1",
        fullListLastObjectid: "-1",
        isOrderByDesc: false,
        dynamicValue: []
    },
    dynamicFunctions: {
        searchTerm: "",
        count: 20,
        lastIndexId: "-1",
        fullListLastObjectid: "-1",
        isOrderByDesc: false,
        dynamicValue: []
    },
    pageFunctions: [],
    componentNames: [],
    customFunctions: []

};



export function currentstatesReducer(state = initialState, {
    type,
    payload
}) {
    switch (type) {

        case currentstatesConstants.SET_RESET:
            return {
                ...initialState
            };
        case currentstatesConstants.SET_CURRENTPAGE:
            return {
                ...state,
                component: {
                    //...state.component,
                    ...payload.page
                }
            };
        case currentstatesConstants.SET_UPLOADIMAGELOADING:
            return {
                ...state,
                isImageUploadLoading: payload.data,
            };

        case currentstatesConstants.SET_MYSTREAM:
            return {
                ...state,
                currentMyStream: payload.data,
            };
        case currentstatesConstants.SET_PROJECTSTREAM:
            return {
                ...state,
                currentProjectStream: payload.data,
            };
        case currentstatesConstants.SET_SYSTEMSTREAM:
            return {
                ...state,
                currentSystemStream: payload.data,
            };

        case currentstatesConstants.SET_MYSTREAMLOADING:
            return {
                ...state,
                isMyStreamLoading: payload.data,
            };
        case currentstatesConstants.SET_PROJECTSTREAMLOADING:
            return {
                ...state,
                isProjectStreamLoading: payload.data,
            };
        case currentstatesConstants.SET_SYSTEMSTREAMLOADING:
            return {
                ...state,
                isSystemStreamLoading: payload.data,
            };
        case currentstatesConstants.SET_PAGESTATUS:
            return {
                ...state,
                pageStatus: payload.data,
            };
        case currentstatesConstants.SET_ONADD_STATUS:
            return {
                ...state,
                updateComponentPosition: {
                    ...state.updateComponentPosition,
                    isAdded: payload.data,
                } // payload.data,
            };
        case currentstatesConstants.SET_ONMOVE_STATUS:
            return {
                ...state,
                updateComponentPosition: {
                    ...state.updateComponentPosition,
                    onMove: payload.status,
                    moveObj: payload.data
                }
            };

        case currentstatesConstants.SET_ONADD_UPDATE:
            return {
                ...state,
                updateComponentPosition: {
                    ...state.updateComponentPosition,
                    objFrom: payload.objFrom,
                    objFromId: payload.objFromId,
                    currentEditor: payload.currentEditor,
                }
            };

        case currentstatesConstants.SET_ONREMOVE_STATUS:
            return {
                ...state,
                updateComponentPosition: {
                    ...state.updateComponentPosition,
                    isRemove: true, //payload.data
                }
            };
        case currentstatesConstants.SET_ONEND_STATUS:
            return {
                ...state,
                updateComponentPosition: {
                    onMove: false,
                    isAdded: false,
                    isRemove: false,
                    objFrom: null,
                    currentEditor: null,
                }
            };
        case currentstatesConstants.SET_PROJECT_ID:
            return {
                ...state,
                currentProjectId: payload.id
            };
        case currentstatesConstants.SET_PROJECT:
            return {
                ...state,
                currentProject: payload.data
            };
        case currentstatesConstants.SET_PROJECT_STATIC_COLLECTIONS:
            return {
                ...state,
                staticCollections: payload.data
            };
        case currentstatesConstants.SET_UI_EDITOR_STATUS:

            let _uiEditorHistory = payload.data ? [...(state.uiEditorComponent ? state.uiEditorComponent : [])] : null;
            if (_uiEditorHistory) _uiEditorHistory.push({
                backup:JSON.parse(JSON.stringify(payload.component)),
                current:JSON.parse(JSON.stringify(payload.component)),
            });
            return {
                ...state,
                uiEditorStatus: payload.data,
                uiEditorComponent: _uiEditorHistory
            };
        case currentstatesConstants.SET_UI_EDITOR_STATUS_BACK:

            let _uiEditorHistoryBack =(state.uiEditorComponent && state.uiEditorComponent.length > 0) ? state.uiEditorComponent : null;
            if (_uiEditorHistoryBack) {
                _uiEditorHistoryBack.pop();
            }
            if(_uiEditorHistoryBack.length === 0){_uiEditorHistoryBack=null;}

            
             return {
                ...state,
                uiEditorStatus: (_uiEditorHistoryBack && _uiEditorHistoryBack.length > 0) ? state.uiEditorStatus:false,
                uiEditorComponent: _uiEditorHistoryBack
            };
        case currentstatesConstants.SET_ENVIROMENTS:
            return {
                ...state,
                environments: payload.data
            };
        case currentstatesConstants.SET_HTTPOBJECTS:
            return {
                ...state,
                httpObjects: payload.data
            };
        case currentstatesConstants.SET_DYNAMICFUNCS:
            return {
                ...state,
                dynamicFunctions: payload.data
            };
        case currentstatesConstants.SET_PAGEFUNCS:
            return {
                ...state,
                pageFunctions: payload.data
            };
        case currentstatesConstants.SET_PROJECT_EXPORTS:
            return {
                ...state,
                currentProjectExports: payload.data
            };
        case currentstatesConstants.SET_MY_PROJECTS:
            return {
                ...state,
                myProjects: payload.data
            };
        case currentstatesConstants.SET_COMPONENT_NAMES:
            return {
                ...state,
                componentNames: payload.data
            };
        case currentstatesConstants.SET_CUSTOM_FUNCTIONS:
            return {
                ...state,
                customFunctions: payload.data
            };

        default:
            return state;
    }
}