import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from 'i18n';
import Emitter from "_actions/emitter";
import { ResponseStatusCode } from "_helpers";
import { setHttpObjects, sweetAlertActions } from '_actions';
import { HttpObjectService } from '_services'

import { Alert, IconButton, Icon, Whisper, Tooltip, ButtonToolbar, ButtonGroup } from 'rsuite';
import CloneHttpObject from 'components/Modals/CloneHttpObject'
import { Badge } from "reactstrap";

class HttpObjectCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isCloneHttpObject: false,
        }
    }
    componentDidMount = () => {
        Emitter.on("handleNo", newValue =>
            Emitter.off("handleYes")
        );
    }
    componentWillUnmount() {
        Emitter.off("handleYes");
    }

    handleCloneHttpObject = (name) => {
        const { data, currentProjectId } = this.props;
        const that=this;
        this.setState({ loading: true });

        HttpObjectService.CLONE_HTTP_OBJECT(
            data.id, currentProjectId,name
        ).then(_data => {
            if(_data.operationResult){
                let newArray = [...that.props.httpObjects.dynamicValue];
                newArray.push(_data.dynamicValue);
                let newObj = {
                    ...that.props.httpObjects,
                    dynamicValue: newArray,
                }
                that.props.setHttpObjects(newObj);
                that.setState({ loading: false });
            }
         
        }).catch(err => {
            debugger
            that.setState({ loading: false });
            Alert.error(ResponseStatusCode(err.statusCode));
        });
    }

    handleClone = () => {
        this.setState({
            isCloneHttpObject: !this.state.isCloneHttpObject,
        })
    }

    handleDelete = () => {
        const { data, currentProjectId } = this.props;
        const that = this;
        HttpObjectService.REMOVE_HTTP_OBJECT(
            data.id, currentProjectId
        ).then(_data => {
            let newArray = [...that.props.httpObjects.dynamicValue];
            const fIndex = newArray.findIndex(x => x.id === data.id);
            debugger;
            if (fIndex > -1) {
                newArray.splice(fIndex, 1);

                let newObj = {
                    ...that.props.httpObjects,
                    dynamicValue: newArray,
                }

                that.props.setHttpObjects(newObj);

            }
            that.setState({ loading: false });


        }).catch(err => {
            debugger
            that.setState({ loading: false });
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Emitter.emit("SHOW_WARNING_MODAL", data);

            //Alert.error(data.dynamicValue)
        });
    }
    handleAskDelete = () => {
        Emitter.on("handleYes", newValue => this.handleDelete()
        );

        sweetAlertActions.SHOW_ASK(
            "",
            "",
            "",
            i18n.t("MESSAGE_DELETE_HTTPOBJECT_SUBTITLE"),
            i18n.t("MESSAGE_DELETE_HTTPOBJECT_SUCCESS"),
            i18n.t("MESSAGE_DELETE_HTTPOBJECT_UNSUCCESS")
        );
    }
    handleEdit = () => {
        const { handleOpenModal, data } = this.props;
        handleOpenModal(data);
    }

    render() {
        const { data } = this.props;
        const types = {
            0: "post",
            1: "get",
            2: "delete",
            3: "put"
        };
        return (
            <Translation>
                {t => (
                    <>
                        <div className={"ho-card"}>
                            <div>
                                <div className={"ho-name-div"}>
                                    <span className={"ho-name"}>{data.httpObjectName}</span>
                                </div>
                                <Badge pill className="ho-badge">{types[data.httpType]}</Badge>
                            </div>
                            <ButtonToolbar>
                                <ButtonGroup>
                                    <IconButton appearance="ghost" onClick={this.handleClone} icon={
                                        <Whisper placement="bottom" trigger="hover" speaker={
                                            <Tooltip>
                                                {t("BUTTON_CLONE")}
                                            </Tooltip>
                                        }>
                                            <Icon icon="copy-o" />
                                        </Whisper>
                                    } />
                                    <IconButton appearance="ghost" onClick={this.handleEdit} icon={
                                        <Whisper placement="bottom" trigger="hover" speaker={
                                            <Tooltip>
                                                {t("BUTTON_EDIT")}
                                            </Tooltip>
                                        }>
                                            <Icon icon="edit" />
                                        </Whisper>
                                    } />
                                    <IconButton appearance="ghost" onClick={this.handleAskDelete} icon={
                                        <Whisper placement="bottom" trigger="hover" speaker={
                                            <Tooltip>
                                                {t("BUTTON_DELETE")}
                                            </Tooltip>
                                        }>
                                            <Icon icon="trash-o" />
                                        </Whisper>
                                    } />


                                </ButtonGroup>
                            </ButtonToolbar>

                        </div>
                        <CloneHttpObject isActive={this.state.isCloneHttpObject} handleClone={this.handleCloneHttpObject} handleToggle={this.handleClone} />

                    </>

                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { currentstatesReducer } = state;
    const { httpObjects, currentProjectId } = currentstatesReducer;

    return {
        httpObjects,
        currentProjectId
    };
}
const mapDispatchToProps = {
    setHttpObjects,
}

export default connect(mapStateToProps, mapDispatchToProps)(HttpObjectCard)
