import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { FormGroup, Form, Input, Row, Col, ButtonGroup, Button } from "reactstrap";

import { updateComponent,updateList } from '_actions';
import { findObjUpdate ,findNestedObjAndChangeALL} from 'assets/util';

import Slider from "nouislider";


import FlexProperty from "../Core/FlexProperty";
import IconProperty from "../Core/IconProperty";
import ColorPicker from "../Core/ColorPicker";



import {
    PropertyTitle,
} from "../Core/";

class IconSettings extends Component {
    constructor(props) {
        super(props)
        this.iconSizeSlider = React.createRef();

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style,value } = object.props;

        this.state = {
            componentClone: component,
            iconColor: component && (style["iconColor"] == undefined ? (style["color"] == undefined ? "#000000" : style["color"]) : style["iconColor"]),
            iconSize: style["iconSize"] == undefined ? (style["fontSize"] == undefined ? "14px" : style["fontSize"]) : style["iconSize"],
        }

    }

    handleUpdateEditor = (_clone) => {

        /*
            state eski halinden farklı ise güncelleme gönderilmeli..
        */
       this.props.updateList(findNestedObjAndChangeALL(this.props.editor, _clone.id, _clone));
    }

    handleChangeColor = (color) => {
        this.setState({
            iconColor: color,
        }, () => {
            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.style = {
                    ..._clone.object.components[0].object.props.style,
                    iconColor: `rgba(${this.state.iconColor.r}, ${this.state.iconColor.g}, ${this.state.iconColor.b}, ${this.state.iconColor.a})`
                }
            } else {
                _clone.components[0].object.props.style = {
                    ..._clone.components[0].object.props.style,
                    iconColor: `rgba(${this.state.iconColor.r}, ${this.state.iconColor.g}, ${this.state.iconColor.b}, ${this.state.iconColor.a})`
                }
            }
 
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }

    componentDidUpdate = (prevProps) => {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style,value } = object.props;
        let isElement = component && (object.props.style.iconType == "element" ? true : false);

        if (prevProps.component != component) {
            this.setState({
                componentClone: component,
                iconColor: component && (style["iconColor"] == undefined ? (style["color"] == undefined ? "#000000" : style["color"]) : style["iconColor"]),
                iconSize: style["iconSize"] == undefined ? (style["fontSize"] == undefined ? "14px" : style["fontSize"]) : style["iconSize"],
            }, () => {
                //if (isElement) {
                    var _iconSizeSlider = this.iconSizeSlider.current;
                    _iconSizeSlider.noUiSlider.set(this.state.iconSize)
                //}
            })
        }
    }

    generateIconSizeInput = () => {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        let isElement = component && (object.props.style.iconType == "element" ? true : false);

        //if (isElement) {
            var _iconSizeSlider = this.iconSizeSlider.current;
            const that = this;
            Slider.create(_iconSizeSlider, {
                start: [this.state.iconSize],
                connect: [true, false],
                step: 1,
                tooltips: true,
                format: {
                    to: function (value) {
                        return parseInt(value) + 'px';
                    },
                    from: function (value) {
                        return Number(value.replace('px', ''));
                    }
                },
                range: { min: 5, max: 150 }
            });
            _iconSizeSlider.noUiSlider.on('change', function (values, handle) {
                that.setState({
                    iconSize: values[handle],
                }, () => {
                    let _clone = Object.assign({}, that.props.component);
                    if (_clone.hasOwnProperty("componentOwner")) {
                        _clone.object.components[0].object.props.style = {
                            ..._clone.object.components[0].object.props.style,
                            iconSize: that.state.iconSize,
                        }
                    } else {
                        _clone.components[0].object.props.style = {
                            ..._clone.components[0].object.props.style,
                            iconSize: that.state.iconSize,
                        }
                    }
                   let _newEditor = JSON.parse(JSON.stringify(that.props.editor))
                   let _result = findObjUpdate(_newEditor, _clone);
                   that.props.updateComponent(_clone);
                   that.props.onUpdateList(_result);
                })
            });
        //}



    }

    componentDidMount() {
        this.generateIconSizeInput();
    }

    render() {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        let isElement = component && (object.props.style.iconType == "element" ? true : false);

        return (
            <Translation>
                {t => (
                    <Form>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_ICON")}
                                </label>
                                <IconProperty />
                            </div>
                        </FormGroup>

                        {
                            !isElement &&
                            <FormGroup className={"property-form-group"}>
                                <PropertyTitle title={t("PROPERTY_ICON_POSITION")} subtitle={""} />
                                <FlexProperty
                                    propName={"iconPosition"}
                                    nullOption={"left"}
                                    options={[
                                        "left",
                                        "right",
                                        "top",
                                        "bottom"
                                    ]} />
                            </FormGroup>
                        }


                        {
                           // isElement &&
                            <>
                                <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}>
                                        <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_ICON_COLOR")}
                                        </label>
                                        <ColorPicker isComponent={true} id={"icon-color"} color={this.state.iconColor} handle={this.handleChangeColor} />
                                    </div>
                                </FormGroup>
                                <FormGroup className={"property-form-group"}>
                                    <PropertyTitle title={t("PROPERTY_ICON_SIZE")} subtitle={this.state.iconSize} />
                                    <div className="slider" ref={this.iconSizeSlider} />
                                </FormGroup>
                            </>
                        }




                    </Form>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, globalpropsReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;
    const { globalProps } = globalpropsReducer;

    return {
        editor,
        component,
        globalProps
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,

}
export default connect(mapStateToProps, mapDispatchToProps)(IconSettings)
