import {
    editorConstants
} from "../_constants";

export function updateListSocket(newList) {
   
    return {
        type: editorConstants.UPDATE_LIST_SOCKET,
        payload: {
            editor: newList,
        }
    }
}
export function updateList(newList) {
   
    return {
        type: editorConstants.UPDATE_LIST,
        payload: {
            editor: newList,
        }
    }
}
export function updateCurrentPage(id) {
    return {
        type: editorConstants.UPDATE_CURRENT_PAGE,
        payload: {
            currentPage: id,
        }
    }
}

export function addNewPage(data) {
    return {
        type: editorConstants.ADD_NEW_PAGE,
        payload: {
            newPage: data,
        }
    }
}
export function updatePageName(data) {
    return {
        type: editorConstants.CHANGE_PAGE_NAME,
        payload: {
            newPage: data,
        }
    }
}

export function updatePageStyle(_style) {
    return {
        type: editorConstants.UPDATE_PAGE_STYLE,
        payload: {
            style: _style,
        }
    }
}

export function setPages(data) {
    return {
        type: editorConstants.SET_PAGES,
        payload: {
            pages: data,
        }
    }
}
export function setPage(data) {
    return {
        type: editorConstants.SET_PAGE,
        payload: {
            page: data,
        }
    }
}