import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";

import {
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroup,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap";

import { updateComponent, updateList } from '_actions';
import { findObjUpdate } from 'assets/util';

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class SizeProperty extends Component {

    constructor(props) {
        super(props)
        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style, value } = object.props;

        /**
         * Eğer Width veya Height değeri bir diğerinin değerini alacaksa birinden birinin
         * Normal bir değer alması gerekiyor aynı bunlar;
         * px, %, full olabilir.
         */


        let letr = (style[props.propName] == undefined ? "auto" : style[props.propName]).match(/[a-zA-Z%]+/g);
        var num = (style[props.propName] == undefined ? "auto" : style[props.propName]).match(/\d+/g);

        this.state = {
            component: component,
            type: letr[0],
            [props.propName]: num != null ? num[0] : "",
        }

    }



    setProperties = () => {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        let _clone = Object.assign({}, this.props.component);

        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props.style = {
                ..._clone.object.components[0].object.props.style,
                [this.props.propName]: (this.state[this.props.propName]) + this.state.type
            }
        } else {
            _clone.components[0].object.props.style = {
                ..._clone.components[0].object.props.style,
                [this.props.propName]: (this.state[this.props.propName]) + this.state.type
            }
        }

        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);
    }


    handleChange = (event) => {
        const { target } = event;
        var theEvent = event || window.event;
        clearTimeout(this.timer);
        var regex = /^[0-9]*$/;
        if (!regex.test(target.value)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        } else {
            this.setState({
                [this.props.propName]: event.target.value,
            });
        }
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    }

    triggerChange=()=> {
        this.setProperties();
    }

    componentWillMount() {
        this.timer = null;
    }

    componentDidUpdate = (prevProps) => {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style, value } = object.props;




        let letr = (style[this.props.propName] == undefined ? "auto" : style[this.props.propName]).match(/[a-zA-Z%]+/g);
        var num = (style[this.props.propName] == undefined ? "auto" : style[this.props.propName]).match(/\d+/g);

        const _canBeSquare = this.canBeSquare();

        let _letr = letr[0] == "square" ? (_canBeSquare ? letr[0] : "auto") : letr[0];



        if (prevProps.component != component) {
            this.setState({
                component: component,
                type: _letr,
                [this.props.propName]: num != null ? num[0] : "",
            }, () => {
                if (_letr != letr[0]) {
                    this.setProperties();
                }
            })
        }
    }
    handleChangeType = (e, selected) => {
        e.preventDefault()
 
        const _num = (selected == "px" || selected == "%") ? (this.state[this.props.propName] == "" ? "50" : this.state[this.props.propName]) : ""
        this.setState({
            type: selected,
            [this.props.propName]: _num,
        }, () => {
            this.setProperties();
        })
 
    }

    onKeyDown = (event) => {
        if (event.key === 'Enter') { 
            event.preventDefault();
         }
    }

    canBeSquare = () => {
        const { component, propName } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];


        const otherProps = propName == "minHeight" ? "minWidth" : "minHeight";
        let canBeSquare = false;
        if (component) {

            const _otherPropsValue = object.props.style[otherProps];
            let _valueType = (_otherPropsValue).match(/[a-zA-Z%]+/g);

            canBeSquare = (_valueType[0] == "px" || _valueType[0] == "device" || _valueType[0] == "half_device") ? true : false;

        }
        return canBeSquare;
    }


    render() {
        const { component, propName } = this.props;
        //const {  } = this.state;

        const _canBeSquare = this.canBeSquare();


         const { object } = component && component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];


        return (
            <Translation>
                {t => (
                    <div>

                        <InputGroup className="mb-3">
                            {
                                <Input
                                    pattern="[0-9]*"
                                    name={this.props.propName}
                                    value={this.state[this.props.propName]}
                                    onBlur={() => { }}
                                    onKeyDown={this.onKeyDown.bind(this)}
                                    onInput={this.handleChange.bind(this)}
                                    className={"form-control-sm property-dropdown-input " + ((this.state.type == "px" || this.state.type == "%") ? "" : "property-dropdown-input-display")}
                                    placeholder="" type="text" />
                            }
                            <InputGroupAddon addonType="prepend" className={"property-dropdown"} >
                                <UncontrolledDropdown group color="primary">
                                    <DropdownToggle caret color="primary" size="sm" className={"property-dropdown"}>
                                        {this.state.type}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem href="#type" style={this.state.type == "px" ? { display: "none" } : {}} onClick={e => (this.handleChangeType(e, "px"))}>
                                            <span>px</span>
                                        </DropdownItem>
                                        <DropdownItem href="#type" style={this.state.type == "%" ? { display: "none" } : {}} onClick={e => (this.handleChangeType(e, "%"))}>
                                            %
                                                </DropdownItem>
                                        {
                                            object && object.type !== "layout" &&
                                            <DropdownItem href="#type" style={this.state.type == "auto" ? { display: "none" } : {}} onClick={e => (this.handleChangeType(e, "auto"))}>
                                                {t("PROPERTY_LAYOUT_WIDTH_HEIGHT_AUTO")}
                                            </DropdownItem>
                                         }

                                        <DropdownItem href="#type" style={this.state.type == "device" ? { display: "none" } : {}} onClick={e => (this.handleChangeType(e, "device"))}>
                                            {t("PROPERTY_LAYOUT_WIDTH_HEIGHT_DEVICE")}
                                        </DropdownItem>
                                        <DropdownItem href="#type" style={this.state.type == "half_device" ? { display: "none" } : {}} onClick={e => (this.handleChangeType(e, "half_device"))}>
                                            {t("PROPERTY_LAYOUT_WIDTH_HEIGHT_HALF")}
                                        </DropdownItem>
                                        {
                                            _canBeSquare &&
                                            <DropdownItem href="#type" style={this.state.type == "square" ? { display: "none" } : {}} onClick={e => (this.handleChangeType(e, "square"))}>
                                                {t("PROPERTY_LAYOUT_WIDTH_HEIGHT_SQUARE")}
                                            </DropdownItem>
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </InputGroupAddon>
                        </InputGroup>

                    </div>

                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor } = present;

    return {
        component,
        editor
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
}
export default connect(mapStateToProps, mapDispatchToProps)(SizeProperty)
