import { userConstants } from "_constants";
let user=null;
try {
  user = JSON.parse(localStorage.getItem("user"));
} catch (error) {
  user=null;
}
 
const initialState = user
  ? {
      loggedIn: true,
      user,
      LoginFailed: false
    }
  : {
      loggedIn: false,
      LoginFailed: false
    };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        LoginFailed: true
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        user:null
      };
    default:
      return state;
  }
}
