import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { updateComponent, updateList } from '_actions';
import { findObjUpdate } from 'assets/util';

import {
    Card,
    CardBody,
    Row,
} from "reactstrap";

import { Button, IconButton, ButtonGroup, ButtonToolbar, Icon } from 'rsuite';
import ChangeComponentName from 'components/Modals/ChangeComponentName'

export class BasicInfo extends Component {
    constructor(props) {
        super(props)
        const { component } = props;
        this.state = {
            isChangeName: false,
        }

    }

    handleChangeName = () => {
        this.setState({
            isChangeName: !this.state.isChangeName,
        })
    }

    handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        /* Standar ve cutom componentleri standart a çevirme işlemi */
        let _component;
        if (this.props.component.hasOwnProperty("componentOwner"))
            _component = this.props.component.object.components[0];
        else
            _component = this.props.component.components[0]


        //const that=this;
        await this.setState({
            [name]: value,
        }, () => {
            let _clone = Object.assign({}, _component);
            _clone.object.props.value = this.state.value;
            // debugger
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        });
    }

    componentDidUpdate = (prevProps) => {
        const { component, componentNames, page } = this.props;
        let _component;
        if (component.hasOwnProperty("componentOwner"))
            _component = component.object.components[0];
        else
            _component = component.components[0]


        if (prevProps.component != component) {
           /*
            const _cName = componentNames.find(x => x.relComponentId === component.id);
            const _addNewField = {
                pageId: page.id,
                pageName: page.pageName,
                componentName: _cName ? _cName.componentName : null

            }
             if (component.hasOwnProperty("componentOwner")){
                if(_cName && !component.object.components[0].object.componentName){
                    component.object.components[0].object={
                        ...component.object.components[0].object,
                        ..._addNewField,
                    }
                }
               
            }
            else{
                if(_cName && !component.components[0].object.componentName){
                    component.components[0].object={
                        ...component.components[0].object,
                        ..._addNewField,
                    }
                }
            }
            */

            this.setState({
                value: _component.object.props.value
            })
        }
    }

    render() {
        const { component, page, componentNames } = this.props;
        let _nameIndex = componentNames.findIndex(x => x.relComponentId === component.id);

        return (
            <Translation>
                {t => (
                    <>
                        {t("PROPERTY_TITLE")}
                        <hr />
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <p className="text-sm text-muted mb-0">{_nameIndex === -1 ? component.componentName : componentNames[_nameIndex].componentName}</p>
                            <IconButton onClick={this.handleChangeName} className={"dv-dropdown"} style={{ background: "#f7f7fa !important", borderColor: "#f2f2f2 !important" }} icon={<Icon icon="edit2" style={{ color: "#2baebf" }} />} size="md" />
                        </div>
                        <ChangeComponentName isActive={this.state.isChangeName} handleToggle={this.handleChangeName} />

                    </>
                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { editor, page } = present;
    const { componentNames } = currentstatesReducer;

    return {
        component,
        editor,
        page,
        componentNames
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    updateComponent,
}
export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo)
