import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from 'i18n';

import {
    FormGroup,
    Form,
    Input,
    ButtonGroup,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,

} from "reactstrap";
import { Dropdown } from 'rsuite';

import { updateComponent, updateList } from '_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';

import {
    PropertyTitle,
} from "../Core/";
import ColorPicker from "../Core/ColorPicker";


/* plugins */
import Slider from "nouislider";
import Select2 from "react-select2-wrapper";

const defautValueOptions = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: true,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: false,
    }
]

class CheckboxSettings extends Component {
    constructor(props) {
        super(props)
        this.fontSizeSlider = React.createRef();

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        const style = object.props[props.dataPath ? props.dataPath : "style"];

        this.state = {
            componentClone: component,
            fontColor: component && (style["color"] == undefined ? "#000000" : style["color"]),
            borderColor: component && (style["borderColor"] == undefined ? "#000000" : style["borderColor"]),
            borderRadius: component && (this.pxToNumber(style["borderRadius"] == undefined ? "6" : style["borderRadius"])),
            //    fontSize: component && (style["fontSize"] == undefined ? "14px" : style["fontSize"]),
            value: component && (defautValueOptions.find(x => x.value === object.props.value).value),
            // bold: component && (style["fontWeight"] == undefined ? false : (style["fontWeight"] == "bold" ? true : false)),
            //  italic: component && (style["fontStyle"] == undefined ? false : (style["fontStyle"] == "italic" ? true : false)),
            // underline: component && (style["textDecoration"] == undefined ? false : (style["textDecoration"] == "underline" ? true : false)),
            // lineThrough: component && (style["textDecoration"] == undefined ? false : (style["textDecoration"] == "line-through" ? true : false)),
            // textAlign: component && (style["textAlign"] == undefined ? "unset" : style["textAlign"]),
        }

    }

    pxToNumber = (_value) => {
        if (typeof _value === 'string' || _value instanceof String) {
            if (_value.includes("px")) { return parseInt(_value.replace(/px$/, '')) }
            else { return _value }
        } else { return _value }
    }

    handleUpdateEditor = (_clone) => {

        /*
            state eski halinden farklı ise güncelleme gönderilmeli..

            
        */
        this.props.updateList(findNestedObjAndChangeALL(this.props.editor, _clone.id, _clone));
    }
    handleChangeBorderColor = (color) => {
        const that = this;
        this.setState({
            borderColor: color,
        }, () => {
            const _style = that.props.dataPath ? that.props.dataPath : "style";
            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    borderColor: `rgba(${this.state.borderColor.r}, ${this.state.borderColor.g}, ${this.state.borderColor.b}, ${this.state.borderColor.a})`

                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    borderColor: `rgba(${this.state.borderColor.r}, ${this.state.borderColor.g}, ${this.state.borderColor.b}, ${this.state.borderColor.a})`
                }
            }

            //this.props.updateList(findNestedObjAndChangeALL(this.props.editor,_clone._id,_clone));
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }
    handleChangeFontColor = (color) => {
        const that = this;
        this.setState({
            fontColor: color,
        }, () => {
            const _style = that.props.dataPath ? that.props.dataPath : "style";
            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    color: `rgba(${this.state.fontColor.r}, ${this.state.fontColor.g}, ${this.state.fontColor.b}, ${this.state.fontColor.a})`

                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    color: `rgba(${this.state.fontColor.r}, ${this.state.fontColor.g}, ${this.state.fontColor.b}, ${this.state.fontColor.a})`
                }
            }

            //this.props.updateList(findNestedObjAndChangeALL(this.props.editor,_clone._id,_clone));
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

    handleChangeFontStyle = (event) => {
        const { currentTarget } = event;
        const { name } = currentTarget;
        this.setState({
            underline: name == "underline" ? !this.state[name] : (name == "lineThrough" ? (this.state.lineThrough ? this.state.underline : false) : this.state.underline),
            lineThrough: name == "lineThrough" ? !this.state[name] : (name == "underline" ? (this.state.underline ? this.state.lineThrough : false) : this.state.lineThrough),
            bold: name == "bold" ? !this.state[name] : this.state.bold,
            italic: name == "italic" ? !this.state[name] : this.state.italic,
        }, () => {
            let _clone = Object.assign({}, this.props.component);
            const _style = this.props.dataPath ? this.props.dataPath : "style";

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    fontWeight: this.state.bold ? "bold" : "normal",
                    fontStyle: this.state.italic ? "italic" : "normal",
                    textDecoration: this.state.underline ? "underline" : (this.state.lineThrough ? "line-through" : "none"),
                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    fontWeight: this.state.bold ? "bold" : "normal",
                    fontStyle: this.state.italic ? "italic" : "normal",
                    textDecoration: this.state.underline ? "underline" : (this.state.lineThrough ? "line-through" : "none"),
                }
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }

    handleChangeTextAlign = (event) => {
        const { currentTarget } = event;
        const { name } = currentTarget;
        const that = this;
        this.setState({
            textAlign: name == this.state.textAlign ? "unset" : name,
        }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";

            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    textAlign: this.state.textAlign,
                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    textAlign: this.state.textAlign,
                }
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        })
    }

    handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        //const that=this;
        await this.setState({
            [name]: value,
        }, () => {
            let _clone = Object.assign({}, this.props.component);
            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.value = this.state.value;

            } else {
                _clone.components[0].object.props.value = this.state.value;

            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);
        });
    }

    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const style = object.props[this.props.dataPath ? this.props.dataPath : "style"];

        if (prevProps.component != component) {
            this.setState({
                componentClone: component,
                value: (defautValueOptions.find(x => x.value === object.props.value).value),
                fontColor: style["color"] == undefined ? "#000000" : style["color"],
                borderColor: style["borderColor"] == undefined ? "#000000" : style["borderColor"],
                borderRadius: this.pxToNumber(style["borderRadius"] == undefined ? "6" : style["borderRadius"]),

                // fontSize: style["fontSize"] == undefined ? "14px" : style["fontSize"],
            }, () => {
                //this.generateFontSizeInput();
                //   var _fontSizeSlider = this.fontSizeSlider.current;
                // _fontSizeSlider.noUiSlider.set(this.state.fontSize)

            })
        }
    }

    generateFontSizeInput = () => {
        var _fontSizeSlider = this.fontSizeSlider.current;
        const that = this;
        Slider.create(_fontSizeSlider, {
            start: [this.state.fontSize],
            connect: [true, false],
            step: 1,
            tooltips: true,
            format: {
                to: function (value) {
                    return parseInt(value) + 'px';
                },
                from: function (value) {
                    return Number(value.replace('px', ''));
                }
            },
            range: { min: 5, max: 72 }
        });
        _fontSizeSlider.noUiSlider.on('change', function (values, handle) {
            that.setState({
                fontSize: values[handle],
            }, () => {
                const _style = this.props.dataPath ? this.props.dataPath : "style";

                let _clone = Object.assign({}, that.props.component);
                if (_clone.hasOwnProperty("componentOwner")) {
                    _clone.object.components[0].object.props[_style] = {
                        ..._clone.object.components[0].object.props[_style],
                        fontSize: that.state.fontSize,
                        globalFontSize: null,
                    }
                } else {
                    _clone.components[0].object.props[_style] = {
                        ..._clone.components[0].object.props[_style],
                        fontSize: that.state.fontSize,
                        globalFontSize: null,
                    }
                }

                let _newEditor = JSON.parse(JSON.stringify(that.props.editor))
                let _result = findObjUpdate(_newEditor, _clone);
                that.props.updateComponent(_clone);
                that.props.onUpdateList(_result);

            })
        });
    }


    handleGlobalFontSize = (_data) => {
        let propName = "FontSize";
        const that = this;
        this.setState({ fontSize: _data.value }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";

            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    ["global" + propName]: _data.id,
                    fontSize: _data.value,
                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    ["global" + propName]: _data.id,
                    fontSize: _data.value,
                }
            }

            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })

    }

    componentDidMount() {
        //   this.generateFontSizeInput();
    }

    handleUpdateValue = (_data) => {

        this.setState({
            value: _data.value,
        }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";
            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.value = _data.value;
            } else {
                _clone.components[0].object.props.value = _data.value;
            }

            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

    handleUpdateRadius = (_data) => {
        this.setState({
            borderRadius: _data,
        }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";

            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props[_style] = {
                    ..._clone.object.components[0].object.props[_style],
                    borderRadius: _data+"px",
                }
            } else {
                _clone.components[0].object.props[_style] = {
                    ..._clone.components[0].object.props[_style],
                    borderRadius: _data+"px",
                }
            }

            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);


        })
    }

    render() {
        const { component, globalProps } = this.props;
        let _component;
        if (component.hasOwnProperty("componentOwner"))
            _component = component.object.components[0];
        else
            _component = component.components[0]

        const _style = this.props.dataPath ? this.props.dataPath : "style";

        return (
            <Translation>
                {t => (
                    <Form>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_CHECKBOX_DEFAULT_VALUE")}
                                </label>
                                {
                                    this.state.value !== null &&
                                    <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state.value).label}>
                                        {
                                            defautValueOptions.map((x, key) => (
                                                x.value === this.state.value ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handleUpdateValue(x)}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                }

                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_CHECKBOX_ICON_COLOR")}
                                </label>
                                <ColorPicker isComponent={true} id={"checkbox-icon-color"} color={this.state.fontColor} handle={this.handleChangeFontColor} />
                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-color" className={"propertyTitle"}>{t("PROPERTY_CHECKBOX_COLOR")}
                                </label>
                                <ColorPicker isComponent={true} id={"checkbox-color"} color={this.state.borderColor} handle={this.handleChangeBorderColor} />
                            </div>
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-color" className={"propertyTitle"}>{t("PROPERTY_CHECKBOX_RADIUS")}
                                </label>
                            </div>
                            <div className={"propertyTitleDiv"}>

                                <Input
                                    value={this.state.borderRadius}
                                    id="checkbox-radius-input"
                                    type="number"
                                    min="1"
                                    max="100"
                                    className="form-control-sm"
                                    onChange={(e) => {
                                        const _last = e.target.value;
                                        if (_last !== "") {
                                            const _value = parseInt(_last);
                                            if (_value > 0 && _value < 101) {
                                                this.handleUpdateRadius(_value);
                                            }
                                        }
                                         
                                    }}
                                />
                            </div>
                        </FormGroup>




                    </Form>
                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, globalpropsReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;

    const { globalProps } = globalpropsReducer;

    return {
        editor,
        component,
        globalProps
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,

}
export default connect(mapStateToProps, mapDispatchToProps)(CheckboxSettings)
