import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { FormGroup, Form, Input, Row, Col } from "reactstrap";
import { updateComponent, updateList } from '_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';
import i18n from 'i18n';
import moment from "moment";

import {
    PropertyTitle,
} from "../Core/";
import { DatePicker,Icon, Dropdown, List, Alert, Button, IconButton, ButtonToolbar, } from 'rsuite';
import ColorPicker from "../Core/ColorPicker";
import DatePickerProperty from '../Core/DatePickerProperty'
import DynamicFieldValue from "../Core/DynamicFieldValue"

const WAIT_INTERVAL = 500;
moment.locale(i18n.t("LANG"));

const defautVisibleDateFormatOptions = [
    {
        label: "04/07/2021",
        value: "L",
    },
    {
        label: "4/7/2021",
        value: "l",
    },
    {
        label: "April 7, 2021",
        value: "LL",
    },
    {
        label: "Apr 7, 2021",
        value: "ll",
    },
    {
        label: "Apr 7th 21",
        value: "MMM Do YY",
    },
    {
        label: "Wednesday",
        value: "dddd",
    }

]

class FabMenuSettings extends Component {
    constructor(props) {
        super(props)
        this.fontSizeSlider = React.createRef();

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const _p = object.props;
        this.state = {
            componentClone: component,
            value:component && (_p["value"] == undefined ? "" : _p["value"]),
            mainColor: component && (_p["mainColor"] == undefined ? "rgba(255, 255, 255, 0)" : _p["mainColor"]),
            minDate: component && (_p["minDate"] == undefined ? null : _p["minDate"]),
            maxDate: component && (_p["maxDate"] == undefined ? null : _p["maxDate"]),
            visibleFormat: component && (_p["visibleFormat"] == undefined ? "L" : _p["visibleFormat"]),
        }
 
    }

 

    updatePropEditor = (key, value) => {
        const that = this;
        let _clone = Object.assign({}, this.props.component);
        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props[key] = value
        } else {
            _clone.components[0].object.props[key] = value
        }

        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);
    }

    handleChangeMainColor = (color) => {
        const that = this;
        this.setState({
            mainColor: color,
        }, () => { that.updatePropEditor("mainColor", `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`) })
    }
    
    handleUpdateVisibleFormat = (_data) => {
        const that = this;
        this.setState({
            visibleFormat: _data.value,
        }, () => {
            that.updatePropEditor("visibleFormat", _data.value)
        })
    }


    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const _p = object.props;

        if (prevProps.component != component) {
            this.setState({
                componentClone: component,
                value:component && (_p["value"] == undefined ? "" : _p["value"]),
                mainColor: component && (_p["mainColor"] == undefined ? "rgba(255, 255, 255, 0)" : _p["mainColor"]),
                minDate: component && (_p["minDate"] == undefined ? null : _p["minDate"]),
                maxDate: component && (_p["maxDate"] == undefined ? null : _p["maxDate"]),
                visibleFormat: component && (_p["visibleFormat"] == undefined ? "L" : _p["visibleFormat"]),
            
            }, () => { })
        }
    }

    
    handleChangeMinDate = (_data) => {
        const that = this;
        this.setState({
            minDate: _data,
        }, () => {
            that.updatePropEditor("minDate", _data)
        })
    }
    
    handleChangeMaxDate = (_data) => {
        const that = this;
        this.setState({
            maxDate: _data,
        }, () => {
            that.updatePropEditor("maxDate", _data)
        })
    }
    handleChangeValue= (_data) => {
        const that = this;
        this.setState({
            value: _data,
        }, () => {
            that.updatePropEditor("value", _data)
        })
    }

    render() {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const that = this;

        const _selectedFormat = this.state.visibleFormat && (defautVisibleDateFormatOptions.find(x => x.value === this.state.visibleFormat))
        let _format;
        if(_selectedFormat){
            _format = moment((!isNaN(Date.parse(this.state.value))?(new Date(this.state.value)):(new Date("09/03/1993")))).format( (_selectedFormat).value)
        }else{ _format =moment().format(defautVisibleDateFormatOptions[0].value)}
        return (
            <Translation>
                {t => (
                    <>
                        <Form>
                        <FormGroup className={"property-form-group"}>
                            <DynamicFieldValue basicField={<DatePickerProperty placeholder={t("PROPERTY_VALUE")} data={this.state.value} handleChange={this.handleChangeValue}/>} />
                        </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_DATAPICKER_COLOR")}
                                    </label>
                                    <ColorPicker isComponent={true} id={"datepicker-mainColor"} color={this.state.mainColor} handle={this.handleChangeMainColor} />
                                </div>
                            </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}><label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_DATAPICKER_FORMAT")}</label>
                                </div>
                                <Dropdown className={"property-dropdown-long"} size="sm" title={_format}>
                                        {
                                            defautVisibleDateFormatOptions.map((x, key) => (
                                                x.value === this.state.visibleFormat ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handleUpdateVisibleFormat(x)}>{
                                                        moment((!isNaN(Date.parse(this.state.value))?(new Date(this.state.value)):(new Date("09/03/1993")))).format(x.value)
                                                    }</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                            </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_DATAPICKER_MIN_DATE")}
                                    </label>
                                 </div>
                                 <DatePickerProperty placeholder={t("PROPERTY_DATAPICKER_MIN_DATE")} data={this.state.minDate} handleChange={this.handleChangeMinDate}  />
                            </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_DATAPICKER_MAX_DATE")}
                                    </label>
                                 </div>
                                 <DatePickerProperty placeholder={t("PROPERTY_DATAPICKER_MAX_DATE")} data={this.state.maxDate} handleChange={this.handleChangeMaxDate}  />
                            </FormGroup>
                        </Form>
                    
                    </>

                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { pageStatus, currentProjectId } = currentstatesReducer;

    const { present } = editorReducer;
    const { editor, currentPage } = present;
    return {
        editor,
        currentPage,
        component,
        currentProjectId
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,


}
export default connect(mapStateToProps, mapDispatchToProps)(FabMenuSettings)
