import React, { Component } from 'react'
import { Translation } from "react-i18next";
import { Alert, IconButton, Icon } from 'rsuite';

import {
    Row, Col
} from "reactstrap";
export class ImageBox extends Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef();
        this.state = {
            img: "",
        }
    }

    componentDidMount() {
        this.getMyIdealImage();
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps != this.props) {
            this.getMyIdealImage();
        }
    }



    getMyIdealImage = () => {
        const { item } = this.props;

        const { renderedImages } = item;


        let current = this.myRef.current;

        if (renderedImages == null) {
            this.setState({ img: null })
            return
        }
        else if (renderedImages.length == 0) {
            this.setState({ img: null })
            return
        }


        if (current != null) {
            let w = this.myRef.current.offsetWidth;
            let h = this.myRef.current.offsetHeight;

            if (renderedImages == null || renderedImages.length < 1)
                return;

            let closest = null;
            try {

                if (w > h) {
                    closest = renderedImages.reduce(function (prev, curr) {
                        let sizes = curr.key.split("x");
                        let _x = parseInt(sizes[0]);
                       let _y = parseInt(sizes[1]);

                        let psizes = prev.key.split("x");
                        let _px = parseInt(psizes[0]);
                        let _py = parseInt(psizes[1]);



                        return (Math.abs(_x - w) < Math.abs(_px - w) ? curr : prev);
                    });
                } else {
                    closest = renderedImages.reduce(function (prev, curr) {
                        let sizes = curr.key.split("x");
                        //let _x = parseInt(sizes[0]);
                        let _y = parseInt(sizes[1]);

                        let psizes = prev.key.split("x");
                       // let _px = parseInt(psizes[0]);
                        let _py = parseInt(psizes[1]);
                        return (Math.abs(_y - h) < Math.abs(_py - h) ? curr : prev);
                    });
                }


            } catch (error) {
                //console.log(error);
            }



            if (closest != null) {
                this.setState({ img: closest.path })
            }

        }

    }

    render() {
        const { src, handleRemove, onClick, currentSelected, item, handleModalToggle, isMine, } = this.props;
        return (
            <Translation>
                {t => (
                    <>
                        {
                            isMine ?
                                <Col xs={2}
                                    tabIndex="1"
                                    className={
                                        "custom-fade-in " +
                                        "gallery-image-modal-box"
                                        + (item.id == currentSelected ? " icons-modal-box-active" : "")
                                    }>
                                    <div className={"gallery-image-modal-box-controls"}
                                        onClick={() => {
                                            onClick(item);
                                            handleModalToggle();
                                        }}>
                                        <div className={"gallery-image-modal-box-img"}
                                            ref={this.myRef}
                                            style={{
                                                backgroundImage: "url(" + this.state.img + ")",
                                            }}>
                                        </div>
                                    </div>
                                    <div className={"gallery-image-modal-box-actions"} onClick={() => { return }}>
                                        <IconButton onClick={() => { handleRemove(item.id); }} icon={<Icon icon="trash-o" />} circle size="sm" />
                                    </div>
                                </Col> :
                                <Col xs={2}
                                    tabIndex="1"
                                    onClick={() => {
                                        onClick(item);
                                        handleModalToggle();
                                    }}
                                    className={
                                        "custom-fade-in " +
                                        "gallery-image-modal-box"
                                        + (item.id == currentSelected ? " icons-modal-box-active" : "")
                                    }>
                                    <div className={"gallery-image-modal-box-controls"}>
                                        <div className={"gallery-image-modal-box-img"}
                                            ref={this.myRef}
                                            style={{
                                                backgroundImage: "url(" + this.state.img + ")",
                                            }}>
                                        </div>

                                    </div>

                                </Col>
                        }
                    </>
                )}
            </Translation>
        )
    }
}

export default ImageBox
