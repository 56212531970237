import { SocketOperationsLocal } from "_constants";

const initialState = {
    socket: null,
};

export function SocketReducer(state = initialState, action) {
  switch (action.type) {
    
    case SocketOperationsLocal.SET_SOCKET_REF:
      return { ...state, socket: action.socket };

 

    default:
      return state;
  }
}
