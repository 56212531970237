import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import i18n from 'i18n';

import {
    FormGroup,
    Form,

} from "reactstrap";

import { updateComponent, updateList } from '_actions';
import { findObjUpdate } from 'assets/util';
 

import { Dropdown} from 'rsuite';
 
const defautValueOptions = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: true,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: false,
    }
]

 

class ScrollSettings extends Component {
    constructor(props) {
        super(props)
        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];

        this.state = {
            isActive: component && object.props.scrollable ? true : false,
         }

    }

    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
      
        if (prevProps.component != component) {
            this.setState({
                isActive: component && object.props.scrollable ? true : false,
            })
        }
    }
 

    handlIsActive = (_data) => {
        const that = this;
        this.setState({
            ["isActive"]: _data.value,
        }, () => {
             let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.scrollable = that.state.isActive;
            } else {
                _clone.components[0].object.props.scrollable = that.state.isActive;
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }
 
    render() {
        const { component, globalProps } = this.props;
      
        return (
            <Translation>
                {t => (
                    <Form>
                        <FormGroup className={"property-form-group"}>
                            <div className={"propertyTitleDiv"}>
                                <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_TITLE_SCROLLEABLE_ISACTIVE")}
                                </label>
                                {
                                    this.state["secureTextEntry"] !== null &&
                                    <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["isActive"]).label}>
                                        {
                                            defautValueOptions.map((x, key) => (
                                                x.value === this.state["isActive"] ?
                                                    "" :
                                                    <Dropdown.Item key={key} onSelect={() => this.handlIsActive(x)}>{x.label}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown>
                                }
                            </div>
                        </FormGroup>
                         
                         
                    </Form>
                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, globalpropsReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;

    const { globalProps } = globalpropsReducer;

    return {
        editor,
        component,
        globalProps
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,

}
export default connect(mapStateToProps, mapDispatchToProps)(ScrollSettings)
