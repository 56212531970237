import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AreObjectsEqual, getTime, createId, buildFullTree } from 'assets/util';
import { ReactSortable, Sortable, Swap } from "react-sortablejs";
import { Icon } from 'rsuite';

import {
    Card,
    CardHeader,
    CardBody,
    Collapse,
    UncontrolledCollapse
} from "reactstrap";

import {
    updateList,
    setOnMove,
    setOnAdd,
    setOnAddUpdate,
    setOnRemove,
    setOnEnd
} from '_actions';

import GenerateTree from 'components/Tree/GenerateTree';

class TreeLayout extends Component {
    constructor(props) {
        super(props)

        this.state = {
            list2: [],
            openedCollapses: true,
        }
    }
    collapsesToggle = () => {
        this.setState({
            openedCollapses: !this.state.openedCollapses,
        })
    };
    handleGetIndex = (eArray, index) => {
        let _array = [];
        const that = this;
        try {
            if (index == -1) {
                debugger
            }
            else if (eArray[index].hasOwnProperty("componentOwner")) {
                _array = eArray[index].object.components[0].object.data;
            }
            else {
                _array = eArray[index].components[0].object.data;
            }

            return _array;
        } catch (error) {
            return []
        }

    }
    handleGetObjectData = (eArray, index) => {
        let _array = [];
        const that = this;
        try {
            if (index == -1) {
                debugger
            }
            else if (eArray.hasOwnProperty("componentOwner")) {
                _array = eArray.object.components[0].object.data;
            }
            else {
                _array = eArray.components[0].object.data;
            }
            return _array;
        } catch (error) {
            return []
        }

    }
    handleSetArrayNewData = async (_old, _new, _id) => {
        const { main } = this.props;
        let _childIds = await this.findIndexesIds(_id);
        //let indexesTarget = _path !== null ? _path.split("-") : [];

        let _array2 = _old;
        await _childIds.slice(0, _childIds.length).map(item => {
            _array2 = this.handleGetIndex(_array2, _array2.findIndex(xx => xx.id == item));
        })
        _array2.splice(0, _array2.length);
        await _new.map(item => {
            _array2.push(item);
        })


    }

    handleDirectIntegrationToEditor = async (old, _new, _path) => {
        const that = this;
        const { main } = this.props;
        let _childIds = await this.findIndexesIds(main.id);
        let _old = Object.values(JSON.parse(JSON.stringify(old)));

        let _array2 = _old;

        await _childIds.slice(0, _childIds.length).map(item => {
            _array2 = this.handleGetIndex(_array2, _array2.findIndex(xx => xx.id == item));
        })
        _array2.splice(0, _array2.length);
        await _new.map(item => {
            _array2.push(item);
        })

        that.props.onUpdateList(_old);


    }
    findCommonIndex = async () => {
        const { path, objFrom } = this.props;

        let it1 = path !== null ? path.split("-") : [];
        let it2 = objFrom !== null ? objFrom.split("-") : [];

        let currentIndex = null;
        const currentArray = it1.length > it2.length ? it2 : it1;
        await currentArray.find((item, key) => {
            if (it1[key] != it2[key]) {
                currentIndex = (key == 0 ? null : key - 1);
                return true;
            } else {
                currentIndex = currentIndex === null ? 0 : currentIndex++;
                return false;
            }

        })

        return currentIndex;


    }


    findIndexesIds = (_id) => {

        const { objFromId, main } = this.props;

        let _newArray = Object.values(JSON.parse(JSON.stringify(this.props.editor)));
        const cloneObject = { id: "canvas", components: [{ object: { data: _newArray } }], }
        const _buildArray = ((buildFullTree(cloneObject, { id: _id }).map(node => node.id).join('-')).split("-"));
        const result = _buildArray.slice(1, _buildArray.length - 1);
        return result;

    }
    prepareParentArray = async (_pArray, _cArray, _commonIndex, _childIds) => {
        /**
         * PARENT OLAN BİR ARRAY İÇERİSİNDE Kİ CHILD'IN 
         * SON HALİNİ ENTEGRE ETMEK İÇİN KULLANDIĞIMIZ FONKSION
         * ANCAK BURADA KI CHILD DERİNLERDE OLABİLİR ONUN BULUNMASI GEREKMEKTEDİR
         * ORN: ARRAY[BELLİ BİR İNDEX] OLARAK DEĞİL
         * İÇ İÇE NESNELER OLADĞU İÇİN HERHANGİ BİR DERİNLİKTE OLABİLİR.
         * BULUNDUĞU ZAMAN REF OLARAK ALINAN PARAMETRELERİN İÇERİSİNİ SET ETMEDEN
         * BOŞALT VE DOLDUR OLARAK İŞLEM UYGULUYORUZ.
         */
        let _newParent = _pArray
        await _childIds.slice(_commonIndex + 1, _childIds.length).map(item => {
            _newParent = this.handleGetIndex(_newParent, _newParent.findIndex(xx => xx.id == item));
        })
        _newParent.splice(0, _newParent.length);
        await _cArray.map(item => {
            _newParent.push(item);
        })
    }
    updateState = async (newState) => { // Kurnaz
        try {
            if (!AreObjectsEqual(this.state.list2, newState)) {
                const { index, baseProgress, main, isChild, path, component } = this.props;
                const { onMove, moveObj, objFromId, isAdded, objFrom, currentEditor, isRemove, editor } = this.props;
                const { list2 } = this.state;
                const that = this;
                /*
                GELDİĞİ YER VE GİTTİĞİ YER VAR
                HANGİSİ PARENT DEĞERİNİN KONTROLÜ YAPILMASI GEREKMEKTEDİR.
                ANCAK HER İKİSİ EŞİT BİR PATH'TE OLABİLİR.

                CUSTOMLAYOUT VEYA CANVAS TARAFINDA İŞLEM TALEBİ OLABİLİR / GİDEN VEYA GELEN

                HEDEF GELDIGI YERDE MEVZUYU ÇÖZMEK !! TEK SEFERDE
                */

                if (!onMove) {
                    console.log("Yeni bir komponent Layout içerisine direk bırakıldı", "TEST-1")

                    // Direk buraya nesne bırakılmış olabilir
                    this.props.setOnEnd();
                    this.setState({ list2: newState })
                    this.handleDirectIntegrationToEditor(editor, newState, path);
                    return;
                };

                if (!moveObj) {
                    // CANVASTAN GELMİŞTİR.
                    /*
                        REDUX TARAFINA MEVCUT ARRAY VE PATH DEĞERLERİNİ GÖNDERİYORUZ
                        CANVAS TARAFINDA DİREK İSREMOVE KONTROLUNE GİRMESİNİ SAĞLIYORUZ
                        VE ORADA MEVCUT NEWSTATE İÇERİNE BURANIN ARRAY İNİ ENTEGRE EDİYORUZ
                    */
                    this.setState({ list2: newState })
                    this.props.setOnRemove(); // CANVAS TARAFINDA REDUX DEĞERLERİNİ SIFIRLAYACAK
                    console.info("Canvas tarafından bir komponent Layout içine bırakıldı.", "TEST-1")

                    console.info(newState, "TEST-1 - onADD")
                    console.info(path, "TEST-1 - onADD Path")

                    this.props.setOnAddUpdate(path, newState, main.id);
                } else if (isRemove) {
                    // TRANSFER İŞİNİ SONLANDIRIYORUZ
                    /*
                      CANVAS VEYA CUSTOMLAYOUT TAN GELMIS OLABILIR
                    */

                    //BU İŞLEM BELKİ PROBLEM OLABİLİR CL ARASINDA
                    this.setState({ list2: newState })

                    if (objFrom) {
                        console.info("Bir layouttan farklı bir layout a komponent taşındı", "TEST-1")

                        /* 
                            CUSTOM LAYOUT'LAR ARASI BIR ISLEM BU
                            BURADA ID BİLGİLERİ VE PATH BİLGİLERİ KIYASLANABİLİR                            
                        */
                        const commonIndex = await this.findCommonIndex();

                        if (commonIndex !== null) {
                            // ORTAK BİR INDEX'I VAR
                            if (objFrom.length > path.length) {
                                /*
                                    path parent 
                                 */
                                // a = editor e  path ine newState'i çak geç
                                // a 'nin içinde ilgili ID bulunmalı (moveObj.main.id)
                                // 

                                let _newArray = Object.values(JSON.parse(JSON.stringify(this.props.editor)));
                                await this.handleSetArrayNewData(_newArray, newState, main.id);
                                await this.handleSetArrayNewData(_newArray, currentEditor, objFromId);

                                this.props.onUpdateList(_newArray);
                            } else if (objFrom.length < path.length) {
                                /*
                                    objFrom parent
                                */
                                let _newArray = Object.values(JSON.parse(JSON.stringify(this.props.editor)));
                                await this.handleSetArrayNewData(_newArray, currentEditor, objFromId);
                                await this.handleSetArrayNewData(_newArray, newState, main.id);
                                this.props.onUpdateList(_newArray);
                            } else {
                                /*
                                    eşit path
                                */
                                let _newArray = Object.values(JSON.parse(JSON.stringify(this.props.editor)));
                                await this.handleSetArrayNewData(_newArray, newState, main.id);
                                await this.handleSetArrayNewData(_newArray, currentEditor, objFromId);
                                this.props.onUpdateList(_newArray);
                            }
                        } else {
                            // ORTAK BİR INDEX'I YOK
                            let _newArray = Object.values(JSON.parse(JSON.stringify(this.props.editor)));
                            await this.handleSetArrayNewData(_newArray, newState, main.id);
                            await this.handleSetArrayNewData(_newArray, currentEditor, objFromId);
                            this.props.onUpdateList(_newArray);
                            // debugger
                        }

                    }
                    else {
                        /*
                            CANVAS TARAFINA BIR COMPONENT BIRAKILMIS
                            BU YÜZDEN objFrom olmaz direk currentEditor üzerinden işlem yapılır.
                        */

                        let _newArray = Object.values(JSON.parse(JSON.stringify(currentEditor)));

                        await this.handleSetArrayNewData(_newArray, newState, main.id);
                        this.props.onUpdateList(_newArray);
                    }

                    this.props.setOnEnd();
                }
                else {
                    /*
                       DEĞER BU ALANA GELDİYSE CUSTOMLAYOUTLAR İÇERİSİNDE DÖNEN BİR İŞLEMDİR.

                       BURADA TEK SORUN AYNI PART ISE EK MUHDALE GEREKIYOR
                    */
                    if (moveObj.path == path) {
                        /*
                            PATH'İ AYNI İSE BU İŞLEM HİÇ BİR ZAMAN ONREMOVE'E DÜŞMEYECEĞİ İÇİN ATAMA İŞLEMİ BURADA YAPILIR.
                        */
                        let _newArray = Object.values(JSON.parse(JSON.stringify(editor)));
                        await this.handleSetArrayNewData(_newArray, newState, main.id);
                        this.props.onUpdateList(_newArray);
                        this.props.setOnEnd();
                    } else {

                        this.props.setOnRemove();
                        this.props.setOnAddUpdate(path, newState, main.id);
                    }

                    //BU İŞLEM BELKİ PROBLEM OLABİLİR CL ARASINDA
                    this.setState({ list2: newState })
                }

            }
        } catch (error) {

            debugger
        }

    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount = () => {
        this.setState({
            list2: this.props.data.data.map(x => ({
                ...JSON.parse(JSON.stringify(x))
            })),
        })
    }

    findNestedObj = (mainState, valToFind) => {
        if (mainState) {
            let foundObj;
            JSON.stringify(mainState, (_, nestedValue) => {
                if (nestedValue && nestedValue["id"] === valToFind) {
                    foundObj = nestedValue;
                }
                return nestedValue;
            });
            //debugger;

            return foundObj;
        } else {
            return null;
        }

    };

    componentDidUpdate = (prevProps) => {
        let _newParent = Object.values(JSON.parse(JSON.stringify(this.props.editor)));

        if (this.props.data.data != prevProps.data.data) {
            if (this.props.data.data != this.state.list2) {
                this.setState({
                    list2: this.props.data.data,
                })
            }
        } else if (!AreObjectsEqual(this.findNestedObj(_newParent, this.props.main.id), this.findNestedObj((this.prevProps && this.prevProps.editor), this.props.main.id))) {
            let findedObj = this.findNestedObj(_newParent, this.props.main.id);
            let _newData = this.handleGetObjectData(findedObj);
            //  debugger
            if (!AreObjectsEqual(this.state.list2, _newData)) {
                //  debugger
                this.setState({ list2: _newData });
                /**
        * İÇ İÇE SINIRSIZ SAYIDA LAYOUT OLABİLECEĞİNDEN VE
        * HER BİR LAYOUT ASLINDA KENDİ STATE'I OLDUĞU İÇİN
        * PARÇALARA AYRILMIŞ DURUMDA OLMAKTADIR
        * TEK BİR İÇEĞİN DEĞİŞİMİ İLE HER BİR ALAN KENDİNE AİT BİLGİLERİ ALIR
        * BURADA YAPILAN İŞLEM BU ANA ARRAY İÇERİSİNDE BANA AİT OLAN KISIMDA
        * BİR DEĞİŞİKLİK VAR MI VARSA GÖRÜNÜRLÜĞÜ SAĞLAYAN STATE DEĞERİNE ATA
        * İŞLEMİ YAPILIYOR
        */
            }

        }
    }


    childSizeProgress = (_item, _side) => {
        const _element = document.getElementsByClassName("deviceInside");

        const _elementW = _element[0].offsetWidth + "px";
        const _elementH = _element[0].offsetHeight + "px";
        const _elementW2 = _element[0].offsetWidth / 2 + "px";
        const _elementH2 = _element[0].offsetHeight / 2 + "px";
        /*
          standat komponent ve custom komponent arasında ki model farklı olduğu için böyle bir yol tercih ettim
        */
        let _component;
        if (_item.hasOwnProperty("componentOwner")) {
            _component = _item.object.components[0];
        } else {
            _component = _item.components[0];
        }

        const _h = (_component.object.props.style.minHeight == "device" ? _elementH : (_component.object.props.style.minHeight == "half_device" ? _elementH2 : _component.object.props.style.minHeight));
        const _w = (_component.object.props.style.minWidth == "device" ? _elementW : (_component.object.props.style.minWidth == "half_device" ? _elementW2 : _component.object.props.style.minWidth));

        return _side == "w" ? _w : _h;

    }

    handleOnMove = (e) => {
        const that = this;
        /**
         * TAŞINACAK NESNENİN BİRKAÇ ÖZELLİĞİNİ 
         * REDUX A ALIYORUZ
         */
        const _data = {
            data: this.state.list2,
            path: this.props.path,
            main: this.props.main,

        }
        this.props.setOnMove(_data);
    }
    handleOnAdd = (e) => {
        const that = this;
        /**
         * BİR EKLEME İŞLEMİ OLDU
         */
        this.props.setOnAdd();
    }
    handleOnRemove = (e) => {
        const that = this;
        /**
         * BİR SİLME İŞLEMİ OLDU
         */
        this.props.setOnRemove();
    }

    render() {
        const _baseObject = {
            handleUpdate: this.updateState,
            baseArray: this.state.list2,
        }
        const { data, component, main, isChild,page,componentNames } = this.props;
        let _nameIndex = componentNames.findIndex(x => x.relComponentId === main.id);

        return (
            <>
               
                <div className={component && component.id == main.id ? "custom-tree-selected" : ""} style={{ width: "100%" }}>
                    <div className={"custom-tree-collapse-button"} onClick={() => this.collapsesToggle()}>
                        <Icon icon={this.state.openedCollapses ?'angle-down':"angle-right"} />
                    </div>
                    <div>{ _nameIndex === -1 ? main.componentName:componentNames[_nameIndex].componentName}</div>
                    <Collapse
                        // role="tabpanel"
                        isOpen={this.state.openedCollapses}
                        style={{
                            marginLeft: "15px",
                            padding: 0,
                        }}>
                        <ReactSortable
                            group={{
                                name: (this.props.currentProjectId && this.props.pageStatus.status) ? 'Kurnaz-Tree' : 'busy-page2-tree',
                                pull: true,
                                put: [
                                    (this.props.currentProjectId && this.props.pageStatus.status) ? 'Kurnaz' : 'busy-page2-tree',
                                    (this.props.currentProjectId && this.props.pageStatus.status) ? 'Kurnaz-Tree' : 'busy-page2-tree2'
                                ],
                            }}
                            swapThreshold={0.02}
                            invertSwap={false}
                            easing={"cubic-bezier(1, 0, 0, 1)"}
                            animation="0"
                            fallbackClass = {"sortable-falback1"}
                            ghostClass = {"sortable-ghost1"}
                            className={"tree-layout"}
                            list={this.state.list2}
                            setList={this.updateState}
                            onStart={this.handleOnMove}
                            onAdd={this.handleOnAdd}
                            onRemove={this.handleOnRemove}
                        >
                            {this.state.list2 &&
                                this.state.list2.map((item, index) => (
                                    <GenerateTree
                                        isChild={true}
                                        key={index + "tree-cl" + item.id}
                                        index={index}
                                        path={this.props.path + "-" + index + ""}
                                        data={item}
                                        baseProgress={_baseObject}
                                        style={{
                                            minHeight: this.childSizeProgress(item, "h"),//item.object.props.style.height,
                                            minWidth: this.childSizeProgress(item, "w")
                                        }}
                                    />
                                ))}
                        </ReactSortable>
                    </Collapse>

                </div>

            </>




        )
    }
}

const mapStateToProps = (state, props) => {
    const { editorReducer, componentReducer, globalpropsReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { present } = editorReducer;
    const { globalProps } = globalpropsReducer;

    const { editor ,page} = present;

    const { pageStatus, updateComponentPosition, currentProjectId ,componentNames} = currentstatesReducer;
    const { onMove, moveObj, isAdded, objFrom, objFromId, currentEditor, isRemove } = updateComponentPosition;


    return {
        //componentReducer,
        component,
        editor,//: JSON.parse(editor),
        globalProps,
        pageStatus,
        onMove,
        moveObj,
        isAdded,
        objFrom,
        objFromId,
        currentEditor,
        isRemove,
        currentProjectId,
        page,
        componentNames
    };
}
const mapDispatchToProps = {
    onUpdateList: updateList,
    setOnMove,
    setOnAdd,
    setOnAddUpdate,
    setOnRemove,
    setOnEnd
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeLayout)
