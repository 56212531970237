import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { Alert } from 'rsuite';
import {
    Button,
    Row,
    Col,
    ListGroup,
} from "reactstrap";
import QRCode from "react-qr-code";

class Test extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isHttpObjectModal: false,
            isEnvironmentsModal: false,

        }
    }
    handleHttpObjectModal = () => {
        this.setState({
            isHttpObjectModal: !this.state.isHttpObjectModal,
        })
    }

    handleEnvironmentsModal = () => {
        this.setState({
            isEnvironmentsModal: !this.state.isEnvironmentsModal,
        })
    }

  


    render() {
        const { currentPage, currentProjectId } = this.props;

        return (
            <Translation>
                {t => (
                    <>
                        {"Test area"}
                        <hr />
                        {
                            this.props.currentProjectId ?
                                <>
                                  
                                    {
                                        currentPage &&
                                        <>
                                            <Row>
                                                <Col>
                                                    <p>QR Code</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div style={{ marginBottom: "10px" }}>
                                                        <QRCode value={currentProjectId + "/" + currentPage} size={128} />
                                                    </div>
                                                </Col>
                                            </Row>

                                            
                                        </>
                                    }
                                </> : t("HAS_PROJECT_ERROR")

                        }

                    </>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, globalpropsReducer, currentstatesReducer, editorReducer } = state;
    const { component } = componentReducer;
    const { globalProps } = globalpropsReducer;
    const { currentProjectId, uiEditorStatus } = currentstatesReducer;
    const { present } = editorReducer;
    const { editor, pages, currentPage, page } = present;

    return {
        componentReducer,
        component,
        globalProps,
        currentProjectId,
        uiEditorStatus,
        currentPage
    };
}
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(Test)


