import React, {
  Component
} from 'react'
import {
  connect
} from 'react-redux';
import Lottie from 'react-lottie';
import animationData from 'assets/lottie/Mobile360.json';

import {
  setProjectId,
  setProjectData
} from "_actions";


import {
  Link
} from "react-router-dom";
// reactstrap components

import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
// core components


import {
  userService,
  ProjectService
} from "_services"


class Auth extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      status: false,
      data: null
    };

  }
  //1 saniye bekletiyorum. eger bekletilmez ise browserlarda problem olabilir, cok falza yonlendirme var sekilinde.
  openHomeWithDelay = () => {
    let that = this;
    setTimeout(() => {
      if (!this.state.status) {
        this.openHomeWithDelay()
      } else {
        window.location.href = that.state.data.RedirectURL;
      }
    }, 500);
    //window.location.href = that.state.data.RedirectURL;
  }

  animationStart = () => {
    const phrases = [
      'bismillahirrahmanirrahim,',
      'ilgili ayarlamalar yapılıyor',
      'harika',
      'bir',
      'uygulama olacak'
    ]
    const el = document.getElementsByClassName("text")[0];
    const el_logo = document.getElementsByClassName("loading-logo")[0];

    const fx = new TextScramble(el)

    let counter = 0
    const next = () => {
      //  debugger
      if ((counter) == phrases.length) {
        el.classList.add("puff-out-center")
        el_logo.classList.add("puff-out-center")


        setTimeout(() => {
          // window.location.href=this.state.data.RedirectURL;
          this.openHomeWithDelay()
        }, 500);

        return
      }
      fx.setText(phrases[counter]).then(() => {
        setTimeout(next, 600)
      })

      counter = counter + 1; // (counter + 1) % phrases.length
    }

    next()
  }

  handleAnimationComplete = () => {
    const {
      match: {
        params
      },
    } = this.props;
    if (params.hasOwnProperty("projectid")) {
      let projectId = params.projectid;
      this.props.history.push("/");
    }

    if (params.hasOwnProperty("token")) {

      let token = params.token;
      let that = this;
      if (!(token === null || token === undefined)) {
        userService.getProject(token)
          //.getRedirectToken(token)
          .then((data) => {
            //debugger;
            let mydata = data.dynamicValue; //JSON.parse(data.dynamicValue);
            //eger token geldi ise sal.
            //if (mydata.hasOwnProperty("Token")) {
            if (mydata.hasOwnProperty("user")) {
              localStorage.setItem("user", JSON.stringify(mydata.user));
              that.props.setProjectId(mydata.projectID);
              ProjectService.Getprojectbyid(mydata.projectID).then(data => {
                that.props.setProjectData(data.dynamicValue[0]);
                that.props.history.push("/");
              }).catch(err => {
              });
            }
            this.setState({
              data: mydata,
              status: true
            }, () => {
              // that.openHomeWithDelay();
            });
          })
          .catch((data) => {
            that.state.data = null;
            //debugger
            that.props.history.push("/");

          });


      } else {
        let User = localStorage.getItem("user");
        if (User !== null) this.getMyInformation();
      }
    }
  }

  componentDidMount = () => {

    //   this.animationStart();
  }


  render() {


    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      }
    };

    return (
      <>
        <div style={{
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
          className="container" >
          <Lottie options={defaultOptions}
            isClickToPauseDisabled={true}
            width={'100%'}
            height={500}
            eventListeners={
              [{
                eventName: 'complete',
                callback: () => {
                  this.handleAnimationComplete()
                },
              },]
            }
            style={{
              marginTop: '2rem',
              opacity: '0.8'
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { currentstatesReducer } = state;
  const { currentProjectId } = currentstatesReducer;
  return { currentProjectId };
}

const mapDispatchToProps = {
  setProjectId,
  setProjectData
}
export default connect(mapStateToProps, mapDispatchToProps)(Auth)

class TextScramble {
  constructor(el) {
    this.el = el
    //  debugger
    this.chars = '!<>-_\\/[]{}—=+*^?#________'
    this.update = this.update.bind(this)
  }
  setText(newText) {
    //  debugger
    const oldText = this.el.innerText
    const length = Math.max(oldText.length, newText.length)
    const promise = new Promise((resolve) => this.resolve = resolve)
    this.queue = []
    for (let i = 0; i < length; i++) {
      const from = oldText[i] || ''
      const to = newText[i] || ''
      const start = Math.floor(Math.random() * 40)
      const end = start + Math.floor(Math.random() * 40)
      this.queue.push({
        from,
        to,
        start,
        end
      })
    }
    cancelAnimationFrame(this.frameRequest)
    this.frame = 0
    this.update()
    return promise
  }
  update() {
    let output = ''
    let complete = 0
    for (let i = 0, n = this.queue.length; i < n; i++) {
      let {
        from,
        to,
        start,
        end,
        char
      } = this.queue[i]
      if (this.frame >= end) {
        complete++
        output += to
      } else if (this.frame >= start) {
        if (!char || Math.random() < 0.28) {
          char = this.randomChar()
          this.queue[i].char = char
        }
        output += `<span style={color: red} class="dud">${char}</span>`
      } else {
        output += from
      }
    }
    this.el.innerHTML = output
    if (complete === this.queue.length) {
      this.resolve()
    } else {
      this.frameRequest = requestAnimationFrame(this.update)
      this.frame++
    }
  }
  randomChar() {
    return this.chars[Math.floor(Math.random() * this.chars.length)]
  }
}