import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { FormGroup, Form, Input, Row, Col } from "reactstrap";
import { updateComponent, updateList } from '_actions';
import { findObjUpdate, findNestedObjAndChangeALL } from 'assets/util';
import i18n from 'i18n';
import {
    PropertyTitle,
} from "../Core/";
import { Icon, Dropdown, List, Alert, Button, IconButton, ButtonToolbar, } from 'rsuite';
import ColorPicker from "../Core/ColorPicker";
import NumaricDataProperty from "../Core/NumaricDataProperty"
import { ComponentService } from '_services';
import { ResponseStatusCode } from "_helpers";
import StaticTextValue from '../Core/StaticTextValue';
import NewScript from '../../Modals/NewScript';

import IconDetailProperty from '../Core/IconDetailProperty'
 
const defautValueOptions = [
    {
        label: i18n.t("DROPDOWN_TRUE"),
        value: true,
    },
    {
        label: i18n.t("DROPDOWN_FALSE"),
        value: false,
    }
]
const WAIT_INTERVAL = 500;

class RangeTimePickerSettings extends Component {
    constructor(props) {
        super(props)
        this.fontSizeSlider = React.createRef();

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const _p = object.props;
        this.state = {
            componentClone: component,
            editable: component && object.props.editable ? true : false,
            color: component && (_p["color"] == undefined ? "rgba(255, 255, 255, 1)" : _p["color"]),
            selectedColor: component && (_p["selectedColor"] == undefined ? "rgba(255, 255, 255, 1)" : _p["selectedColor"]),
            disabledColor: component && (_p["disabledColor"] == undefined ? "rgba(255, 255, 255, 1)" : _p["disabledColor"]),


        }


    }

    generateComponentId = async () => {
        return await ComponentService.GenerateComponentId(this.props.currentProjectId, this.props.currentPage).then(data => {
            return data.dynamicValue;
        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Alert.warning(data.desc)
            return null;
        });
    }
    removeComponentId = async (_id) => {
        const { component, currentPage } = this.props;
        return await ComponentService.RemoveComponentId(this.props.currentProjectId, currentPage, _id).then(data => {
        }).catch(err => {
            debugger
            let data = { title: i18n.t("warning"), desc: ResponseStatusCode(err.statusCode) };
            Alert.warning(data.desc)
            return null;
        });
    }

    updatePropEditor = (key, value) => {
        const that = this;
        let _clone = Object.assign({}, this.props.component);
        if (_clone.hasOwnProperty("componentOwner")) {
            _clone.object.components[0].object.props[key] = value
        } else {
            _clone.components[0].object.props[key] = value
        }

        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);
        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);
    }

    handleChangeColor = (color) => {
        const that = this;
        this.setState({
            color: color,
        }, () => { that.updatePropEditor("color", `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`) })
    }
    handleChangeSelectedColor = (color) => {
        const that = this;
        this.setState({
            selectedColor: color,
        }, () => { that.updatePropEditor("selectedColor", `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`) })
    }
    handleChangeDisabledColor = (color) => {
        const that = this;
        this.setState({
            disabledColor: color,
        }, () => { that.updatePropEditor("disabledColor", `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`) })
    }


    componentDidUpdate = (prevProps) => {

        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;
        const _p = object.props;

        if (prevProps.component != component) {
            this.setState({
                componentClone: component,
                color: component && (_p["color"] == undefined ? "rgba(255, 255, 255, 1)" : _p["color"]),
                selectedColor: component && (_p["selectedColor"] == undefined ? "rgba(255, 255, 255, 1)" : _p["selectedColor"]),
                disabledColor: component && (_p["disabledColor"] == undefined ? "rgba(255, 255, 255, 1)" : _p["disabledColor"]),
                editable: component && object.props.editable ? true : false,
                // orientation: component && (defautOrientationOptions.find(x => x.value === _p.orientation).value),
                // verticalOrientation: component && (defautVerticalOrientationOptions.find(x => x.value === _p.verticalOrientation).value),
                // position: component && (defautPositionOptions.find(x => x.value === _p.position).value),
            }, () => { })
        }
    }


    componentDidMount() {
    }



    handleChange = async (event, index) => {
        const that = this;
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        clearTimeout(this.timer);

        let _list = Object.values(JSON.parse(JSON.stringify(this.state.menu)));
        _list[index].title = value;

        await this.setState({
            ["menu"]: _list,
        }, () => {
            //that.updatePropEditor("menu", that.state.menu)
        });
        this.timer = setTimeout(() => { that.updatePropEditor("menu", that.state.menu) }, WAIT_INTERVAL);

    }


    componentWillMount() {
        this.timer = null;
    }

    handleEditable = (_data) => {
        const that = this;
        this.setState({
            ["editable"]: _data.value,
        }, () => {
            const _style = this.props.dataPath ? this.props.dataPath : "style";
            let _clone = Object.assign({}, this.props.component);

            if (_clone.hasOwnProperty("componentOwner")) {
                _clone.object.components[0].object.props.editable = that.state.editable;
            } else {
                _clone.components[0].object.props.editable = that.state.editable;;
            }
            let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
            let _result = findObjUpdate(_newEditor, _clone);
            this.props.updateComponent(_clone);
            this.props.onUpdateList(_result);

        })
    }

    render() {
        const { component } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];


        const that = this;
        return (
            <Translation>
                {t => (
                    <>
                        <Form>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="checkbox-icon-color" className={"propertyTitle"}>{t("PROPERTY_RANGETIMEPICKER_EDITABLE")}
                                    </label>
                                    {
                                        this.state["editable"] !== null &&
                                        <Dropdown size="xs" title={defautValueOptions.find(x => x.value === this.state["editable"]).label}>
                                            {
                                                defautValueOptions.map((x, key) => (
                                                    x.value === this.state["editable"] ?
                                                        "" :
                                                        <Dropdown.Item key={key} onSelect={() => this.handleEditable(x)}>{x.label}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown>
                                    }
                                </div>
                            </FormGroup>

                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_RANGETIMEPICKER_TIMECOLORS")}
                                    </label>
                                    <ColorPicker isComponent={true} id={"rangetimepicker-color"} color={this.state.color} handle={this.handleChangeColor} />
                                </div>
                            </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_RANGETIMEPICKER_SELECTEDCOLORS")}
                                    </label>
                                    <ColorPicker isComponent={true} id={"rangetimepicker-selectedcolor"} color={this.state.selectedColor} handle={this.handleChangeSelectedColor} />
                                </div>
                            </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <div className={"propertyTitleDiv"}>
                                    <label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_RANGETIMEPICKER_DISABLEDCOLORS")}
                                    </label>
                                    <ColorPicker isComponent={true} id={"rangetimepicker-disabledcolor"} color={this.state.disabledColor} handle={this.handleChangeDisabledColor} />
                                </div>
                            </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <PropertyTitle title={t("PROPERTY_RANGETIMEPICKER_OPTION")} subtitle={""} />
                                <NumaricDataProperty withoutExtension={true}  key={"rtp-option" + component.id} propName={"option"} isCustom={"qr-size"} isType={false} defaultValue={"100"} />
                            </FormGroup>
                            <FormGroup className={"property-form-group"}>
                                <PropertyTitle title={t("PROPERTY_RANGETIMEPICKER_TIME")} subtitle={""} />
                                <NumaricDataProperty withoutExtension={true} key={"rtp-time" + component.id} propName={"time"} isCustom={"qr-size"} isType={false} defaultValue={"100"} />
                            </FormGroup>
                            {
                                /*
                                component &&
                                object &&
                                object.type === "fabmenu" &&
                                <FormGroup className={"property-form-group"}>
                                    <div className={"propertyTitleDiv"}><label htmlFor="exampleFormControlInput1" className={"propertyTitle"}>{t("PROPERTY_FABMENU_ORIENTATION")}</label>
                                        <Dropdown size="xs" title={defautOrientationOptions.find(x => x.value === this.state.orientation).label}>
                                            {
                                                defautOrientationOptions.map((x, key) => (
                                                    x.value === this.state.orientation ?
                                                        "" :
                                                        <Dropdown.Item key={key} onSelect={() => this.handleUpdateOrientation(x)}>{x.label}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown>
                                    </div>
                                </FormGroup>
*/

                            }



                        </Form>

                    </>

                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { pageStatus, currentProjectId } = currentstatesReducer;

    const { present } = editorReducer;
    const { editor, currentPage } = present;
    return {
        editor,
        currentPage,
        component,
        currentProjectId
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
    onUpdateList: updateList,


}
export default connect(mapStateToProps, mapDispatchToProps)(RangeTimePickerSettings)
