import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import { FormGroup, Form, Input, Row, Col, ButtonGroup, Button } from "reactstrap";
import { updateComponent, updateList } from '../../../_actions';
import { findNestedObjAndChangeALL } from "../../../assets/util"

import FlexProperty from "../Core/FlexProperty";

import {
    PropertyTitle,
} from "../Core/";

class FlexSettings extends Component {
    constructor(props) {
        super(props)
        this.fontSizeSlider = React.createRef();

        const { component } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
 
        this.state = {
            componentClone: component,

        }

    }

    handleUpdateEditor = (_clone) => {

        /*
            state eski halinden farklı ise güncelleme gönderilmeli..

            
        */
       this.props.updateList(findNestedObjAndChangeALL(this.props.editor, _clone.id, _clone));
    }

    componentDidMount() {

    }

    render() {
        const { component } = this.props;
        return (
            <Translation>
                {t => (
                    <Form>
                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_FLEX_DIRECTION")} subtitle={""} />
                            <FlexProperty
                                propName={"flexDirection"}
                                nullOption={"unset"}
                                options={[
                                    "unset",
                                    "column",
                                    "column-reverse",
                                    "row",
                                    "row-reverse",
                                ]} />
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_FLEX_JUSTFY")} subtitle={""} />
                            <FlexProperty
                                propName={"justifyContent"}
                                nullOption={"start"}
                                options={[
                                    "center",
                                    "flex-start",
                                    "flex-end",
                                    "space-around",
                                    "space-evenly",
                                    "space-between",
                                ]} />
                        </FormGroup>
                        <FormGroup className={"property-form-group"}>
                            <PropertyTitle title={t("PROPERTY_FLEX_ALIGN")} subtitle={""} />
                            <FlexProperty
                                propName={"alignItems"}
                                nullOption={"start"}
                                options={[
                                    "center",
                                    "flex-start",
                                    "flex-end",
                                ]} />
                        </FormGroup>
                    </Form>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    const { componentReducer, editorReducer } = state;
    const { component } = componentReducer;

    const { present } = editorReducer;
    const { editor } = present;
    return {
        editor,
        component,
    };
}
const mapDispatchToProps = {
    updateComponent,
    updateList,
}
export default connect(mapStateToProps, mapDispatchToProps)(FlexSettings)
