import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import classnames from "classnames";
import Emitter from "_actions/emitter";
/*
import InputGenerator from './Properties/InputGenerator'
import StaticCMSSettings from './Properties/StaticCMSSettings'
import HttpObjectSelector from 'components/HttpObjects/HttpObjectSelector';
*/

import FontSettings from './Properties/FontSettings'
import LayoutSettings from './Properties/LayoutSettings'
import FlexSettings from './Properties/FlexSettings'

import ImageSettings from './Properties/ImageSettings'
import IconSettings from './Properties/IconSettings'

import ListItemSettings from './Properties/ListItemSettings'
import CarouselSettings from './Properties/CarouselSettings'
import ListGridItemSettings from './Properties/ListGridItemSettings'
import PageSwiperSettings from './Properties/PageSwiperSettings'
import FlipItemSettings from './Properties/FlipItemSettings'


import BasicInfo from './Core/BasicInfo'
import CheckboxSettings from './Properties/CheckboxSettings'
import RadiobuttonSettings from './Properties/RadiobuttonSettings'

import QRSettings from './Properties/QRSettings'
import TextInputSettings from './Properties/TextInputSettings'

import BlurSettings from './Properties/BlurSettings'
import SelectPickerSettings from './Properties/SelectPickerSettings'
import FabMenuSettings from './Properties/FabMenuSettings'

import DatePickerSettings from './Properties/DatePickerSettings'
import ProgressBarSettings from './Properties/ProgressBarSettings'
import ChipListSettings from './Properties/ChipListSettings'

import ScrollSettings from './Properties/ScrollSettings'

import DynamicPropsSettings from './Properties/DynamicPropsSettings'
import RangeTimePickerSettings from './Properties/RangeTimePickerSettings';
import VideoPlayerSettings from './Properties/VideoPlayerSettings';

import WebviewSettings from './Properties/WebviewSettings';


import DynamicFieldValue from './Core/DynamicFieldValue'
//import DynamicValue from './Core/DynamicValue'
import StaticTextValue from './Core/StaticTextValue'


import NewScript from '../Modals/NewScript';

import {
    NavItem,
    NavLink,
    Nav
} from "reactstrap";

import { PropertyCategory } from "./Core"

class Properties extends Component {
   

    generateProperty = () => {

    }
    state = {
        tabs: 1,
        scriptEditor: false,
    };

    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index
        });
    };


    handleOpenFieldTAB = () => {
        // Component değiştiğinde otomatik field tab görünmesi
        this.setState({
            tabs: 1
        });
    }
    componentDidMount = () => {
        Emitter.on("HANDLE_OPEN_FIELD_TAP", newValue =>
            this.handleOpenFieldTAB()
        );
    }
    componentWillUnmount() {
        Emitter.off("HANDLE_OPEN_FIELD_TAP");

    }


    handleScriptEditorModal = (_data) => {
        this.setState({
            scriptData: this.state.scriptEditor ? null : _data,
            scriptEditor: !this.state.scriptEditor,
        })
    }

    render() {
        //JSON.parse(httpObjects.dynamicValue[uiData.object.data.value].successDynamicObjects)[0]
        const object = this.props.component && (this.props.component.hasOwnProperty("componentOwner") ? this.props.component.object.components[0] : this.props.component.components[0]);
        const cComp = this.props.component && (this.props.component.hasOwnProperty("componentOwner") ? this.props.component.object : this.props.component);

        const component = this.props.component ? object : null;
        const { page, uiEditorStatus, uiEditorComponent, httpObjects } = this.props;



      //  const uiData = uiEditorComponent && (uiEditorComponent.hasOwnProperty("componentOwner") ? uiEditorComponent.object.components[0] : uiEditorComponent.components[0])
        //debugger
        return (
            <Translation>
                {t => (
                    <>
                        {
                            (this.props.currentProjectId && this.props.pageStatus.status) ? (
                                this.props.component ?
                                    ((this.props.component.id) && (
                                        <div key={this.props.component.id}>
                                            <BasicInfo />

                                            <hr className={"hr-margin-top"} />
                                            <div className="nav-wrapper" style={{ padding: 0 }}>
                                                <Nav
                                                    className="nav-fill flex-row custom-property-tab"
                                                    id="tabs-icons-text"
                                                    pills
                                                    role="tablist">
                                                    <NavItem>
                                                        <NavLink
                                                            aria-selected={this.state.tabs === 1}
                                                            className={classnames("mb-sm-3 mb-md-0", {
                                                                active: this.state.tabs === 1
                                                            })}
                                                            onClick={e => this.toggleNavs(e, "tabs", 1)}
                                                            href="#properties"
                                                            role="tab"
                                                        >
                                                            {t("PROPERTY_TITLE")}
                                                        </NavLink>
                                                    </NavItem>
                                                    {
                                                        cComp &&
                                                        cComp.eventLists &&
                                                        cComp.eventLists.length > 0 &&

                                                        <NavItem>
                                                            <NavLink
                                                                aria-selected={this.state.tabs === 2}
                                                                className={classnames("mb-sm-3 mb-md-0", {
                                                                    active: this.state.tabs === 2
                                                                })}
                                                                onClick={e => this.toggleNavs(e, "tabs", 2)}
                                                                href="#fuctions"
                                                                role="tab"
                                                            >
                                                                {t("FUNCTIONS_TITLE")}
                                                            </NavLink>
                                                        </NavItem>
                                                    }


                                                </Nav>
                                            </div>
                                            <hr className={"hr-margin-bottom"} />


                                            {this.state.tabs === 1 &&
                                                component.properties != undefined && (
                                                    <div className={"custom-fade-in"}>
                                                        {
                                                            component.properties.map((item, key) => {
                                                                switch (item.name) {
                                                                    case "value":
                                                                        return (
                                                                            <>
                                                                                <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_VALUE")} status={true}>
                                                                                    {
                                                                                        <DynamicFieldValue basicField={
                                                                                            <StaticTextValue propName={"value"} />
                                                                                        } />

                                                                                        /*
                                                                                        (uiEditorStatus && uiData && uiData.object.data) ?
                                                                                                    <HttpObjectSelector isProperty={true} component={null} data={uiData.object.data.children[0].children} dataType={"string"} />
                                                                                            :
                                                                                                    <StaticCMSSettings key={key + this.props.component.id} />
                                                                                            */
                                                                                    }

                                                                                    {
                                                                                        component.object.type == "webview" &&
                                                                                        <WebviewSettings />
                                                                                    }

                                                                                </PropertyCategory>
                                                                                {
                                                                                    (component.object.type === "textbox" || component.object.type === "multiline") &&
                                                                                    <PropertyCategory key={key + "placeholder" + this.props.component.id} title={t("PROPERTY_TITLE_TEXT_INPUT")} status={true}>
                                                                                        <TextInputSettings />
                                                                                    </PropertyCategory>
                                                                                }
                                                                            </>

                                                                        )
                                                                    case "text":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_TEXT")} status={true}>
                                                                                <FontSettings />
                                                                            </PropertyCategory>
                                                                        )

                                                                    case "selectpicker":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_SELECTPICKER")} status={true}>
                                                                                <SelectPickerSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "layout":
                                                                        return (
                                                                            <>
                                                                                <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_LAYOUT")} status={true}>
                                                                                    <LayoutSettings />
                                                                                </PropertyCategory>
                                                                                {
                                                                                    (component.object.type === "layout") &&
                                                                                    <PropertyCategory key={key + "scroll" + this.props.component.id} title={t("PROPERTY_TITLE_SCROLLEABLE")} status={true}>
                                                                                        <ScrollSettings />
                                                                                    </PropertyCategory>
                                                                                }
                                                                            </>
                                                                        )
                                                                    case "flex":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_FLEX")} status={true}>
                                                                                <FlexSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "image":
                                                                        return (
                                                                            <>
                                                                                <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_IMAGE")} status={true}>
                                                                                    <ImageSettings />
                                                                                </PropertyCategory>
                                                                                <PropertyCategory key={key + "blur" + this.props.component.id} title={t("PROPERTY_TITLE_BLUR")} status={true}>
                                                                                    <BlurSettings />
                                                                                </PropertyCategory>
                                                                            </>
                                                                        )
                                                                    case "icon":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_ICON")} status={true}>
                                                                                <IconSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "datalayout":
                                                                        // 
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_DATA_ITEM")} status={true}>
                                                                                <ListItemSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "qrlayout":
                                                                        // 
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_QR_LAYOUT")} status={true}>
                                                                                <QRSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "datagridlayout":
                                                                        // 
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_DATA_GRID_ITEM")} status={true}>
                                                                                <ListGridItemSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "checkboxtext":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_CHECKBOX_LABEL")} status={true}>
                                                                                <FontSettings dataPath={"fontStyle"} editableLabelValue={true} />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "checkbox":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_CHECKBOX")} status={true}>
                                                                                <CheckboxSettings dataPath={"boxStyle"} />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "radiobuttontext":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_RADIOBUTTON_LABEL")} status={true}>
                                                                                <FontSettings dataPath={"fontStyle"} editableLabelValue={false} />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "radiobutton":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_RADIOBUTTON")} status={true}>
                                                                                <RadiobuttonSettings dataPath={"boxStyle"} />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "datacarousel":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_CAROUSEL")} status={true}>
                                                                                <CarouselSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "pageswiper":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_PAGE_SWIPER")} status={true}>
                                                                                <PageSwiperSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "fabmenu":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_FAB_MENU")} status={true}>
                                                                                <FabMenuSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "datepicker":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_DATEPICKER")} status={true}>
                                                                                <DatePickerSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "progressbar":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_PROGRESSBAR")} status={true}>
                                                                                <ProgressBarSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "chiplist":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_CHIPLIST")} status={true}>
                                                                                <ChipListSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "rangetimepicker":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_RTPICKER")} status={true}>
                                                                                <RangeTimePickerSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "videoplayer":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_VIDEOPLAYER")} status={true}>
                                                                                <VideoPlayerSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    case "fliplayout":
                                                                        return (
                                                                            <PropertyCategory key={key + this.props.component.id} title={t("PROPERTY_TITLE_FLIP_LAYOUT")} status={true}>
                                                                                <FlipItemSettings />
                                                                            </PropertyCategory>
                                                                        )
                                                                    default:

                                                                        return null;
                                                                }

                                                            })
                                                        }
                                                        <PropertyCategory key={"dynamicProps" + this.props.component.id} title={t("PROPERTY_TITLE_DYNAMIC")} status={true}>
                                                            <DynamicPropsSettings />
                                                        </PropertyCategory>
                                                    </div>

                                                )
                                            }
                                            {this.state.tabs === 2 &&
                                                component.properties != undefined && (
                                                    <div className={"custom-fade-in"}>
                                                        {
                                                            cComp &&
                                                            cComp.eventLists.map((item, key) => (
                                                                <p
                                                                    onClick={() => {
                                                                        this.handleScriptEditorModal(item);
                                                                    }}
                                                                    className={"clickable func-item"} key={key+"cComp"}>{item.eventName}</p>
                                                            ))
                                                        }
                                                    </div>

                                                )
                                            }

                                            <NewScript data={this.state.scriptData} isActive={this.state.scriptEditor} handleToggle={this.handleScriptEditorModal} />
                                        </div>
                                    )) :
                                    <p className="text-muted">
                                        {t("MESSAGE_PROPERTY_NOT_SELECTED_COMPONENT")}
                                    </p>
                            ) :
                                <p className={"text-muted"}>
                                    {t("PAGE_BUSY_ERROR")}
                                </p>

                        }
                    </>
                )
                }
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { pageStatus, currentProjectId, uiEditorStatus, uiEditorComponent, httpObjects } = currentstatesReducer;

    return {
        componentReducer,
        component,
        pageStatus,
        currentProjectId,
        uiEditorStatus,
        uiEditorComponent,
        httpObjects
    };
}
const mapDispatchToProps = {
    //onUpdateConponent: updateComponent,

}
export default connect(mapStateToProps, mapDispatchToProps)(Properties)
