import React, { Component } from 'react'
import PropTypes, { array } from 'prop-types';

import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import i18n from 'i18n';
import Emitter from "_actions/emitter";

import SplitterLayout from 'react-splitter-layout';
//import 'react-splitter-layout/lib/index.css';

import { CheckTreePicker, Nav, Dropdown, Alert, Loader, Steps, Panel, Navbar, ButtonGroup, Message, Icon, Popover, Whisper, Tooltip } from 'rsuite';
//import MonacoEditor from 'react-monaco-editor';
import { loader } from '@monaco-editor/react';

import { DynamicFunctionService } from '_services'
import { setDynamicFuncs } from '_actions'

import {
    Button,
    Modal,
    Row,
    Col,
} from "reactstrap";

class NewScript extends Component {
    constructor(props) {
        super(props)
        this.state = {
            script: "",
            myDetail: null,
            loading: false,
        }
    }

    componentDidMount = () => {
        /*   monaco
               .init()
               .then(monaco => {
                   monaco.languages.registerCompletionItemProvider('javascript', {
                       provideCompletionItems: () => {
                           var suggestions = [{
   
                               label: 'executeHttpObject',
                               kind: monaco.languages.CompletionItemKind.Snippet,
                               insertText: [
                                   'executeHttpObject(httpObjects.$0);',
                               ].join('\n'),
                               insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                               documentation: 'executeHttpObject detail'
                           }];
                           return { suggestions: suggestions };
                       }
                   });
                   monaco.languages.registerCompletionItemProvider('javascript', {
                       provideCompletionItems: () => {
                           var suggestions = [{
   
                               label: 'executeHttpObject',
                               kind: monaco.languages.CompletionItemKind.Snippet,
                               insertText: [
                                   'try {',
                                   '   executeHttpObject($0);',
                                   '} catch (error) {',
                                   '}'
                               ].join('\n'),
                               insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                               documentation: 'executeHttpObject detail'
                           }];
                           return { suggestions: suggestions };
                       }
                   });
               })
               */
    }

    componentDidUpdate = (prevProps) => {
        const { isActive } = this.props;
        if (prevProps.isActive !== isActive) {
            if (isActive) {
                this.getMyDetail();
            }
            else {
                this.setState({
                    script: "",
                    myDetail: null,
                    loading: false,

                })
            }


        }
    }


    getMyDetail = async () => {
        const { dynamicFunctions, data, isPage, page, isFiledEvent, fieldData } = this.props;
        const component = isFiledEvent ? fieldData : this.props.component;
        const _event = await dynamicFunctions.dynamicValue.find(x => x.relEventListId === data.id && x.relComponentId === (isPage ? page.id : component.id));
        // debugger
        if (_event) {
            this.setState({
                myDetail: _event,
                script: _event.script,
            });
            this.handleGenerateScriptEditor("script", _event);
        }
        else {
            this.handleGenerateScriptEditor("script");
        }

    }

    getCompObj = (_data) => {
        try {
            if (_data.hasOwnProperty("componentOwner")) { return _data.object.components[0].object; }
            else { return _data.components[0].object; }
        } catch (error) {
            debugger
        }
    }

    handleGenerateScriptEditor = async (_where) => {
        const that = this;
        //componentNames
        let _pages = {}; // mevcut sayfa dışında ki sayfalar
        let _pages2 = {};
        await that.props.pages.map(x => {
            if (that.props.page.pageName !== x.pageName) {
                _pages[x.pageName] = "string";
                _pages2[x.pageName] = "string";
            } else {
                let _cNames = {};
                that.props.componentNames.forEach((item, key) => {
                    _cNames[item.componentName] = "string";
                })
                _pages2[x.pageName] = _cNames;
            }
        });

        const { component, httpObjects, data } = this.props;
        const _CompObject = this.getCompObj(component);
        let _httpDataPath = null;

        
        if (data.params && data.params.length > 0) {
            if (data.params.find(x => x === "indexData" && _CompObject.data)) {
                if(_CompObject.data.path){
                    let _httpPath = _CompObject.data.path.split("-");
                    let _httpData = httpObjects.dynamicValue.find(x => x.httpObjectName === _httpPath[0])
                    if (_httpData) {
                       await _httpPath.forEach((element, index) => {
                           // debugger
                            if(index !== 0){
                                if (index === 1)
                                _httpDataPath = JSON.parse(_httpData.successDynamicObjects)[element];
                            else
                                _httpDataPath = _httpDataPath[element]
                            }
                        });
                         if(Array.isArray(_httpDataPath) && _httpDataPath.length>0){
                            _httpDataPath = _httpDataPath[0];
                        }
                    }
                }
              
            }
        }
        






        let _httpObjects = {}
        await that.props.httpObjects.dynamicValue.map(x => {
            _httpObjects[x.httpObjectName] = "string"
        });

        loader
            .init()
            .then(monaco => {
                let keys = [];
                monaco.languages.typescript.javascriptDefaults.setExtraLibs({})

                monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
                    //target: monaco.languages.typescript.ScriptTarget.ES6,
                    allowNonTsExtensions: true,
                    noLib: true,
                    //  target: monaco.languages.typescript.ScriptTarget.ES2015,
                    lib: []
                });

                if(_httpDataPath){
                    monaco.languages.typescript.javascriptDefaults.addExtraLib([
                        'var indexData =',
                        JSON.stringify(_httpDataPath)
                    ].join('\n'));
                }

                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var pages =',
                    JSON.stringify(_pages2)
                ].join('\n'));

                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var httpObjects =',
                    JSON.stringify(_httpObjects)
                ].join('\n'));

                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var toastTypes = {success:"success",warning:"warning",danger:"danger"}',
                ].join('\n'));



                // ! FIX EVENTS START ----------------------------------------------

                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var executeHttpObject =(httpObjects:name) =>{',
                    '}'
                ].join('\n'));

                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var makeCall =(phone:string) =>{',
                    '}'
                ].join('\n'));


                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var turnBackPage =() =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var openPage =(page:pages) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var openGroupPage =(page:pages) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var openHamburgerMenu =(page:pages) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var openModalPage =(page:pages) =>{',
                    '}'
                ].join('\n'));

                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var GetDynamicStore =(keyName) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var SetDynamicStore =(keyName,value) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var SetComponentProperties =(page:pages,componentName,object:Object) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var Toast =(message:string,type:toastTypes) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var OpenShare =(title:string,message:string,url:string) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var ActionSheet =(title:string, options:array, destructiveButtonIndex:number, cancelButtonIndex:number) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var OpenPrint =(url:string) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var ToMoneyFormat =(price:number) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var imagePicker =(limit:number,callback:function) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var videoPicker =(callback:function) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var takePhoto =(callback:function) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var takeVideo =(callback:function) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var pageLoading =(status:boolean) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var uploadFileBase64 =(url:string,callback:function) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var deviceInfo = {',
                        'getDeviceId:function,',
                        'getDeviceType:function',
                        'getDeviceName:function,',
                        'getIpAddress:function,',
                        'getMacAddress:function,',
                        'getManufacturer:function,',
                        'isTablet:function,',
                        'isLandscape:function,',
                        'isBatteryCharging:function,',
                        'hasNotch:function,',
                        'getVersion:function,',
                        'getSystemName:function',
                        'getSystemVersion:function',
                        'getModel:function',
                        'getInstallerPackageName:function',
                        
                    '}'
                ].join('\n'));

                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var runVibration =(duration:number) =>{',
                    '}'
                ].join('\n'));

                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var adsShowRewardedVideo =() =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var adsShowNonSkippableVideo =(isTop:boolean) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var adsSetAge =(data:string) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var adsSetGender =(data:string) =>{',
                    '}'
                ].join('\n'));
                monaco.languages.typescript.javascriptDefaults.addExtraLib([
                    'var adsSetUserId =(data:string) =>{',
                    '}'
                ].join('\n'));

                // ! FIX EVENTS END ----------------------------------------------

                let _monaco = monaco.editor.create(document.getElementById(_where), {
                    loading: <Loader />,
                    //onChange: that.onChangeBody,
                    value: that.state[_where],
                    language: 'javascript',

                });

                _monaco.onDidChangeModelContent(function (e, v) {
                    that.setState({
                        [_where]: _monaco.getValue()
                    })
                });



            });

    }

    updateScript = () => {
        const { currentProjectId, setDynamicFuncs, data, dynamicFunctions, handleToggle, isPage, page } = this.props;

        const { isFiledEvent, fieldData } = this.props;
        const component = isFiledEvent ? fieldData : this.props.component;

        const { myDetail, script } = this.state;
        const that = this;
        this.setState({ loading: true })
        DynamicFunctionService.UPDATE_FUNCTION(
            myDetail.id,
            currentProjectId,
            (isPage ? page.pageName.split(" ").join("") + page.pageName.id + "_" + data.eventName : component.componentName.split(" ").join("") + component.id + "_" + data.eventName),
            (isPage ? page.id : component.id),
            data.id,
            script,
            data.params,
            isPage
        )
            .then(data => {
                const _data = data.dynamicValue;

                let _obj = Object.assign({}, dynamicFunctions);
                const fIndex = _obj.dynamicValue.findIndex(x => x.id == _data.id)
                if (fIndex > -1) {
                    _obj.dynamicValue[fIndex] = _data;
                    setDynamicFuncs(_obj);
                    Alert.success(i18n.t("SUCCESS_UPDATED"));
                    handleToggle(null);
                } else {
                    Alert.warning(i18n.t("somethingWrong"));
                }
                that.setState({ loading: false })

                //setDynamicFuncs(data.dynamicValue);
            }).catch(err => {
                debugger;
                Alert.error("Error code : " + err.statusCode);
                that.setState({ loading: false })


            })
    }
    createScript = () => {
        //CREATE_FUNCTION
        const { currentProjectId, setDynamicFuncs, data, dynamicFunctions, handleToggle, isPage, page } = this.props;
        const { isFiledEvent, fieldData } = this.props;
        const component = isFiledEvent ? fieldData : this.props.component;

        const { myDetail, script } = this.state;
        const that = this;
        this.setState({ loading: true })
        DynamicFunctionService.CREATE_FUNCTION(
            currentProjectId,
            (isPage ? page.pageName.split(" ").join("") + page.pageName.id + "_" + data.eventName : component.componentName.split(" ").join("") + component.id + "_" + data.eventName),
            (isPage ? page.id : component.id),
            data.id,
            script,
            data.params,
            isPage
        )
            .then(data => {
                const _data = data.dynamicValue;
                let _obj = Object.assign({}, dynamicFunctions);
                _obj.dynamicValue.push(_data);
                setDynamicFuncs(_obj);
                Alert.success(i18n.t("SUCCESS_CREATED"));
                handleToggle(null);
                that.setState({ loading: false })

            }).catch(err => {
                debugger;
                Alert.error("Error code : " + err.statusCode);
                that.setState({ loading: false })


            })
    }

    render() {
        const { isActive, handleToggle, dynamicFunctions, data } = this.props;
        const { loading, myDetail } = this.state;

        return (
            <Translation>
                {t => (
                    <Modal
                        isOpen={isActive}
                        size="xl"
                        scrollable={true}
                        className={"modal-dialog-centered httpobject-modal"}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="CheckProjectModal">
                                {t("MODAL_TITLE_FUNC_EDITOR")}
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => handleToggle()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <Row>
                                    <Col sm={12} >
                                        {
                                            data &&
                                            <>
                                                <span className={"script-text script-text-blue"}>{"function "}
                                                    <span className={"script-text script-text-black"}>{data.eventName}</span>
                                                    <span className={"script-text script-text-black"}>{"("}</span>
                                                    {
                                                        data.params.map((x, key) => (
                                                            <span key={key} className={"script-text script-text-grey"}>{(key > 0 ? ", " : "") + x}</span>

                                                        ))
                                                    }
                                                    <span className={"script-text script-text-black"}>{") {"}</span>
                                                </span>
                                                <div className={"custom-fade-in"} id={"script"} style={{ height: "40vh", border: "1px solid #dee2e6" }} />
                                                <span className={"script-text script-text-black"}>{" }"}</span>
                                            </>
                                        }

                                    </Col>
                                </Row>
                            </div>

                            <ButtonGroup className={"httpobject-status"} style={{ paddingTop: "10px" }}>
                                <Button className={"custom-fade-in"} size="sm" onClick={() => handleToggle(null)} disabled={loading}>
                                    {t("BUTTON_CANCEL")}
                                </Button>

                                {
                                    myDetail ?
                                        <Button color={"primary"} className={"custom-fade-in"} size="sm" onClick={this.updateScript} disabled={loading}>
                                            {t("BUTTON_UPDATE")}
                                        </Button> :
                                        <Button color={"primary"} className={"custom-fade-in"} size="sm" onClick={this.createScript} disabled={loading}>
                                            {t("BUTTON_CREATE")}
                                        </Button>
                                }

                            </ButtonGroup>
                        </div>

                    </Modal>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state, props) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { present } = editorReducer;
    const { pages, page } = present;

    const { component } = componentReducer;
    const { componentNames, environments, httpObjects, currentProjectId, dynamicFunctions } = currentstatesReducer;

    return {
        page,
        pages,
        componentNames,
        component,
        environments,
        httpObjects,
        currentProjectId,
        dynamicFunctions,
    };
}
const mapDispatchToProps = {
    setDynamicFuncs,
    //  onUpdateComponent: updateComponent,

}
export default connect(mapStateToProps, mapDispatchToProps)(NewScript)



