import {
    //ServiceURLs,
    HttpObjectEndPoints,
    GetBaseCreatorURL,
    MakeReqestAndGetResponse,
    GetUserLang,
  } from "./index";
  import { HTTPOperationType } from "./httpOperations";
  
  export const HttpObjectService = {
    CREATE_HTTP_OBJECT,
    UPDATE_HTTP_OBJECT,
    REMOVE_HTTP_OBJECT,
    STEPBYLOAD,
    CLONE_HTTP_OBJECT,
  };

/* 
    public string URL { get; set; }

        public string DevURL { get; set; }

*/

function CLONE_HTTP_OBJECT(id,RelProjectId,HttpObjectName) {
    var js = {
      id:id,
      RelProjectId:RelProjectId,
      HttpObjectName:HttpObjectName
 
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + HttpObjectEndPoints.CLONE_HTTP_OBJECT,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 


//PAGE ID NULL VERIRSEN ALAYI GELIR.
  function CREATE_HTTP_OBJECT(RelProjectId,HttpObjectName,Headers,Body,DevHeaders,DevBody,SuccessScript,FailScript,SuccessDynamicObjects,FailDynamicObjects,URL,DevURL,HttpType) {
    var js = {
      RelProjectId:RelProjectId ,
      HttpObjectName:HttpObjectName ,
      Headers:Headers,
      Body:Body,
      DevHeaders:DevHeaders,
      DevBody:DevBody,
      SuccessScript:SuccessScript,
      FailScript:FailScript,
      SuccessDynamicObjects:SuccessDynamicObjects,
      FailDynamicObjects,
      URL,
      DevURL,
      HttpType
    };
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + HttpObjectEndPoints.CREATE_HTTP_OBJECT,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function UPDATE_HTTP_OBJECT(id,RelProjectId,HttpObjectName,Headers,Body,DevHeaders,DevBody,SuccessScript,FailScript,SuccessDynamicObjects,FailDynamicObjects,URL,DevURL, HttpType) {
    var js = {
      id:id,
      RelProjectId:RelProjectId ,
      HttpObjectName:HttpObjectName ,
      Headers:Headers,
      Body:Body,
      DevHeaders:DevHeaders,
      DevBody:DevBody,
      SuccessScript:SuccessScript,
      FailScript:FailScript,
      SuccessDynamicObjects:SuccessDynamicObjects,
      FailDynamicObjects,
      URL,
      DevURL,
      HttpType
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + HttpObjectEndPoints.UPDATE_HTTP_OBJECT,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function REMOVE_HTTP_OBJECT(id,RelProjectId) {
    var js = {
      id:id,
      RelProjectId:RelProjectId 
 
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + HttpObjectEndPoints.REMOVE_HTTP_OBJECT,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  } 

  function STEPBYLOAD(Count,DynamicObj,IsOrderByDesc,LastIndexId,SearchTerm) {
    var js = {
        Count: Count,
        DynamicObj:DynamicObj,// null,
        IsOrderByDesc: IsOrderByDesc,
        LastIndexId: LastIndexId,//null,
        SearchTerm: SearchTerm
    };
  
    return MakeReqestAndGetResponse(
      GetBaseCreatorURL() + HttpObjectEndPoints.STEPBYLOAD,
      HTTPOperationType.post,
      js
    ).then((res) => {
      if (res.operationResult === false) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
  }