import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'rsuite';
import { Translation } from "react-i18next";
import i18n from 'i18n';
import Emitter from "_actions/emitter";
import { Loader, Alert, Icon } from 'rsuite';
import {
    setProjectId,
    setProjectData,
    setReset
} from "_actions";
import { Tooltip, Whisper } from 'rsuite';

class ProjectCard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            img: null,
            isCloneModal: false,
        }
        this.myRef = React.createRef();
    }

    handleOpenProject = () => {
        const { setProjectId, isChangeProject, data, handleClose, isBussy, setProjectData, setReset } = this.props;
        if (!isBussy) {
            if (isChangeProject) { setReset(); }
            setProjectId(data.id);
            setProjectData(data);
            Emitter.emit("UPDATE_PROJECT_ID", null);
            handleClose();
        }


    }

    handleOpenSettings = () => {
        const { data } = this.props;
        this.props.customHistory.push("project-settings", data)
        // this.props.customHistory.push("project-settings")

    }
    handleOpenAnalyzer = () => {
        Alert.warning(i18n.t("ERROR_ITS_NOT_WORKING"))

    }


    handleCloneModal = () => {
        this.setState({
            isCloneModal: !this.state.isCloneModal
        })
    }
    handleDelete = () => {
        Alert.warning(i18n.t("ERROR_ITS_NOT_WORKING"))

    }

    componentDidMount = () => {
        this.getMyIdealImage();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.isViewer) {
            if (JSON.stringify(prevProps.currentProject) !== JSON.stringify(this.props.currentProject)) {
                this.getMyIdealImage();
            }
        }
    }

    getMyIdealImage = () => {
        const { currentProject, data, isViewer } = this.props;
        try {
            const renderedImages = isViewer ? (currentProject && currentProject.setting.icons) : data.setting.icons;
            let current = this.myRef.current;
            this.setState({ loading: true, })
            if (!renderedImages) {
                this.setState({ loading: false, })
                return;
            }
            if (renderedImages == null) {
                this.setState({ img: null, loading: false, })
                return
            }
            else if (renderedImages.length == 0) {
                this.setState({ img: null, loading: false, })
                return
            }

            if (current != null) {
                let w = this.myRef.current.offsetWidth;
                let h = this.myRef.current.offsetHeight;
                if (renderedImages == null || renderedImages.length < 1) {
                    this.setState({ loading: false, })
                    return;
                }

                let closest = null;
                try {
                    if (w > h) {
                        closest = renderedImages.reduce(function (prev, curr) {
                            let _x = curr.width;
                            let _y = curr.height;
                            let _px = prev.width;
                            let _py = prev.height;
                            return (Math.abs(_x - w) < Math.abs(_px - w) ? curr : prev);
                        });
                    } else {
                        closest = renderedImages.reduce(function (prev, curr) {
                            let _x = curr.width;
                            let _y = curr.height;
                            let _px = prev.width;
                            let _py = prev.height;
                            return (Math.abs(_y - h) < Math.abs(_py - h) ? curr : prev);
                        });
                    }
                } catch (error) {
                    //console.log(error);
                }
                if (closest != null) { this.setState({ img: closest.url, loading: false, }) }

            }
        } catch (error) {
            this.setState({ img: null, loading: false, })
        }
    }

    render() {
        const { data, isBussy, isViewer, currentProject } = this.props;
        const { loading, img } = this.state;
        const tooltip = !isViewer && (
            <Tooltip>
                {data.projectName}
            </Tooltip>
        );

        return (
            <Translation>
                {t => (
                    <>
                        {
                            isViewer ?
                                <div aria-label={data ? data.projectName : ""} className={"project-container1 custom-fade-in"} style={this.props.isHeader && {
                                    padding: 0,
                                }} >
                                    {
                                        !img ?
                                            <div ref={this.myRef} className={this.props.isHeader ? "header-project-null-img" : "project-null-img" + " eproject-img"}>
                                                +
                                                {
                                                    loading &&
                                                    <Loader backdrop vertical />
                                                }
                                            </div>
                                            :
                                            <div ref={this.myRef} className={this.props.isHeader ? "header-project-img" : "project-img" + " eproject-img"}
                                                style={{
                                                    backgroundImage: "url(" + img + ")",
                                                }}>
                                                {
                                                    loading &&
                                                    <Loader backdrop vertical />
                                                }
                                            </div>
                                    }
                                    {
                                        this.props.isHeader ? "" :
                                            <div className={"mt-2 project-title eproject-img"}>
                                                {currentProject && currentProject.projectName}
                                            </div>
                                    }

                                </div>
                                :
                                <Whisper placement="bottom" trigger="hover" speaker={tooltip}>

                                    <div aria-label={data ? data.projectName : ""} className={"project-container custom-fade-in"} >
                                        {
                                            !img ?
                                                <div ref={this.myRef} className={"project-null-img"} onClick={() => { this.handleOpenProject() }}>
                                                    +
                                                    {
                                                        loading &&
                                                        <Loader backdrop vertical />
                                                    }
                                                </div>
                                                :
                                                <div ref={this.myRef} className={"project-img"}
                                                    onClick={() => { this.handleOpenProject() }}
                                                    style={{
                                                        backgroundImage: "url(" + img + ")",
                                                    }}>
                                                    {
                                                        loading &&
                                                        <Loader backdrop vertical />
                                                    }
                                                </div>
                                        }

                                        <div className={"mt-2 project-title"}>
                                            {data && data.projectName}
                                        </div>
                                    </div>

                                </Whisper>
                        }


                    </>

                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    const { currentstatesReducer } = state;
    const { customHistory, currentProject } = currentstatesReducer;

    return {
        customHistory,
        currentProject
    };

}

const mapDispatchToProps = (dispatch) => {
    return {
        setProjectId: (_id) => dispatch(setProjectId(_id)),
        setProjectData: (_data) => dispatch(setProjectData(_data)),
        setReset: () => dispatch(setReset()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCard)
