import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { Translation } from "react-i18next";

import { updateComponent, updateListSocket, setPage, removeComponent, setUIEditorStatus } from '_actions';
import { TreePicker, Icon } from 'rsuite';
import { findObjUpdate } from 'assets/util';

class HttpObjectSelectorDynamic extends Component {
    constructor(props) {
        super(props)

        const { component, fieldName } = props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style, value } = object.props;
        const _field = object.type === "layout" ? "dynamicData" : "data";
        this.handleOpen = this.handleOpen.bind(this);
        this.handleOnExpand = this.handleOnExpand.bind(this);
        this.handleClose = this.handleClose.bind(this);
        let data = null; //object[_field];
        if (fieldName) {
            data = object[_field].hasOwnProperty(fieldName) ? object[_field][fieldName] : null;

        } else {
            data = object[_field];
        }

        this.state = {
            httpObjectsData: [],
            httpObjectDisabledData: [],
            data: data
        }
    }

    handleToggleHttpObject = (_data) => {
        const that = this;
        const { fieldName } = this.props;

        let _clone = Object.assign({}, this.props.component);
        let { object } = _clone.hasOwnProperty("componentOwner") ? _clone.object.components[0] : _clone.components[0];

        const _field = object.type === "layout" ? "dynamicData" : "data";

        if (fieldName) {
            object[_field][fieldName] = _data;

        } else {
            object[_field] = _data;
        }
 
        let _newEditor = JSON.parse(JSON.stringify(this.props.editor))
        let _result = findObjUpdate(_newEditor, _clone);

        this.props.updateComponent(_clone);
        this.props.onUpdateList(_result);

        this.setState({
            data: _data,
        })
    }

    componentDidUpdate = (prevProps) => {
        
        const { component, fieldName } = this.props;
        const { object } = component.hasOwnProperty("componentOwner") ? component.object.components[0] : component.components[0];
        const { style } = object.props;

        const _field = object.type === "layout" ? "dynamicData" : "data";
        let data = object[_field][fieldName]

        if (prevProps.component != component) {
             
            this.setState({
                data: data,
            })
        }
    }

    handleOpen = () => {
        setTimeout(() => {
            const { component, data, dataType, isProperty } = this.props;
            const that = this;
            let result = data;
            this.setState({
                httpObjectsData: result,
                httpObjectDisabledData: this.findDisabledData(result, dataType)
            })
        }, 100);
    }


    handleOnExpand = (expandItemValues, activeNode, concat) => {

    }



    componentDidMount = () => {
        const that = this;
        setTimeout(() => {
            const { component, data, dataType, isProperty } = that.props;
        
            let result = data;
          //  debugger
          that.setState({
                httpObjectsData: result,
                httpObjectDisabledData: that.findDisabledData(result, dataType)
            })
        }, 1200);
    }


    handleClose = () => {
        /*  this.setState({
              httpObjectsData: [],
              httpObjectDisabledData: []
          })
          */
    }

    findDisabledData = (mainState, valToFind) => {
        let _ms = mainState;

        if (mainState) {
            let foundObj = [];
            let aa = JSON.stringify(_ms, (_, nestedValue) => {
                if (nestedValue && nestedValue.type && !nestedValue.type.includes(valToFind)) {
                    foundObj.push(nestedValue.value);
                }
                return nestedValue;
            });
            return foundObj;
        } else {
            return null;
        }
    }

    render() {
        const { component, data, dataType, typeName, fieldName, } = this.props;
        const { httpObjectsData, httpObjectDisabledData } = this.state;


        return (
            <Translation>
                {t => (
                    <>
                        <TreePicker
                            // defaultExpandAll
                            placement={"autoVerticalStart"}
                            className={"custom-fade-in"}
                            //height={160}
                            onOpen={this.handleOpen}
                            //onExpand={this.handleOnExpand}
                            onClose={this.handleClose}
                            // virtualized
                            renderMenu={menu => {
                                debugger
                                if (httpObjectsData.length === 0) {
                                    return (
                                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                            <Icon icon="spinner" spin />
                                        </p>
                                    )
                                }
                                return menu;

                            }}
                            data={Array.isArray(httpObjectsData) ? httpObjectsData : []}
                            disabledItemValues={Array.isArray(httpObjectDisabledData) ? httpObjectDisabledData : []}
                            style={{ width: "100%" }}
                            defaultValue={this.state.data ? this.state.data.value : null}
                            onSelect={(activeNode, value) => {    
                                const _p = activeNode.path.split("-");
                                debugger
                                // const aa = httpObjects.dynamicValue.find(x => x.httpObjectName === _p[0]);
                                let _obj = {
                                    type: typeName,
                                    name: _p[0],
                                    path: activeNode.path,
                                    value: value,
                                   
                                }
                                if(activeNode.children){
                                    _obj["children"]=activeNode.children
                                }
                                this.handleToggleHttpObject(_obj);

                            }}
                            onClean={() => {
                                this.handleToggleHttpObject(null);
                            }}
                            renderTreeNode={(nodeData) => {
                                let _nData = null;
                                try {
                                    _nData =(
                                        <div className={"ho-tree-node"}>
                                          <p>{nodeData.label}</p>
                                          <span>{" (" + nodeData.type + ")"}</span>
                                        </div>
                                      );
                                } catch (error) {
                                    const _ccc = nodeData;
                                    debugger
                                }
                               
                
                                return _nData;
                              }}
                        />
                    </>
                )}
            </Translation>
        )
    }
}

//export default InputGenerator
const mapStateToProps = (state) => {
    const { componentReducer, editorReducer, currentstatesReducer } = state;
    const { component } = componentReducer;
    const { currentProjectId, uiEditorStatus, httpObjects } = currentstatesReducer;

    const { present } = editorReducer;
    const { editor, page } = present;
    return {

        component,
        httpObjects,
        editor
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
        removeComponent: () => dispatch(removeComponent()),
        setPage: (_data) => dispatch(setPage(_data)),
        onUpdateList: (_data) => dispatch(updateListSocket(_data)),
        updateComponent: (_data) => dispatch(updateComponent(_data)),
        setUIEditorStatus: (_data, _component) => dispatch(setUIEditorStatus(_data, _component)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HttpObjectSelectorDynamic)
