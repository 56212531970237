import React, { Component } from "react";
import { connect } from "react-redux";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import { Translation } from "react-i18next";
import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "reactstrap";

import {
  updateComponent,
  updateListSocket,
  setPage,
  removeComponent,
  setUIEditorStatus,
} from "_actions";
import { TreePicker } from "rsuite";
import HttpObjectSelector from "components/HttpObjects/HttpObjectSelector";
import HttpObjectSelectorDynamic from "components/HttpObjects/HttpObjectSelectorDynamic";

import { PropertyTitle } from "../Core/";
import StaticCMSSettings from "../Properties/StaticCMSSettings";
import UISelector from "components/RigthSidebar/UISettings/UISelector";

class SelectPickerSettings extends Component {
  constructor(props) {
    super(props);

    const { component } = props;
    const { object } = component.hasOwnProperty("componentOwner")
      ? component.object.components[0]
      : component.components[0];
    const { style } = object.props;
    const { listItem } = object;

    this.state = {
      ui: listItem,
      httpObjectsData: [],
      httpObjectDisabledData: [],
      isHttpObjectsData: false,
    };
  }

  handleToggleHttpObject = () => {
    const { isHttpObjectsData } = this.state;
    this.setState({
      isHttpObjectsData: !isHttpObjectsData,
    });
  };

  componentDidUpdate = (prevProps) => {
    const { component } = this.props;
    const { object } = component.hasOwnProperty("componentOwner")
      ? component.object.components[0]
      : component.components[0];
    const { style } = object.props;
    const { listItem } = object;

    if (prevProps.component != component) {
      this.setState({
        ui: listItem,
      });
    }
  };

  componentDidMount = async () => {
    const { httpObjects } = this.props;
    let result = await this.prepareCascaderData(httpObjects.dynamicValue);

    this.setState({
      httpObjectsData: result,
      httpObjectDisabledData: this.findDisabledData(result, "array"),
    });
  };

  handleTest = (_field = "listItem") => {
    const {
      setUIEditorStatus,
      uiEditorStatus,
      onUpdateList,
      clearHistory,
      setPage,
      component,
      removeComponent,
      page,
      editor,
    } = this.props;

    const _currentEditor = Object.assign({}, editor);
    const _currentPage = Object.assign({}, page);
    const _currentComponent = Object.assign({}, component);

    let _currentComponentData = null;

    if (component.hasOwnProperty("componentOwner")) {
      _currentComponentData = Object.assign({}, component.object.components[0]);
    } else {
      _currentComponentData = Object.assign({}, component.components[0]);
    }

    if (uiEditorStatus) {
      /*
                ! UI Editor Kapatılacaktır.
                Mevcut sayfa içeriği bir diğer değişken içerisine veri kaybı 
                olmaması için aktarılacaktır.
            */
      _currentPage.detail = _currentEditor;
      //  setPage(_currentPage); // Editor
      onUpdateList(
        _currentComponentData.object[_field]
          ? [_currentComponentData.object[_field]]
          : []
      );
      clearHistory();
      removeComponent();
      setUIEditorStatus(true, { ..._currentComponent, _field: _field }, _field);
    } else {
      /*
                ! UI Editor Açılacak.
                Mevcut sayfa içeriği bir diğer değişken içerisine veri kaybı 
                olmaması için aktarılacaktır.
            */
      // debugger
      _currentPage.detail = _currentEditor;
      setPage(_currentPage); // Editor
      onUpdateList(
        _currentComponentData.object[_field]
          ? [_currentComponentData.object[_field]]
          : []
      );
      clearHistory();
      removeComponent();
      setUIEditorStatus(true, { ..._currentComponent, _field: _field }, _field);
    }
  };

  prepareCascaderData = async (_data) => {
    let _array = [];

    await _data.map(async (item, key) => {
      let _obj = {
        value: key + "", //item.id,
        label: item.httpObjectName,
        children: [],
      };
      let detail = JSON.parse(item.successDynamicObjects);
      if (Array.isArray(detail)) {
        _obj["type"] = "array";
        _obj["children"] = await this.generateCascaderChild(
          "array",
          detail,
          key
        );
      } else if (typeof detail == "object") {
        _obj["type"] = typeof detail;
        _obj["children"] = await this.generateCascaderChild(
          "object",
          detail,
          key
        );
      } else {
        _obj["type"] = typeof detail;
      }
      _array.push(_obj);
    });
    return _array;
  };

  generateCascaderChild = async (_type, _value, _parentKey) => {
    let _array = [];

    await (_type == "array" ? _value : Object.values(_value)).map(
      async (item, key) => {
        let _obj = {
          //  label: "[index]",
          value: _parentKey + "-" + key,
        };
        if (Array.isArray(item)) {
          _obj.label = Object.keys(_value)[key];
          //  debugger

          try {
            _obj["children"] =
              item[0] === null
                ? []
                : await this.generateCascaderChild(
                    "array",
                    item,
                    _parentKey + "-" + key
                  );
          } catch (error) {
            debugger;
          }
          _obj["type"] = "array";
        } else if (typeof item == "object") {
          // debugger
          _obj.label = Object.keys(_value)[key];
          _obj["type"] = typeof item;
          try {
            if (Object.keys(item).length > 0)
              _obj["children"] = await this.generateCascaderChild(
                "object",
                item,
                _parentKey + "-" + key
              );
          } catch (error) {
            debugger;
          }
        } else {
          _obj["label"] = Object.keys(_value)[key];
          _obj["type"] = typeof item;
        }

        _array.push(_obj);
      }
    );

    return _array;
  };

  findDisabledData = (mainState, valToFind) => {
    let _ms = mainState;

    if (mainState) {
      let foundObj = [];
      let aa = JSON.stringify(_ms, (_, nestedValue) => {
        if (nestedValue && nestedValue.type !== valToFind) {
          foundObj.push(nestedValue.value);
        }
        return nestedValue;
      });
      return foundObj;
    } else {
      return null;
    }
  };

  render() {
    const { component, httpObjects } = this.props;
    const { httpObjectsData, httpObjectDisabledData } = this.state;
    const { object } = component.hasOwnProperty("componentOwner")
      ? component.object.components[0]
      : component.components[0];

    return (
      <Translation>
        {(t) => (
          <>
            <FormGroup className={"property-form-group"}>
              <div className={"propertyTitleDiv"}>
                <label
                  htmlFor="exampleFormControlInput1"
                  className={"propertyTitle"}
                >
                  {t("PROPERTY_PLACEHOLDER")}
                </label>
              </div>
              <StaticCMSSettings propName={"placeholder"} />
            </FormGroup>
            <Form>
              <FormGroup className={"property-form-group"}>
                <PropertyTitle
                  title={t("PROPERTY_SELECTPICKER_COLLECTION")}
                  subtitle={""}
                />
                <HttpObjectSelector
                  component={null}
                  data={httpObjects.dynamicValue}
                  dataType={"array"}
                  typeName={"httpobject"}
                  key={"1-http-items"}
                />
              </FormGroup>
            </Form>

            {object &&
              object.data &&
              (Array.isArray(object.data) ? object.data.length > 0 : true) &&
              object.data.children.length > 0 &&
              object.data.children[0].children.length > 0 && (
                <>
                  <Form>
                    <FormGroup className={"property-form-group"}>
                      <PropertyTitle
                        title={t("PROPERTY_SELECTPICKER_ITEMLABEL")}
                        subtitle={""}
                      />
                      <div >
                        <HttpObjectSelectorDynamic
                          mantar={object.data}
                          fieldName={"itemLabel"}
                          isProperty={true}
                          typeName={"indexdata"}
                          component={null}
                          data={
                            object.data ? object.data.children[0].children : []
                          }
                          dataType={"string"}
                        />
                      </div>
                    </FormGroup>
                  </Form>
                  <Form>
                    <FormGroup className={"property-form-group"}>
                      <PropertyTitle
                        title={t("PROPERTY_SELECTPICKER_ITEMVALUE")}
                        subtitle={""}
                      />
                      <div >
                        <HttpObjectSelectorDynamic
                          mantar={object.data}
                          fieldName={"itemValue"}
                          isProperty={true}
                          typeName={"indexdata"}
                          component={null}
                          data={
                            object.data ? object.data.children[0].children : []
                          }
                          dataType={"string"}
                          //key={"1-itemvalue" + object.data.path}
                        />
                      </div>
                    </FormGroup>
                  </Form>
                </>
              )}
          </>
        )}
      </Translation>
    );
  }
}

//export default InputGenerator
const mapStateToProps = (state) => {
  const { componentReducer, editorReducer, currentstatesReducer } = state;
  const { component } = componentReducer;
  const { currentProjectId, uiEditorStatus, httpObjects } =
    currentstatesReducer;

  const { present } = editorReducer;
  const { editor, page } = present;
  return {
    page,
    editor,
    component,
    uiEditorStatus,
    currentProjectId,
    httpObjects,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearHistory: () => dispatch(UndoActionCreators.clearHistory()),
    removeComponent: () => dispatch(removeComponent()),
    setPage: (_data) => dispatch(setPage(_data)),
    onUpdateList: (_data) => dispatch(updateListSocket(_data)),
    setUIEditorStatus: (_data, _component) =>
      dispatch(setUIEditorStatus(_data, _component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectPickerSettings);
